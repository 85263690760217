import * as _ from 'lodash';
import { filter } from 'rxjs/operators';

import { IntegrationListModel } from '../../../../models/Integration/IntegrationListModel';
import { BehaviorSubject, Observable } from 'rxjs';
import { IntegrationSourceModel } from '../../../../models/Integration/IntegrationSourceModel';

export class RentaIntegrationListFilterDataSource {
  public loading: boolean = false;
  private selectedIntegration: IntegrationSourceModel;
  private integrationList: Array<IntegrationListModel> = [];
  private availableSource: Array<IntegrationSourceModel> = [];
  private sourceData: Array<IntegrationSourceModel> = null;
  private dataSource: BehaviorSubject<Array<IntegrationSourceModel>> = new BehaviorSubject<Array<IntegrationSourceModel>>(null);

  constructor(private selected: IntegrationSourceModel) {
    this.selectedIntegration = selected;
  }

  public connect(): Observable<Array<IntegrationSourceModel>> {
    this.updateDataSource();
    return this.dataSource.asObservable().pipe(filter((f: Array<IntegrationSourceModel>): boolean => f !== null));
  }

  public setSelectedIntegration(selected: IntegrationSourceModel): void {
    this.selectedIntegration = selected;
  }

  public getSelectedIntegration(): IntegrationSourceModel {
    return this.selectedIntegration;
  }

  public containsSelectedIntegration(): boolean {
    const result = this.sourceData.find(
      (f: IntegrationSourceModel): boolean => f.integrationType === this.selectedIntegration.integrationType
    );
    return !_.isNil(result);
  }

  public refreshIntegrationList(arr: Array<IntegrationListModel>): void {
    this.integrationList = arr || [];
    this.setIntegrations();
  }

  public refreshAvailableSource(arr: Array<IntegrationSourceModel>): void {
    this.availableSource = arr || [];
    this.setIntegrations();
  }

  public setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  private setIntegrations(): void {
    if (this.availableSource.length === 0 && this.integrationList.length === 0) {
      return;
    }

    const validIntegrations = [];
    this.availableSource.forEach((elem: IntegrationSourceModel): void => {
      if (!_.isNil(this.integrationList.find((f: IntegrationListModel): boolean => f.integrationType === elem.integrationType))) {
        validIntegrations.push(elem);
      }
    });

    this.refresh(validIntegrations);
  }

  private refresh(dataSource: Array<IntegrationSourceModel> = null): void {
    if (dataSource !== null) {
      this.sourceData = dataSource;
    }

    this.updateDataSource();
  }

  private updateDataSource(): void {
    this.dataSource.next(this.sourceData);
  }
}
