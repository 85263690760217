import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RentaInputDataSource } from '../../models/DataSource/RentaInputDataSource';
import { Observable } from 'rxjs';
import {
  IconOptions,
  IconPositionEnum,
  RentaInputDataSourceModel,
} from '../../models/DataSource/DataSourceModels/RentaInputDataSourceModel';
import { transliterate } from '../../../../helpers/helpers';
import * as _ from 'lodash';

@Component({
  selector: 'app-renta-input',
  templateUrl: './renta-input.component.html',
  styleUrls: ['../internal/base-settings-styles.css'],
})
export class RentaInputComponent implements OnInit {
  @Input()
  public toolTipInfo: string;

  @Input()
  public RentaInputSource: RentaInputDataSource;

  @Output()
  public OnApply: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public additionalInfo: string ;

  @Input()
  public IsShowName: boolean = true;

  @Input()
  public IsTransliterate: boolean;

  @ViewChild('rentaInput', { static: false })
  public element: ElementRef;

  public ds: Observable<RentaInputDataSourceModel>;

  constructor() {}

  public ngOnInit(): void {
    this.ds = this.RentaInputSource.connect();
    const val = this.RentaInputSource.getValue();
    if (_.some(val)){
      this.onSetChange(val);
    }
  }

  public getId(name: string): string {
    return name.replace(/ /g, '_') + 'DataSource';
  }

  public getIconPosition(iconOptions: IconOptions): string {
    return iconOptions.position !== IconPositionEnum.none ? 'p-input-icon-' + iconOptions.position.toString() : '';
  }

  public getIcon(iconOptions: IconOptions): string {
    if (iconOptions.position === IconPositionEnum.none) {
      return 'p-d-none';
    }

    return iconOptions.icon ? iconOptions.icon : 'pi-pencil';
  }

  public onChange(val: any): void {
    if (!this.IsTransliterate) {
      return;
    }

    const transVal = transliterate(val);
    const prevPosStart = this.element.nativeElement.selectionStart;
    const prevPosEnd = this.element.nativeElement.selectionEnd;
    this.RentaInputSource.refreshValue(transVal);
    setTimeout((): void => {
      this.element.nativeElement.setSelectionRange(prevPosStart, prevPosEnd, 'none');
    });
  }

  public onSetChange(value: any): void {
    this.OnApply.emit(value);
  }
}
