<div *ngIf="ds | async; let dataSource">
  <div class="settings-component-container p-d-flex p-flex-column" *ngIf="dataSource.length > 0">
    <div class="setting-title-wrapper">
      <span class="renta-font-normal setting-title-font">{{DataSource.name}}</span>
    </div>
    <div [sortablejs]="ds | async" [sortablejsOptions]="sortableOptions" [class.p-disabled]='DataSource.loading'
         class="p-d-flex p-flex-lg-wrap chips-container">
      <div *ngFor="let chip of ds | async" class="p-d-flex p-ai-center chip mr-l-5" [ngClass]="getClass(chip.type)">
        <span class="chip-title renta-font-small">{{chip.title}}</span>
        <i class="close-btn-wrapper close-btn pi pi-times" (click)="closeChip(chip)"></i>
      </div>
    </div>
  </div>
</div>
