import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationModalModel } from '../../../../../../models/Integration/ConfirmationModalModel';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  public integration: ConfirmationModalModel;

  constructor(private readonly ref: DynamicDialogRef, private readonly config: DynamicDialogConfig) {}

  public ngOnInit(): void {
    this.integration = this.config.data;
    this.setDefault();
  }

  public onNo(): void {
    this.ref.close(false);
  }

  public onYes(): void {
    this.ref.close(true);
  }

  private setDefault(): void {
    this.integration.successBtn = this.integration.successBtn ? this.integration.successBtn : 'YES';
    this.integration.cancelBtn = this.integration.cancelBtn ? this.integration.cancelBtn : 'NO';
  }
}
