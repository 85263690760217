<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="mr-b-5">
    <app-renta-error-message [errorMessage]="error" *ngIf="error"></app-renta-error-message>
  </div>

  <label for="serverName" class="p-d-none"></label>
  <input
    id="serverName"
    type="text"
    appTextInputTrim
    pInputText
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    placeholder="Server name"
    [(ngModel)]="ServerName"
    [disabled]="loading"
  />

  <label for="dbName" class="p-d-none"></label>
  <input
    id="dbName"
    type="text"
    appTextInputTrim
    pInputText
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    placeholder="Database Name"
    [(ngModel)]="DatabaseName"
    [disabled]="loading"
  />

  <label for="warehouse" class="p-d-none"></label>
  <input
    id="warehouse"
    type="text"
    appTextInputTrim
    pInputText
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    placeholder="Warehouse"
    [(ngModel)]="Warehouse"
    [disabled]="loading"
  />

  <label for="login" class="p-d-none"></label>
  <input
    id="login"
    type="text"
    appTextInputTrim
    pInputText
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    placeholder="Login"
    [(ngModel)]="Login"
    [disabled]="loading"
  />

  <label for="dbPwd" class="p-d-none"></label>
  <input
    id="dbPwd"
    type="password"
    appTextInputTrim
    pInputText
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    placeholder="Database Password"
    [(ngModel)]="DatabasePassword"
    [disabled]="loading"
  />

  <label for="schema" class="p-d-none"></label>
  <input
    id="schema"
    type="text"
    appTextInputTrim
    pInputText
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    placeholder="Schema"
    [(ngModel)]="Schema"
    [disabled]="loading"
  />

  <span class="ip-connection-info ap-ta-center renta-font-normal">
    You should allow connection from next IP: 13.94.215.246, 116.203.213.108
  </span>
  <a href="https://renta.im/docs/destination/snowflake" class="renta-href check-dock" target="_blank">Check
    documentation for
    details</a>
</div>
