import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { UserInfo } from '../models/user/UserInfo';
import { ApiResponse } from '../models/common/ApiResponse`T';
import { IntegrationSourceModel } from '../models/Integration/IntegrationSourceModel';
import { IntegrationToken } from '../models/Integration/IntegrationToken';
import { IntegrationTokensModel } from '../models/Integration/IntegrationTokensModel';
import { AvailableIntegrationsModel } from '../models/Integration/AvailableIntegrationsModel';
import { IntegrationGroupEnum, IntegrationTypeEnum } from '../models/common/IntegrationTypeEnum';
import { IntegrationSettings } from '../models/Integration/IntegrationSettings';
import { IntegrationEditInfo } from '../models/Integration/IntegrationEditInfo';
import { StorageService } from './storage.service';
import { BaseResponse } from '../models/common/BaseResponse';
import { IntegrationInfoData } from '../models/Integration/integrationInfoData';
import { ProfileModel, TimeZoneModel } from '../modules/shared/models/ProfileModel';
import { IntegrationListModel } from '../models/Integration/IntegrationListModel';
import { environment } from '../../environments/environment';
import { InvoicesModel } from '../modules/manage/models/InvoicesModel';
import { PromoCodePayload } from '../modules/manage/modules/billing/models/requests/PromoCodePayload';
import { BillingPayload } from '../modules/manage/modules/billing/models/requests/BillingPayload';
import { NotificationHubPayload, AddNotificationHubEmailPayload, NotificationHubType, NotificationHubUpdateState} from '../notification-hub/models/requests/NotificationHubPayload';
import { ProductionLogsRun, ProductionLogsRunActions } from '../integration-overview/models/request/IntegrationOverviewPayloads';
import { Token } from '@stripe/stripe-js';
import * as _ from 'lodash';
import { ErrorMessage } from '../models/common/ErrorMessage';
import { IBaseWizardRequest } from '../models/common/BaseWizardRequest';
import { UserDataIntercomService } from './user-data-intercom-service';

@Injectable({
  providedIn: 'root'
})
export class RentaApiService {
  constructor(public readonly httpClient: HttpClient,
              public readonly storageService: StorageService,
              public readonly userDataIntercomService: UserDataIntercomService) {
  }

  public getAvailableIntegrationSource(withAccounts: boolean = true): Observable<Array<IntegrationSourceModel>> {
    const cachedAvailable = this.storageService.sessionGetItem<Array<IntegrationSourceModel>>('AvailableIntegrationSource');

    if (cachedAvailable) {
      return of<Array<IntegrationSourceModel>>(cachedAvailable).pipe(
        map<Array<IntegrationSourceModel>, Array<IntegrationSourceModel>>(
          (res: Array<IntegrationSourceModel>): Array<IntegrationSourceModel> => {
            return this.mapAvailableIntegrations(res);
          }
        )
      );
    } else {
      return this.httpClient.get(environment.baseApiUrl + environment.getAvailableSource + (withAccounts ? '/all' : '')).pipe(
        map<ApiResponse<AvailableIntegrationsModel>, Array<IntegrationSourceModel>>(
          (res: ApiResponse<AvailableIntegrationsModel>): Array<IntegrationSourceModel> => {
            return this.mapAvailableIntegrations(res.result.data);
          }
        )
      );
    }
  }

  public getAvailableIntegrationDestinations(sourceType: IntegrationTypeEnum, withAccounts: boolean = true): Observable<Array<IntegrationSourceModel>> {
    return this.httpClient.get(environment.baseApiUrl + environment.getAvailableDestination + (withAccounts ? '/all' : '')).pipe(
      map<any, Array<IntegrationSourceModel>>((res: ApiResponse<any>): Array<IntegrationSourceModel> => {
        return res.result.data.filter((f: IntegrationSourceModel): boolean => !withAccounts || this.isDestinationInGroup(f.group, sourceType) && f.available).map(
          (item: IntegrationSourceModel): IntegrationSourceModel => ({
            available: item.available,
            icon: item.icon,
            integrationType: item.integrationType,
            title: item.title,
            accounts: item.accounts,
            oAuthUrl: item.oAuthUrl,
            tokens: of<Array<IntegrationToken>>(null)
          })
        );
      })
    );
  }

  public signIn(email: string, password: string, recapchaToken: string, isSignUp: boolean): Observable<ApiResponse<{}>> {
    return this.httpClient.post<ApiResponse<UserInfo>>(environment.baseApiUrl + environment.signIn, {
      email, password, recapchaToken, isSignUp
    }).pipe(tap((res: ApiResponse<UserInfo>): void => {
      if (res.result) {
        this.storageService.localUpdateItem('userInfo', res.result);
        this.userDataIntercomService.setUserData(res.result.userEmail, res.result.userId);
      }
    }));
  }

  public getUserInfo(): Observable<ApiResponse<UserInfo>> {
    return this.httpClient.get<ApiResponse<UserInfo>>(environment.baseApiUrl + environment.getUserInfo).pipe(
      catchError(this.errorHandler)
    );
  }

  public errorHandler(error: HttpErrorResponse): Observable<ApiResponse<any>> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return of<ApiResponse<UserInfo>>(null);
  }

  public getIntegrationTokens(integrationType: IntegrationTypeEnum): Observable<Array<IntegrationToken>> {
    return this.httpClient
      .get<ApiResponse<IntegrationTokensModel>>(`${environment.baseApiUrl}${environment.integrationTokens}${integrationType}`)
      .pipe(
        map<any, Array<IntegrationToken>>((res: ApiResponse<any>): Array<IntegrationToken> => {
          return res.result.data.map(
            (item: IntegrationToken): IntegrationToken => ({
              title: item.title,
              additionalInfo: item.additionalInfo,
              error: item.error || null,
              icon: item.icon,
              iconColor: item.iconColor,
              id: item.id,
              oAuthUrl: res.result.meta != null ? res.result.meta.oAuthUrl : null
            })
          );
        }),
        tap((accounts: Array<IntegrationToken>): void => {
          const avIntegr: Array<IntegrationSourceModel> =
            this.storageService.sessionGetItem<Array<IntegrationSourceModel>>('AvailableIntegrationSource');

          if (!_.isNil(avIntegr) && avIntegr.length > 0) {
            avIntegr.forEach((e: IntegrationSourceModel): void => {
              if (e.integrationType !== integrationType) {
                return;
              }

              e.accounts = accounts.length;
            });
          }

          this.storageService.sessionUpdateItem('AvailableIntegrationSource', avIntegr);
        })
      );
  }

  public initIntegration(wizardData: IBaseWizardRequest): Observable<IntegrationSettings> {
    return this.httpClient
      .post<ApiResponse<IntegrationSettings>>(
        environment.baseApiUrl + environment.integrationWizard.replace('{integrationGroup}', this.getIntegrationTypePart(wizardData.sourceTokenType)).replace('{integrationType}', wizardData.sourceTokenType),
        wizardData
      )
      .pipe(
        map<ApiResponse<IntegrationSettings>, any>((res: ApiResponse<IntegrationSettings>): IntegrationSettings => {
          return res.result;
        })
      );
  }

  public loadIntegration(data: IBaseWizardRequest): Observable<IntegrationSettings> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.baseApiUrl}/integration/${data.sourceTokenType}/${data.integrationId}`).pipe(
      map<ApiResponse<IntegrationSettings>, any>((res: ApiResponse<IntegrationSettings>): IntegrationSettings => {
        return res.result;
      })
    );
  }

  public validateResetPasswordCode(code: string): Observable<ApiResponse<{}>> {
    return this.httpClient
      .post<ApiResponse<{}>>(environment.baseApiUrl + environment.validateResetPassword, { code });
  }

  public resetPassword(password: string, code: string): Observable<ApiResponse<{}>> {
    return this.httpClient
      .post<ApiResponse<{}>>(environment.baseApiUrl + environment.resetPassword, { code, password });

  }

  public signUp(email: string, password: string, name: string, recapchaToken: string): Observable<ApiResponse<UserInfo>> {
    return this.httpClient
      .post<ApiResponse<UserInfo>>(environment.baseApiUrl + environment.signUp, {
        name,
        email,
        password,
        recapchaToken
      });

  }

  public forgotPassword(email: string, recapchaToken: string): Observable<ApiResponse<{}>> {
    return this.httpClient
      .post<ApiResponse<{}>>(environment.baseApiUrl + environment.forgotPassword, { email, recapchaToken });

  }

  public signOut(): Observable<ApiResponse<{}>> {
    return this.httpClient
      .get<ApiResponse<{}>>(environment.baseApiUrl + environment.signOut);

  }

  public saveToken(integrationType: IntegrationTypeEnum, body: any): Observable<ApiResponse<{}>> {
    return this.httpClient
      .post<ApiResponse<{}>>(`${environment.baseApiUrl}${environment.integrationTokens}${integrationType}`, body);

  }

  public updateToken(integrationType: IntegrationTypeEnum, id: string, body: any): Observable<ApiResponse<{}>> {
    return this.httpClient
      .put<ApiResponse<{}>>(`${environment.baseApiUrl}${environment.integrationTokens}${integrationType}/${id}`, body);

  }

  public removeToken(integrationType: IntegrationTypeEnum, tokenId: string): Observable<ApiResponse<{}>> {
    return this.httpClient
      .delete<ApiResponse<{}>>(`${environment.baseApiUrl}${environment.integrationTokens}${integrationType}/${tokenId}`);

  }

  public saveIntegration(integrationType: string, body: any): Observable<ApiResponse<{ integrationId: string }>> {
    return this.httpClient
      .post<ApiResponse<{
        integrationId: string
      }>>(`${environment.baseApiUrl}${environment.integrationAction.replace('{integrationGroup}', this.getIntegrationTypePart(integrationType))}${integrationType}`, body);

  }

  public integrationsList(): Observable<Array<IntegrationListModel>> {
    return this.httpClient
      .get<ApiResponse<{
        data: Array<IntegrationListModel>
      }>>(`${environment.baseApiUrl}${environment.integrationsList}`)
      .pipe(
        map((res: ApiResponse<any>): Array<IntegrationListModel> => res.result.data)
      );
  }

  public deleteIntegration(integrationId: string, integrationType: string): Observable<boolean> {
    return this.httpClient.delete<ApiResponse<any>>(`${environment.baseApiUrl}${environment.integrationAction.replace('{integrationGroup}', this.getIntegrationTypePart(integrationType))}${integrationId}`).pipe(
      map((res: ApiResponse<IntegrationInfoData>): boolean => res.baseResponse.status)
    );
  }


  public refreshIntegration(integrationId: string, integrationType: string): Observable<ApiResponse<any>> {
    const url = `${environment.baseApiUrl}/integration/${integrationType}/fullrefresh/${integrationId}`;
    return this.httpClient.get<ApiResponse<any>>(url);
  }

  public runIntegration(integrationId: string, integrationType: string): Observable<ApiResponse<any>> {
    const url = `${environment.baseApiUrl}/integration/${integrationType}/run/${integrationId}`;
    return this.httpClient.get<ApiResponse<any>>(url);
  }

  public updateIntegration(integrationId: string, integrationType: string, updateRequest: {
    enable: boolean
  }): Observable<boolean> {
    return this.httpClient
      .patch<ApiResponse<any>>(`${environment.baseApiUrl}${environment.integrationAction.replace('{integrationGroup}', this.getIntegrationTypePart(integrationType))}${integrationType}/${integrationId}`, updateRequest)
      .pipe(
        map((res: ApiResponse<IntegrationInfoData>): boolean => res.baseResponse.status)
      );
  }

  public getIntegrationInfo(integrationId: string, integrationType: string): Observable<IntegrationInfoData> {
    return this.httpClient
      .get<ApiResponse<IntegrationInfoData>>(`${environment.baseApiUrl}${environment.integrationAction.replace('{integrationGroup}', this.getIntegrationTypePart(integrationType))}${integrationId}`)
      .pipe(
        map((res: ApiResponse<IntegrationInfoData>): IntegrationInfoData => res.result)
      );
  }

  public getStorages(integrationType: IntegrationTypeEnum, id: string): Observable<Array<IntegrationSourceModel>> {
    return this.httpClient
      .get<ApiResponse<any>>(`${environment.baseApiUrl}${environment.getStorages.replace('{integrationType}', integrationType)}${id}`)
      .pipe(
        map<any, Array<IntegrationSourceModel>>((res: ApiResponse<any>): Array<IntegrationSourceModel> => {
          return res.result.data.map(
            (item: IntegrationSourceModel): IntegrationSourceModel => ({
              available: item.available,
              icon: item.icon,
              integrationType: item.integrationType,
              title: item.title,
              accounts: item.accounts,
              oAuthUrl: item.oAuthUrl,
              tokens: of<Array<IntegrationToken>>(new Array<IntegrationToken>(0))
            })
          );
        })
      );
  }

  public getStorageTokens(integrationType: IntegrationTypeEnum, projectId: string, dbId: string): Observable<Array<IntegrationToken>> {
    const url = `${environment.baseApiUrl}${environment.getStorageTokens
      .replace('{integrationType}', integrationType)
      .replace('{projectId}', dbId)}${projectId}`;

    return this.httpClient.get<ApiResponse<any>>(url).pipe(
      map<any, Array<IntegrationToken>>((res: ApiResponse<any>): Array<IntegrationToken> => {
        return res.result.data.map(
          (item: IntegrationToken): IntegrationToken => ({
            title: item.title,
            additionalInfo: item.additionalInfo,
            error: item.error || null,
            icon: item.icon,
            iconColor: item.iconColor,
            id: item.id,
            oAuthUrl: res.result.meta != null ? res.result.meta.oAuthUrl : null
          })
        );
      })
    );
  }

  public getIntegrationTokenData(integrationId: string, integrationType: string): Observable<IntegrationEditInfo> {
    return this.httpClient
      .get<ApiResponse<IntegrationEditInfo>>(`${environment.baseApiUrl}${environment.integrationData.replace('{integrationGroup}', this.getIntegrationTypePart(integrationType))}${integrationId}`)
      .pipe(
        map<ApiResponse<IntegrationEditInfo>, IntegrationEditInfo>((res: ApiResponse<IntegrationEditInfo>): IntegrationEditInfo => {
          if (!res.baseResponse.status) {
            return null;
          }

          return {
            integrationId,
            destinationType: res.result.destinationType,
            integrationType: res.result.integrationType,
            destinationTokenId: res.result.destinationTokenId,
            sourceTokenId: res.result.sourceTokenId
          };
        })
      );
  }

  public checkToken(integrationType: IntegrationTypeEnum, tokenId: string): Observable<BaseResponse> {
    const url = `${environment.baseApiUrl}${environment.integrationTokens}${integrationType}/${tokenId}`;

    return this.httpClient.get<ApiResponse<IntegrationToken>>(url).pipe(
      map<ApiResponse<IntegrationToken>, BaseResponse>((res: ApiResponse<IntegrationToken>): BaseResponse => {
        return res.baseResponse;
      })
    );
  }

  public getProfile(): Observable<ProfileModel> {
    const url = `${environment.baseApiUrl}${environment.profile}`;
    return this.httpClient.get<ApiResponse<ProfileModel>>(url).pipe(
      map((res: ApiResponse<ProfileModel>): ProfileModel => res.result)
    );
  }

  public updateProfile(body: { userName?: string; timezones?: Array<TimeZoneModel> }): Observable<boolean> {
    const url = `${environment.baseApiUrl}${environment.profile}`;
    return this.httpClient.post<ApiResponse<boolean>>(url, body).pipe(
      map((res: ApiResponse<boolean>): boolean => res.baseResponse.status)
    );
  }

  public updateProfileImage(body: FormData): Observable<string> {
    const url = `${environment.baseApiUrl}${environment.profileImage}`;
    return this.httpClient.post<ApiResponse<string>>(url, body).pipe(
      map((res: ApiResponse<string>): string => {
        if (res.baseResponse.status) {
          return res.result;
        }
        return null;
      })
    );
  }

  public updatePassword(body: { currentPassword: string; newPassword: string }): Observable<ApiResponse<boolean>> {
    const url = `${environment.baseApiUrl}${environment.updatePassword}`;
    return this.httpClient.post<ApiResponse<boolean>>(url, body);
  }

  public getBillingInfo(): Observable<BillingPayload> {
    return this.httpClient.get<ApiResponse<BillingPayload>>(`${environment.baseApiUrl}${environment.billing}`).pipe(
      map((res: ApiResponse<BillingPayload>): BillingPayload => {
        if (!res.baseResponse.status) {
          return null;
        }

        return res.result;
      })
    );
  }

  public getProductionLogsRuns(integrationId: string): Observable<ProductionLogsRun[] | string> {
    return this.httpClient.get<ApiResponse<ProductionLogsRun[]>>(
      `${environment.baseApiUrl}${environment.productionLogs}/runs?integration_id=${integrationId}`
    ).pipe(
      map((res: ApiResponse<ProductionLogsRun[]>): ProductionLogsRun[] | string => {
        if (!res.baseResponse.status) {
          return res.baseResponse.errors?.map((item: ErrorMessage): string => item.userMessage).join('. ') || 'Unknown error';
        }
        return res.result;
      })
    );
  }


  public getProductionLogsRunActions(integrationId: string, runId: string): Observable<ProductionLogsRunActions[] | string> {
    return this.httpClient.get<ApiResponse<ProductionLogsRunActions[]>>(
      `${environment.baseApiUrl}${environment.productionLogs}/actions?integration_id=${integrationId}&run_id=${runId}`
    ).pipe(
      map((res: ApiResponse<ProductionLogsRunActions[]>): ProductionLogsRunActions[] | string => {
        if (!res.baseResponse.status) {
          return res.baseResponse.errors?.map((item: ErrorMessage): string => item.userMessage).join('. ') || 'Unknown error';
        }
        return res.result;
      })
    );
  }

  public getNotificationHubTypes(): Observable<NotificationHubType[] | string> {
    return this.httpClient.get<ApiResponse<NotificationHubType[]>>(`${environment.baseApiUrl}${environment.notificationHub}/notification_types`).pipe(
      map((res: ApiResponse<NotificationHubType[]>): NotificationHubType[] | string => {
        if (!res.baseResponse.status) {
          return res.baseResponse.errors.map((item: ErrorMessage): string => item.userMessage).join('. ');
        }
        return res.result;
      })
    );
  }

  public getNotificationHubEmails(): Observable<NotificationHubPayload | string> {
    return this.httpClient.get<ApiResponse<NotificationHubPayload>>(`${environment.baseApiUrl}${environment.notificationHub}/emails`).pipe(
      map((res: ApiResponse<NotificationHubPayload>): NotificationHubPayload | string => {
        if (!res.baseResponse.status) {
          // Извлекаем сообщения об ошибках и объединяем их в одну строку
          return res.baseResponse.errors.map((item: ErrorMessage): string => item.userMessage).join('. ');
        }
        return res.result;
      })
    );
  }

  public deleteNotificationHubEmail(email: string): Observable<string> {
    return this.httpClient.delete<ApiResponse<any>>(
      `${environment.baseApiUrl}${environment.notificationHub}/email?email=${encodeURIComponent(email)}`
    ).pipe(
      map((res: ApiResponse<any>): string => {
        if (!res.baseResponse.status) {
          // Извлекаем сообщения об ошибках и объединяем их в одну строку
          return res.baseResponse.errors.map((item: ErrorMessage): string => item.userMessage).join('. ');
        }
        return null;
      })
    );
  }

  public AddNotificationHubEmail(email: string): Observable<AddNotificationHubEmailPayload | string> {
    return this.httpClient.post<ApiResponse<AddNotificationHubEmailPayload>>(
      `${environment.baseApiUrl}${environment.notificationHub}/email`,
      { email }
    ).pipe(
      map((res: ApiResponse<AddNotificationHubEmailPayload>): AddNotificationHubEmailPayload | string => {
        if (!res.baseResponse.status) {
          return res.baseResponse.errors.map((item: ErrorMessage): string => item.userMessage).join('. ');
        }
        return res.result;
      })
    );
  }

  public UpdateNotificationHubState(stateName: string, stateValue: boolean): Observable<NotificationHubUpdateState | string> {
    return this.httpClient.put<ApiResponse<NotificationHubUpdateState>>(
      `${environment.baseApiUrl}${environment.notificationHub}/state`,
      { state_name: stateName, state_value: stateValue }
    ).pipe(
      map((res: ApiResponse<NotificationHubUpdateState>): NotificationHubUpdateState | string => {
        if (!res.baseResponse.status) {
          return res.baseResponse.errors.map((item: ErrorMessage): string => item.userMessage).join('. ');
        }
        return null;
      })
    );
  }


  public confirmCheckout(plan: string): Observable<string> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.baseApiUrl}${environment.checkout}`, { plan }).pipe(
      map((res: ApiResponse<any>): string => {
        return res.baseResponse.status ? null : res.baseResponse.errors.map((item: ErrorMessage): string => item.userMessage).join('. ');
      })
    );
  }

  public checkout(checkout: { plan: string }): Observable<{ clientSecret?: string; error?: string }> {
    return this.httpClient.post<ApiResponse<any>>(environment.baseApiUrl + environment.checkout, checkout).pipe(
      map((res: ApiResponse<any>): { error?: string, paymentLink: string } => {
        return {
          error: res.baseResponse.status
            ? null
            : res.baseResponse.errors.map((item: ErrorMessage): string => item.userMessage).join('. '),
          paymentLink: res.result.paymentLink
        };
      })
    );
  }

  public cancelSubscription(): Observable<{ status: boolean, link: string }> {
    return this.httpClient.delete<ApiResponse<any>>(`${environment.baseApiUrl}${environment.cancelSubscription}`).pipe(
      map((res: ApiResponse<any>): { status: boolean, link: string } => {
        return {
          status: res.baseResponse.status,
          link: null
        };
      })
    );
  }

  public getPromoCodeInfo(promoCode: string): Observable<PromoCodePayload> {
    if (promoCode !== 'sitkina') {
      return of({
        isValid: false
      });
    }

    return of({
      discount: 100,
      isValid: true,
      plan: 'Expert',
      period: 'All'
    });
  }

  public getInvoices(first: number, rows: number): Observable<Array<InvoicesModel>> {
    return this.httpClient.get(environment.baseApiUrl + environment.invoices).pipe(
      map((res: ApiResponse<any>): Array<InvoicesModel> => {
        return res.result.data;
      })
    );
  }

  public updateInvoicesData(body: {
    country: string,
    city: string,
    zip: string,
    unit: string,
    address: string,
    companyName: string
  }): Observable<boolean> {
    return this.httpClient.post(environment.baseApiUrl + environment.invoices, body).pipe(
      map((res: ApiResponse<any>): boolean => {
        return res.baseResponse.status;
      })
    );
  }

  public renewSubscription(plan: string): Observable<{ status: boolean, link: string }> {
    const url = environment.baseApiUrl + environment.renewSubscription;
    return this.httpClient.post(url, { plan }).pipe(
      map((res: ApiResponse<any>): { status: boolean, link: string } => {
        return {
          status: res.baseResponse.status,
          link: res.result.paymentLink
        };
      })
    );
  }

  public updatePaymentCard(token: Token): Observable<ApiResponse<any>> {
    return this.httpClient.post(environment.baseApiUrl + environment.createPaymentMethod, token).pipe(
      map((res: ApiResponse<any>): ApiResponse<any> => {
        return res;
      })
    );
  }

  public initializeStreaming(): Observable<{ id: string, ip: string, defaultARecord: string }> {
    return this.httpClient.get<ApiResponse<{
      id: string,
      ip: string,
      defaultARecord: string
    }>>(environment.baseApiUrl + environment.streaming.initialize).pipe(
      map((res: ApiResponse<{ id: string, ip: string, defaultARecord: string }>): {
        id: string,
        ip: string,
        defaultARecord: string
      } => {
        return res.result;
      }));
  }

  public errorStringHandler(error: HttpErrorResponse): Observable<string> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return of<string>('Something went wrong, please try again later.');
  }

  public checkDsn(body: { aRecord: string, ip: string }): Observable<string> {
    return this.httpClient.post<ApiResponse<string>>(environment.baseApiUrl + environment.streaming.checkDns, body).pipe(
      map((res: ApiResponse<string>): string => {
        return res.baseResponse.status ? null : res.baseResponse.errors.map((item: ErrorMessage): string => item.userMessage).join('. ');
      }),
      catchError(this.errorStringHandler));
  }

  public getStreamingInfo(tokenId: string): Observable<{
    dnsData: { aRecord: string, ip: string },
    id: string,
    title: string
  }> {
    return this.httpClient.get<ApiResponse<{
      dnsData: { aRecord: string, ip: string },
      id: string,
      title: string
    }>>(environment.baseApiUrl + environment.streaming.edit.replace('{id}', tokenId)).pipe(
      map((res: ApiResponse<{ dnsData: { aRecord: string, ip: string }, id: string, title: string }>): {
        dnsData: { aRecord: string, ip: string },
        id: string,
        title: string
      } => {
        return res.result;
      }));
  }

  public getWebHookInfo(tokenId: string): Observable<any> {
    let url = environment.baseApiUrl + environment.streaming.editWebhook;
    if (_.isNil(tokenId)) {
      url = url.replace('/{id}', '');
    } else {
      url = url.replace('{id}', tokenId);
    }
    return this.httpClient.get<ApiResponse<any>>(url).pipe(
      map((res: ApiResponse<any>): any => {
        return res.result;
      }));
  }

  private mapAvailableIntegrations(items: Array<IntegrationSourceModel>): Array<IntegrationSourceModel> {
    return items.map(
      (item: IntegrationSourceModel): IntegrationSourceModel => ({
        available: item.available,
        icon: item.icon,
        integrationType: item.integrationType,
        title: item.title,
        accounts: item.accounts,
        oAuthUrl: item.oAuthUrl,
        tokens: of<Array<IntegrationToken>>(null)
      })
    );
  }

  private isDestinationInGroup(group: string[], sourceType: IntegrationTypeEnum): boolean {
    if (sourceType === IntegrationTypeEnum.javascript_sdk) {
      return group.includes(IntegrationGroupEnum.addon);
    }
    return group.includes(IntegrationGroupEnum.integration);
  }

  private getIntegrationTypePart(sourceType: IntegrationTypeEnum | string): string {
    return sourceType === IntegrationTypeEnum.javascript_sdk ? IntegrationGroupEnum.addon : IntegrationGroupEnum.integration;
  }

}
