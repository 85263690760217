import {
  AfterViewChecked,
  ContentChild,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { animate, AnimationBuilder, AnimationMetadata, AnimationPlayer, style } from '@angular/animations';
import * as _ from 'lodash';

class Constants {
  public static unset: string = 'unset';
  public static transformOriginToTop: string = 'center bottom';
  public static transformOriginToBottom: string = 'center top';
  public static boxShadowToTop: string = 'rgba(0, 0, 0, 0.2) 0px -1px 8px';
  public static boxShadowToBottom: string = 'rgba(0, 0, 0, 0.2) 0px 1px 8px';
  public static absolute: string = 'absolute';
  public static hidden: string = 'hidden';
  public static relative: string = 'relative';
  public static px: string = 'px';
  public static zero: string = '0';
  public static one: string = '1';
}

@Directive({
  selector: '[appOverlayDropdown]',
})
export class OverlayDropdownDirective implements AfterViewChecked {
  @ContentChild('dropdownContent')
  public templateDropdownContentRef: TemplateRef<any>;

  @Input()
  public DropdownCount: number;

  @Input()
  public opened: boolean;

  @Input()
  public dropdownContentSelector: string;

  @Output()
  public OnToggleOverlay: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public animationOpen: string = '.12s cubic-bezier(0, 0, 0.2, 1)';

  @Input()
  public animationClose: string = '.1s linear';

  @Input()
  public animationMetadataOpen: Array<AnimationMetadata> = [
    style({ opacity: 0, transform: 'scaleY(0.8)' }),
    animate(this.animationOpen, style({ opacity: 1, transform: '*' })),
  ];

  @Input()
  public animationMetadataClose: Array<AnimationMetadata> = [
    style({ opacity: 0, transform: 'scaleY(0.8)' }),
    animate(this.animationOpen, style({ opacity: 1, transform: '*' })),
  ];

  private elementDropdownContent: HTMLElement;
  private viewRefDropdownContent: EmbeddedViewRef<any>;
  private player: AnimationPlayer;
  private dropdownElementsCount: number = 0;

  constructor(
    private builder: AnimationBuilder,
    private elementRef: ElementRef,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2
  ) {}

  public ngAfterViewChecked(): void {
    if (!_.isNil(this.DropdownCount) && this.dropdownElementsCount !== this.DropdownCount && this.isPositionTop()) {
      this.dropdownElementsCount = this.DropdownCount;
      setTimeout((): void => {
        this.elementDropdownContent.style.top = -(this.elementDropdownContent.offsetHeight + 2) + 'px';
      });
    }
  }

  public ToggleOverlay(open: boolean): void {
    if (open === true && this.opened !== open) {
      this.open();
    } else {
      this.close();
    }
  }

  @HostListener('document:click', ['$event'])
  private onClickDocument($event: MouseEvent): void {
    const clickedInside = this.elementRef.nativeElement.contains($event.target);
    if (!clickedInside && this.opened) {
      this.close();
    }
  }

  @HostListener('click', ['$event'])
  private onClickElement($event: MouseEvent): void {
    const clickedInside = this.elementRef.nativeElement.contains($event.target);
    if (!clickedInside) {
      return;
    }

    if (!this.opened) {
      this.open();
    }
  }

  private close(): void {
    this.opened = false;
    this.playAnimation();
    this.viewContainer.clear();
    this.OnToggleOverlay.emit(this.opened);
  }

  private open(): void {
    this.opened = true;

    this.addTemplate();

    setTimeout((): void => {
      this.setOpenStyles();
      this.playAnimation();
      this.OnToggleOverlay.emit(this.opened);
    });
  }

  private addTemplate(): void {
    this.elementRef.nativeElement.style.position = Constants.relative;
    this.viewRefDropdownContent = this.templateDropdownContentRef.createEmbeddedView(null);
    this.elementDropdownContent = this.viewRefDropdownContent.rootNodes[0];
    this.setDefaultStyles();
    this.viewContainer.insert(this.viewRefDropdownContent);
    this.renderer.appendChild(this.viewContainer.element.nativeElement, this.elementDropdownContent);
  }

  private playAnimation(): void {
    if (this.player) {
      this.player.destroy();
    }
    const factory = this.builder.build(this.getAnimation());
    this.player = factory.create(this.elementDropdownContent);
    this.player.play();
  }

  private getAnimation(): Array<AnimationMetadata> {
    return this.opened ? this.animationMetadataOpen : this.animationMetadataClose;
  }

  private setDefaultStyles(): void {
    if (!this.elementDropdownContent) {
      return;
    }

    this.elementDropdownContent.style.opacity = Constants.zero;
    this.elementDropdownContent.style.position = Constants.absolute;
    this.elementDropdownContent.style.overflowY = Constants.hidden;
    this.elementDropdownContent.style.zIndex = Constants.one;
  }

  private setOpenStyles(): void {
    if (!this.elementDropdownContent) {
      return;
    }

    const maxHeightOffset = this.elementDropdownContent.offsetHeight;
    const rect: DOMRect = this.elementDropdownContent.getBoundingClientRect();
    const toTop = rect.y + maxHeightOffset + 5 > window.innerHeight;
    this.elementDropdownContent.style.transformOrigin = toTop ? Constants.transformOriginToTop : Constants.transformOriginToBottom;
    this.elementDropdownContent.style.boxShadow = toTop ? Constants.boxShadowToTop : Constants.boxShadowToBottom;
    this.elementDropdownContent.style.top = (toTop ? -maxHeightOffset - 1 : this.elementRef.nativeElement.offsetHeight + 1) + Constants.px;
  }

  private isPositionTop(): boolean {
    return !_.isNil(this.elementDropdownContent) && this.elementDropdownContent.offsetTop < 0;
  }
}
