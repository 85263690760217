<div class="setting-row-wrapper" *ngIf="isShowClients">
  <app-renta-chips
    *ngIf="isLoaded"
    [DataSource]="chipsClientsDataSource"
    (OnDeleteChip)="onCloseSelectedClientsChip($event)"
  ></app-renta-chips>
</div>
<div class="setting-row-wrapper p-mb-4" *ngIf="isShowClients">
  <!--  TODO: надо создать структурную директиву, которая будет рендерить прелоадер, пока значение фолс-->
  <app-renta-multiselect-dropdown
    *ngIf="isLoaded; else loadingSetting"
    (OnApply)="onApplyClients($event)"
    [RentaMultiselectDropdownSource]="ClientsDataSource"
    [hotRefresh]="false"
  >
  </app-renta-multiselect-dropdown>
</div>

<div class="setting-row-wrapper p-m-0">
  <app-renta-dropdown
    *ngIf="isLoaded; else loadingSetting"
    [RentaDropdownSource]="ReportTypeDataSource"
    [toolTipInfo]="'Report type consists of the unique set of parameters and metrics available for uploading to DWH. Each Report Type has its own parameters and metrics collection.'"
    (OnApply)="onApplyReportType($event)"
  ></app-renta-dropdown>
</div>

<div class="setting-row-wrapper">
  <app-renta-chips *ngIf="isLoaded" [DataSource]="chipsParametersDataSource" (OnDeleteChip)="onCloseChip($event)"></app-renta-chips>
</div>
<div class="setting-row-wrapper">
  <app-renta-multiselect-dropdown
    *ngIf="isLoaded; else loadingSetting"
    (OnApply)="onApplyParameters($event)"
    [RentaMultiselectDropdownSource]="ParametersDataSource"
    [toolTipInfo]="'Parameters contains the list of parameters and metrics available for export.'"
    [additionalInfo]="'Specify the parameters required for uploading.'"
    [hotRefresh]="true"
  >
  </app-renta-multiselect-dropdown>
</div>

<div *ngIf="isShowExtraSettings">
  <div class="setting-row-wrapper">
    <app-renta-selection
      [RentaSelectionOptionDataSource]="includeVatDataSource"
      (OnApply)="onApplyIncludeVat($event)"
      [toolTipInfo]="'Enabling this option will add VAT to your costs.'"
    >
    </app-renta-selection>
  </div>

  <div class="setting-row-wrapper">
    <app-renta-selection
      [RentaSelectionOptionDataSource]="includeDiscountDataSource"
      (OnApply)="onApplyIncludeDiscount($event)"
      [toolTipInfo]="'Enabling this option will add agency discount to your costs.'">
    </app-renta-selection>
  </div>
</div>

<ng-template #loadingSetting>
  <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
  <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
</ng-template>
