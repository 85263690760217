<div class="p-d-flex p-align-center width-max" [class.p-dir-col]="PositionCheckMark === 'top'">
  <div [class.ckecmark-padding-bottom]="PositionCheckMark === 'top'">
    <svg *ngIf="Success" class="checkmark_success" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle class="checkmark_circle_success" cx="26" cy="26" r="25" fill="none" />
      <path class="checkmark_check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
    <svg *ngIf="!Success" class="checkmark_error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle class="checkmark_circle_error" cx="26" cy="26" r="25" fill="none" />
      <path class="checkmark_check_error" fill="none" d="M16 16 36 36 M36 16 16 36" />
    </svg>
  </div>
  <div class="p-d-flex p-flex-column checkmark-title-wrapper" [class.p-m-0]="TextAlign === 'center'">
    <span
      class="renta-font-big p-text-bold"
      [class.p-text-left]="TextAlign === 'left'"
      [class.p-text-center]="TextAlign === 'center'"
      [class.p-mb-2]="PositionCheckMark === 'top'"
      >{{ Title }}</span
    >
    <span
      class="renta-font-normal renta-subtext-color"
      [class.p-text-left]="TextAlign === 'left'"
      [class.p-text-center]="TextAlign === 'center'"
      >{{ TitleDescription }}</span
    >
  </div>
</div>
