import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppGuardService } from './services/app-guard.service';
import { AuthGuardService } from './services/auth-guard.service';
import {AppCompletePopupGuardService} from "./services/app-complete-popup-guard.service";


const routes: Routes = [
  {
    path: 'auth',
    canLoad: [AuthGuardService],
    loadChildren: (): any => import(`./modules/auth/auth.module`).then((m: any): any => m.AuthModule),
  },
  {
    path: 'integrations',
    canLoad: [AppGuardService],
    loadChildren: (): any => import(`./modules/integrations/integrations.module`).then((m: any): any => m.IntegrationsModule),
  },
  {
    path: 'manage',
    canLoad: [AppGuardService],
    loadChildren: (): any => import(`./modules/manage/manage.module`).then((m: any): any => m.ManageModule),
  },
  {
    path: 'completed_destination',
    canLoad: [AppCompletePopupGuardService],
    redirectTo: '/completed_destination',
  },
  {
    path: 'completed',
    canLoad: [AppCompletePopupGuardService],
    redirectTo: '/completed',
  },
  {
    path: 'failure',
    canLoad: [AppCompletePopupGuardService],
    redirectTo: '/failure',
  },
  {
    path: '**',
    redirectTo: 'integrations/list',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
