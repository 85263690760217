import { Component } from '@angular/core';
import * as _ from 'lodash';

import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { ChipsModel } from '../../../../../shared/models/ChipsModel';
import { RentaChipsDataSource } from '../../../../../shared/models/DataSource/RentaChipsDataSource';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';

@Component({
  template: '',
})
export abstract class SettingsBaseComponent {
  public isDataSourceLoading: boolean;
  private dataSources: Array<any>;

  protected constructor(private integrationTypeEnum: IntegrationTypeEnum) {
    this.isDataSourceLoading = false;
  }

  protected get integrationType(): IntegrationTypeEnum {
    return this.integrationTypeEnum;
  }

  protected onError(errors: Array<ErrorMessage>): void {
    const prefix = 'DataSource';
    for (const error of errors) {
      const dataSourceName = error.message + prefix;
      const dataSourceError = error.userMessage;
      if (this.hasOwnProperty(dataSourceName)) {
        this[dataSourceName].setError(dataSourceError);
      }
    }
  }

  protected connectParamsChipsDS(paramsChipsDS: RentaChipsDataSource, settingsService: any): void {
    paramsChipsDS.connect().subscribe((res: Array<ChipsModel>): void => {
      const selectedParameters = res.map<ListItemModel>((m: ChipsModel): ListItemModel => {
        return { id: m.id, name: m.title, group: m.type };
      });

      settingsService.setSelectedParameters(selectedParameters);
    });
  }

  protected onApplyParams($event: Array<ListItemModel>, chipsDataSource: RentaChipsDataSource): void {
    if (_.isNil($event)) {
      return;
    }

    const chipsDs = $event.map<ChipsModel>((item: ListItemModel): ChipsModel => {
      return { title: item.name, type: item.group, id: item.id, order: 0 };
    });

    chipsDataSource.refresh(chipsDs);
  }

  protected setRelatedDataSources(sources: Array<any>): void {
    this.dataSources = sources;
  }

  protected refreshRelatedDataSources(): void {
    if (_.isNil(this.dataSources)){
      return;
    }
    setTimeout((): void => {
      this.isDataSourceLoading = false;

      this.dataSources.forEach((elem: any): void => {
        if (!this.isDataSourceLoading && elem.loading) {
          this.isDataSourceLoading = true;
        }
      });
    });
  }

  protected clearErrors(): void {
    const prefix = 'DataSource';
    for (const key of Object.keys(this)) {
      if (key.endsWith(prefix)) {
        // tslint:disable-next-line:no-unused-expression
        this[key].setError && this[key].setError(null);
      }
    }
  }
}
