import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-snowflake-auth',
  templateUrl: './snowflake-auth.component.html',
  styleUrls: ['./snowflake-auth.component.css']
})
export class SnowflakeAuthComponent implements OnInit {

  @Input()
  public error: string;

  @Input()
  public ServerName: string;

  @Input()
  public Warehouse: string;

  @Input()
  public Schema: string;

  @Input()
  public Login: string;

  @Input()
  public DatabasePassword: string;

  @Input()
  public DatabaseName: string;

  @Input()
  public loading: boolean;

  constructor() {
  }

  public ngOnInit(): void {
  }
}
