<div appDontTouch>
  <div *ngIf="!createMode; else settingsBlock">
    <div class="edit-integration-settings-wrapper p-d-flex p-flex-column p-ai-center p-jc-center">
      <div class="edit-integration-settings-container">
        <div class="edit-title-container">
          <span class="ap-ta-center p-text-bold renta-font-big">{{ getPageTitle() }}</span>
          <span class="ap-ta-center renta-font-normal edit-title-info">{{ getPageSubtitle() }}</span>
        </div>
        <div class="edit-wrapper p-d-flex">
          <div class="back-page-btn-container">
            <p-button styleClass="cancel-btn-wrapper renta-btn-secondary" (onClick)="cancelSettings()">
              <i class="pi pi-angle-left"></i>
            </p-button>
          </div>
          <div class="edit-content-container width-max">
            <div class="integration-settings-container p-d-flex p-flex-column p-p-6" *ngIf='isEtl'>
              <ng-container #integrationSourceDestination></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="settingsBlock"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #settingsBlock>
    <div class="common-error-message-container p-d-flex p-ai-center p-jc-center" *ngIf="commonErrorMessage">
      <span class="p-error p-text-bold">{{ commonErrorMessage }}</span>
    </div>
    <div *ngIf='!isEtl'>
      <div class="integration-settings-container p-d-flex p-flex-column p-p-6" *ngIf="!createMode">
        <ng-container #integrationSourceDestination></ng-container>
      </div>
      <div class="integration-settings-container addon-settings p-d-flex p-flex-column p-p-6">
        <ng-container #integrationInfo></ng-container>
      </div>
      <ng-container #integrationSettings></ng-container>
    </div>
    <div *ngIf='isEtl'>
      <div class="integration-settings-container p-d-flex p-flex-column p-p-6">
        <ng-container #integrationSettings></ng-container>
      </div>
      <div class="integration-settings-container p-d-flex p-flex-column p-p-6">
        <ng-container #integrationInfo></ng-container>
      </div>
      <div class="integration-settings-container p-d-flex p-flex-column p-p-6">
        <ng-container #integrationSchedule></ng-container>
      </div>
    </div>
    <div class="settings-action p-d-flex p-flex-row-reverse">
      <p-button
        styleClass="integration-btn-wrapper renta-color"
        type="submit"
        label="{{ saveButton }}"
        (onClick)="createIntegration()"
        [disabled]="blockedPanel || !(canSave | async)"
      >
        <div class="spinner-container p-d-flex p-align-center p-justify-start">
          <i class="pi pi-spin pi-spinner" *ngIf="blockedPanel"></i>
        </div>
      </p-button>
    </div>
  </ng-template>
</div>
