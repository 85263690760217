import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { MultiselectDropdownDataSourceModel } from '../../models/DataSource/DataSourceModels/MultiselectDropdownDataSourceModel';
import { RentaMultiselectDropdownDataSource } from '../../models/DataSource/RentaMultiselectDropdownDataSource';
import { OverlayDropdownDirective } from '../../directives/overlay-dropdown.directive';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-renta-multiselect-dropdown',
  templateUrl: './renta-multiselect-dropdown.component.html',
  styleUrls: ['../internal/base-settings-styles.css', './renta-multiselect-dropdown.component.scss'],
})
export class RentaMultiselectDropdownComponent implements OnInit {
  @Input()
  public toolTipInfo: string;
  @Input()
  public additionalInfo: string;
  @Input()
  public RentaMultiselectDropdownSource: RentaMultiselectDropdownDataSource;

  @Input()
  public ShowName: boolean = true;

  @Input()
  public hotRefresh: boolean;

  @Output()
  public OnApply: EventEmitter<Array<ListItemModel>> = new EventEmitter<Array<ListItemModel>>();

  @ViewChild(OverlayDropdownDirective)
  public directive: OverlayDropdownDirective = null;

  @ViewChild('dropdownSearch')
  public dropdownSearch: ElementRef;

  public ds: Observable<MultiselectDropdownDataSourceModel> = null;
  public selectedDataSource: Observable<Array<ListItemModel>> = null;
  public isOpen: boolean = false;
  public searchString: string;

  constructor(private cdr: ChangeDetectorRef) {}

  public onFocus(isFocus: boolean): void {
    this.isOpen = isFocus;

    if (!this.isOpen) {
      this.searchString = '';
      this.RentaMultiselectDropdownSource.filter('');
      if (!this.hotRefresh) {
        this.RentaMultiselectDropdownSource.refresh();
      }
    }
  }

  public ngOnInit(): void {
    this.ds = this.RentaMultiselectDropdownSource.connect();
    this.selectedDataSource = this.RentaMultiselectDropdownSource.getSelectedItems();
  }

  public onToggleListItem(item: ListItemModel): void {
    setTimeout((): void => {
      this.RentaMultiselectDropdownSource.toggleSelect(item);
      if (this.hotRefresh) {
        this.onApply();
      }
      this.dropdownSearch.nativeElement.focus();
      this.cdr.detectChanges();
    });
  }

  public onCheckAll(groupName: string): void {
    this.RentaMultiselectDropdownSource.checkAll(groupName);
  }

  public onUncheckAll(groupName: string): void {
    this.RentaMultiselectDropdownSource.uncheckAll(groupName);
  }

  public onApply(): void {
    this.RentaMultiselectDropdownSource.getSelectedItems()
      .pipe(first())
      .subscribe((selectedItems: Array<ListItemModel>): void => {
        this.OnApply.emit(selectedItems);

        if (!this.hotRefresh) {
          this.directive.ToggleOverlay(false);
        }
      });
  }

  public getId(name: string): string {
    return name.replace(/ /g, '_') + 'DataSource';
  }

  public onTabChanged(): void {
    this.dropdownSearch.nativeElement.focus();
  }
}
