<div class="integration-token-row integration-token-skeleton-loader-row p-d-flex p-ai-center">
  <div class="integration-token-icon-add p-d-flex p-jc-center p-ai-center">
    <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
  </div>
  <div class="integration-token-info p-d-flex p-flex-column">
    <ngx-skeleton-loader count="1" appearance="" class="skeleton-token-title-preloader"
                         [theme]="{height: '17px', 'margin': '0'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" appearance="" class="skeleton-integration-token-expiration-preloader"
                         [theme]="{height: '17px', 'margin': '0'}"></ngx-skeleton-loader>
  </div>
  <div class="integration-token-btn">
  </div>
  <div class="integration-token-select-icon">
  </div>
</div>
