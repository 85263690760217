import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-renta-action-placeholder',
  templateUrl: './renta-action-placeholder.component.html',
  styleUrls: ['./renta-action-placeholder.component.scss'],
})
export class RentaActionPlaceholderComponent implements OnInit {
  @Input()
  public text: string;

  @Input()
  public placeholder: string;

  @Input()
  public isEditable: boolean;

  @Input()
  public btnIcon: string;

  @Input()
  public mask: string;

  @Output()
  public placeholderHandler: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  public ngOnInit(): void {
    this.setDefaultValue();
    this.convertTextToMask();
  }

  public activatePlaceholder(): void {
    this.placeholderHandler.emit(true);
  }

  private setDefaultValue(): void {
    this.text = this.text || '';
    this.placeholder = this.placeholder || 'placeholder';
    this.isEditable = _.isNil(this.isEditable) ? true : this.isEditable;
    this.btnIcon = this.btnIcon || 'pi-pencil';
  }

  private convertTextToMask(): void {
    if (_.isNil(this.mask) || this.mask.length === 0) {
      return;
    }

    this.text = _.map(this.text, (): string => {
      return this.mask;
    }).join('');
  }
}
