import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { map, mergeMap } from 'rxjs/operators';

import { RentaApiService } from '../../../../services/renta-api.service';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { ApiResponse } from '../../../../models/common/ApiResponse`T';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { GaMeta } from '../../../../models/Integration/IntegrationsMeta/GaMeta';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { EtlSettingsServiceBase } from './EtlSettingsServiceBase';

@Injectable()
export class GaSettingsService extends EtlSettingsServiceBase<GaMeta> {
  protected tablePrefix: string = 'ga4';
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.ga;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      Parameters: null,
      Account: null,
      Property: null,
      Filters: null
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  constructor(
    public integrationInfoData: IntegrationInfoViewModel,
    protected readonly rentaApiService: RentaApiService,
    protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.memoryCacheKey = `tablePrefix-${IntegrationTypeEnum.ga}`;
  }

  public getAccounts(): Observable<Array<ListItemModel>> {
    return this.rentaApiService.httpClient
      .post(this.meta.accountsUrl, { sourceTokenId: this.integrationInfoData.integrationSourceToken, integrationId: this.integrationInfoData.integrationId || null })
      .pipe(
        map((res: ApiResponse<{ accounts: Array<ListItemModel> }>): Array<ListItemModel> => res.result.accounts)
      );
  }

  public getProperties(account: ListItemModel): Observable<Array<ListItemModel>> {
    return this.rentaApiService.httpClient
      .post<ApiResponse<{ properties: Array<ListItemModel> }>>(this.meta.propertiesUrl.replace('{accountId}', account.id),
        { sourceTokenId: this.integrationInfoData.integrationSourceToken, integrationId: this.integrationInfoData.integrationId || null }
      )
      .pipe(map((res: ApiResponse<{ properties: Array<ListItemModel> }>): Array<ListItemModel> => res.result.properties));
  }

  public getParameters(): Observable<Array<ListItemModel>> {
    const accountId = this.selectedIntegrationSettings[this.integrationTypeEnum].Account[0]?.id;
    const propertyId = this.selectedIntegrationSettings[this.integrationTypeEnum].Property[0]?.id;

    if (_.isNil(accountId) || _.isNil(propertyId) ) {
      return of<[]>([]);
    }

    const url = this.meta.parametersUrl.replace('{accountId}', accountId).replace('{propertyId}', propertyId);
    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken, integrationId: this.integrationInfoData.integrationId || null };

    return this.getCurrentParameters(url, body);
  }

  public getFilters(): Observable<any> {
    const accountId = this.selectedIntegrationSettings[this.integrationTypeEnum].Account[0]?.id;
    const propertyId = this.selectedIntegrationSettings[this.integrationTypeEnum].Property[0]?.id;

    if (_.isNil(accountId) || _.isNil(propertyId) ) {
      return of<[]>([]);
    }

    const url = this.meta.filtersUrl.replace('{accountId}', accountId).replace('{propertyId}', propertyId);
    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken, integrationId: this.integrationInfoData.integrationId || null };

    return this.rentaApiService.httpClient.post<ApiResponse<{ filters: Array<ListItemModel> }>>(url, body).pipe(
        map((res: ApiResponse<{ filters: Array<ListItemModel> }>): Array<ListItemModel> => {
        return _.isNil(res.result.filters) ? [] : res.result.filters;
      })
    );
  }

  public setAccount(selectedAccount: ListItemModel): boolean {
    const indexProperty = this.selectedSettings.Account?.findIndex((f: ListItemModel): boolean => f.id === selectedAccount.id);

    if (!_.isNil(selectedAccount) && indexProperty > -1) {
      return false;
    }

    this.selectedSettings.Account = [selectedAccount];
    return true;
  }

  public setProperty(property: ListItemModel): boolean {
    const indexProperty = this.selectedSettings.Property?.findIndex((f: ListItemModel): boolean => f.id === property.id);

    if (!_.isNil(indexProperty) && indexProperty > -1) {
      return false;
    }

    this.selectedSettings.Property = [property];
    return true;
  }

  public setSelectedParameters(selectedParameters: Array<ListItemModel>): void {
    super.setSelectedParameters(selectedParameters);
  }

  public setFilters(selectedFilters: Array<ListItemModel>): boolean {
    if (_.isNil(selectedFilters)) {
      return false;
    }

    this.selectedSettings.Filters = selectedFilters;
    return true;
  }

  protected mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: response.dateRange || [],
      Date: response.date || null,
      Schedule: response.schedule || [],
      OverwritePeriod: response.overwritePeriod || [],
      [this.integrationTypeEnum]: {
        Account: response.accounts || [],
        Property: response.properties || [],
        Parameters: response.parameters || [],
        Filters: response.filters || []
      },
      Name: response.name || defaultIntegrationName,
      TableName: response.tableName || defaultTableName
    };
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].Account = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Property = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].View = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Filters = [];
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings, isRefresh: boolean = false): void {
    const isRefreshSettings = this.editMode && isRefresh;
    this.selectedIntegrationSettings.Id = initialSettings.Id;
    this.selectedSettings.Account = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].Account[0])
      ? initialSettings[this.integrationTypeEnum].Account.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].Account[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].Account.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Property = isRefreshSettings
      ? initialSettings[this.integrationTypeEnum].Property.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].Property[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].Property.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );

    this.selectedSettings.Filters = initialSettings[this.integrationTypeEnum].Filters.filter((f: ListItemModel): boolean => f.selected);

    this.initSelectedCommonSettings(initialSettings, isRefreshSettings);
  }

  protected refreshParameters(): Observable<any> {
    return this.rentaApiService.initIntegration(this.getWizardRequestData())
      .pipe(
        mergeMap((res: any): Observable<IntegrationSettings> => {
          this.meta = res.meta;
          res = this.mapIntegrationSettings(res, this.selectedIntegrationSettings.TableName, this.selectedIntegrationSettings.Name);
          const selectedAcc = this.selectedIntegrationSettings[this.integrationTypeEnum].Account[0] ||
            res[this.integrationTypeEnum].Account.find((f: ListItemModel): boolean => f.selected);

          const selectedProp = this.selectedIntegrationSettings[this.integrationTypeEnum].Property[0] ||
            res[this.integrationTypeEnum].Property.find((f: ListItemModel): boolean => f.selected);

          return (_.isNil(selectedAcc) || _.isNil(res[this.integrationTypeEnum].Account.find((f: ListItemModel): boolean => f.id === selectedAcc.id)))
            ? of(res)
            : this.getProperties(selectedAcc).pipe(
              mergeMap((props: Array<ListItemModel>): Observable<IntegrationSettings> => {
                res[this.integrationTypeEnum].Property = props;
                return (_.isNil(selectedProp) || _.isNil(res[this.integrationTypeEnum].Property.find((f: ListItemModel): boolean => f.id === selectedProp.id)))
                  ? of(res)
                  : this.getParameters().pipe(
                    mergeMap((params: Array<ListItemModel>): Observable<IntegrationSettings> => {
                      res[this.integrationTypeEnum].Parameters = params;
                      this.compareParametersWithCacheData(params);
                      return this.getFilters().pipe(
                        mergeMap((filters: Array<ListItemModel>): Observable<IntegrationSettings> => {
                          res[this.integrationTypeEnum].Filters = filters;
                          this.compareFiltersWithCacheData(filters);
                          return of(res);
                        })
                      );
                    })
                  );
              })
            );
        })
      );
  }
}
