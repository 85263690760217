import { Component, OnDestroy, OnInit } from '@angular/core';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { delay } from 'rxjs/operators';
import * as _ from 'lodash';

import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { PostgreSqlSettingsService } from '../../../../services/integrationSettings/postgre-sql-settings.service';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';

@Component({
  selector: 'app-postgre-sql-settings',
  templateUrl: './postgre-sql-settings.component.html',
  styleUrls: ['../common-settings.scss']
})
export class PostgreSqlSettingsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;
  public DatabaseDataSource: RentaDropdownDataSource;
  public ParametersDataSource: RentaDropdownDataSource;

  constructor(public settingsService: PostgreSqlSettingsService) {
    super(IntegrationTypeEnum.postgreSql);
  }

  public ngOnInit(): void {
    this.DatabaseDataSource = new RentaDropdownDataSource('Databases', []);
    this.ParametersDataSource = new RentaDropdownDataSource('Tables', []);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
      this.DatabaseDataSource.setLoading(res);
      this.ParametersDataSource.setLoading(res);
    });

    this.settingsService.connectToLoading().subscribe((res: boolean): void => {
      this.isLoaded = !res;
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      this.DatabaseDataSource.refresh(_.cloneDeep(res[this.integrationType].Databases));
      this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
      this.setRelatedDataSources([this.DatabaseDataSource, this.ParametersDataSource]);
      this.settingsService.initCache();
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onApplyDatabase($event: ListItemModel): void {
    if (!this.settingsService.setDatabases($event)) {
      return;
    }
    this.ParametersDataSource.setLoading(true);
    this.refreshRelatedDataSources();
    this.settingsService
      .getParameters($event)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(_.cloneDeep(res));
        }
      });
  }

  public onApplyParameter(event: ListItemModel): void {
    this.settingsService.setParameter(event);
    this.settingsService.setDataToCache();
  }
}
