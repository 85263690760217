import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-postgre-sql-auth',
  templateUrl: './postgre-sql-auth.component.html',
  styleUrls: ['./postgre-sql-auth.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PostgreSqlAuthComponent implements OnInit {
  @Input()
  public error: string;

  @Input()
  public ip: string;

  @Input()
  public port: string;

  @Input()
  public login: string;

  @Input()
  public password: string;

  @Input()
  public databaseName: string;

  @Input()
  public useSsl: boolean;

  @Input()
  public loading: boolean;

  constructor() {
    this.port = '5432';
  }

  public ngOnInit(): void {
    this.useSsl = false;
  }

  public onChangeListItem($event: any): void {
    this.useSsl = $event.checked;
  }
}
