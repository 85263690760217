<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="mr-b-5">
    <app-renta-error-message [errorMessage]="error" *ngIf="error"></app-renta-error-message>
  </div>

    <label for="awsAccessKey" class="p-d-none"></label>
    <input
      id="awsAccessKey"
      class="p-inputtext-lg auth-dialog-input dialog-input-margin"
      appTextInputTrim
      pInputText
      placeholder="Aws Access Key"
      type="text"
      [(ngModel)]="AwsAccessKey"
      [disabled]="loading"
    />
    <label for="AwsSecretAccessKey" class="p-d-none"></label>
    <input
      id="AwsSecretAccessKey"
      class="p-inputtext-lg auth-dialog-input dialog-input-margin"
      appTextInputTrim
      pInputText
      placeholder="Aws Secret Access Key"
      [disabled]="loading"
      type="text"
      [(ngModel)]="AwsSecretAccessKey"
    />

  <label for="bucketName" class="p-d-none"></label>
  <input
    id="bucketName"
    type="text"
    appTextInputTrim
    pInputText
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    placeholder="Bucket Name"
    [(ngModel)]="BucketName"
    [disabled]="loading"
  />

  <div class='width-max p-mt-3 p-mb-2 amazon-s3-region-wrapper'>
  <app-renta-dropdown
    [maxHeight]="120"
    [ShowName]="false"
    [CanSearch]="true"
    (OnApply)="onApplyRegion($event)"
    [RentaDropdownSource]="RegionDataSource"
    [placeholder]="'Region'"
  ></app-renta-dropdown>
  </div>

  <a href="https://renta.im/docs/destination/amazons3" class="renta-href check-dock" target="_blank">Check
    documentation for
    details</a>
</div>
