import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-x-ads-auth',
  templateUrl: './x-ads-auth.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class XAdsAuthComponent implements OnInit {
  @Input()
  public error: string;

  @Input()
  public siteName: string;

  @Input()
  public siteId: string;

  @Input()
  public clientApiId: string;

  @Input()
  public loading: boolean;

  constructor() {}

  public ngOnInit(): void {}
}
