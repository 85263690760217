import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';

import { RentaApiService } from '../../../../services/renta-api.service';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { PostgreSqlMeta } from '../../../../models/Integration/IntegrationsMeta/PostgreSqlMeta';
import { mergeMap } from 'rxjs/operators';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { EtlSettingsServiceBase } from './EtlSettingsServiceBase';

@Injectable()
export class PostgreSqlSettingsService extends EtlSettingsServiceBase<PostgreSqlMeta> {
  protected tablePrefix: string = 'postgresql';
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.postgreSql;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      Database: null,
      Parameters: null
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  constructor(public integrationInfoData: IntegrationInfoViewModel, protected readonly rentaApiService: RentaApiService,
              protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.memoryCacheKey = `tablePrefix-${IntegrationTypeEnum.postgreSql}`;
  }

  public setDatabases(item: ListItemModel): boolean {
    if (_.isNil(item) || _.some(this.selectedSettings.Database, item)) {
      return false;
    }

    this.selectedSettings.Database = [item];
    return true;
  }

  public getParameters(selectedDatabase: ListItemModel): Observable<Array<ListItemModel>> {
    const databaseId: string = _.isNil(selectedDatabase) ? _.first(this.selectedSettings.Database)?.id : selectedDatabase.id;

    if (_.isNil(databaseId)) {
      return of<[]>([]);
    }

    const url: string = this.meta.parametersUrl.replace('{databaseId}', databaseId);
    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };

    return this.getCurrentParameters(url, body);
  }

  public setParameter(elem: ListItemModel): void {
    if (_.isNil(elem) || _.some(this.selectedSettings.Parameters, elem)) {
      return;
    }

    this.selectedSettings.Parameters = [elem];
  }

  protected mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: response.dateRange || [],
      Date: response.date || null,
      Schedule: response.schedule || [],
      OverwritePeriod: response.overwritePeriod || [],
      [this.integrationTypeEnum]: {
        Databases: response.databases || [],
        Parameters: response.parameters || []
      },
      Name: response.name || defaultIntegrationName,
      TableName: response.tableName || defaultTableName
    };
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].Databases = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [];
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings, isRefresh: boolean = false): void {
    const isRefreshSettings = this.editMode && isRefresh;
    this.selectedIntegrationSettings.Id = initialSettings.Id;
    this.selectedSettings.Database = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].Database[0])
      ? initialSettings[this.integrationTypeEnum].Databases.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].Database[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].Databases.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );

    this.initSelectedCommonSettings(initialSettings, isRefreshSettings);
  }

  protected refreshParameters(): Observable<any> {
    return this.rentaApiService.initIntegration(this.getWizardRequestData())
      .pipe(
        mergeMap((res: any): Observable<IntegrationSettings> => {
          this.meta = res.meta;
          res = this.mapIntegrationSettings(res, this.selectedIntegrationSettings.TableName, this.selectedIntegrationSettings.Name);
          const selectedDatabases = this.selectedIntegrationSettings[this.integrationTypeEnum].Database[0] ||
            res[this.integrationTypeEnum].Databases.find((f: ListItemModel): boolean => f.selected);

          return (_.isNil(selectedDatabases) || _.isNil(res[this.integrationTypeEnum].Databases.find((f: ListItemModel): boolean => f.id === selectedDatabases.id)))
            ? of(res)
            : this.getParameters(selectedDatabases).pipe(
              mergeMap((params: Array<ListItemModel>): Observable<IntegrationSettings> => {
                res[this.integrationTypeEnum].Parameters = params;
                this.compareParametersWithCacheData(params);
                return of(res);
              })
            );
        })
      );
  }
}
