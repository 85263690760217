<div class="overview-container">
  <div class="runs-list">
    <h3>Integration runs</h3>
    <div class="runs-scroll-container">
      <!-- Отображаем скелетоны, если данные загружаются -->
      <ng-container *ngIf="runsLoading; else runsList">
        <p-skeleton styleClass="p-mb-2" width="100%" height="24px" borderRadius="8px"></p-skeleton>
        <p-skeleton styleClass="p-mb-2" width="100%" height="24px" borderRadius="8px"></p-skeleton>
        <p-skeleton styleClass="p-mb-2" width="100%" height="24px" borderRadius="8px"></p-skeleton>
      </ng-container>

      <!-- Основной список запусков -->
      <ng-template #runsList>
        <div *ngFor="let dateGroup of (groupedRuns$ | async) | sortKeys:'desc'">
          <h4>{{ dateGroup.key | formatDate }}</h4>
          <ul>
            <li
              *ngFor="let run of dateGroup.value"
              (click)="selectRun(run)"
              class="run-item"
              [class.selected]="run.run_id === selectedRunId">
              <span class="run-status">{{ run.data.status }}</span>
              <span class="run-action-time">{{ run.data.action_time_str }}</span>
            </li>
          </ul>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="actions-container">
    <!-- Отображение сообщения об ошибке -->
    <div *ngIf="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>

    <div class="metadata">
      <h3>Run metadata</h3>
      <div *ngIf="metadata">
        <p>
          Run status:
          <i *ngIf="metadata.status === 'success'"
             class="pi pi-check-circle"
             style="color: green; margin-right: 5px; margin-left: 5px;">
          </i>

          <i *ngIf="metadata.status === 'error'"
             class="pi pi-times-circle"
             style="color: red; margin-right: 5px; margin-left: 5px;">
          </i>

          <i *ngIf="metadata.status === 'in process'"
             class="pi pi-spin pi-spinner"
             style="color: blue; margin-right: 5px; margin-left: 5px;">
          </i>          {{ metadata.status }}
        </p>
        <p>Run type: {{ metadata.run_type }}</p>
        <p>Run started: {{ metadata.run_started | date:'medium' }}</p>
        <p *ngIf="metadata.run_finish_date_time">Run finished: {{ metadata.run_finish_date_time | date:'medium' }}</p>
        <p *ngIf="metadata.full_refresh">Full refresh: {{ metadata.full_refresh }}</p>
        <p *ngIf="metadata.rows_extracted">Total rows extracted: {{ metadata.rows_extracted }}</p>
        <p *ngIf="metadata.duration">Duration: {{ metadata.duration }}</p>
      </div>
      <div *ngIf="!metadata">
        <p>Select a run to see metadata</p>
      </div>
    </div>

    <!-- Контейнер для действий и деталей -->
    <div class="actions-content" [ngClass]="{'details-open': selectedActionData}">
      <div class="actions-list">
        <!-- Отображаем скелетоны, если данные загружаются -->
        <ng-container *ngIf="actionsLoading; else actionsList">
          <p-skeleton
            styleClass="p-mb-2"
            width="50%"
            height="16px"
            borderRadius="8px"
          ></p-skeleton>
          <p-skeleton
            styleClass="p-mb-2"
            width="50%"
            height="16px"
            borderRadius="8px"
          ></p-skeleton>
          <p-skeleton
            styleClass="p-mb-2"
            width="50%"
            height="16px"
            borderRadius="8px"
          ></p-skeleton>
        </ng-container>

        <!-- Основной список действий -->
        <ng-template #actionsList>
          <h3>Run actions</h3>
          <ul>
            <li
              *ngFor="let action of (selectedActions$ | async)"
              (click)="selectAction(action)"
              class="action-item"
              [class.selected]="action.action_id === selectedActionId">
              <span class="action-type">{{ action.type }}</span>
            </li>
          </ul>
        </ng-template>
      </div>

      <!-- Вертикальная линия-разделитель -->
      <div class="vertical-divider" *ngIf="selectedActionData"></div>

      <!-- Детали выбранного действия -->
      <div class="action-details" *ngIf="selectedActionData">
        <!-- Кнопка закрытия -->
        <button class="close-button" (click)="closeActionDetails()">×</button>
        <h3>Action details</h3>
        <pre>{{ selectedActionData | json }}</pre>
      </div>
    </div>
  </div>
</div>
