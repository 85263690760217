<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="mr-b-5">
    <app-renta-error-message [errorMessage]="error" *ngIf="error"></app-renta-error-message>
  </div>
  <div class="p-d-flex p-justify-between">
    <label for="serverName" class="p-d-none"></label>
    <input
      id="serverName"
      class="p-inputtext-lg auth-dialog-input server-name-input"
      appTextInputTrim
      pInputText
      placeholder="Server name"
      type="text"
      [(ngModel)]="ServerName"
      [disabled]="loading"
    />
    <label for="port" class="p-d-none"></label>
    <input
      id="port"
      class="p-inputtext-lg auth-dialog-input server-port-input"
      appTextInputTrim
      pInputText
      placeholder="port"
      [disabled]="loading"
      type="text"
      [(ngModel)]="Port"
    />
  </div>

  <label for="login" class="p-d-none"></label>
  <input
    id="login"
    type="text"
    appTextInputTrim
    pInputText
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    placeholder="Login"
    [(ngModel)]="Login"
    [disabled]="loading"
  />
  <label for="dbPwd" class="p-d-none"></label>
  <input
    id="dbPwd"
    type="password"
    appTextInputTrim
    pInputText
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    placeholder="Database Password"
    [(ngModel)]="DatabasePassword"
    [disabled]="loading"
  />
  <label for="dbName" class="p-d-none"></label>
  <input
    id="dbName"
    type="text"
    appTextInputTrim
    pInputText
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    placeholder="Database Name"
    [(ngModel)]="DatabaseName"
    [disabled]="loading"
  />
  <span class="ip-connection-info ap-ta-center renta-font-normal">
    You should allow connection from next IP: 13.94.215.246, 116.203.213.108
  </span>
  <a href="https://renta.im/docs/destination/clickhouse" class="renta-href check-dock" target="_blank">Check
    documentation for
    details</a>
</div>
