<div class="settings-component-container p-d-flex p-flex-column" *ngIf="dataSource | async as ds$">
  <div class="setting-title-wrapper p-d-flex p-flex-column">
    <div class='p-d-flex'>
      <span class="renta-font-normal setting-title-font">{{ DateRangeDataSource.name }}</span>
      <i *ngIf='toolTipInfo !== null && toolTipInfo !== undefined && toolTipInfo.length > 0' class='renta-ic renta-ic-info-square' [pTooltip]="toolTipInfo" tooltipPosition="top"  style='max-width: 20px;min-width: 20px;max-height: 20px;min-height: 20px'></i>
    </div>
    <span class='renta-font-small renta-subtext-color p-my-1' *ngIf='additionalInfo !== null && additionalInfo !== undefined && additionalInfo.length > 0'>{{additionalInfo}}</span>
  </div>
  <app-extended-prime-calendar
    [styleClass]="'app-extended-prime-calendar'"
    [(ngModel)]="ds$.range"
    [firstDayOfWeek]="1"
    (onSelect)="onSelect()"
    [readonlyInput]="true"
    [showOtherMonths]="true"
    [calendarHeader]="calendarHeader"
    [selectionMode]="'range'"
    [monthNavigator]="true"
    [yearNavigator]="true"
    [customNavigationMode]="false"
    [maxDate]="maxDateLimit"
    [minDate]="minDateLimit"
    [untilTodayDateMode]="untilTodayDateMode"
    [untilYesterdayDateMode]="untilYesterdayDateMode"
    [dataSource]="dataSource"
    ngDefaultControl
    dateFormat="dd.mm.yy"
    [yearRange]="yearRangeLimit"
    (onShow)="onCalendarOpen()">
    <p-header>
      <p-tieredMenu #menu [model]="menuItems"></p-tieredMenu>
    </p-header>
    <div class="ap-datepicker-header"></div>
    <ng-template pTemplate="date" let-date>
      <span>{{date.day}}</span>
    </ng-template>
  </app-extended-prime-calendar>
</div>

<ng-template #calendarHeader>
  <app-calendar-header
    [dataSource]="dataSource"
    [dateFrom]="headerDateFrom"
    [dateTo]="headerDateTo"
    [dateRangeType]="headerDateRangeType"
    (changeToDate)="onCalendarHeaderChange($event)"
  ></app-calendar-header>
</ng-template>
