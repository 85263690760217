import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import * as _ from 'lodash';
import { MenuPopupComponent } from '../components/menu-popup/menu-popup.component';
import { GbuttonHorizontalEnum } from '../models/GbuttonHorizontalEnum';
import { GbuttonVerticalEnum } from '../models/GbuttonVerticalEnum';
import { MenuPopupModel } from '../models/MenuPopupModel';

@Directive({
  selector: '[appMenuPopup]',
})
export class MenuPopupDirective {
  @Input()
  public menuPopupSource: MenuPopupModel;

  @Output()
  public closeMenuPopup: EventEmitter<any> = new EventEmitter<any>();

  public isOpen: boolean = false;

  constructor(
    private view: ViewContainerRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2
  ) {}

  @HostListener('click', ['$event'])
  private elementRefClickHandler(event: MouseEvent): void {
    !this.isOpen ? this.createComponent() : this.destroyComponent();
    event.stopPropagation();
  }

  private createComponent(): void {
    document.body.click();
    this.view.clear();
    this.isOpen = true;
    const comp = this.componentFactoryResolver.resolveComponentFactory(MenuPopupComponent);
    const popupMenuComponent = this.view.createComponent(comp);

    popupMenuComponent.instance.menuPopupData = this.menuPopupSource;
    popupMenuComponent.instance.selfDestruction = (): void => {
      this.destroyComponent();
    };
    popupMenuComponent.instance.setPosition = (popupMenu: ElementRef): void => {
      this.setPosition(popupMenu);
    };
  }

  private destroyComponent(): void {
    this.isOpen = false;
    this.view.clear();
    this.closeMenuPopup.emit(null);
  }

  private setPosition(popupMenu: ElementRef): void {
    this.renderer.setStyle(popupMenu.nativeElement, 'width', this.menuPopupSource.width + 'px');
    this.renderer.setStyle(popupMenu.nativeElement, 'left', this.menuPopupSource.left + 'px');

    if (this.menuPopupSource.horizontal === GbuttonHorizontalEnum.left) {
      const left = popupMenu.nativeElement.offsetLeft - popupMenu.nativeElement.offsetWidth;
      this.renderer.setStyle(popupMenu.nativeElement, 'left', left + 'px');
    }

    if (this.menuPopupSource.horizontal === GbuttonHorizontalEnum.right) {
      const left = popupMenu.nativeElement.offsetLeft + popupMenu.nativeElement.offsetWidth;
      this.renderer.setStyle(popupMenu.nativeElement, 'left', left + 'px');
    }

    if (this.menuPopupSource.vertical === GbuttonVerticalEnum.top) {
      const top = popupMenu.nativeElement.offsetTop - popupMenu.nativeElement.offsetHeight;
      this.renderer.setStyle(popupMenu.nativeElement, 'top', top + 'px');
    }

    if (this.menuPopupSource.vertical === GbuttonVerticalEnum.bottom) {
      const top = popupMenu.nativeElement.offsetTop + popupMenu.nativeElement.offsetHeight;
      this.renderer.setStyle(popupMenu.nativeElement, 'top', top + 'px');
    }

    const menuRect = popupMenu.nativeElement.getBoundingClientRect();
    const restTop = Math.round(menuRect.top);

    if (window.innerHeight <= restTop + popupMenu.nativeElement.offsetHeight) {
      const top = -popupMenu.nativeElement.offsetHeight + (-this.menuPopupSource.top || 0);
      this.renderer.setStyle(popupMenu.nativeElement, 'top', top + 'px');

      setTimeout((): void => {
        this.menuPopupSource.classList = _.replace(this.menuPopupSource.classList, 'top-right', 'bottom-right');
        this.menuPopupSource.classList = _.replace(this.menuPopupSource.classList, 'left-top', 'left-bottom');
      });
    } else {
      const top = this.menuPopupSource.top ? this.menuPopupSource.top : popupMenu.nativeElement.offsetTop;
      this.renderer.setStyle(popupMenu.nativeElement, 'top', top + 'px');

      setTimeout((): void => {
        this.menuPopupSource.classList = _.replace(this.menuPopupSource.classList, 'bottom-right', 'top-right');
        this.menuPopupSource.classList = _.replace(this.menuPopupSource.classList, 'left-bottom', 'left-top');
      });
    }
  }
}
