import { Injectable } from '@angular/core';
import { SettingsServiceBase } from './SettingsServiceBase';
import { FbAdsAddonMeta } from '../../../../models/Integration/IntegrationsMeta/FbAdsAddonMeta';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { RentaApiService } from '../../../../services/renta-api.service';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import * as _ from 'lodash';
import { ApiResponse } from '../../../../models/common/ApiResponse`T';

@Injectable({
  providedIn: 'root'
})
export class FbAdsAddonSettingsService extends SettingsServiceBase<FbAdsAddonMeta>{

  public meta: FbAdsAddonMeta;

  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.fbAdsAddon;

  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      EventTypes:  null,
      EventNames:  null,
      Parameters:  null,
      Filters:  null,
      Output:  null,

      FbPixel:  null,
      FacebookPixelEventName:  null,
      ActionSource:  null,
      Accounts:  null,
      TestEventCode: null
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };
  private pixelsData: {};
  private propertiesData: Array<ListItemModel>;

  constructor(
    public integrationInfoData: IntegrationInfoViewModel,
    protected readonly rentaApiService: RentaApiService,
    protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService)
  }

  public isETL(): boolean {
    return false;
  }



  public loadSettings(): Observable<IntegrationSettings> {
    if (this.loading.getValue() === false) {
      this.loading.next(true);
      this.rentaApiService.initIntegration(this.getWizardRequestData()).pipe(
        map<any, IntegrationSettings>((res: any): IntegrationSettings => {
          this.meta = res.meta;
          return this.mapIntegrationSettings(res, this.meta.integrationName || '');
        }),
      ).subscribe((res: IntegrationSettings): void => {
        this.initSelectedSettings(res);
        this.loading.next(false);
        this.sourceIntegrationSettings.next(res);
      });
    }

    return this.sourceIntegrationSettings.asObservable().pipe(filter((result: IntegrationSettings): boolean => result !== null));
  }

  public setAccount(selectedEventType: ListItemModel): boolean {
    if (_.isNil(selectedEventType) || _.some(this.selectedSettings.Accounts, selectedEventType)) {
      return false;
    }

    this.selectedSettings.Accounts = [selectedEventType];
    return true;
  }

  public getFbPixel($event: ListItemModel): Observable<Array<ListItemModel>> {
    return of<Array<ListItemModel>>(this.pixelsData[$event.id]);
  }

  public setFbPixel(selectedEventType: ListItemModel): boolean {
    if (_.isNil(selectedEventType) || _.some(this.selectedSettings.FbPixel, selectedEventType)) {
      return false;
    }

    this.selectedSettings.FbPixel = [selectedEventType];
    return true;
  }

  public setEventType(selectedEventType: ListItemModel): boolean {
    if (_.isNil(selectedEventType) || _.some(this.selectedSettings.EventTypes, selectedEventType)) {
      return false;
    }

    this.selectedSettings.EventTypes = [selectedEventType];
    return true;
  }

  public setEventName(selectedEventName: ListItemModel): boolean {
    if (_.isNil(selectedEventName) || _.some(this.selectedSettings.EventNames, selectedEventName)) {
      return false;
    }

    this.selectedSettings.EventNames = [selectedEventName];
    return true;
  }

  public setEventParameters(eventParameters: Array<{ key: string, value: string }>): boolean {
    if (_.isNil(eventParameters)) {
      return false;
    }

    this.selectedSettings.Output = eventParameters.map(item => ({parameter: item.key, value: item.value})) ;
    return true;
  }

  public getEventFilters(selectedEventType: ListItemModel, selectedEventName?: ListItemModel): Observable<Array<ListItemModel>> {
    const req = this.getParametersFiltersRequestData(true, selectedEventType, selectedEventName);
    return this.getParametersFiltersData(req.url, req.body).pipe(map((res: { parameters: Array<ListItemModel>, filters: Array<ListItemModel> }): ListItemModel[] => res.filters));
  }

  public getEventParametersAndProperties(selectedEventType: ListItemModel, selectedEventName?: ListItemModel): Observable<{keys: Array<ListItemModel>, values: Array<ListItemModel>}> {
    const req = this.getParametersFiltersRequestData(false, selectedEventType, selectedEventName);
    return this.getParametersFiltersData(req.url, req.body).pipe(map((res: { parameters: Array<ListItemModel>, filters: Array<ListItemModel> }): {keys: Array<ListItemModel>, values: Array<ListItemModel>} => {
      return {keys: this.propertiesData, values: res.parameters};
    }));
  }

  public setFilters(selectedFilters: Array<ListItemModel>): boolean {
    if (_.isNil(selectedFilters)) {
      return false;
    }

    this.selectedSettings.Filters = selectedFilters;
    return true;
  }

  public getEventNames(selectedEventType: ListItemModel): Observable<Array<ListItemModel>> {
    const eventType: string = selectedEventType.id;

    if (_.isNil(eventType)) {
      return of<[]>([]);
    }

    const url: string = this.meta.eventNamesUrl.replace('{eventType}', eventType);
    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };
    return this.rentaApiService.httpClient
      .post<ApiResponse<{ eventNames: Array<ListItemModel> }>>(url, body)
      .pipe(
        tap((res: ApiResponse<{ eventNames: Array<ListItemModel> }>): void => console.log(res)),
        map((res: ApiResponse<{ eventNames: Array<ListItemModel> }>): Array<ListItemModel> => res.result.eventNames)
      );
  }

  public setIntegrationName(name: string): void {
    this.selectedIntegrationSettings.Name = name;
  }

  public connectToSourceDestinationLoading(): Observable<boolean> {
    return of<boolean>(false);
  }

  public setTableName(tableName: string): void {
    console.error("NOT USE 'setTableName' in webhook addon settings")
  }

  public setPixelEventName(selectedEventType: ListItemModel): boolean {
    if (_.isNil(selectedEventType) || _.some(this.selectedSettings.Accounts, selectedEventType)) {
      return false;
    }

    this.selectedSettings.FacebookPixelEventName = [selectedEventType];
    return true;
  }

  public setActionSource(selectedEventType: ListItemModel): boolean {
    if (_.isNil(selectedEventType) || _.some(this.selectedSettings.Accounts, selectedEventType)) {
      return false;
    }

    this.selectedSettings.ActionSource = [selectedEventType];
    return true;
  }

  public setTestEventCode(testEventCode: string): void {
    this.selectedSettings.TestEventCode = testEventCode;
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].EventTypes = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].EventNames = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].Output = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].Filters = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].FacebookPixelEventName = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].ActionSource = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].Accounts = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].FbPixel = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].TestEventCode = null;
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings): void {
    this.selectedIntegrationSettings.Id = initialSettings.Id;
    this.selectedSettings.EventTypes = initialSettings[this.integrationTypeEnum].EventTypes.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.EventNames = initialSettings[this.integrationTypeEnum].EventNames.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.Filters = initialSettings[this.integrationTypeEnum].Filters.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.FacebookPixelEventName = initialSettings[this.integrationTypeEnum].FacebookPixelEventName.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.ActionSource = initialSettings[this.integrationTypeEnum].ActionSource.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.Accounts = initialSettings[this.integrationTypeEnum].Accounts.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.FbPixel = initialSettings[this.integrationTypeEnum].FbPixel.filter(
      (f: ListItemModel): boolean => f.selected
    );

    this.selectedSettings.TestEventCode = initialSettings[this.integrationTypeEnum].TestEventCode;
    this.selectedSettings.Output = initialSettings[this.integrationTypeEnum].Output || [{parameter: '', value: ''}];

    this.selectedIntegrationSettings.Name = initialSettings.Name;

    this.selectedIntegrationSettings.TableName = null;
    this.selectedIntegrationSettings.OverwritePeriod  = null;
    this.selectedIntegrationSettings.DateRange = null;
    this.selectedIntegrationSettings.Date = null;
    this.selectedIntegrationSettings.Schedule = null;
    this.selectedIntegrationSettings.DateRange = null;
  }

  protected mapIntegrationSettings(response: any, integrationName: string): IntegrationSettings {
    this.pixelsData = response.accounts.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.values }), {});
    const selectedAccount = response.accounts.find(f => f.selected)?.id;
    this.propertiesData = response.properties || [];
    return {
      Id: null,
      DateRange: [],
      Date: null,
      Schedule: [],
      OverwritePeriod: [],
      [this.integrationTypeEnum]: {
        EventTypes: response.eventTypes || [],
        EventNames: response.eventNames || [],
        Parameters: response.parameters || [],
        Output: response.output || [],
        Filters: response.filters || [],
        FacebookPixelEventName: response.facebookPixelEventName || [],
        ActionSource: response.actionSource || [],
        Accounts: response.accounts || [],
        FbPixel:  this.pixelsData[selectedAccount] || [],
        TestEventCode: response.testEventCode,
        Properties: this.propertiesData
      },
      Name: integrationName,
      TableName: null
    };
  }

  protected refreshParameters(): Observable<any> {
    return of<{}>(null);
  }

  private getParametersFiltersRequestData(forFilters: boolean, selectedEventType: ListItemModel, selectedEventName?: ListItemModel): {url: string, body: any} {
    const metaUrl = forFilters ? this.meta.filtersUrl : this.meta.parametersUrl;

    let url: string = metaUrl.replace('{eventType}', selectedEventType.id);

    if (_.isNil(selectedEventName)) {
      url = url.replace('{eventName}', '').slice(0, -1);
    } else {
      url = url.replace('{eventName}', selectedEventName?.id || '');
    }

    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };
    return { url, body };
  }

  private getParametersFiltersData(url: string, body: any): Observable<{ parameters: Array<ListItemModel>, filters: Array<ListItemModel> }> {
    return this.rentaApiService.httpClient
      .post<ApiResponse<{ parameters: Array<ListItemModel>, filters: Array<ListItemModel> }>>(url, body)
      .pipe(
        tap((res: ApiResponse<{ parameters: Array<ListItemModel>, filters: Array<ListItemModel> }>): void => console.log(res)),
        map((res: ApiResponse<{ parameters: Array<ListItemModel>, filters: Array<ListItemModel> }>): { parameters: Array<ListItemModel>, filters: Array<ListItemModel> } => res.result)
      );
  }
}
