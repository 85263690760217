import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RentaDropdownDataSource } from '../../../../models/DataSource/RentaDropdownDataSource';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';

@Component({
  selector: 'app-filter-edit',
  templateUrl: './filter-edit.component.html',
  styleUrls: ['./filter-edit.component.scss'],
})
export class FilterEditComponent implements OnInit {
  @Input()
  public Filters: Array<ListItemModel>;

  @Input()
  public Selected: ListItemModel;

  @Output()
  public ApplySave: EventEmitter<ListItemModel> = new EventEmitter<ListItemModel>();

  @Output()
  public ApplyCancel: EventEmitter<any> = new EventEmitter<any>();

  public FiltersDataSource: RentaDropdownDataSource;
  public OperatorsDataSource: RentaDropdownDataSource;
  public dsFilters: Observable<Array<ListItemModel>> = null;
  public dsOperators: Observable<Array<ListItemModel>> = null;
  public filterInput: string;

  constructor() {}

  public ngOnInit(): void {
    this.setDefault();

    this.dsFilters.subscribe((__: Array<ListItemModel>): void => {
      if (_.isNil(this.Selected.id) || _.isNil(this.FiltersDataSource.getSelectedItem())) {
        return;
      }

      if (this.Selected.id === this.FiltersDataSource.getSelectedItem().id) {
        return;
      }

      this.Selected = this.FiltersDataSource.getSelectedItem();
      this.Selected.operators = this.unselectAll(this.Selected.operators);
      this.Selected.value = null;
      this.OperatorsDataSource.refresh(this.Selected.operators);
    });

    this.dsOperators.subscribe((res: Array<ListItemModel>): void => {
      this.Selected.operators = res;
    });
  }

  public isSelectedFilterValid(): boolean {
    let condition: boolean = true;

    if (_.isNil(this.Selected.id) || this.Selected.id.length === 0) {
      condition = false;
    }

    if (condition) {
      condition = false;
      this.Selected.operators.forEach((elem: ListItemModel): void => {
        if (!condition && elem.selected) {
          condition = elem.selected;
        }
      });
    }

    if (condition) {
      if (_.isNil(this.Selected.value) || this.Selected.value.length === 0) {
        condition = false;
      }
    }

    return condition;
  }

  public save(): void {
    this.ApplySave.emit(this.Selected);
  }

  public cancel(): void {
    this.ApplyCancel.emit();
  }

  private setDefault(): void {
    this.FiltersDataSource = new RentaDropdownDataSource('', this.unselectAll(this.Filters));
    this.OperatorsDataSource = new RentaDropdownDataSource('', []);
    this.dsFilters = this.FiltersDataSource.connect();
    this.dsOperators = this.OperatorsDataSource.connect();

    if (_.isNil(this.Selected)) {
      this.Selected = {
        id: '1',
        name: '',
        value: '',
        operators: [],
      };
    } else {
      this.Selected.selected = true;
      this.FiltersDataSource.selectItem(this.Selected);
      this.OperatorsDataSource.refresh(this.Selected.operators);
    }
  }

  private unselectAll(arr: Array<ListItemModel>): Array<ListItemModel> {
    return arr.map((elem: ListItemModel): ListItemModel => {
      elem.selected = false;
      return elem;
    });
  }
}
