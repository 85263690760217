import { Injectable } from '@angular/core';
import { RentaApiService } from './renta-api.service';
import { IntegrationTypeEnum } from '../models/common/IntegrationTypeEnum';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/common/ApiResponse`T';
import { map } from 'rxjs/operators';
import { IntegrationToken } from '../models/Integration/IntegrationToken';
import { IntegrationSourceModel } from '../models/Integration/IntegrationSourceModel';

@Injectable({
  providedIn: 'root',
})
export class TokensServiceService {
  constructor(private readonly rentaApiService: RentaApiService) {}

  public createNewToken(integrationType: IntegrationTypeEnum, body: {}): Observable<string> {
    return this.rentaApiService.saveToken(integrationType, body).pipe(
      map<ApiResponse<{}>, string>((res: ApiResponse<{}>): string => {
        if (res.baseResponse.status) {
          return null;
        }

        return res.baseResponse.errors[0].userMessage;
      })
    );
  }

  public removeToken(integrationType: IntegrationTypeEnum, tokenId: string): Observable<string> {
    return this.rentaApiService.removeToken(integrationType, tokenId).pipe(
      map<ApiResponse<{}>, string>((res: ApiResponse<{}>): string => {
        if (res.baseResponse.status) {
          return null;
        }

        return res.baseResponse.errors[0].userMessage;
      })
    );
  }

  public getStorages(integrationType: IntegrationTypeEnum, id: string): Observable<Array<IntegrationSourceModel>> {
    return this.rentaApiService.getStorages(integrationType, id);
  }

  public getStorageTokens(integrationType: IntegrationTypeEnum, projectId: string, dbId: string): Observable<Array<IntegrationToken>> {
    return this.rentaApiService.getStorageTokens(integrationType, projectId, dbId);
  }
}
