import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-integration-token-rows-loading-template',
  templateUrl: './integration-token-rows-loading-template.component.html',
  styleUrls: [
    '../token-row-styles.scss',
    './integration-token-rows-loading-template.component.css'
  ]
})
export class IntegrationTokenRowsLoadingTemplateComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {

  }

}
