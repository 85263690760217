<div class='p-d-flex p-flex-column width-max' *ngIf='CollectionDataSource | async; let ds'>
  <div class="setting-title-wrapper names-wrapper width-max p-mb-2 p-d-flex ">
    <div class='f-grow-1 p-d-flex' >
    <span class="renta-font-small renta-text-color setting-title-font" >{{ Collection.keyName }} </span>
      <i class='renta-ic renta-ic-info-square' pTooltip="Specify which JS event values you want to synchronize with the custom destination fields" tooltipPosition="top" style='max-width: 20px;'></i>
    </div>
    <span class="renta-font-small renta-text-color setting-title-font f-grow-1 value-margin">{{ Collection.valueName }}</span>
  </div>
  <div *ngFor='let item of ds; let i = index' class="width-max p-mb-2">
    <app-renta-key-value-dropdowns-row
      [Id]='i'
      [KeyValueDropdownRowDataSource]='item'
      (OnDelete)='onDeleteItem(i)'
      [CanRemove]='ds.length > 1'
      (OnValidChange)='onValidChange($event)'
    >
    </app-renta-key-value-dropdowns-row>
  </div>
  <div class='p-mt-2 p-ml-1 add-btn-container'>
    <p-button styleClass="renta-btn-secondary renta-font-small" (onClick)="onAdd()" [label]="'ADD FIELD'" [disabled]='!isValid'>
    </p-button>
  </div>
  <app-renta-error-message [errorMessage]="Collection.error"
                           *ngIf="Collection.error"></app-renta-error-message>
</div>
