<div class='height-fix'>
  <p-progressBar mode='indeterminate' styleClass='modal-preloader' *ngIf='isLoading || isRefreshing'></p-progressBar>
</div>
<div class='modal-token-wrapper webhook-container p-d-flex p-jc-start p-ai-center p-flex-column width-max p-py-5 p-px-6'>

  <div class='p-mt-3 p-mb-2 p-d-flex p-flex-column p-ai-center '>
    <span class='renta-font-big p-text-bold'>Configure Webhook destination</span>
  </div>

  <div *ngIf='!isLoaded' class='p-mt-3'>
    <i class='drop-down-icon pi pi-spin pi-spinner'></i>
  </div>

  <div class='p-d-flex p-flex-column width-max' *ngIf='isLoaded'>
    <div
      class='integration-settings-container modal-token-form-container width-max p-d-flex p-flex-column p-px-6 p-py-4'>
      <div class='p-py-2'>
        <app-renta-input [RentaInputSource]='NameDataSource' (OnApply)='onApplyName($event)'></app-renta-input>
      </div>
      <div class='p-py-2'>
        <app-renta-dropdown [RentaDropdownSource]='SendingMethodDataSource'
                            [class.disabled]='isRefreshing'
                            [CanSearch]='false'
                            (OnApply)='onApplySendingMethod($event)'></app-renta-dropdown>
      </div>
      <div class='p-py-2'>
        <app-renta-input [RentaInputSource]='HostDataSource' (OnApply)='onApplyHost($event)'></app-renta-input>
      </div>
    </div>

    <div
      class='integration-settings-container modal-token-form-container width-max p-d-flex p-flex-column p-px-6 p-py-4'>
      <div class='p-py-2 p-d-flex width-max switcher-row'>
        <div class='p-d-flex p-flex-column description'>
          <span class='renta-font-normal p-text-bold'>Headers</span>
          <span
            class='renta-font-small renta-subtext-color'>The header will not be used.</span>
        </div>
        <div class='p-d-flex p-jc-end p-ai-center switcher'>
          <app-renta-switcher
            [Disabled]='isRefreshing'
            [IsChecked]='headers'
            (OnSwitcherChange)='onSwitcherHeadersChange($event)'
          ></app-renta-switcher>
        </div>
      </div>
      <div class='width-max p-d-flex width-max' *ngIf='headers'>
        <div class='p-py-2'>
          <div class='webhook-container-border'></div>
        </div>
        <div class='p-d-flex p-flex-column width-max'>
          <div class='p-py-2'>
            <app-renta-key-value-collection
              [Collection]='HeaderOptionsDataSource'
            ></app-renta-key-value-collection>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class='p-d-flex p-justify-end width-max p-mt-auto p-pt-2' *ngIf='isLoaded'>
    <p-button
      styleClass='modal-btn renta-btn-secondary close-btn'
      type='submit'
      label='close'
      [disabled]='isRefreshing'
      (onClick)='onClose()'>
      <div class='spinner-container p-d-flex p-align-center p-justify-start'>
      </div>
    </p-button>
    <p-button
      styleClass='modal-btn renta-btn-primary create-btn-wrapper'
      type='submit'
      label='{{ tokenId ? "update" : "create" }}'
      [disabled]='isRefreshing'
      (onClick)='create()'>
      <div class='spinner-container p-d-flex p-align-center p-justify-start'>
      </div>
    </p-button>
  </div>
</div>

