<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="mr-b-5">
    <app-renta-error-message [errorMessage]="error"
                             *ngIf="error"></app-renta-error-message>
  </div>
  <div class="p-d-flex p-align-center inline-block-container">
    <div class="inline-block mr-r-5">
      <label for="serverName" class="p-d-none"></label>
      <input id="serverName"
             class="p-inputtext-lg auth-dialog-input"
             type="text"
             placeholder="Server name"
             pInputText
             appTextInputTrim
             [(ngModel)]="ip"
             [disabled]="loading">
    </div>
    <div class="inline-block mr-l-5">
      <label for="serverId" class="p-d-none"></label>
      <input id="serverId"
             class="p-inputtext-lg auth-dialog-input"
             type="text"
             placeholder="3306"
             pInputText
             appTextInputTrim
             [(ngModel)]="port"
             [disabled]="loading">
    </div>
  </div>
  <label for="login" class="p-d-none"></label>
  <input id="login"
         class="p-inputtext-lg auth-dialog-input dialog-input-margin"
         type="text"
         placeholder="Login"
         pInputText
         appTextInputTrim
         [(ngModel)]="login"
         [disabled]="loading">
  <label for="password" class="p-d-none"></label>
  <input id="password"
         class="p-inputtext-lg auth-dialog-input dialog-input-margin"
         type="password"
         placeholder="Database Password"
         pInputText
         appTextInputTrim
         [(ngModel)]="password"
         [disabled]="loading">
</div>
