import { Component, Inject, OnInit } from '@angular/core';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import {
  DateRangeEnum,
  RentaDateRangeDataSource
} from '../../../../../shared/models/DataSource/RentaDateRangeDataSource';
import { IEtlSettingsService } from '../../../../services/integrationSettings/IEtlSettingsService';
import { SelectedDateRangeViewModel } from '../../../../../../models/viewModels/SelectedDateRangeViewModel';
import { ETL_SETTINGS_SERVICE } from '../../../../services/DiExtensions';
import { IntegrationSettingsBase } from '../../../../../../models/Integration/IntegrationSettings';

@Component({
  templateUrl: './schedule-settings.component.html',
  styleUrls: ['../common-settings.scss']
})
export class ScheduleSettingsComponent implements OnInit {
  public isShowOverwritePeriod: boolean;
  public isShowDateRange: boolean;
  public updateTimeDropdownDataSource: RentaDropdownDataSource;
  public overwritePeriodDropdownDataSource: RentaDropdownDataSource;
  public dateRangeDataSource: RentaDateRangeDataSource;
  public isLoaded: boolean = true;

  constructor(@Inject(ETL_SETTINGS_SERVICE) private settingsService: IEtlSettingsService) {
  }

  public ngOnInit(): void {
    this.dateRangeDataSource = new RentaDateRangeDataSource('Date Range', null, null, null);
    this.updateTimeDropdownDataSource = new RentaDropdownDataSource('Update Time', []);
    this.overwritePeriodDropdownDataSource = new RentaDropdownDataSource('Overwrite Period', []);
    this.settingsService.isShowOverwritePeriod().subscribe((res: boolean): boolean => (this.isShowOverwritePeriod = res));
    this.isShowDateRange = this.settingsService.isShowDateRange();

    this.settingsService.connectToLoading().subscribe((res: boolean): void => {
      setTimeout((): void => {
        this.isLoaded = !res;
      });
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettingsBase): void => {
      if (this.isShowDateRange) {
        this.dateRangeDataSource.refreshDataSource(
          res?.Date?.startDate,
          res?.Date?.endDate,
          (res.DateRange.find((f: ListItemModel): boolean => f.selected).id as unknown) as DateRangeEnum
        );
      }

      this.updateTimeDropdownDataSource.refresh(res.Schedule);
      this.overwritePeriodDropdownDataSource.refresh(res.OverwritePeriod);
    });
  }

  public onApplyUpdateTimeType($event: ListItemModel): void {
    this.settingsService.setUpdateTime($event);
  }

  public onApplyDateRange($event: SelectedDateRangeViewModel): void {
    this.settingsService.setDateRange($event);
  }

  public onApplyOverwritePeriod($event: ListItemModel): void {
    this.settingsService.setOverwritePeriod($event);
  }
}
