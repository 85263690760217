import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-renta-error-message',
  templateUrl: './renta-error-message.component.html',
  styleUrls: ['./renta-error-message.component.css']
})
export class RentaErrorMessageComponent implements OnInit {
  @Input()
  public errorMessage: string;

  @Input()
  public shortVersion: boolean = false;

  constructor() {
  }

  public ngOnInit(): void {
  }

}
