import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { KeyValueDropdownDataSource } from '../../models/DataSource/KeyValueDropdownDataSource';
import { KeyValueDropdownRowDataSource } from '../../models/DataSource/KeyValueDropdownRowDataSource';

@Component({
  selector: 'app-renta-key-value-dropdown',
  templateUrl: './renta-key-value-dropdown.component.html',
  styleUrls: ['../internal/base-settings-styles.css', './renta-key-value-dropdown.component.scss']
})
export class RentaKeyValueDropdownComponent implements OnInit {
  @Input()
  public Collection: KeyValueDropdownDataSource;
  @Output()
  public OnApplyCollection: EventEmitter<Array<{ parameter: string, value: string }>> = new EventEmitter<Array<{ parameter: string; value: string }>>();

  public isValid: boolean;
  public canEdit: boolean;
  public CollectionDataSource: Observable<Array<KeyValueDropdownRowDataSource>>;

  constructor(private changeDetectionRef: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.CollectionDataSource = this.Collection.connect();

    this.Collection.addEmptyKeyValueRow();
    this.Collection.isDataSourceValid().subscribe((res: boolean): void => {
      this.isValid = res;
      this.changeDetectionRef.detectChanges();
    });

    this.Collection.isCanEdit().subscribe((res: boolean): void => {
      this.canEdit = res;
      this.changeDetectionRef.detectChanges();
    });
  }

  public onDeleteItem(index: number): void {
    this.Collection.removeKeyValueRow(index);
    this.OnApplyCollection.emit(this.Collection.getSelectedData());
  }

  public onAdd(): void {
    this.Collection.addEmptyKeyValueRow();
    // this.Collection.checkValidation();
  }

  public onValidChange(__: boolean): void {
    this.Collection.checkValidation()
    this.OnApplyCollection.emit(this.Collection.getSelectedData());
  }
}
