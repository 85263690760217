import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RentaInputDataSource } from '../../models/DataSource/RentaInputDataSource';
import { Observable } from 'rxjs';
import { RentaInputDataSourceModel } from '../../models/DataSource/DataSourceModels/RentaInputDataSourceModel';

@Component({
  selector: 'app-renta-input-num',
  templateUrl: './renta-input-num.component.html',
  styleUrls: ['../internal/base-settings-styles.css'],
})
export class RentaInputNumComponent implements OnInit {
  @Input() public toolTipInfo: string;
  @Input() public RentaInputSource: RentaInputDataSource;
  @Input() public additionalInfo: string;
  @Input() public IsShowName: boolean = true;
  @Input() public min: number
  @Input() public max: number

  @Output() public OnApply: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('rentaInput', { static: false }) public element: ElementRef;

  public ds: Observable<RentaInputDataSourceModel>;

  public ngOnInit(): void {
    this.ds = this.RentaInputSource.connect();
    const val = this.RentaInputSource.getValue();
    if (val) {
      this.onSetChange(val);
    }
  }

  public getId(name: string): string {
    return name.replace(/ /g, '_') + 'DataSource';
  }

  public onChange(val: any): void {
    this.RentaInputSource.refreshValue(val);
  }

  public onSetChange(value: any): void {
    const numValue = parseInt(value, 10);
    this.RentaInputSource.refreshValue(String(numValue));
    this.OnApply.emit(numValue);
  }
}
