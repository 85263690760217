import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-renta-switcher',
  templateUrl: './renta-switcher.component.html',
  styleUrls: ['./renta-switcher.component.scss']
})
export class RentaSwitcherComponent {
  @Input()
  public Disabled: boolean = false;

  @Input()
  public IsChecked: boolean = false;

  @Output()
  public OnSwitcherChange: EventEmitter<{originalEvent: PointerEvent, checked: boolean}> = new EventEmitter<{originalEvent: PointerEvent, checked: true}>();

  public stateChange(e: {originalEvent: PointerEvent, checked: boolean}): void {
    this.OnSwitcherChange.emit(e);
  }
}
