<div class="setting-row-wrapper p-d-flex p-align-start" [class.disabled]="isDisabled">
  <div class="width-max">
    <app-renta-dropdown
      *ngIf="!(isLoaded | async); else loadingSetting"
      [RentaDropdownSource]="SourceDataSource"
      [ExtendedDescription]="true"
      (OnApply)="onApplySource($event)"
    ></app-renta-dropdown>
  </div>
  <div class="add-source-account-button mr-l-5">
    <p-button styleClass="renta-btn-secondary" (onClick)="addSourceAccount()" [disabled]='isLoaded | async'>
      <i class="pi pi-plus"></i>
    </p-button>
  </div>
</div>
<div class="setting-row-wrapper p-d-flex p-align-end" [class.disabled]="isDisabled">
  <div class="width-max">
    <app-renta-dropdown
      *ngIf="!(isLoaded | async); else loadingSetting"
      [RentaDropdownSource]="DestinationDataSource"
      (OnApply)="onApplyDestinationType($event)"
    ></app-renta-dropdown>
  </div>
</div>
<div class="setting-row-wrapper p-d-flex p-align-start" [class.disabled]="isDisabled">
  <div class="width-max">
    <app-renta-dropdown
      *ngIf="!(isLoaded | async); else loadingSetting"
      [RentaDropdownSource]="DestinationAccountsDataSource"
      [ExtendedDescription]="true"
      (OnApply)="onApplyDestinationToken($event)"
    ></app-renta-dropdown>
  </div>
  <div class="add-source-account-button mr-l-5">
    <p-button styleClass="renta-btn-secondary" (onClick)="addDestinationAccount()" [disabled]='isLoaded | async'>
      <i class="pi pi-plus"></i>
    </p-button>
  </div>
</div>
<ng-template #loadingSetting>
  <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
  <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
</ng-template>
