<div class="p-d-flex p-flex-column p-ai-center p-jc-center dialog-wrapper p-p-5">
  <div class="p-m-auto p-pb-3">
    <app-checkmark
      [Success]="isSuccess"
      [Title]="title"
      [TitleDescription]="info"
      [PositionCheckMark]="'top'"
      [TextAlign]="'center'"
    ></app-checkmark>
  </div>
  <div class="p-d-flex" *ngIf="submitLabel">
    <p-button styleClass="ok-btn" (click)="submit()" label="{{ submitLabel }}"></p-button>
  </div>
</div>
