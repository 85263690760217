
<div class="integration-settings-container p-d-flex p-flex-column p-p-6">
  <div class='p-d-flex p-flex-column description p-p-1'  *ngIf='isLoaded; else loadingSetting'>
    <span class='renta-font-normal p-text-bold'>Event type</span>
    <span class='renta-font-small renta-subtext-color p-mt-1'>Select the Javascript SDK event from which you want to sync data.</span>
  </div>

  <div class='setting-row-wrapper p-mt-2'>
    <app-renta-dropdown *ngIf='isLoaded; else loadingSetting'
                        [RentaDropdownSource]='EventTypesDataSource'
                        (OnApply)='onEventTypeApply($event)'
                        [ShowName]='false'></app-renta-dropdown>
  </div>
  <div class='setting-row-wrapper p-d-flex p-ai-end' *ngIf='isShowEventNames'>
    <div class='width-max'>
      <app-renta-dropdown *ngIf='isLoaded; else loadingSetting'
                          [RentaDropdownSource]='EventNameDataSource'
                          (OnApply)='onEventNameApply($event)'></app-renta-dropdown>
    </div>
    <div class="p-ml-2">
      <p-button styleClass="renta-btn-secondary" (onClick)="onRefreshEventNames()">
        <i class="pi pi-refresh"></i>
      </p-button>
    </div>
  </div>
      <div class='setting-row-wrapper'>
        <app-renta-setting-filters
          *ngIf="isLoaded; else loadingSetting"
          (OnApply)="onApplyFilters($event)"
          [RentaSettingsFiltersSource]="FiltersDataSource">
        </app-renta-setting-filters>
      </div>

      <div class='setting-row-wrapper p-p-0'>
        <app-renta-key-value-dropdown
          [Collection]='OutputDataSource'
          (OnApplyCollection)='onApplyCollection($event)'
        ></app-renta-key-value-dropdown>
      </div>

</div>
<ng-template #loadingSetting>
  <p-skeleton styleClass='p-mb-2' width='120px' height='24px' borderRadius='8px'></p-skeleton>
  <p-skeleton styleClass='p-mb-3' height='37px' borderRadius='8px'></p-skeleton>
</ng-template>
