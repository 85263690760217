import { Injectable } from '@angular/core';
import { RentaApiService } from '../../../services/renta-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IntegrationInfoViewModel } from '../../../models/viewModels/CreateIntegrationViewModel';
import { RentaModalsService } from '../../shared/services/renta-modals.service';

import { isNullOrEmpty } from '../../../helpers/helpers';
import { tap } from 'rxjs/operators';
import { IntegrationService } from './integration.service';
import { StorageService } from '../../../services/storage.service';
import { IntegrationTypeEnum } from '../../../models/common/IntegrationTypeEnum';
import { BaseResponse } from '../../../models/common/BaseResponse';
import { IntegrationSourceModel } from '../../../models/Integration/IntegrationSourceModel';

export enum CreationState {
  Source = 0,
  Destination = 1,
  Settings = 2,
}

@Injectable()
export class CreateIntegrationService extends IntegrationService {
  private state: BehaviorSubject<CreationState> = new BehaviorSubject<CreationState>(null);

  private selectedSourceIntegrationType: BehaviorSubject<IntegrationSourceModel> = new BehaviorSubject<IntegrationSourceModel>(null);

  constructor(private readonly storageService: StorageService, rentaApiService: RentaApiService, modalDialogService: RentaModalsService) {
    super(rentaApiService, modalDialogService);
    const savedIntegrationInfo = sessionStorage.getItem('integrationInfoViewModel');
    this.integrationInfoViewModel = isNullOrEmpty(savedIntegrationInfo) ? new IntegrationInfoViewModel() : JSON.parse(savedIntegrationInfo);
    this.setState();
  }

  public checkState(): Observable<CreationState> {
    this.setState();
    return this.state.asObservable();
  }

  // TODO: common
  public getIntegrationType(): Observable<IntegrationSourceModel> {
    return this.selectedSourceIntegrationType.asObservable();
  }

  public getAvailableIntegrationSource(): Observable<Array<IntegrationSourceModel>> {
    return this.rentaApiService.getAvailableIntegrationSource().pipe(
      tap((res: Array<IntegrationSourceModel>): void => {
        this.storageService.sessionUpdateItem('AvailableIntegrationSource', res);
      })
    );
  }

  // TODO: common
  public setSource(integrationType: IntegrationSourceModel, integrationToken: string, integrationName: string): void {
    const type = this.integrationInfoViewModel.integrationSourceType;
    const token = this.integrationInfoViewModel.integrationSourceToken;

    if (type?.integrationType === integrationType?.integrationType && token === integrationToken) {
      return;
    }

    super.setSource(integrationType, integrationToken, integrationName);
    super.setDestination(null, null, null);
    sessionStorage.setItem('integrationInfoViewModel', JSON.stringify(this.integrationInfoViewModel));
    this.setState();
  }

  // TODO: common
  public setDestination(integrationType: IntegrationSourceModel, integrationToken: string, integrationName: string): void {
    const type = this.integrationInfoViewModel.integrationDestinationType;
    const token = this.integrationInfoViewModel.integrationDestinationToken;

    if (type?.integrationType === integrationType?.integrationType && token === integrationToken) {
      return;
    }

    super.setDestination(integrationType, integrationToken, integrationName);
    sessionStorage.setItem('integrationInfoViewModel', JSON.stringify(this.integrationInfoViewModel));
    this.setState();
  }

  public checkToken(integrationTypeEnum: IntegrationTypeEnum, tokenId: string): Observable<BaseResponse> {
    return this.rentaApiService.checkToken(integrationTypeEnum, tokenId);
  }

  private setState(): void {
    if (isNullOrEmpty(this.integrationInfoViewModel.integrationSourceToken)) {
      this.selectedSourceIntegrationType.next(null);
      this.state.next(CreationState.Source);
    } else if (isNullOrEmpty(this.integrationInfoViewModel.integrationDestinationToken)) {
      this.selectedSourceIntegrationType.next(this.integrationInfoViewModel.integrationSourceType);
      this.state.next(CreationState.Destination);
    } else {
      this.selectedSourceIntegrationType.next(this.integrationInfoViewModel.integrationSourceType);
      this.state.next(CreationState.Settings);
    }
  }
}
