<p-progressBar mode="indeterminate" styleClass="modal-preloader" *ngIf="loading"></p-progressBar>
<fa-icon [icon]="faTimes" class="close-modal-btn" *ngIf="closable" (click)="cancel()"></fa-icon>
<div class="p-d-flex p-flex-column p-ai-center p-jc-center dialog-wrapper">
  <div class="p-d-flex p-flex-column p-ai-center p-jc-center integration-dialog-title-wrapper">
    <img class="integration-icon" [src]="integration.icon" alt="" />
    <span class="integration-dialog-title renta-font-big">{{ dialogTitle }}</span>
    <span class="integration-dialog-title-info">{{ dialogTitleInfo }}</span>
    <div class="delete-error">
      <app-renta-error-message [errorMessage]="errorDelete"
                               *ngIf="errorDelete"></app-renta-error-message>
    </div>

    <div class="integration-dialog-body-wrapper">
      <ng-container #integrationBody></ng-container>
    </div>
    <div class="p-d-flex p-ai-center p-jc-between integration-dialog-button-wrapper" *ngIf="!integrationBodyWithSubmit">
      <p-button styleClass="auth-token-btn cancel-btn" (click)="cancel()" label="CANCEL" [disabled]="loading"></p-button>
      <p-button styleClass="renta-color auth-token-btn" (click)="submit(null)" label="{{ submitLabel }}" [disabled]="loading"></p-button>
    </div>
  </div>
</div>
