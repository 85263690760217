<div class="settings-component-container p-d-flex p-flex-column" >
  <div class="setting-title-wrapper p-d-flex p-flex-column">
    <div class='p-d-flex'>
      <span class="renta-font-normal setting-title-font">{{RentaSelectionOptionDataSource.name}}</span>
      <i *ngIf='toolTipInfo !== null && toolTipInfo !== undefined && toolTipInfo.length > 0' class='renta-ic renta-ic-info-square' [pTooltip]="toolTipInfo" tooltipPosition="top"  style='max-width: 20px;min-width: 20px;max-height: 20px;min-height: 20px'></i>
    </div>
    <span class='renta-font-small renta-subtext-color p-my-1' *ngIf='additionalInfo !== null && additionalInfo !== undefined && additionalInfo.length > 0'>{{additionalInfo}}</span>
  </div>
  <div class="select-button-container" *ngIf="selectionDataSourceModel | async; let ds$">
    <p-selectButton [options]="ds$.values" [ngModel]="ds$.selected" (onOptionClick)="onChange($event)" optionLabel="label" optionValue="value"></p-selectButton>
  </div>
</div>
