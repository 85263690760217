import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ProfileService } from '../../../../../services/profile.service';

@Component({
  selector: 'app-header-ava',
  templateUrl: './header-ava.component.html',
  styleUrls: ['./header-ava.component.scss'],
})
export class HeaderAvaComponent implements OnInit {
  public avatarImg: Observable<string> = new Observable<string>();
  public avatarLoading: Observable<boolean> = new Observable<boolean>();
  public userName: Observable<string> = new Observable<string>();
  public userEmail: Observable<string> = new Observable<string>();

  constructor(private readonly profileService: ProfileService) {}

  public ngOnInit(): void {
    this.avatarImg = this.profileService.getAvatarUrl();
    this.avatarLoading = this.profileService.getAvatarLoading();
    this.userName = this.profileService.getName();
    this.userEmail = this.profileService.getEmail();
  }
}
