import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-header-row-template',
  templateUrl: './header-row-template.component.html',
  styleUrls: ['./header-row-template.component.css']
})
export class HeaderRowTemplateComponent implements OnInit {


  @Input()
  public IconSrc: string;

  @Input()
  public Title: string;

  constructor() { }

  public ngOnInit(): void {
  }

}
