import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './services/user.service';
import { SignalrService } from './services/signalR/signalr.service';
import { ProfileService } from './services/profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public display: Observable<boolean>;
  public preLoader: boolean = true;
  constructor(private readonly userService: UserService, private signalrService: SignalrService, public profileService: ProfileService) {}

  public ngOnInit(): void {
    this.display = this.userService.connectToAuth();

    this.display.subscribe((res: boolean): void => {
      if (res) {
        this.signalrService.connect();
        this.profileService.InitProfile();
      }
    });
  }

  public onRouteActivate(__: any): void {
    this.preLoader =  false;
  }
}
