import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-amo-crm-auth',
  templateUrl: './amo-crm-auth.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class AmoCrmAuthComponent implements OnInit {
  @Input()
  public error: string;

  @Input()
  public email: string;

  @Input()
  public apiKey: string;

  @Input()
  public apiUrl: string;

  @Input()
  public loading: boolean;

  constructor() {
  }

  public ngOnInit(): void {
  }
}
