import { Component, Input, OnInit } from '@angular/core';
import { ProfileService } from '../../../../services/profile.service';

@Component({
  selector: 'app-avatar-image-block',
  templateUrl: './avatar-image-block.component.html',
  styleUrls: ['./avatar-image-block.component.scss']
})
export class AvatarImageBlockComponent implements OnInit {
  @Input()
  public imgUrl: string;

  @Input()
  public loading: boolean;

  public bgColor1: string;
  public bgColor2: string;
  public avaSymbol: string;

  constructor(private profileService: ProfileService) {
  }

  public ngOnInit(): void {
    this.profileService.getGoogleOAuth().subscribe((isOAuth: boolean): void => {
      if (!isOAuth) {
        this.profileService.getEmail().subscribe((email: string): void => {
          this.avaSymbol = email[0].toUpperCase();
          this.bgColor1 = this.stringToColour(email);
          this.bgColor2 = this.stringToColour(email + 'extra');
        });
      }
    });
  }

  private stringToColour(str: string): string {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      // tslint:disable-next-line:no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      // tslint:disable-next-line:no-bitwise
      const value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }
}
