<div class="choose-storage-container">
<div class="integration-source-container p-d-flex p-flex-column p-justify-center">
  <p-accordion [multiple]="true" (onOpen)="onTabOpen($event)">
    <p-accordionTab *ngFor="let storage of storages; let i = index">
      <ng-template pTemplate="header">
        <app-header-row-template [Title]="storage.title" [IconSrc]="storage.icon"></app-header-row-template>
      </ng-template>
      <ng-template pTemplate="content">

        <ng-container *ngIf="storage.tokens | async; let list else loading;">
          <div class="integration-token-wrapper">
            <app-integration-token-row-template class="integration-token-row-container"  *ngFor="let storageToken of list; let tokenInd = index"
                                                [TokenViewModel]="{
                  additionalInfo: storageToken.additionalInfo,
                  error: storageToken.error,
                  title: storageToken.title,
                  tokenId: storageToken.id,
                  icon: storageToken.icon,
                  tokenType: storage.integrationType
                  }"
                                                [IsLast]="tokenInd !== list.length - 1"
                                                (click)="selectStorage(storage.title, storageToken.id)"
                                                [CanRemove]="false">
            </app-integration-token-row-template>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #loading>
        <div class="integration-token-wrapper">
          <app-integration-token-rows-loading-template
            class="integration-token-row-container">
          </app-integration-token-rows-loading-template>
        </div>
      </ng-template>
    </p-accordionTab>
  </p-accordion>
</div>
</div>
