import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, NgModel, ReactiveFormsModule} from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { InputTextModule } from 'primeng/inputtext';
import { BadgeModule } from 'primeng/badge';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ProgressBarModule } from 'primeng/progressbar';
import { AccordionModule } from 'primeng/accordion';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { SkeletonModule } from 'primeng/skeleton';
import { DropdownModule } from 'primeng/dropdown';

import { RentaModalsService } from './services/renta-modals.service';
import { HoverChildIconClassDirective } from '../../directives/hover-child-icon-class.directive';
import { OverlayDropdownDirective } from './directives/overlay-dropdown.directive';
import { TextInputTrimDirective } from './directives/text-input-trim.directive';
import { DontTouchDirective } from './directives/dont-touch.directive';
import { NgForFilterPipe } from './pipes/ng-for-filter.pipe';

import { BaseIntegrationModalComponent } from './components/internal/modal/base-integration-modal/base-integration-modal.component';
import { CriteoAuthComponent } from './components/internal/modal/criteo-auth/criteo-auth.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { ClickhouseAuthComponent } from './components/internal/modal/clickhouse-auth/clickhouse-auth.component';
import { SnowflakeAuthComponent } from './components/internal/modal/snowflake-auth/snowflake-auth.component';
import { AmazonRedshiftAuthComponent } from './components/internal/modal/amazon-redshift-auth/amazon-redshift-auth.component';
import { RentaDropdownComponent } from './components/renta-dropdown/renta-dropdown.component';
import { DropdownItemComponent } from './components/internal/dropdown/dropdown-item/dropdown-item.component';
import { MultiselectDropdownContentComponent } from './components/internal/dropdown/multiselect-dropdown-content/multiselect-dropdown-content.component';
import { DropdownContentComponent } from './components/internal/dropdown/dropdown-content/dropdown-content.component';
import { RentaMultiselectDropdownComponent } from './components/renta-multiselect-dropdown/renta-multiselect-dropdown.component';
import { RentaChipsComponent } from './components/renta-chips/renta-chips.component';
import { RentaDateRangeComponent } from './components/renta-date-range/renta-date-range.component';
import { ExtendedPrimeCalendarComponent } from './components/internal/extended-prime-calendar/extended-prime-calendar.component';
import { SettingInfoComponent } from './components/setting-info/setting-info.component';
import { RentaSelectionComponent } from './components/renta-selection/renta-selection.component';
import { CheckmarkComponent } from './components/checkmark/checkmark.component';
import { BaseModalDialogComponent } from './components/internal/modal/base-modal-dialog/base-modal-dialog.component';
import { ChooseStorageModalComponent } from './components/internal/modal/choose-storage-modal/choose-storage-modal.component';
import { IntegrationTokenRowTemplateComponent } from './components/integration-accordion-components/integration-token-row-template/integration-token-row-template.component';
import { HeaderRowTemplateComponent } from './components/integration-accordion-components/header-row-template/header-row-template.component';
import { IntegrationTokenRowsLoadingTemplateComponent } from './components/integration-accordion-components/integration-token-rows-loading-template/integration-token-rows-loading-template.component';
import { AddTokenRowTemplateComponent } from './components/integration-accordion-components/add-token-row-template/add-token-row-template.component';
import { RentaInputComponent } from './components/renta-input/renta-input.component';
import { RentaErrorMessageComponent } from './components/internal/renta-error-message/renta-error-message.component';
import { AmoCrmAuthComponent } from './components/internal/modal/amo-crm-auth/amo-crm-auth.component';
import { KommoAuthComponent } from './components/internal/modal/kommo-auth/kommo-auth.component';
import { XAdsAuthComponent } from './components/internal/modal/x-ads-auth/x-ads-auth.component';
import { EsputnikAuthComponent } from './components/internal/modal/esputnik/esputnik-auth.component';
import { AmplitudeAuthComponent } from './components/internal/modal/amplitude-auth/amplitude-auth.component';
import { ActiveCampaignAuthComponent } from './components/internal/modal/active-campaign-auth/active-campaign-auth.component';
import { MySqlAuthComponent } from './components/internal/modal/my-sql-auth/my-sql-auth.component';
import { RentaActionPlaceholderComponent } from './components/renta-action-placeholder/renta-action-placeholder.component';
import { RentaActionInputComponent } from './components/renta-action-input/renta-action-input.component';
import { RentaPlaceholderInputComponent } from './components/renta-placeholder-input/renta-placeholder-input.component';
import { AvatarImageBlockComponent } from './components/avatar-image-block/avatar-image-block.component';
import { CropperBlockComponent } from './components/cropper-block/cropper-block.component';
import { GetintentAuthComponent } from './components/internal/modal/getintent-auth/getintent-auth.component';
import { IikowebAuthComponent } from './components/internal/modal/iikoweb-auth/iikoweb-auth.component';
import { CalltouchAuthComponent } from './components/internal/modal/calltouch-auth/calltouch-auth.component';
import { IikocloudAuthComponent } from './components/internal/modal/iikocloud-auth/iikocloud-auth.component';
import { PostgreSqlAuthComponent } from './components/internal/modal/postgre-sql-auth/postgre-sql-auth.component';
import { HeaderAvaComponent } from './components/menu-popup-custom-componants/header-ava/header-ava.component';
import { PasswordVerificationModalComponent } from './components/internal/modal/password-verification-modal/password-verification-modal.component';
import { CroppingImageModalComponent } from './components/internal/modal/cropping-image-modal/cropping-image-modal.component';
import { GbuttonDirective } from './directives/gbutton.directive';
import { OnEnterDirective } from './directives/on-enter.directive';
import { MenuPopupDirective } from './directives/menu-popup.directive';
import { MenuPopupComponent } from './components/menu-popup/menu-popup.component';
import { CalendarHeaderComponent } from './components/internal/calendar-header/calendar-header.component';
import { TooltipModule } from 'primeng/tooltip';
import { RentaSettingsFiltersComponent } from './components/renta-settings-filters/renta-settings-filters.component';
import { RentaSettingsFilterComponent } from './components/renta-settings-filter/renta-settings-filter.component';
import { FilterViewComponent } from './components/renta-settings-filter/components/filter-view/filter-view.component';
import { FilterEditComponent } from './components/renta-settings-filter/components/filter-edit/filter-edit.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { RentaTabDropdownComponent } from './components/renta-tab-dropdown/renta-tab-dropdown.component';
import { ConfirmationModalComponent } from './components/internal/modal/confirmation-modal/confirmation-modal.component';
import { InfoModalComponent } from './components/internal/modal/info-modal/info-modal.component';
import { PaymentCardModalComponent } from './components/internal/modal/payment-card-modal/payment-card-modal.component';
import { RentaPaymentCardComponent } from './components/renta-payment-card/renta-payment-card.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../../../environments/environment';
import { FilesAuthComponent } from './components/internal/modal/files-auth/files-auth.component';
import { RentaFileColumnMappingComponent } from './components/renta-file-column-mapping/renta-file-column-mapping.component';
import { ColumnMappingRowComponent } from './components/renta-file-column-mapping/components/column-mapping-row/column-mapping-row.component';
import { RentaSwitcherComponent } from './components/renta-switcher/renta-switcher.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { WebHookModalComponent } from './components/internal/modal/web-hook-modal/web-hook-modal.component';
import { RentaKeyValueRowComponent } from './components/renta-key-value-row/renta-key-value-row.component';
import { RentaKeyValueCollectionComponent } from './components/renta-key-value-collection/renta-key-value-collection.component';
import { RentaKeyValueDropdownComponent } from './components/renta-key-value-dropdown/renta-key-value-dropdown.component';
import { RentaKeyValueDropdownRowComponent } from './components/renta-key-value-dropdown/renta-key-value-dropdown-row/renta-key-value-dropdown-row.component';
import { RentaInputStringArrayComponent } from './components/renta-input-string-array/renta-input-string-array.component';
import { RentaInputStringRowComponent } from './components/renta-input-string-array/renta-input-string-row/renta-input-string-row.component';
import { MssqlAuthComponent } from './components/internal/modal/mssql-auth/mssql-auth.component';
import { RentaKeyValueDropdownsComponent } from './components/renta-key-value-dropdowns/renta-key-value-dropdowns.component';
import { RentaKeyValueDropdownsRowComponent } from './components/renta-key-value-dropdowns/renta-key-value-dropdowns-row/renta-key-value-dropdowns-row.component';
import { AmazonS3AuthComponent } from './components/internal/modal/amazon-s3-auth/amazon-s3-auth.component';
import { BillingService } from '../manage/modules/billing/services/billing.service';
import {FileIntNumericService} from "./services/file-int-numeric-service.service";
import { InputNumberModule } from 'primeng/inputnumber';
import { RentaInputNumComponent } from './components/renta-input-numeric/renta-input-num.component';

@NgModule({
  declarations: [
    BaseIntegrationModalComponent,
    CriteoAuthComponent,
    StepperComponent,
    ClickhouseAuthComponent,
    SnowflakeAuthComponent,
    AmazonRedshiftAuthComponent,
    MssqlAuthComponent,
    HoverChildIconClassDirective,
    RentaDropdownComponent,
    DropdownItemComponent,
    NgForFilterPipe,
    RentaChipsComponent,
    MultiselectDropdownContentComponent,
    DropdownContentComponent,
    RentaMultiselectDropdownComponent,
    RentaDateRangeComponent,
    ExtendedPrimeCalendarComponent,
    SettingInfoComponent,
    RentaSelectionComponent,
    OverlayDropdownDirective,
    CheckmarkComponent,
    OverlayDropdownDirective,
    BaseModalDialogComponent,
    ChooseStorageModalComponent,
    IntegrationTokenRowTemplateComponent,
    HeaderRowTemplateComponent,
    IntegrationTokenRowsLoadingTemplateComponent,
    AddTokenRowTemplateComponent,
    RentaInputComponent,
    RentaInputNumComponent,
    RentaErrorMessageComponent,
    TextInputTrimDirective,
    DontTouchDirective,
    AmoCrmAuthComponent,
    KommoAuthComponent,
    XAdsAuthComponent,
    EsputnikAuthComponent,
    AmplitudeAuthComponent,
    ActiveCampaignAuthComponent,
    MySqlAuthComponent,
    RentaActionPlaceholderComponent,
    RentaActionInputComponent,
    RentaPlaceholderInputComponent,
    AvatarImageBlockComponent,
    CropperBlockComponent,
    GetintentAuthComponent,
    PostgreSqlAuthComponent,
    HeaderAvaComponent,
    PasswordVerificationModalComponent,
    CroppingImageModalComponent,
    GbuttonDirective,
    OnEnterDirective,
    MenuPopupDirective,
    MenuPopupComponent,
    CalendarHeaderComponent,
    RentaSettingsFiltersComponent,
    RentaSettingsFilterComponent,
    FilterViewComponent,
    FilterEditComponent,
    RentaTabDropdownComponent,
    ConfirmationModalComponent,
    InfoModalComponent,
    PaymentCardModalComponent,
    RentaPaymentCardComponent,
    FilesAuthComponent,
    RentaFileColumnMappingComponent,
    ColumnMappingRowComponent,
    RentaSwitcherComponent,
    MssqlAuthComponent,
    WebHookModalComponent,
    RentaKeyValueRowComponent,
    RentaKeyValueCollectionComponent,
    RentaKeyValueDropdownComponent,
    RentaKeyValueDropdownRowComponent,
    RentaInputStringArrayComponent,
    RentaInputStringRowComponent,
    MssqlAuthComponent,
    RentaKeyValueDropdownsComponent,
    RentaKeyValueDropdownsRowComponent,
    AmazonS3AuthComponent,
    IikowebAuthComponent,
    CalltouchAuthComponent,
    IikocloudAuthComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    DynamicDialogModule,
    DialogModule,
    InputTextModule,
    TabViewModule,
    CheckboxModule,
    FormsModule,
    BadgeModule,
    CalendarModule,
    TieredMenuModule,
    SortablejsModule.forRoot({
      animation: 150,
      forceFallback: true
    }),
    RippleModule,
    SelectButtonModule,
    ProgressBarModule,
    AccordionModule,
    NgxSkeletonLoaderModule,
    FontAwesomeModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    SkeletonModule,
    TooltipModule,
    DropdownModule,
    TabMenuModule,
    ReactiveFormsModule,
    NgxStripeModule.forRoot(environment.stripe_key_pbc),
    InputSwitchModule,
    InputNumberModule
  ],
  entryComponents: [CriteoAuthComponent],
  exports: [
    StepperComponent,
    HoverChildIconClassDirective,
    RentaDropdownComponent,
    RentaChipsComponent,
    RentaMultiselectDropdownComponent,
    RentaDateRangeComponent,
    SettingInfoComponent,
    RentaSelectionComponent,
    CheckmarkComponent,
    HeaderRowTemplateComponent,
    IntegrationTokenRowTemplateComponent,
    AddTokenRowTemplateComponent,
    IntegrationTokenRowsLoadingTemplateComponent,
    RentaInputComponent,
    DontTouchDirective,
    RentaActionPlaceholderComponent,
    RentaActionInputComponent,
    RentaPlaceholderInputComponent,
    AvatarImageBlockComponent,
    CropperBlockComponent,
    GbuttonDirective,
    OnEnterDirective,
    MenuPopupDirective,
    CalendarHeaderComponent,
    RentaErrorMessageComponent,
    RentaSettingsFiltersComponent,
    RentaTabDropdownComponent,
    TextInputTrimDirective,
    RentaPaymentCardComponent,
    RentaFileColumnMappingComponent,
    NgForFilterPipe,
    RentaSwitcherComponent,
    RentaKeyValueCollectionComponent,
    RentaKeyValueDropdownRowComponent,
    RentaKeyValueDropdownComponent,
    RentaInputStringArrayComponent,
    RentaKeyValueDropdownsComponent,
    RentaInputNumComponent
  ],
  providers: [ NgModel, DialogService, BillingService, FileIntNumericService],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [RentaModalsService],
    };
  }
}
