import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';

@Component({
  selector: 'app-filter-view',
  templateUrl: './filter-view.component.html',
  styleUrls: ['./filter-view.component.scss'],
})
export class FilterViewComponent implements OnInit {
  @Input()
  public SelectedFilter: ListItemModel;

  @Input()
  public standby: boolean;

  @Input()
  public canEdit: boolean;

  @Output()
  public ApplyEdit: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public ApplyDelete: EventEmitter<any> = new EventEmitter<any>();

  public placeholder: string;

  constructor() {}

  public ngOnInit(): void {
    this.placeholder = `${this.SelectedFilter.name} / ${this.getSelectedOperatorName()} / ${this.SelectedFilter.value}`;
  }

  public edit(): void {
    this.ApplyEdit.emit();
  }

  public delete(): void {
    this.ApplyDelete.emit();
  }

  private getSelectedOperatorName(): string {
    let operatorName: string = '';

    this.SelectedFilter.operators.forEach((e: ListItemModel): void => {
      if (e.selected) {
        operatorName = e.name;
      }
    });

    return operatorName;
  }
}
