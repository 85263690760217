<div class="p-d-flex p-flex-column p-ai-center p-jc-center dialog-wrapper">
  <div class="p-d-flex p-flex-column p-ai-center p-jc-center integration-dialog-title-wrapper width-max">
    <img class="integration-icon" *ngIf="integration.icon" [src]="integration.icon" alt="icon">
    <span class="integration-dialog-title renta-font-big">{{integration.title}}</span>
    <span class="integration-dialog-title-info" *ngIf="integration.subtitle">{{integration.subtitle}}</span>
    <span class="integration-dialog-title-info" *ngIf="integration.description">{{integration.description}}</span>
    <div class="p-d-flex p-ai-center p-jc-between integration-dialog-button-wrapper width-max">
      <p-button
        styleClass="dialog-btn cancel-btn"
        label="{{integration.cancelBtn}}"
        (click)="onNo()"></p-button>
      <p-button
        styleClass="renta-color dialog-btn"
        label="{{integration.successBtn}}"
        (click)="onYes()"></p-button>
    </div>
  </div>
</div>
