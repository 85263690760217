<div class="setting-row-wrapper p-m-0">
  <app-renta-chips
    *ngIf="isLoaded; else loadingSetting"
    [DataSource]="chipsParametersDataSource"
    [class.disabled]="isDataSourceLoading"
    (OnDeleteChip)="onCloseChip($event)"
  ></app-renta-chips>
</div>
<div class="setting-row-wrapper">
  <app-renta-multiselect-dropdown
    *ngIf="isLoaded; else loadingSetting"
    (OnApply)="onApplyParameters($event)"
    [RentaMultiselectDropdownSource]="ParametersDataSource"
    [class.disabled]="isDataSourceLoading"
    [hotRefresh]="true"
  >
  </app-renta-multiselect-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-dropdown
    *ngIf="isLoaded; else loadingSetting"
    [RentaDropdownSource]="CostsCurrencyDataSource"
    (OnApply)="onApplyCostsCurrency($event)"
  ></app-renta-dropdown>
</div>

<ng-template #loadingSetting>
  <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
  <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
</ng-template>
