import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export abstract class DataSourceBase<T> {
  public error: string;
  public loading: boolean = false;
  protected sourceData: T = null;
  protected dataSource: BehaviorSubject<T> = new BehaviorSubject<T>(null);

  protected constructor(dataSource: T) {
    this.sourceData = dataSource;
  }

  public setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  public connect(): Observable<T> {
    this.updateDataSource();

    return this.dataSource.asObservable().pipe(filter((f: T): boolean => f !== null));
  }

  public refresh(dataSource: T = null): void {
    this.error = null;
    if (dataSource !== null) {
      this.sourceData = dataSource;
    }

    this.updateDataSource();
  }

  public disconect(): void {
    this.dataSource.complete();
  }

  protected updateDataSource(): void {
    this.dataSource.next(this.sourceData);
  }
}
