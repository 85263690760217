import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { RentaChipsDataSource } from '../../../../../shared/models/DataSource/RentaChipsDataSource';
import {
  RentaMultiselectDropdownDataSource
} from '../../../../../shared/models/DataSource/RentaMultiselectDropdownDataSource';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { AmoCrmSettingsService } from '../../../../services/integrationSettings/amo-crm-settings.service';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import * as _ from 'lodash';

import { ChipsModel } from '../../../../../shared/models/ChipsModel';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { delay } from 'rxjs/operators';
import { getSelectedChips } from '../../../../../../helpers/helpers';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';

@Component({
  selector: 'app-amo-crm-settings',
  templateUrl: './amo-crm-settings.component.html',
  styleUrls: ['../common-settings.scss']
})
export class AmoCrmSettingsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;
  public ReportTypesDataSource: RentaDropdownDataSource;
  public chipsParametersDataSource: RentaChipsDataSource;
  public ParametersDataSource: RentaMultiselectDropdownDataSource;

  constructor(private settingsService: AmoCrmSettingsService) {
    super(IntegrationTypeEnum.amocrm);
    this.isLoaded = false;
  }

  public ngOnInit(): void {
    this.ReportTypesDataSource = new RentaDropdownDataSource('Report type', []);
    this.chipsParametersDataSource = new RentaChipsDataSource('Selected Parameters', []);
    this.ParametersDataSource = new RentaMultiselectDropdownDataSource('Parameters', []);

    this.connectParamsChipsDS(this.chipsParametersDataSource, this.settingsService);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
      this.ReportTypesDataSource.setLoading(res);
      this.chipsParametersDataSource.setLoading(res);
      this.ParametersDataSource.setLoading(res);
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      if (res[this.integrationType].ReportTypes) {
        this.ReportTypesDataSource.refresh(_.cloneDeep(res[this.integrationType].ReportTypes));
      }

      if (res[this.integrationType].Parameters) {
        this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
        const chipsParamsDs = getSelectedChips(res[this.integrationType].Parameters);
        this.chipsParametersDataSource.refresh(chipsParamsDs);
      }

      this.settingsService.initCache();

      setTimeout((): void => {
        this.isLoaded = true;
      }, 1000);
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onCloseChip(e: ChipsModel): void {
    this.ParametersDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
    setTimeout((): void => {
      this.settingsService.setDataToCache();
    });
  }

  public onApplyParameters($event: Array<ListItemModel>): void {
    this.onApplyParams($event, this.chipsParametersDataSource);
    this.settingsService.setDataToCache();
  }

  public onApplyReportType($event: ListItemModel): void {
    if (!this.settingsService.setReportType($event)) {
      return;
    }
    this.ParametersDataSource.setLoading(true);
    this.settingsService
      .getParameters($event)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(_.cloneDeep(res));
          this.chipsParametersDataSource.refresh(getSelectedChips(res));
        }
      });
  }
}
