import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SettingsComponentFactoryResolver } from '../../services/settingsComponentFactory';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateIntegrationService } from '../../services/create-integration.service';
import { IntegrationSettingsComponent } from './integration-settings.component';
import { AppGlobalPreloaderService } from '../../../../services/app-global-preloader.service';
import { UserService } from '../../../../services/user.service';
import {ProfileService} from "../../../../services/profile.service";

@Component({
  selector: 'app-create-integration-settings',
  templateUrl: './integration-settings.component.html',
  styleUrls: ['../internal/integration-settings/common-settings.scss', './integration-settings.component.css'],
  providers: [SettingsComponentFactoryResolver],
})
export class CreateIntegrationSettingsComponent extends IntegrationSettingsComponent implements OnInit, AfterViewInit {
  constructor(
    protected readonly integrationService: CreateIntegrationService,
    protected readonly settingsComponentFactoryResolver: SettingsComponentFactoryResolver,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly appGlobalPreloaderService: AppGlobalPreloaderService,
    protected readonly userService: UserService,
    protected readonly profileService: ProfileService
  ) {
    super(integrationService, settingsComponentFactoryResolver, router, activatedRoute, appGlobalPreloaderService, userService, profileService);
    this.saveButton = 'Save Integration';
  }

  public ngOnInit(): void {
    (window as any).renta.page('Integration settings page', {
      email: this.userEmail,
      userId: this.userService.getUserId()
    });
  }

  public getPageTitle(): string {
    return ``;
  }

  public getPageSubtitle(): string {
    return ``;
  }
}
