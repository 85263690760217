import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';

import { UserService } from './user.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate, CanLoad {
  constructor(public auth: UserService, private router: Router) {}

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.checkAccess();
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkAccess();
  }

  public checkAccess(): Observable<boolean> {
    return this.auth.isAuthenticated().pipe(
      map((r: boolean): boolean => {
        if (r === true) {
          this.router.navigate(['integrations']).then();
          return false;
        }
        return true;
      })
    );
  }
}
