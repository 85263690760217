import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as _ from 'lodash';

export interface InfoModalModel {
  isSuccess: boolean;
  title: string;
  info: string;
  submitLabel?: string;
}

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent implements OnInit {
  public isSuccess: boolean;
  public title: string;
  public info: string;
  public submitLabel: string;
  private modalRef: DynamicDialogRef;

  constructor(private readonly ref: DynamicDialogRef, private readonly config: DynamicDialogConfig) {
    const { info, isSuccess, title, submitLabel } = this.config.data as InfoModalModel;

    this.isSuccess = isSuccess;
    this.info = info;
    this.title = title;
    this.submitLabel = submitLabel;
    this.modalRef = ref;
    if (_.isNil(this.submitLabel)) {
      setTimeout((): void => {
        this.ref.close(true);
      }, 2500);
    }
  }

  public ngOnInit(): void {}

  public submit(): void {
    this.modalRef.close(true);
  }
}
