<div class="setting-row-wrapper p-m-0">
  <app-renta-dropdown
    *ngIf="isLoaded; else loadingSetting"
    (OnApply)="onApplyParameter($event)"
    [additionalInfo]="'Specify the Salesforce object for export to the destination.'"
    [toolTipInfo]="'Renta creates a table that is equal to the Salesforce object, and the columns are Salesforce fields within one specific object.'"
    [RentaDropdownSource]="ParametersDataSource">
  </app-renta-dropdown>
</div>
<ng-template #loadingSetting>
  <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
  <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
</ng-template>
