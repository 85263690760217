import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RentaApiService } from '../../../../../../services/renta-api.service';
import { ApiResponse } from '../../../../../../models/common/ApiResponse`T';

@Component({
  selector: 'app-password-verification-modal',
  templateUrl: './password-verification-modal.component.html',
  styleUrls: ['./password-verification-modal.component.scss'],
})
export class PasswordVerificationModalComponent implements OnInit {
  public display: boolean;
  public loading: boolean;
  public errorMsg: string;
  public disabledBtn: boolean;
  public userEmail: string;
  public currentPassword: string = '';
  public newPassword: string = '';
  public confirmNewPassword: string = '';

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig,
    private readonly rentaApiService: RentaApiService
  ) {}

  public ngOnInit(): void {
    this.userEmail = this.config.data.userEmail;
    this.disabledBtn = true;
  }

  public cancelPasswordDialog(isSuccess: boolean = false): void {
    if (this.loading) {
      return;
    }

    this.ref.close(isSuccess);
  }

  public savePasswordDialog(): void {
    if (this.newPassword === this.confirmNewPassword) {
      this.loading = true;

      this.rentaApiService
        .updatePassword({
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        })
        .subscribe((res: ApiResponse<boolean>): void => {
          this.loading = false;

          if (!res.baseResponse.status) {
            this.errorMsg = res.baseResponse.errors[0].userMessage;
          } else {
            this.cancelPasswordDialog(true);
          }
        });
    }
  }

  public passwordsChangeHandler(): void {
    this.disabledBtn = !(this.currentPassword.length > 0 && this.newPassword.length > 0 && this.confirmNewPassword.length > 0);
  }
}
