import { Component, OnInit } from '@angular/core';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { RentaInputDataSource } from '../../../../../shared/models/DataSource/RentaInputDataSource';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';
import {
  IconPositionEnum,
  RentaInputDataSourceModel
} from '../../../../../shared/models/DataSource/DataSourceModels/RentaInputDataSourceModel';
import * as _ from 'lodash';
import { JsSdkSettingsService } from '../../../../services/integrationSettings/js-sdk-settings.service';

@Component({
  templateUrl: './warehouse-addon-settings.component.html',
  styleUrls: ['../common-settings.scss']
})
export class WarehouseAddonSettingsComponent extends SettingsBaseComponent implements OnInit {
  public isLoaded: boolean;
  public useSessionsTable: boolean = false;

  public HitsTableNameDataSource: RentaInputDataSource;
  public SessionsTableNameDataSource: RentaInputDataSource;
  constructor(private settingsService: JsSdkSettingsService) {
    super(IntegrationTypeEnum.javascript_sdk);
  }

  public ngOnInit(): void {
    this.HitsTableNameDataSource = new RentaInputDataSource('Event table name', null);
    this.SessionsTableNameDataSource = new RentaInputDataSource('Collect session data', null);
    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToLoading().subscribe((res: boolean): void => {
      this.isLoaded = !res;
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      const hitsTableName: RentaInputDataSourceModel = {
        value: res[this.integrationType].HitsTableName || '',
        iconOptions: {
          position: IconPositionEnum.right
        },
        disabled: false,
        placeholder: 'Set the event table name'
      };

      this.HitsTableNameDataSource.refresh(hitsTableName);

      this.useSessionsTable = _.some(res[this.integrationType].SessionsTableName);

      const sessionsTableNameName: RentaInputDataSourceModel = {
        value: res[this.integrationType].SessionsTableName || '',
        iconOptions: {
          position: IconPositionEnum.right
        },
        placeholder: 'Set the session table name',
        disabled: false
      };

      this.SessionsTableNameDataSource.refresh(sessionsTableNameName);

    });
  }

  public onApplyHitsTableName(hitsTableName: string): void {
    this.settingsService.setHitsTableName(hitsTableName);
  }

  public onApplySessionsTableName(sessionsTableName: string): void {
    this.settingsService.setSessionsTableName(sessionsTableName);
  }

  public onSwitcherChange($event: { originalEvent: PointerEvent; checked: boolean }): void {
    this.useSessionsTable = $event.checked;
    this.settingsService.setUseSessionsTable(this.useSessionsTable);
  }
}
