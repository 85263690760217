import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { SalesForceSettingsService } from '../../../../services/integrationSettings/sales-force-settings.service';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';
import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';

@Component({
  selector: 'app-sales-force-settings',
  templateUrl: './sales-force-settings.component.html',
  styleUrls: ['../common-settings.scss']
})
export class SalesForceSettingsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;
  public ParametersDataSource: RentaDropdownDataSource;

  constructor(private settingsService: SalesForceSettingsService) {
    super(IntegrationTypeEnum.salesforce);
  }

  public ngOnInit(): void {
    this.ParametersDataSource = new RentaDropdownDataSource('Objects', []);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
      this.ParametersDataSource.setLoading(res);
    });

    this.settingsService.connectToLoading().subscribe((res: boolean): void => {
      this.isLoaded = !res;
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
      this.settingsService.initCache();
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onApplyParameter(event: ListItemModel): void {
    this.settingsService.setParameter(event);
    this.settingsService.setDataToCache();
  }
}
