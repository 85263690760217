import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { delay } from 'rxjs/operators';

import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { RentaChipsDataSource } from '../../../../../shared/models/DataSource/RentaChipsDataSource';
import { RentaMultiselectDropdownDataSource } from '../../../../../shared/models/DataSource/RentaMultiselectDropdownDataSource';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { getSelectedChips } from '../../../../../../helpers/helpers';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { ChipsModel } from '../../../../../shared/models/ChipsModel';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { AppMetricaLogsApiSettingsService } from '../../../../services/integrationSettings/app-metrica-logs-api-settings.service';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';

@Component({
  templateUrl: './app-metrica-logs-api-settings.component.html',
  styleUrls: ['../common-settings.scss']
})
export class AppMetricaLogsApiSettingsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;
  public CounterDataSource: RentaDropdownDataSource;
  public chipsClientsDataSource: RentaChipsDataSource;
  public ClientsDataSource: RentaMultiselectDropdownDataSource;
  public SourceDataSource: RentaDropdownDataSource;
  public chipsParametersDataSource: RentaChipsDataSource;
  public ParametersDataSource: RentaMultiselectDropdownDataSource;
  public isShowClients: boolean;

  constructor(private settingsService: AppMetricaLogsApiSettingsService) {
    super(IntegrationTypeEnum.appmetrica_logs_api);
    this.isLoaded = false;
  }

  public ngOnInit(): void {
    this.CounterDataSource = new RentaDropdownDataSource('Counter', []);
    this.chipsClientsDataSource = new RentaChipsDataSource('Selected clients', []);
    this.ClientsDataSource = new RentaMultiselectDropdownDataSource('Clients', []);
    this.SourceDataSource = new RentaDropdownDataSource('Report types', []);
    this.chipsParametersDataSource = new RentaChipsDataSource('Selected Parameters', []);
    this.ParametersDataSource = new RentaMultiselectDropdownDataSource('Parameters', []);

    this.chipsClientsDataSource.connect().subscribe((res: Array<ChipsModel>): void => {
      const selectedClients = res.map<ListItemModel>((m: ChipsModel): ListItemModel => {
        return { id: m.id, name: m.title, group: m.type };
      });

      this.settingsService.setSelectedClients(selectedClients);
    });

    this.connectParamsChipsDS(this.chipsParametersDataSource, this.settingsService);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
      this.CounterDataSource.setLoading(res);
      this.chipsClientsDataSource.setLoading(res);
      this.ClientsDataSource.setLoading(res);
      this.SourceDataSource.setLoading(res);
      this.chipsParametersDataSource.setLoading(res);
      this.ParametersDataSource.setLoading(res);
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      this.CounterDataSource.refresh(_.cloneDeep(res[this.integrationType].Counter));
      this.ClientsDataSource.refresh(_.cloneDeep(res[this.integrationType].Clients));
      const chipsClientsDs = getSelectedChips(res[this.integrationType].Clients);
      this.chipsClientsDataSource.refresh(chipsClientsDs);
      this.SourceDataSource.refresh(_.cloneDeep(res[this.integrationType].SourceType));
      this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
      const chipsParamsDs = getSelectedChips(res[this.integrationType].Parameters);
      this.chipsParametersDataSource.refresh(chipsParamsDs);
      this.isShowClients = res[this.integrationType].SourceType.find((f: ListItemModel): boolean => f.selected && f.name === 'Clicks');
      this.settingsService.initCache();
      this.isLoaded = true;
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onCloseChip(e: ChipsModel): void {
    this.ParametersDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
    setTimeout((): void => {
      this.settingsService.setDataToCache();
    });
  }

  public onCloseSelectedClientsChip(e: ChipsModel): void {
    this.ClientsDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
  }

  public onApplyClients(selectedClients: Array<ListItemModel>): void {
    this.settingsService.setSelectedClients(selectedClients);
    this.chipsClientsDataSource.refresh(getSelectedChips(selectedClients));
  }

  public onApplyParameters($event: Array<ListItemModel>): void {
    this.onApplyParams($event, this.chipsParametersDataSource);
    this.settingsService.setDataToCache();
  }

  public onApplyCounter($event: ListItemModel): void {
    if (!this.settingsService.setSelectedCounter($event)) {
      return;
    }

    this.ParametersDataSource.setLoading(true);
    this.ClientsDataSource.setLoading(true);

    this.settingsService
      .getParameters($event, null)
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(_.cloneDeep(res));
          this.chipsParametersDataSource.refresh(getSelectedChips(res));
        }
      });

    this.settingsService
      .getClients($event)
      .subscribe((res: Array<ListItemModel>): void => {
        this.ClientsDataSource.setLoading(false);
        if (res.length > 0) {
          this.ClientsDataSource.refresh(_.cloneDeep(res));
          this.chipsClientsDataSource.refresh(getSelectedChips(res));
        }
      });
  }

  public onApplySource($event: ListItemModel): void {
    if (!this.settingsService.setSourceType($event)) {
      return;
    }
    this.isShowClients = $event.name === 'Clicks';
    this.ParametersDataSource.setLoading(true);
    this.settingsService
      .getParameters(null, $event)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(_.cloneDeep(res));
          this.chipsParametersDataSource.refresh(getSelectedChips(res));
        }
      });
  }
}
