import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { environment } from '../../../../environments/environment';

import { BaseIntegrationModalComponent } from '../components/internal/modal/base-integration-modal/base-integration-modal.component';
import { ModalDialogTypeEnum } from '../models/ModalDialogTypeEnum';
import { IntegrationToken } from '../../../models/Integration/IntegrationToken';
import { DynamicDialogRef } from 'primeng/dynamicdialog/dynamicdialog-ref';
import {  Observable, Subject, Subscription } from 'rxjs';
import { BaseModalDialogComponent } from '../components/internal/modal/base-modal-dialog/base-modal-dialog.component';
import { filter } from 'rxjs/operators';
import { createOauthWindow } from '../../../helpers/helpers';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { PasswordVerificationModalComponent } from '../components/internal/modal/password-verification-modal/password-verification-modal.component';
import { CroppingImageModalComponent } from '../components/internal/modal/cropping-image-modal/cropping-image-modal.component';
import { IntegrationSourceModel } from '../../../models/Integration/IntegrationSourceModel';
import { ConfirmationModalComponent } from '../components/internal/modal/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalModel } from '../../../models/Integration/ConfirmationModalModel';
import { InfoModalComponent, InfoModalModel } from '../components/internal/modal/info-modal/info-modal.component';
import { PaymentCardModalComponent } from '../components/internal/modal/payment-card-modal/payment-card-modal.component';
import { StreamingSourceComponent } from '../../integrations/components/addon-token-settings/streaming-source/streaming-source.component';
import { WebhookDestinationComponent } from '../../integrations/components/addon-token-settings/webhook-destination/webhook-destination.component';

@Injectable({
  providedIn: 'root',
})
export class RentaModalsService {
  private readonly successCompletedOAuthDestinationSegment: string = '/completed_destination';
  private readonly successCompletedOAuthSegment: string = '/completed';
  private readonly failureOAuthSegment: string = '/failure';
  private modalSubscription: Subscription = null;

  private readonly smallModalWidth: string = '462px';
  private readonly tinyModalWidth: string = '431px';
  private readonly wideModalWidth: string = '821px';
  private readonly mediumModalWidth: string = '622px';
  private ref: DynamicDialogRef;

  private windowHandle: Window;
  private intervalId: number = null;

  constructor(public dialogService: DialogService, private router: Router) {
    this.router.events.pipe(filter((event: RouterEvent): boolean => event instanceof NavigationEnd)).subscribe((_: NavigationEnd): void => {
      this.disposeAll();
    });
  }

  public showCreateIntegrationDialog(integrationInfo: IntegrationSourceModel, actionType: string, result: boolean): DynamicDialogRef {
    return this.openModalWindow(ModalDialogTypeEnum.CreateIntegration, {
      integrationInfo,
      actionType,
      result
    });
  }

  public showSourceAuth(integrationInfo: IntegrationSourceModel): Observable<boolean> {
    if (integrationInfo.oAuthUrl) {
      return this.startOAuth(integrationInfo);
    } else {
      return this.openModalWindow(ModalDialogTypeEnum.CreateToken, { integrationInfo }).onClose;
    }
  }

  public showStreamingModal(tokenId: string): DynamicDialogRef {
    return this.openModalWindow(ModalDialogTypeEnum.StreamingModal, { tokenId });
  }

  public showWebhookModal(tokenId: string): DynamicDialogRef {
    return this.openModalWindow(ModalDialogTypeEnum.WebhookModal, { tokenId });
  }

  public showConfirmationModal(model: ConfirmationModalModel): DynamicDialogRef {
    return this.openModalWindow(ModalDialogTypeEnum.ConfirmationModal, model);
  }

  public paymentCardModal(): DynamicDialogRef {
    return this.openModalWindow(ModalDialogTypeEnum.PaymentCardModal, null);
  }

  public showInfoModal(model: InfoModalModel): DynamicDialogRef {
    return this.openModalWindow(ModalDialogTypeEnum.InfoModal, model);
  }

  public showPasswordVerificationDialog(model: { userEmail: string }): DynamicDialogRef {
    return this.openModalWindow(ModalDialogTypeEnum.PasswordVerification, model);
  }

  public showCroppingImageDialog(model: { image: string | ArrayBuffer }): DynamicDialogRef {
    return this.openModalWindow(ModalDialogTypeEnum.CroppingImage, model);
  }

  public removeTokenModal(integrationInfo: IntegrationSourceModel, integrationToken: IntegrationToken): DynamicDialogRef {
    return this.openModalWindow(ModalDialogTypeEnum.DeleteToken, {
      integrationInfo,
      integrationToken
    });
  }

  public disposeAll(): void {
    this.windowHandle?.close();
    window.clearInterval(this.intervalId);
  }

  private getHrefFormChildOAuthWindow(): string | null {
    let href: string;
    try {
      href = this.windowHandle.location.href;
    } catch (e) {
      // ignore
      return null;
    }

    return href;
  }


  private startOAuth(integrationInfo: IntegrationSourceModel): Observable<boolean> {
    const onModalClose = new Subject<boolean>();

    this.windowHandle = createOauthWindow(
      integrationInfo.oAuthUrl,
      'OAuth login',
      () => this.closeOauthWindow(onModalClose)
    );

    const checkWindow = () => {
      if (this.windowHandle.closed) {
        this.closeOauthWindow(onModalClose);
      } else {
        const href = this.getHrefFormChildOAuthWindow();
        if (href) {
          if (href.includes(this.successCompletedOAuthDestinationSegment)) {
            this.handleSuccessfulDestination(href, onModalClose, integrationInfo);
          } else if (href.includes(this.successCompletedOAuthSegment)) {
            this.handleSuccessfulOAuth(onModalClose);
          } else if (href.includes(this.failureOAuthSegment)) {
            this.handleFailedOAuth(onModalClose);
          }
        }
        requestAnimationFrame(checkWindow);
      }
    };


    window.addEventListener('message', (event) => {

      if (
        event.origin === environment.baseUrl
      ) {

        this.windowHandle.close();
        this.closeOauthWindow(onModalClose);
      }

    });

    requestAnimationFrame(checkWindow);

    return onModalClose.asObservable();
  }

  private closeOauthWindow(onModalClose: Subject<boolean>): void {
    window.clearInterval(this.intervalId);
    this.windowHandle.close();
    onModalClose.next(false);
    onModalClose.complete();
  }

  private handleSuccessfulDestination(href: string, onModalClose: Subject<boolean>, integrationInfo: IntegrationSourceModel): void {
    const url = new URL(href);
    const id = url.searchParams.get('id');

    window.clearInterval(this.intervalId);
    this.windowHandle.close();


    this.ref = this.openModalWindow(ModalDialogTypeEnum.ChooseDb, { id, integrationInfo });

    this.modalSubscription = this.ref.onClose.subscribe((s: any) => {
      onModalClose.next(s);
      onModalClose.complete();
    });
  }

  private handleSuccessfulOAuth(onModalClose: Subject<boolean>): void {
    this.windowHandle.close();
    window.clearInterval(this.intervalId);
    onModalClose.next(true);
    onModalClose.complete();
  }

  private handleFailedOAuth(onModalClose: Subject<boolean>): void {
    this.windowHandle.close();
    window.clearInterval(this.intervalId);
    onModalClose.next(false);
    onModalClose.complete();
  }

  private openModalWindow(modalType: ModalDialogTypeEnum, modalData: any): DynamicDialogRef {
    switch (modalType) {
      case ModalDialogTypeEnum.CreateToken:
        return this.dialogService.open(BaseIntegrationModalComponent, {
          data: { ...modalData, dialogType: ModalDialogTypeEnum.CreateToken },
          showHeader: false,
          width: this.smallModalWidth
        });
      case ModalDialogTypeEnum.DeleteToken:
        return this.dialogService.open(BaseIntegrationModalComponent, {
          data: { ...modalData, dialogType: ModalDialogTypeEnum.DeleteToken },
          showHeader: false,
          width: this.smallModalWidth
        });
      case ModalDialogTypeEnum.CreateIntegration:
        return this.dialogService.open(BaseModalDialogComponent, {
          data: { ...modalData, dialogType: ModalDialogTypeEnum.CreateIntegration },
          showHeader: false,
          width: this.smallModalWidth
        });
      case ModalDialogTypeEnum.ChooseDb:
        return this.dialogService.open(BaseIntegrationModalComponent, {
          data: { ...modalData, dialogType: ModalDialogTypeEnum.ChooseDb },
          showHeader: false,
          width: this.wideModalWidth,
          styleClass: 'grey-dialog-background',
          closable: true
        });
      case ModalDialogTypeEnum.PasswordVerification:
        return this.dialogService.open(PasswordVerificationModalComponent, {
          data: modalData,
          modal: true,
          showHeader: false,
          closable: false,
          width: this.smallModalWidth
        });
      case ModalDialogTypeEnum.CroppingImage:
        return this.dialogService.open(CroppingImageModalComponent, {
          data: modalData,
          modal: true,
          showHeader: false,
          closable: false,
          width: this.mediumModalWidth
        });
      case ModalDialogTypeEnum.ConfirmationModal:
        return this.dialogService.open(ConfirmationModalComponent, {
          data: modalData,
          modal: true,
          showHeader: false,
          closable: false,
          width: this.smallModalWidth
        });
      case ModalDialogTypeEnum.InfoModal:
        return this.dialogService.open(InfoModalComponent, {
          data: modalData,
          modal: true,
          showHeader: false,
          closable: false,
          styleClass: 'dialog-top',
          width: this.tinyModalWidth
        });
      case ModalDialogTypeEnum.PaymentCardModal:
        return this.dialogService.open(PaymentCardModalComponent, {
          data: modalData,
          modal: true,
          showHeader: false,
          closable: false,
          styleClass: 'dialog-top',
          width: this.mediumModalWidth
        });
      case ModalDialogTypeEnum.StreamingModal:
        return this.dialogService.open(StreamingSourceComponent, {
          data: modalData,
          modal: true,
          showHeader: false,
          closable: false,
          styleClass: 'grey-dialog-background prime-background',
          style: { height: '810px', overflow: 'hidden' },
          width: '720px'
        });
      case ModalDialogTypeEnum.WebhookModal:
        return this.dialogService.open(WebhookDestinationComponent, {
          data: modalData,
          modal: true,
          showHeader: false,
          closable: false,
          styleClass: 'grey-dialog-background prime-background',
          style: { height: '780px', overflow: 'hidden' },
          width: '720px'
        });
    }
  }
}
