import { Directive, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appTextInputTrim]',
})
export class TextInputTrimDirective {
  constructor(private ngControl: NgModel) {}

  @HostListener('blur')
  public onBlur(): void {
    this.ngControl.control.setValue(this.ngControl.value && this.ngControl.value.length > 0 && this.ngControl.value.trim());
  }
}
