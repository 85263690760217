<div class="setting-row-wrapper p-m-0">
  <app-renta-dropdown *ngIf="isLoaded; else loadingSetting"
                      [RentaDropdownSource]="AccountDataSource"
                      [ExtendedDescription]=true
                      [class.disabled]="isDataSourceLoading"
                      [additionalInfo]="'Specify the ad account that has required for uploading data'"
                      (OnApply)="onApplyAccounts($event)"></app-renta-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-dropdown *ngIf="isLoaded"
                      [RentaDropdownSource]="ReportTypesDataSource"
                      [class.disabled]="isDataSourceLoading"
                      [toolTipInfo]="'Report type consists of the unique set of parameters and metrics available for uploading to DWH. Each Report Type has its own parameters and metrics collection.'"
                      (OnApply)="onApplyReportType($event)"></app-renta-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-chips *ngIf="isLoaded; else loadingSetting"
                   [DataSource]="chipsParametersDataSource"
                   [class.disabled]="isDataSourceLoading"
                   (OnDeleteChip)="onCloseChip($event)"></app-renta-chips>
</div>
<div class="setting-row-wrapper">
  <app-renta-multiselect-dropdown
    *ngIf="isLoaded; else loadingSetting"
    (OnApply)="onApplyParameters($event)"
    [RentaMultiselectDropdownSource]="ParametersDataSource"
    [class.disabled]="isDataSourceLoading"
    [toolTipInfo]="'Parameters contains the list of parameters and metrics available for export.'"
    [additionalInfo]="'Specify the parameters required for uploading.'"
    [hotRefresh]="true">
  </app-renta-multiselect-dropdown>
</div>

<div class="setting-row-wrapper">
  <app-renta-dropdown *ngIf="isLoaded"
                      [RentaDropdownSource]="actionReportTimeDataSource"
                      [class.disabled]="isDataSourceLoading"
                      [additionalInfo]="'The type of ad action used to determine when to count action statistics in reports.'"
                      (OnApply)="onApplyReportTime($event)">

  </app-renta-dropdown>
</div>

<div class="setting-row-wrapper">
  <app-renta-dropdown *ngIf="isLoaded"
                      [RentaDropdownSource]="attributionSettingsDataSource"
                      [class.disabled]="isDataSourceLoading"
                      [additionalInfo]="'Your ad results will be displayed according to the attribution settings specified below.'"
                      (OnApply)="onApplyAttributionSettings($event)">
  </app-renta-dropdown>
</div>

<div *ngIf="customAttributionSectionIsShown" >
<div class="setting-row-wrapper attr-container-item ">
  <app-renta-dropdown *ngIf="isLoaded"
                      [RentaDropdownSource]="customAttributionClickWindowDataSource"
                      [class.disabled]="isDataSourceLoading"
                      (OnApply)="onApplyCustomAttributionClickWindow($event)" >
  </app-renta-dropdown>
</div>

  <div class="setting-row-wrapper attr-container-item">
  <app-renta-dropdown *ngIf="isLoaded"
                      [RentaDropdownSource]="customAttributionViewWindowDataSource"
                      [class.disabled]="isDataSourceLoading"
                      (OnApply)="onApplyCustomAttributionViewWindow($event)" >
  </app-renta-dropdown>
  </div>
</div>
<ng-template #loadingSetting>
  <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
  <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
</ng-template>
