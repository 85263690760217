import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';

@Component({
  selector: 'app-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.css']
})
export class DropdownItemComponent {
  @Input()
  public ListItem: ListItemModel;

  @Input()
  public Multi: boolean;

  @Input()
  public Extended: boolean;

  @Output()
  public OnClickListItem: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor() {
  }

  public listItemClick($event: MouseEvent): void {
    this.OnClickListItem.emit($event);
  }

  public onChangeListItem($event: any): void {
    const event = $event.originalEvent || $event;
    event.stopPropagation();
    event.preventDefault();
    this.OnClickListItem.emit($event);
  }
}
