import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-checkmark',
  templateUrl: './checkmark.component.html',
  styleUrls: ['./checkmark.component.css'],
})
export class CheckmarkComponent {
  @Input()
  public PositionCheckMark: string = 'left';

  @Input()
  public TextAlign: string = 'left';

  @Input()
  public Title: string = 'Success';

  @Input()
  public TitleDescription: string = 'Your operation was completed successfully';

  @Input()
  public Success: boolean = true;

  constructor() {}
}
