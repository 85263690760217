import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { map, mergeMap, tap } from 'rxjs/operators';

import { RentaApiService } from '../../../../services/renta-api.service';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { SocialBakersMeta } from '../../../../models/Integration/IntegrationsMeta/SocialBakersMeta';
import { ApiResponse } from '../../../../models/common/ApiResponse`T';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { EtlSettingsServiceBase } from './EtlSettingsServiceBase';

@Injectable()
export class SocialBakersSettingsService extends EtlSettingsServiceBase<SocialBakersMeta> {
  protected tablePrefix: string = 'sb';
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.socialBakers;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      ReportTypes: null,
      SourceType: null,
      Profiles: null,
      Posts: null,
      Parameters: null
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  constructor(public integrationInfoData: IntegrationInfoViewModel, protected readonly rentaApiService: RentaApiService,
              protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.memoryCacheKey = `tablePrefix-${IntegrationTypeEnum.socialBakers}`;
  }

  public setProfiles(selected: Array<ListItemModel>): boolean {
    if (_.isNil(selected)) {
      return false;
    }

    this.selectedSettings.Profiles = selected;
    return true;
  }


  public setReportType(selected: ListItemModel): boolean {
    if (_.isNil(selected) || _.some(this.selectedSettings.ReportTypes, selected)) {
      return false;
    }

    this.selectedSettings.ReportTypes = [selected];
    return true;
  }

  public setSourceType(selected: ListItemModel): boolean {
    if (_.isNil(selected) || _.some(this.selectedSettings.SourceType, selected)) {
      return false;
    }

    this.selectedSettings.SourceType = [selected];
    return true;
  }

  public setPosts(selected: Array<ListItemModel>): boolean {
    if (_.isNil(selected)) {
      return false;
    }

    this.selectedSettings.Posts = selected;
    return true;
  }

  public getProfiles(selectedSourceType: ListItemModel): Observable<Array<ListItemModel>> {
    const sourceType: string = _.isNil(selectedSourceType) ? _.first(this.selectedSettings.SourceType)?.id : selectedSourceType.id;

    if (_.isNil(sourceType)) {
      return of<[]>([]);
    }

    const url = this.meta.profilesUrl.replace('{sourceTypeId}', sourceType);
    const body = {integrationId: this.integrationInfoData.integrationId || null, sourceTokenId: this.integrationInfoData.integrationSourceToken };

    return this.rentaApiService.httpClient
      .post<ApiResponse<{ profiles: Array<ListItemModel> }>>(url, body)
      .pipe(
        tap((res: ApiResponse<{ profiles: Array<ListItemModel> }>): void => console.log(res)),
        map((res: ApiResponse<{ profiles: Array<ListItemModel> }>): Array<ListItemModel> => {
          if (res.baseResponse.status) {
            return res.result.profiles;
          }
          return [];
        })
      );
  }

  public getPosts(): Observable<Array<ListItemModel>> {
    const url = this.meta.postsUrl;
    const body = {integrationId: this.integrationInfoData.integrationId || null, sourceTokenId: this.integrationInfoData.integrationSourceToken };

    return this.rentaApiService.httpClient
      .post<ApiResponse<{ posts: Array<ListItemModel> }>>(url, body)
      .pipe(
        tap((res: ApiResponse<{ posts: Array<ListItemModel> }>): void => console.log(res)),
        map((res: ApiResponse<{ posts: Array<ListItemModel> }>): Array<ListItemModel> => {
          if (res.baseResponse.status) {
            return res.result.posts;
          }
          return [];
        })
      );
  }

  public getParameters(selectedReportType: ListItemModel, selectedSourceType: ListItemModel): Observable<Array<ListItemModel>> {
    const reportType: string = _.isNil(selectedReportType) ? _.first(this.selectedSettings.ReportTypes)?.id : selectedReportType.id;
    const sourceType: string = _.isNil(selectedSourceType) ? _.first(this.selectedSettings.SourceType)?.id : selectedSourceType.id;

    if (_.isNil(reportType) || _.isNil(sourceType)) {
      return of<[]>([]);
    }

    const url = this.meta.parametersUrl
      .replace('{reportTypeId}', reportType)
      .replace('{sourceTypeId}', sourceType);
    const body = { integrationId: this.integrationInfoData.integrationId || null, sourceTokenId: this.integrationInfoData.integrationSourceToken };

    return this.getCurrentParameters(url, body);
  }

  protected mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: response.dateRange || [],
      Date: response.date || null,
      Schedule: response.schedule || [],
      OverwritePeriod: response.overwritePeriod || [],
      [this.integrationTypeEnum]: {
        ReportTypes: response.reportTypes || [],
        SourceType: response.sourceTypes || [],
        Profiles: response.profiles || [],
        Posts: response.posts || [],
        Parameters: response.parameters || []
      },
      Name: response.name || defaultIntegrationName,
      TableName: response.tableName || defaultTableName
    };
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Profiles = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Posts = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [];
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings, isRefresh: boolean = false): void {
    const isRefreshSettings = this.editMode && isRefresh;
    this.selectedIntegrationSettings.Id = initialSettings.Id;

    this.selectedSettings.ReportTypes = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0])
      ? initialSettings[this.integrationTypeEnum].ReportTypes.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].ReportTypes.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.SourceType = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType[0])
      ? initialSettings[this.integrationTypeEnum].SourceType.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].SourceType.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Profiles = isRefreshSettings && _.some(this.selectedSettings.Profiles)
      ? initialSettings[this.integrationTypeEnum].Profiles.filter((f: ListItemModel): boolean => !_.isNil(this.selectedSettings.Profiles.find((ff: ListItemModel): boolean => ff.id === f.id)))
      : initialSettings[this.integrationTypeEnum].Profiles.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Posts = isRefreshSettings && _.some(this.selectedSettings.Posts)
      ? initialSettings[this.integrationTypeEnum].Posts.filter((f: ListItemModel): boolean => !_.isNil(this.selectedSettings.Posts.find((ff: ListItemModel): boolean => ff.id === f.id)))
      : initialSettings[this.integrationTypeEnum].Posts.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );

    this.initSelectedCommonSettings(initialSettings, isRefreshSettings);
  }

  protected refreshParameters(): Observable<any> {
    return this.rentaApiService.initIntegration(this.getWizardRequestData())
      .pipe(
        mergeMap((res: any): Observable<IntegrationSettings> => {
          this.meta = res.meta;
          res = this.mapIntegrationSettings(res, this.selectedIntegrationSettings.TableName, this.selectedIntegrationSettings.Name);
          const selectedReportTypes = this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0] ||
            res[this.integrationTypeEnum].ReportTypes.find((f: ListItemModel): boolean => f.selected);

          const selectedSourceType = this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType[0] ||
            res[this.integrationTypeEnum].SourceType.find((f: ListItemModel): boolean => f.selected);

          return (_.isNil(selectedReportTypes) || _.isNil(res[this.integrationTypeEnum].ReportTypes.find((f: ListItemModel): boolean => f.id === selectedReportTypes.id)) ||
            _.isNil(selectedSourceType) || _.isNil(res[this.integrationTypeEnum].SourceType.find((f: ListItemModel): boolean => f.id === selectedSourceType.id)))
            ? of(res)
            : this.getProfiles(selectedSourceType).pipe(
              mergeMap((profiles: Array<ListItemModel>): Observable<IntegrationSettings> => {
                res[this.integrationTypeEnum].Profiles = profiles;
                return this.getPosts().pipe(
                  mergeMap((posts: Array<ListItemModel>): Observable<IntegrationSettings> => {
                    res[this.integrationTypeEnum].Posts = posts;
                    return this.getParameters(selectedReportTypes, selectedSourceType).pipe(
                      mergeMap((params: Array<ListItemModel>): Observable<IntegrationSettings> => {
                        res[this.integrationTypeEnum].Parameters = params;
                        this.compareParametersWithCacheData(params);
                        return of(res);
                      })
                    );
                  })
                );
              })
            );
        })
      );
  }
}
