import { EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { ISettingsServiceBase } from './IEtlSettingsService';
import { ErrorMessage } from '../../../../models/common/ErrorMessage';
import { IntegrationSettings, RootIntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { RentaApiService } from '../../../../services/renta-api.service';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { IntegrationMetadataBase } from '../../../../models/Integration/IntegrationsMeta/IntegrationMetadataBase';
import { IntegrationToken } from '../../../../models/Integration/IntegrationToken';
import { BaseResponse } from '../../../../models/common/BaseResponse';
import { IntegrationSourceModel } from '../../../../models/Integration/IntegrationSourceModel';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { IBaseWizardRequest } from '../../../../models/common/BaseWizardRequest';

export abstract class SettingsServiceBase<TMeta extends IntegrationMetadataBase> implements ISettingsServiceBase {
  public onError: EventEmitter<Array<ErrorMessage>> = new EventEmitter<Array<ErrorMessage>>();
  public clearErrors: EventEmitter<any> = new EventEmitter<any>();
  public meta: TMeta;

  protected get selectedSettings(): RootIntegrationSettings {
    return this.selectedIntegrationSettings[this.integrationTypeEnum];
  }

  protected canSave: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  protected sourceIntegrationSettings: BehaviorSubject<IntegrationSettings> = new BehaviorSubject<IntegrationSettings>(null);

  protected editMode: boolean;

  protected abstract selectedIntegrationSettings: IntegrationSettings;
  protected abstract integrationTypeEnum: IntegrationTypeEnum;

  protected loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected sourceDestinationLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  protected availableDestinations: Array<IntegrationSourceModel> = [];

  protected constructor(
    public integrationInfoData: IntegrationInfoViewModel,
    protected readonly rentaApiService: RentaApiService,
    protected readonly rentaModalsService: RentaModalsService
  ) {
    this.editMode = !_.isNil(integrationInfoData.integrationId);
  }

  public isCanSave(): Observable<boolean>{
    return this.canSave.asObservable();
  }

  public setLockSave(lock: boolean): void {
    this.canSave.next(!lock);
  }

  public getSettingsName(): IntegrationTypeEnum {
    return this.integrationTypeEnum;
  }

  public connectToLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

  public connectToSourceDestinationLoading(): Observable<boolean> {
    return this.sourceDestinationLoading.asObservable();
  }

  public getSelectedSettings(): IntegrationSettings {
    return this.selectedIntegrationSettings;
  }

  public setErrors(errors: Array<ErrorMessage>): void {
    this.onError.emit(errors);
  }

  public setIntegrationName(name: string): void {
    this.selectedIntegrationSettings.Name = name;
  }

  public getIntegrationSourceTokens(): Observable<Array<ListItemModel>> {
    return this.rentaApiService.getIntegrationTokens(this.integrationTypeEnum).pipe(
      map((tokens: Array<IntegrationToken>): Array<ListItemModel> => {
        return this.convertTokensToListItemModel(tokens, this.integrationInfoData.integrationSourceToken);
      })
    );
  }

  public getAvailableIntegrationDestinations(): Observable<Array<ListItemModel>> {
    return this.rentaApiService.getAvailableIntegrationDestinations(this.integrationTypeEnum).pipe(
      map((sourceModels: Array<IntegrationSourceModel>): Array<ListItemModel> => {
        this.availableDestinations = sourceModels;
        return sourceModels.filter((f: IntegrationSourceModel): boolean => f.available).map((t: IntegrationSourceModel): ListItemModel => {
          return {
            id: t.integrationType,
            name: t.title,
            selected: t.integrationType === this.integrationInfoData.integrationDestinationType?.integrationType
          };
        });
      })
    );
  }

  public setSource(tokenId: string): void {
    const isIntegrationSourceTokenChanged = this.integrationInfoData.integrationSourceToken !== tokenId;

    setTimeout((): void => this.sourceDestinationLoading.next(true));
    this.rentaApiService.checkToken(this.integrationTypeEnum, tokenId).subscribe((response: BaseResponse): void => {

      if (!response.status) {
        response.errors[0].message = 'Source';
        this.setErrors(response.errors);
        this.integrationInfoData.integrationSourceToken = null;
        this.clearSelectedSettings();
        this.initSelectedSettings(this.selectedIntegrationSettings, true);
        this.sourceIntegrationSettings.next(this.selectedIntegrationSettings);
        this.sourceDestinationLoading.next(false);
        return;
      }

      this.clearErrors.emit(null);
      this.integrationInfoData.integrationSourceToken = tokenId;
      if (isIntegrationSourceTokenChanged) {
        this.refreshParameters().subscribe((res: IntegrationSettings): void => {
          if (_.isNil(res)) {
            return;
          }

          this.initSelectedSettings(res, true);
          this.sourceIntegrationSettings.next(res);
          this.sourceDestinationLoading.next(false);
        });
      }
      else {
        this.sourceDestinationLoading.next(false);
      }

    });
  }

  public setDestination(destination: string): boolean {
    const selectedDestinations = this.availableDestinations.find((f: IntegrationSourceModel): boolean => f.integrationType === destination);

    if (_.isNil(this.integrationInfoData.integrationDestinationType)) {
      this.integrationInfoData.integrationDestinationType = selectedDestinations;
      return true;
    }

    if (this.integrationInfoData.integrationDestinationType.integrationType === destination || _.isNil(selectedDestinations)) {
      this.integrationInfoData.integrationDestinationType = selectedDestinations;
      return false;
    }

    this.integrationInfoData.integrationDestinationType = selectedDestinations;
    return true;
  }

  public getDestinationTokens(): Observable<Array<ListItemModel>> {
    if (_.isNil(this.integrationInfoData.integrationDestinationType)) {
      return of([]);
    }

    return this.rentaApiService.getIntegrationTokens(this.integrationInfoData.integrationDestinationType.integrationType).pipe(
      map((tokens: Array<IntegrationToken>): Array<ListItemModel> => {
        const mappedTokens = this.convertTokensToListItemModel(tokens, this.integrationInfoData.integrationDestinationToken);

        if (mappedTokens.length > 0 && _.isNil(mappedTokens.find((f: ListItemModel): boolean => f.selected))) {
          mappedTokens[0].selected = true;
        }
        return mappedTokens;
      })
    );
  }

  public setDestinationToken(tokenId: string): void {
    if (this.integrationInfoData.integrationDestinationToken === tokenId) {
      return;
    }

    this.rentaApiService
      .checkToken(this.integrationInfoData.integrationDestinationType.integrationType, tokenId)
      .subscribe((response: BaseResponse): void => {
        if (!response.status) {
          response.errors[0].message = 'DestinationAccounts';
          this.setErrors(response.errors);
        }

        this.integrationInfoData.integrationDestinationToken = tokenId;
      });
  }

  public addSourceAccount(): Observable<boolean> {
    return this.rentaModalsService.showSourceAuth(this.integrationInfoData.integrationSourceType);
  }

  public addDestinationAccount(): Observable<boolean> {
    return this.rentaModalsService.showSourceAuth(this.integrationInfoData.integrationDestinationType);
  }

  public setTableName(tableName: string): void {
    this.selectedIntegrationSettings.TableName = tableName;
  }

  public abstract loadSettings(): Observable<IntegrationSettings>

  public abstract isETL(): boolean;

  protected getWizardRequestData(): IBaseWizardRequest {
    return {
      integrationId: this.integrationInfoData.integrationId,
      sourceTokenId: this.integrationInfoData.integrationSourceToken,
      destinationTokenId: this.integrationInfoData.integrationDestinationToken,
      sourceTokenType: this.integrationInfoData.integrationSourceType.integrationType,
      destinationTokenType: this.integrationInfoData.integrationDestinationType.integrationType,
    };
  }

  protected abstract mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings;

  protected abstract initSelectedSettings(initialSettings: IntegrationSettings, isRefresh?: boolean): void;

  protected abstract clearSelectedSettings(): void;

  protected abstract refreshParameters(): Observable<any>;

  private convertTokensToListItemModel(tokens: Array<IntegrationToken>, selectedId: string): Array<ListItemModel> {
    return tokens.map((t: IntegrationToken): ListItemModel => {
      return {
        id: t.id,
        name: t.title,
        additionalInfo: t.additionalInfo,
        selected: t.id === selectedId
      };
    });
  }
}
