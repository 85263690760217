<div class="settings-component-container" *ngIf="ds | async; let dataSource">
  <div class="setting-title-wrapper p-d-flex p-flex-column" *ngIf="ShowName">
    <div class='p-d-flex'>
      <span class="renta-font-normal setting-title-font">{{ dataSource.name }}</span>
      <i *ngIf='toolTipInfo !== null && toolTipInfo !== undefined && toolTipInfo.length > 0' class='renta-ic renta-ic-info-square' [pTooltip]="toolTipInfo" tooltipPosition="top"  style='max-width: 20px;min-width: 20px;max-height: 20px;min-height: 20px'></i>
    </div>
    <span class='renta-font-small renta-subtext-color p-my-1' *ngIf='additionalInfo !== null && additionalInfo !== undefined && additionalInfo.length > 0'>{{additionalInfo}}</span>
  </div>
  <div appOverlayDropdown
       [DropdownCount]="dataSource.length"
       (OnToggleOverlay)="onFocus($event)"
       [class.p-disabled]="RentaMultiselectDropdownSource.loading || dataSource.count === 0 && !searchString"
       class="dropdown-container">
    <span class="input-margin"
          [ngClass]="isOpen ? 'p-input-icon-left' : 'p-input-icon-right'">
      <i class="drop-down-icon pi pi-search" *ngIf="isOpen"></i>
      <i class="drop-down-icon pi pi-chevron-down" *ngIf="!RentaMultiselectDropdownSource.loading && !isOpen"></i>
      <i class="drop-down-icon pi pi-spin pi-spinner" *ngIf="RentaMultiselectDropdownSource.loading"></i>
      <label for="{{getId(dataSource.name)}}" class="p-d-none"></label>
      <input #dropdownSearch
             pInputText
             id="{{getId(dataSource.name)}}"
             type="text"
             class="dropdown-search-input"
             placeholder="{{isOpen ? '' : 'More data source in the list'}}"
             [(ngModel)]="searchString"
             (keyup)="RentaMultiselectDropdownSource.filter(searchString)">
    </span>
    <ng-template #dropdownContent>
      <div [class.show-dropdown]="isOpen" class="dropdown-content">
        <div class="dropdown-content-wrapper">
          <app-multiselect-dropdown-content
            [DropdownDataSource]="dataSource"
            [selectedDataSource]="selectedDataSource"
            [showButtons]="!hotRefresh"
            (OnToggleListItem)="onToggleListItem($event)"
            (OnCheckAll)="onCheckAll($event)"
            (OnUncheckAll)="onUncheckAll($event)"
            (OnApply)="onApply()"
            (OnTabChanged)="onTabChanged()"
          ></app-multiselect-dropdown-content>
        </div>
      </div>
    </ng-template>
  </div>
  <app-renta-error-message [errorMessage]="RentaMultiselectDropdownSource.error"
                           *ngIf="RentaMultiselectDropdownSource.error"></app-renta-error-message>
</div>
