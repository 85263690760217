import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { map, mergeMap } from 'rxjs/operators';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { RentaApiService } from '../../../../services/renta-api.service';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { AppMetricaLogsApiMeta } from '../../../../models/Integration/IntegrationsMeta/AppMetricaLogsApi';
import { ApiResponse } from '../../../../models/common/ApiResponse`T';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { EtlSettingsServiceBase } from './EtlSettingsServiceBase';

@Injectable()
export class AppMetricaLogsApiSettingsService extends EtlSettingsServiceBase<AppMetricaLogsApiMeta> {
  protected tablePrefix: string = 'apm_logs';
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.appmetrica_logs_api;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      Counter: null,
      Clients: null,
      SourceType: null,
      Parameters: null,
     },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  constructor(public integrationInfoData: IntegrationInfoViewModel, protected readonly rentaApiService: RentaApiService,
              protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.memoryCacheKey = `tablePrefix-${IntegrationTypeEnum.appmetrica_logs_api}`;
  }

  public setSelectedClients(selectedClients: Array<ListItemModel>): void {
    if (_.isNil(selectedClients)) {
      return;
    }

    this.selectedSettings.Clients = selectedClients;
  }

  public setSelectedCounter(selectedCounter: ListItemModel): boolean {
    if (_.isNil(selectedCounter) || _.some(this.selectedSettings.Counter, selectedCounter)) {
      return false;
    }

    this.selectedSettings.Counter = [selectedCounter];
    return true;
  }

  public setSourceType(selectedSourceType: ListItemModel): boolean {
    if (_.isNil(selectedSourceType) || _.some(this.selectedSettings.SourceType, selectedSourceType)) {
      return false;
    }

    this.selectedSettings.SourceType = [selectedSourceType];
    return true;
  }

  public getParameters(selectedCounter: ListItemModel, selectedSourceType: ListItemModel): Observable<Array<ListItemModel>> {
    const counterId: string = _.isNil(selectedCounter) ? _.first(this.selectedSettings.Counter)?.id : selectedCounter.id;
    const sourceTypeId: string = _.isNil(selectedSourceType) ? _.first(this.selectedSettings.SourceType)?.id : selectedSourceType.id;

    if (_.isNil(sourceTypeId) || _.isNil(counterId)) {
      return of<[]>([]);
    }

    const url: string = this.meta.parametersUrl.replace('{counterId}', counterId).replace('{sourceTypeId}', sourceTypeId);
    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };

    return this.getCurrentParameters(url, body);
  }

  public getClients(selectedCounter: ListItemModel): Observable<Array<ListItemModel>> {
    const counterId: string = _.isNil(selectedCounter) ? _.first(this.selectedSettings.Counter)?.id : selectedCounter.id;

    if (_.isNil(counterId)) {
      return of<[]>([]);
    }

    // TODO: workaround. need to clarify why this.meta.clientsUrl is undefined
    if ( this.meta.clientsUrl ) {
      const url: string = this.meta.clientsUrl.replace('{counterId}', counterId);
      const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };
      return this.rentaApiService.httpClient.post<ApiResponse<{ clients: Array<ListItemModel> }>>(url, body).pipe(
        map((res: ApiResponse<{ clients: Array<ListItemModel> }>): Array<ListItemModel> => {
          if (res.baseResponse.status) {
            return res.result.clients;
          }
          return [];
        })
      );
    }
    return of<[]>([]);
  }

  protected mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: response.dateRange || [],
      Date: response.date || null,
      Schedule: response.schedule || [],
      OverwritePeriod: response.overwritePeriod || [],
      [this.integrationTypeEnum]: {
        Counter: response.counters || [],
        Clients: response.clients || [],
        SourceType: response.sourceTypes || [],
        Parameters: response.parameters || [],
      },
      Name: response.name || defaultIntegrationName,
      TableName: response.tableName || defaultTableName
    };
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].Counter = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Clients = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [];
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings, isRefresh: boolean = false): void {

    const isRefreshSettings = this.editMode && isRefresh;
    this.selectedIntegrationSettings.Id = initialSettings.Id;

    this.selectedSettings.Counter = isRefreshSettings && !_.isNil(_.first(this.selectedIntegrationSettings[this.integrationTypeEnum].Counter))
      ? initialSettings[this.integrationTypeEnum].Counter.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].Counter[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].Counter.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.SourceType = isRefreshSettings && !_.isNil(_.first(this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType))
      ? initialSettings[this.integrationTypeEnum].SourceType.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].SourceType.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Clients = isRefreshSettings && _.some(this.selectedSettings.Clients)
      ? initialSettings[this.integrationTypeEnum].Clients.filter((f: ListItemModel): boolean => !_.isNil(this.selectedSettings.Clients.find((ff: ListItemModel): boolean => ff.id === f.id)))
      : initialSettings[this.integrationTypeEnum].Clients.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );

    this.initSelectedCommonSettings(initialSettings, isRefreshSettings);
  }

  protected refreshParameters(): Observable<any> {
    return this.rentaApiService.initIntegration(this.getWizardRequestData())
      .pipe(
        mergeMap((res: any): Observable<IntegrationSettings> => {
          this.meta = res.meta;
          res = this.mapIntegrationSettings(res, this.selectedIntegrationSettings.TableName, this.selectedIntegrationSettings.Name);
          const selectedSourceType = _.first(this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType) ||
            res[this.integrationTypeEnum].SourceType.find((f: ListItemModel): boolean => f.selected);

          const selectedCounter = _.first(this.selectedIntegrationSettings[this.integrationTypeEnum].Counter) ||
            res[this.integrationTypeEnum].Counter.find((f: ListItemModel): boolean => f.selected);

          return (_.isNil(selectedSourceType) || _.isNil(res[this.integrationTypeEnum].SourceType.find((f: ListItemModel): boolean => f.id === selectedSourceType.id)) ||
            _.isNil(selectedCounter) || _.isNil(res[this.integrationTypeEnum].Counter.find((f: ListItemModel): boolean => f.id === selectedCounter.id)))
            ? of(res)
            : this.getClients(selectedCounter).pipe(
              mergeMap((clients: Array<ListItemModel>): Observable<IntegrationSettings> => {
                res[this.integrationTypeEnum].Clients = clients;
                return this.getParameters(selectedCounter, selectedSourceType).pipe(
                  mergeMap((params: Array<ListItemModel>): Observable<IntegrationSettings> => {
                    res[this.integrationTypeEnum].Parameters = params;
                    this.compareParametersWithCacheData(params);
                    return of(res);
                  })
                );
              })
            );
        })
      );
  }
}
