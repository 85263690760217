import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mssql-auth',
  templateUrl: './mssql-auth.component.html',
  styleUrls: ['./mssql-auth.component.scss']
})
export class MssqlAuthComponent implements OnInit {

  @Input()
  public error: string;

  @Input()
  public ip: string;

  @Input()
  public port: string;

  @Input()
  public login: string;

  @Input()
  public password: string;

  @Input()
  public databaseName: string;

  @Input()
  public useSsl: boolean;

  @Input()
  public loading: boolean;

  constructor() {
  }

  public ngOnInit(): void {
    this.useSsl = false;
  }

  public onChangeListItem($event: any): void {
    this.useSsl = $event.checked;
  }
}
