import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectDropdownDataSourceModel } from '../../../../models/DataSource/DataSourceModels/MultiselectDropdownDataSourceModel';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-multiselect-dropdown-content',
  templateUrl: './multiselect-dropdown-content.component.html',
  styleUrls: ['./multiselect-dropdown-content.component.scss']
})
export class MultiselectDropdownContentComponent implements OnInit, AfterContentChecked {
  @Input()
  public DropdownDataSource: MultiselectDropdownDataSourceModel;

  @Input()
  public selectedDataSource: Observable<Array<ListItemModel>> = null;

  @Input()
  public showButtons: boolean = false;

  @Output()
  public OnToggleListItem: EventEmitter<ListItemModel> = new EventEmitter<ListItemModel>();

  @Output()
  public OnCheckAll: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public OnUncheckAll: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public OnTabChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public OnApply: EventEmitter<string> = new EventEmitter<string>();

  public activeTab: {
    index: number;
    name: string;
  };
  public selectedTabActive: boolean = false;

  constructor() {
  }

  public ngOnInit(): void {
    if (_.isNil(this.selectedDataSource)) {
      return;
    }

    this.activeTab = {
      index: 0,
      name: this.DropdownDataSource.groups[0].groupName
    };

    this.selectedDataSource.subscribe((s: Array<ListItemModel>): void => {
      if (s.length === 0 && this.selectedTabActive) {
        this.activeTab.index = 0;
        this.selectedTabActive = false;
        this.OnTabChanged.emit(0);
      }
    });
  }

  public onClickListItem(item: ListItemModel): void {
    this.OnToggleListItem.emit(item);
  }

  public getClass(type: string): string {
    switch (type) {
      case 'Metrics':
        return 'metric-background';
      case 'Dimensions':
        return 'dimension-background';
      case 'Goals':
        return 'goal-background';
      default:
        return 'default-background';
    }
  }

  public tabChanged(e: { selected: number }): void {
    this.selectedTabActive = e.selected === this.DropdownDataSource.groups.length;

    if (this.selectedTabActive) {
      this.OnTabChanged.emit(e.selected);
    } else {
      this.activeTab.name = this.DropdownDataSource.groups[e.selected].groupName;
    }
  }

  public onUncheckAll(): void {
    if (this.selectedTabActive) {
      this.activeTab.index = 0;
      this.selectedTabActive = false;
      this.OnUncheckAll.emit(null);
      return;
    }

    this.OnUncheckAll.emit(this.DropdownDataSource.groups[this.activeTab.index].groupName);
  }

  public onCheckAll(): void {
    this.OnCheckAll.emit(this.DropdownDataSource.groups[this.activeTab.index].groupName);
  }

  public onApply(): void {
    this.OnApply.emit();
  }

  public ngAfterContentChecked(): void {
    if (
      _.isNil(
        this.DropdownDataSource.groups.find((f: { count: number; groupName: string }): boolean => f.groupName === this.activeTab.name)
      )
    ) {
      this.activeTab = {
        index: 0,
        name: ''
      };
    } else {
      const index = this.DropdownDataSource.groups.findIndex(
        (f: { count: number; groupName: string }): boolean => f.groupName === this.activeTab.name
      );

      this.activeTab = {
        index,
        name: this.DropdownDataSource.groups[index].groupName
      };
    }
  }
}
