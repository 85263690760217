import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { CalltouchMeta } from '../../../../models/Integration/IntegrationsMeta/CalltouchMeta';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { RentaApiService } from '../../../../services/renta-api.service';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { mergeMap } from 'rxjs/operators';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { EtlSettingsServiceBase } from './EtlSettingsServiceBase';

@Injectable()
export class CalltouchSettingsService extends EtlSettingsServiceBase<CalltouchMeta> {
  protected tablePrefix: string = 'ct';
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.calltouch;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [IntegrationTypeEnum.calltouch]: {
      ReportTypes: null,
      Parameters: null
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  constructor(
    public integrationInfoData: IntegrationInfoViewModel,
    protected readonly rentaApiService: RentaApiService,
    protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.memoryCacheKey = `tablePrefix-${IntegrationTypeEnum.calltouch}`;
  }

  public setSelectedCustomerAccounts(selectedAccounts: Array<ListItemModel>): void {
    if (_.isNil(selectedAccounts)) {
      return;
    }

    this.selectedSettings.CustomerAccounts = selectedAccounts;
  }

  public setReportType(selectedReportType: ListItemModel): boolean {
    if (_.isNil(selectedReportType) || _.some(this.selectedSettings.ReportTypes, selectedReportType)) {
      return false;
    }

    this.selectedSettings.ReportTypes = [selectedReportType];
    return true;
  }

  public getParameters($event: ListItemModel): Observable<Array<ListItemModel>> {
    if (_.isNil($event)) {
      return of<[]>([]);
    }

    const url = this.meta.parametersUrl.replace('{reportTypeId}', $event.id);
    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };

    return this.getCurrentParameters(url, body);
  }

  protected mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: response.dateRange || [],
      Date: response.date || null,
      Schedule: response.schedule || [],
      OverwritePeriod: response.overwritePeriod || [],
      [this.integrationTypeEnum]: {
        CustomerAccounts: response.customerAccounts || [],
        ReportTypes: response.reportTypes || [],
        Parameters: response.parameters || []
      },
      Name: response.name || defaultIntegrationName,
      TableName: response.tableName || defaultTableName
    };
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].CustomerAccounts = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [];
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings, isRefresh: boolean = false): void {
    const isRefreshSettings = this.editMode && isRefresh;
    this.selectedIntegrationSettings.Id = initialSettings.Id;
    this.selectedSettings.CustomerAccounts = isRefreshSettings && _.some(this.selectedSettings.CustomerAccounts)
      ? initialSettings[this.integrationTypeEnum].CustomerAccounts.filter((f: ListItemModel): boolean => !_.isNil(this.selectedSettings.CustomerAccounts.find((ff: ListItemModel): boolean => ff.id === f.id)))
      : initialSettings[this.integrationTypeEnum].CustomerAccounts.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.ReportTypes = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0])
      ? initialSettings[this.integrationTypeEnum].ReportTypes.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].ReportTypes.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );

    this.initSelectedCommonSettings(initialSettings, isRefreshSettings);
  }

  protected refreshParameters(): Observable<any> {
    return this.rentaApiService.initIntegration(this.getWizardRequestData())
      .pipe(
        mergeMap((res: any): Observable<IntegrationSettings> => {
          this.meta = res.meta;

          res = this.mapIntegrationSettings(res, this.selectedIntegrationSettings.TableName, this.selectedIntegrationSettings.Name);
          const selectedReportType = this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0] ||
            res[this.integrationTypeEnum].ReportTypes.find((f: ListItemModel): boolean => f.selected);

          return (_.isNil(selectedReportType) || _.isNil(res[this.integrationTypeEnum].ReportTypes.find((f: ListItemModel): boolean => f.id === selectedReportType.id)))
            ? of(res)
            : this.getParameters(selectedReportType).pipe(
              mergeMap((params: Array<ListItemModel>): Observable<IntegrationSettings> => {
                res[this.integrationTypeEnum].Parameters = params;
                this.compareParametersWithCacheData(params);
                return of(res);
              })
            );
        })
      );
  }
}
