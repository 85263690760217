<div>
  <div [sortablejs]="selectedColumns | async" [sortablejsOptions]="listOptions">
    <div *ngFor="let row of selectedColumns | async" class="p-d-flex p-ai-center width-max">
      <fa-icon [icon]="faGrip" class="close-modal-btn handle renta-font-subtext p-mb-2"></fa-icon>
      <div class="width-inherit p-mb-1">
        <app-column-mapping-row
          [ColumnInfo]="row"
          [ColumnTypesDataSource]="row.additionalData"
          (OnApplyColumnType)="onApplyColumn($event)"
          (OnDeleteColumnType)="onDeleteColumn($event)"
        ></app-column-mapping-row>
      </div>
    </div>
  </div>
</div>
