<div class="p-d-flex p-flex-column sidebar-container p-align-center"
     appDontTouch
     *ngIf="Visible">
  <div class="renta-sidebar">
    <span>Renta</span>
  </div>
  <nav class="p-d-flex p-flex-column width-max main-navigation-wrapper">
    <div class="nav-item-row p-d-flex p-justify-center p-align-center"
         [class.active-route]="integrationsNavActive">
      <button routerLink="integrations"
              class="p-link p-d-flex p-flex-column p-align-center p-justify-center p-ai-center width-max nav-item-font"
              (click)="integrations()">
        <span class="sidebar-icon p-d-flex p-justify-center">
          <img src="assets/icons/sidebar/ic-renta-integrations.svg" alt="integrations" />
          <img src="assets/icons/sidebar/ic-renta-integrations-white-active.svg" alt="integrations active" />
        </span>
        <span class="nav-btn-title">Pipelines</span>
      </button>
    </div>
    <div class="nav-item-row p-d-flex p-justify-center p-align-center"
         [class.active-route]="createNavActive">
      <button class="p-link p-d-flex p-flex-column p-align-center p-justify-center p-ai-center width-max nav-item-font"
              (click)="createNew()">
        <span class="sidebar-icon p-d-flex p-justify-center">
          <img src="assets/icons/sidebar/ic-renta-plus-hover.svg" alt="plus" />
          <img src="assets/icons/sidebar/ic-renta-plus-white-active.svg" alt="plus active" />
        </span>
        <span class="nav-btn-title">Catalog</span>
      </button>
    </div>
  </nav>
  <nav class="p-d-flex p-flex-column p-mt-auto">
    <div class="nav-user-menu p-jc-end">
      <button class="p-link p-d-flex p-flex-column p-ai-center nav-item-font p-align-center"
              appMenuPopup
              [menuPopupSource]="menuPopupModel">
          <span class="profile-image-container p-d-flex p-align-center p-justify-center">
            <app-avatar-image-block
              [imgUrl]="avatarImg | async"
              [loading]="avatarLoading | async"
            ></app-avatar-image-block>
          </span>
        <span class="nav-btn-title">Profile</span>
      </button>
    </div>
  </nav>
</div>

