<div class="setting-row-wrapper p-m-0">
  <app-renta-dropdown
    *ngIf="isLoaded; else loadingSetting"
    [RentaDropdownSource]="SpreadsheetsDataSource"
    [class.disabled]="isDataSourceLoading"
    [toolTipInfo]="'Choose the spreadsheet from which you want to extract data.'"
    (OnApply)="onApplySpreadsheets($event)"></app-renta-dropdown>
</div>

<div class="setting-row-wrapper">
  <app-renta-dropdown
    *ngIf="isLoaded; else loadingSetting"
    [RentaDropdownSource]="WorksheetsDataSource"
    [class.disabled]="isDataSourceLoading"
    [toolTipInfo]="'Choose the worksheet from which you want to extract data.'"
    (OnApply)="onApplyWorksheets($event)"></app-renta-dropdown>
</div>

<div class="setting-row-wrapper">
    <app-renta-input-num
      *ngIf="isLoaded; else loadingSetting"
      [RentaInputSource]="headerRowNumberDataSource"
      [min] = 1
      [max] = 10000
      (change)="onHeaderRowNumberChange($event)"
      [additionalInfo]="'Specify the row number that serves as a header for tables.'"></app-renta-input-num>
  </div>

  <div class="setting-row-wrapper">
    <app-renta-chips
      *ngIf="isLoaded; else loadingSetting"
      [DataSource]="chipsParametersDataSource"
      (OnDeleteChip)="onCloseChip($event)"></app-renta-chips>
  </div>
  <div class="setting-row-wrapper">
    <app-renta-multiselect-dropdown
      *ngIf="isLoaded; else loadingSetting"
      (OnApply)="onApplyWorksheetColumns($event)"
      [RentaMultiselectDropdownSource]="ParametersDataSource"
      [class.disabled]="isDataSourceLoading"
      [additionalInfo]="'Specify the parameters required for uploading.'"
      [toolTipInfo]="'Parameters contains the list of parameters and metrics available for export.'"
      [hotRefresh]="true">
    </app-renta-multiselect-dropdown>
  </div>

  <ng-template #loadingSetting>
    <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
    <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
  </ng-template>
