import { Injectable } from '@angular/core';
import * as _ from 'lodash';


export interface AnyObject {
  [key: string]: any
}

@Injectable({
  providedIn: 'root'
})
export class MemoryCacheService {
  private cachedElement: AnyObject = {};

  constructor() {
  }

  public initCache(key: string, element: any): void {
    if (!_.isNil(element) && _.isNil(this.cachedElement[key])) {
      this.cachedElement[key] = element;
    }
  }

  public setDataToCache(key: string, element: any): void {
    if (_.isNil(this.cachedElement[key])) {
      return;
    }

    this.cachedElement[key] = _.cloneDeep(element);
  }

  public getDataFromCache(key: string): any {
    return this.cachedElement[key];
  }

  public deleteDataInCache(key: string): void {
    this.cachedElement[key] = null;
  }
}
