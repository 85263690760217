<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="mr-b-5">
    <app-renta-error-message [errorMessage]="error"
                             *ngIf="error"></app-renta-error-message>
  </div>

  <form [formGroup]="fileForm">
    <label for="filename" class="p-d-none"></label>
    <input id="filename"
           formControlName="name"
           class="p-inputtext-lg auth-dialog-input filename-input"
           type="text"
           placeholder="File name"
           pInputText
           appTextInputTrim
           [attr.disabled]="loading ? true : null">

      <label for="url" class="p-d-none mr-t-2"></label>
      <input id="url"
             formControlName="url"
             class="p-inputtext-lg auth-dialog-input url-input"
             type="text"
             placeholder="File url"
             pInputText
             appTextInputTrim
             [attr.disabled]="loading ? true : null">

      <label for="separator" class="p-d-none"></label>
      <p-dropdown id="separator"
                  formControlName="separator"
                  [options]="fileIntNumericService.separator"
                  optionLabel="name"
                  optionValue="id"
                  (onChange)="onSeparatorChange($event)"
                     ></p-dropdown>

      <label for="typeofAuth" class="p-d-none"></label>
      <p-dropdown id="typeofAuth"
                  formControlName="typeofAuth"
                  [options]="fileIntNumericService.typeofAuth"
                  optionLabel="name"
                  optionValue="id"
                  (onChange)="onTypeofAuthChange($event)"
                  ></p-dropdown>

    <div *ngIf="fileForm.get('typeofAuth').value === 'basic_auth'">
      <label for="fileLogin" class="p-d-none"></label>
      <input id="fileLogin"
             formControlName="login"
             class="p-inputtext-lg auth-dialog-input filename-input "
             type="text"
             placeholder="Login"
             pInputText
             appTextInputTrim
             [attr.disabled]="loading ? true : null">

      <label for="filePassword" class="p-d-none mr-t-2"></label>
      <input id="filePassword"
             formControlName="password"
             class="p-inputtext-lg auth-dialog-input url-input"
             type="text"
             placeholder="Password"
             pInputText
             appTextInputTrim
             [attr.disabled]="loading ? true : null">
    </div>
  </form>

  <div class="message-container p-d-flex p-flex-column p-align-center">
    <span class="renta-font-normal">You should allow connection from next ip:</span>
    <span class="renta-font-normal">20.93.236.144</span>
  </div>
</div>
