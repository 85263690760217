<div class="setting-row-wrapper p-m-0" *ngIf="isShowDateRange">
  <app-renta-date-range *ngIf="isLoaded; else loadingSetting"
                        [DateRangeDataSource]="dateRangeDataSource"
                        [additionalInfo]="'Choose the inserting period. You can specify any historical time interval.'"
                        [toolTipInfo]="'Additional info in our documentation.'"
                        (OnApply)="onApplyDateRange($event)"></app-renta-date-range>
</div>
<div class="setting-row-wrapper" [class.p-m-0]="!isShowDateRange">
  <app-renta-dropdown *ngIf="isLoaded; else loadingSetting"
                      [RentaDropdownSource]="updateTimeDropdownDataSource"
                      [additionalInfo]="'The update time is the exact interval of time when the integration will be updated each time.'"
                      (OnApply)="onApplyUpdateTimeType($event)"></app-renta-dropdown>
</div>
<div class="setting-row-wrapper" *ngIf="isShowOverwritePeriod">
  <app-renta-dropdown *ngIf="isLoaded; else loadingSetting"
                      [RentaDropdownSource]="overwritePeriodDropdownDataSource"
                      [toolTipInfo]="'Overwrite period is the period for which you want to overwrite the data. Renta will delete the data in your table for the specified period and insert relevant ones.'"
                      [additionalInfo]="'Choose the period of time which will be overwrited. Every day the data for that period will be deleted and inserted again.'"
                      (OnApply)="onApplyOverwritePeriod($event)"></app-renta-dropdown>
</div>
<ng-template #loadingSetting>
  <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
  <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
</ng-template>

