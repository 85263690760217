import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserService } from '../../../../services/user.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';

enum ResetPasswordState {
  resetFrom,
  wrongCode,
  resetSuccess,
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../common-auth-styles.css', './reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public ResetPasswordState: typeof ResetPasswordState = ResetPasswordState;
  public resetPasswordState: ResetPasswordState;
  public loaded: boolean;
  public showForm: boolean;
  public errorMessage: string = null;
  public resetPasswordForm: FormGroup;
  public submitted: boolean;
  public resetInProgress: boolean;
  public showSuccessCheckmark: boolean = false;
  public checkmarkTitle: string;
  public checkmarkDescription: string;

  private code: string;
  private routerSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userService: UserService
  ) {}

  public ngOnInit(): void {
    this.showSuccessCheckmark = true;
    this.resetPasswordForm = new FormGroup(
      {
        newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
        confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      },
      { updateOn: 'submit' }
    );

    this.loaded = false;

    this.routerSubscription = this.activatedRoute.queryParams.subscribe((params: Params): void => {
      if (!params.resetCode) {
        return;
      }
      this.loaded = true;

      if (params.resetCode !== 'invalid') {
        this.resetPasswordState = ResetPasswordState.resetFrom;
        this.code = params.resetCode;

        return;
      }

      this.resetPasswordState = ResetPasswordState.wrongCode;
      this.checkmarkTitle = 'Invalid link!';
      this.checkmarkDescription = 'You automatically redirect to Sign in!';

      setTimeout((): void => {
        this.router.navigate(['auth/sign-in']).then();
      }, 5000);
    });
  }

  public onSubmit(): void {
    this.submitted = true;
    this.errorMessage = null;

    if (!this.isFormValid()) {
      return;
    }

    this.resetInProgress = true;

    this.resetPasswordForm.get('newPassword').disable();
    this.resetPasswordForm.get('confirmPassword').disable();

    const newPassword = this.resetPasswordForm.get('newPassword').value;

    this.userService.resetPassword(newPassword, this.code).subscribe((res: string): void => {
      this.resetInProgress = false;
      if (res === null || res === undefined) {
        this.resetPasswordState = ResetPasswordState.resetSuccess;
        this.checkmarkTitle = 'Success!';
        this.checkmarkDescription = 'Your password changed, automatically redirect to Sign in!';

        setTimeout((): void => {
          this.router.navigate(['auth/sign-in']).then();
        }, 5000);
      }

      this.errorMessage = res;
      this.resetPasswordForm.get('newPassword').enable();
      this.resetPasswordForm.get('confirmPassword').enable();
    });
  }

  public ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  public isFormValid(): boolean {
    if (!this.submitted || this.resetInProgress) {
      return true;
    }
    if (!this.resetPasswordForm.get('newPassword').valid || !this.resetPasswordForm.get('confirmPassword').valid) {
      return false;
    }

    if (this.resetPasswordForm.get('newPassword').value !== this.resetPasswordForm.get('confirmPassword').value) {
      this.resetPasswordForm.setErrors({ error: 'mistach' });
      this.errorMessage = 'Passwords do not match';
      return false;
    }

    return true;
  }
}
