import { BehaviorSubject, Observable } from 'rxjs';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { MultiselectDropdownDataSourceModel } from './DataSourceModels/MultiselectDropdownDataSourceModel';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { groupBy } from '../../../../helpers/helpers';

function getDropdownDataSource(name: string, sourceListItems: Array<ListItemModel>): MultiselectDropdownDataSourceModel {
  const count = sourceListItems.length;
  sourceListItems.forEach((i: ListItemModel): void => {
    if (_.isNil(i.additionalInfo)) {
      i.additionalInfo = [];
    }

    i.additionalInfo.push(i.id);
  });
  const groupData = groupBy(sourceListItems, (item: ListItemModel): string => item.group);
  const groups = new Array<{ groupName: string; count: number }>();

  // for task FEATURE 613
  if (groupData.Dimensions) {
    groups.push({ groupName: 'Dimensions', count: groupData.Dimensions.length });
  }

  for (const key in groupData) {
    if (groupData.hasOwnProperty(key) && key !== 'Dimensions') {
      groups.push({ groupName: key, count: groupData[key].length });
    }
  }

  return {
    count,
    groupData,
    groups,
    name
  };
}

export class RentaMultiselectDropdownDataSource {
  public loading: boolean = false;
  public error: string;

  private readonly name: string;
  private sourceListItems: Array<ListItemModel> = null;
  private filterString: string;
  private dataSource: BehaviorSubject<MultiselectDropdownDataSourceModel> = new BehaviorSubject<MultiselectDropdownDataSourceModel>(null);
  private selectedSourceListItems: BehaviorSubject<Array<ListItemModel>> = new BehaviorSubject<Array<ListItemModel>>(null);

  constructor(name: string, dataSource: Array<ListItemModel>) {
    this.name = name;
    this.sourceListItems = dataSource;
  }

  public connect(): Observable<MultiselectDropdownDataSourceModel> {
    // TODO: make sure for we not need this line, I think it need when we use apply on button in dropdown
    // this.updateDataSource();

    return this.dataSource.asObservable().pipe(filter((f: MultiselectDropdownDataSourceModel): boolean => f !== null));
  }

  public getSelectedItems(): Observable<Array<ListItemModel>> {
    this.applyChanges();
    return this.selectedSourceListItems.asObservable();
  }

  public setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  public refresh(dataSource: Array<ListItemModel> = null): void {
    if (dataSource !== null) {
      this.sourceListItems = dataSource;
    }

    this.updateDataSource();
  }

  public filter(filterStr: string): void {
    this.filterString = filterStr;
    this.updateDataSource();
  }

  public applyUncheck(listItem: ListItemModel): void {
    this.refresh();
    this.uncheck(listItem);
    this.applyChanges();
  }

  public uncheck(listItem: ListItemModel): void {
    this.error = null;
    const ds = this.dataSource.getValue();
    const dsIndex = ds.groupData[listItem.group]
      ? ds.groupData[listItem.group].findIndex((f: ListItemModel): boolean => f.id === listItem.id)
      : null;
    const selectedDs = this.selectedSourceListItems.getValue();

    if (dsIndex !== null && ds.groupData[listItem.group][dsIndex].selected) {
      ds.groupData[listItem.group][dsIndex].selected = false;
    }

    const ind = selectedDs.findIndex((f: ListItemModel): boolean => f.id === listItem.id);
    selectedDs.splice(ind, 1);

    this.dataSource.next(ds);
    this.selectedSourceListItems.next(selectedDs);
  }

  public check(listItem: ListItemModel): void {
    this.error = null;
    const ds = this.dataSource.getValue();
    const selectedDs = this.selectedSourceListItems.getValue();
    const dsIndex = ds.groupData[listItem.group].findIndex((f: ListItemModel): boolean => f.id === listItem.id);

    if (dsIndex === -1 || ds.groupData[listItem.group][dsIndex].selected) {
      return;
    }

    ds.groupData[listItem.group][dsIndex].selected = true;
    selectedDs.push(ds.groupData[listItem.group][dsIndex]);

    this.dataSource.next(ds);
    this.selectedSourceListItems.next(selectedDs);
  }

  public checkAll(groupName: string): void {
    const ds = this.dataSource.getValue();
    const selectedDs = this.selectedSourceListItems.getValue();

    for (let i = 0; i < ds.groupData[groupName].length; i++) {
      ds.groupData[groupName][i].selected = true;
      const selectedInd = selectedDs.findIndex((f: ListItemModel): boolean => f.id === ds.groupData[groupName][i].id);
      if (selectedInd > -1) {
        continue;
      }

      selectedDs.push(ds.groupData[groupName][i]);
    }

    this.dataSource.next(ds);
    this.selectedSourceListItems.next(selectedDs);
  }

  public uncheckAll(groupName: string): void {
    const ds = this.dataSource.getValue();
    const selectedDs = this.selectedSourceListItems.getValue();

    let groupIndex = ds.groups.findIndex((f: { count: number; groupName: string }): boolean => f.groupName === groupName);
    const clearAll = groupIndex === -1;
    groupIndex = clearAll ? 0 : groupIndex;

    do {
      const grName = ds.groups[groupIndex].groupName;

      for (let i = 0; i < ds.groupData[grName].length; i++) {
        ds.groupData[grName][i].selected = false;
        const selectedInd = selectedDs.findIndex((f: ListItemModel): boolean => f.id === ds.groupData[grName][i].id);
        if (selectedInd === -1) {
          continue;
        }
        selectedDs.splice(selectedInd, 1);
      }
    } while (++groupIndex < ds.groups.length && clearAll);

    this.dataSource.next(ds);
    this.selectedSourceListItems.next(selectedDs);
  }

  public toggleSelect(listItem: ListItemModel): void {
    if (listItem.selected) {
      this.uncheck(listItem);
    } else {
      this.check(listItem);
    }
  }

  public setError(error: string): void {
    this.error = error;
  }

  public disconect(): void {
    this.dataSource.complete();
  }

  private applyChanges(): void {
    const selectedDs = this.selectedSourceListItems.getValue();

    if (_.isNil(this.sourceListItems)) {
      return;
    }

    this.sourceListItems.forEach((item: ListItemModel): void => {
      item.selected = selectedDs.findIndex((f: ListItemModel): boolean => f.id === item.id) > -1;
    });
  }

  private updateDataSource(): void {
    if (this.sourceListItems === null) {
      this.dataSource.next(null);
    }
    const selectedItems = this.sourceListItems
      .map((x: ListItemModel): ListItemModel => Object.assign({}, x))
      .filter((f: ListItemModel): boolean => f.selected);

    const data =
      this.filterString !== null && this.filterString !== undefined && this.filterString.length > 0
        ? this.sourceListItems
          .filter(
            (f: ListItemModel): boolean =>
              (typeof f.id === 'string' && f.id.toLowerCase().includes(this.filterString.toLowerCase())) ||
              (typeof f.name === 'string' && f.name.toLowerCase().includes(this.filterString.toLowerCase()))
          )
          .map((x: ListItemModel): ListItemModel => Object.assign({}, x))
        : this.sourceListItems.map((x: ListItemModel): ListItemModel => Object.assign({}, x));

    const nextValue = getDropdownDataSource(this.name, data);

    this.selectedSourceListItems.next(selectedItems);

    this.dataSource.next(nextValue);
  }
}
