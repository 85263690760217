import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { KeyValueDropdownsDataSource } from '../../models/DataSource/KeyValueDropdownsDataSource';
import { KeyValueDropdownsRowDataSource } from '../../models/DataSource/KeyValueDropdownsRowDataSource';
import * as _ from 'lodash';

@Component({
  selector: 'app-renta-key-value-dropdowns',
  templateUrl: './renta-key-value-dropdowns.component.html',
  styleUrls: ['../internal/base-settings-styles.css', './renta-key-value-dropdowns.component.scss']
})
export class RentaKeyValueDropdownsComponent implements OnInit {
  @Input()
  public Collection: KeyValueDropdownsDataSource;
  @Output()
  public OnApplyCollection: EventEmitter<Array<{ key: string, value: string }>> = new EventEmitter<Array<{ key: string; value: string }>>();

  public isValid: boolean = true;

  public CollectionDataSource: Observable<Array<KeyValueDropdownsRowDataSource>>;

  constructor() {
  }

  public ngOnInit(): void {
    this.CollectionDataSource = this.Collection.connect();
    const res = this.Collection.getSelectedData();
    if (res.length === 0){
      this.Collection.addEmptyKeyValueRow();
      this.isValid = res.length > 0 && res.every(e => !_.isEmpty(e.value) && !_.isEmpty(e.value));
    }
  }

  public onDeleteItem(index: number): void {
    this.Collection.removeKeyValueRow(index);
    const res = this.Collection.getSelectedData();
    this.isValid = res.every(e => !_.isEmpty(e.value) && !_.isEmpty(e.value));
    this.OnApplyCollection.emit(this.Collection.getSelectedData());
  }

  public onAdd(): void {
    this.Collection.addEmptyKeyValueRow();
    this.isValid = false;
  }

  public onValidChange(isValid: boolean): void {
    this.isValid = isValid;
    this.OnApplyCollection.emit(this.Collection.getSelectedData());
  }
}
