import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { delay } from 'rxjs/operators';

import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { RentaChipsDataSource } from '../../../../../shared/models/DataSource/RentaChipsDataSource';
import { RentaMultiselectDropdownDataSource } from '../../../../../shared/models/DataSource/RentaMultiselectDropdownDataSource';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { YandexMarketSettingsService } from '../../../../services/integrationSettings/yandex-market-settings.service';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { ChipsModel } from '../../../../../shared/models/ChipsModel';
import { getSelectedChips } from '../../../../../../helpers/helpers';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';

@Component({
  selector: 'app-yandex-market',
  templateUrl: './yandex-market-settings.component.html',
  styleUrls: ['../common-settings.scss']
})
export class YandexMarketSettingsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;
  public SourceDataSource: RentaDropdownDataSource;
  public chipsCampaignsDataSource: RentaChipsDataSource;
  public CampaignsDataSource: RentaMultiselectDropdownDataSource;
  public chipsParametersDataSource: RentaChipsDataSource;
  public ParametersDataSource: RentaMultiselectDropdownDataSource;
  public ByEventTimeDataSource: RentaDropdownDataSource;
  public ClickTypeDataSource: RentaDropdownDataSource;
  public SpendingFilterDataSource: RentaDropdownDataSource;
  public showCampaigns: boolean;
  public showExtraSettings: boolean;
  private MainSourceId: string = '0';

  constructor(private settingsService: YandexMarketSettingsService) {
    super(IntegrationTypeEnum.yandexMarket);
    this.isLoaded = false;
    this.showCampaigns = false;
    this.showExtraSettings = false;
  }

  public ngOnInit(): void {
    this.SourceDataSource = new RentaDropdownDataSource('Source', []);
    this.chipsCampaignsDataSource = new RentaChipsDataSource('Selected campaigns', []);
    this.CampaignsDataSource = new RentaMultiselectDropdownDataSource('Campaigns', []);
    this.chipsParametersDataSource = new RentaChipsDataSource('Selected parameters', []);
    this.ParametersDataSource = new RentaMultiselectDropdownDataSource('Parameters', []);
    this.ByEventTimeDataSource = new RentaDropdownDataSource('Type of date', []);
    this.ClickTypeDataSource = new RentaDropdownDataSource('Click type', []);
    this.SpendingFilterDataSource = new RentaDropdownDataSource('Spending filter', []);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
      this.SourceDataSource.setLoading(res);
      this.chipsCampaignsDataSource.setLoading(res);
      this.CampaignsDataSource.setLoading(res);
      this.chipsParametersDataSource.setLoading(res);
      this.ParametersDataSource.setLoading(res);
      this.ByEventTimeDataSource.setLoading(res);
      this.ClickTypeDataSource.setLoading(res);
      this.SpendingFilterDataSource.setLoading(res);
    });

    this.chipsCampaignsDataSource.connect().subscribe((res: Array<ChipsModel>): void => {
      const selectedCampaigns = res.map<ListItemModel>((m: ChipsModel): ListItemModel => {
        return { id: m.id, name: m.title, group: m.type };
      });

      this.settingsService.setSelectedCampaigns(selectedCampaigns);
    });

    this.connectParamsChipsDS(this.chipsParametersDataSource, this.settingsService);

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      this.SourceDataSource.refresh(_.cloneDeep(res[this.integrationType].SourceType));
      this.CampaignsDataSource.refresh(_.cloneDeep(res[this.integrationType].Campaigns));
      this.chipsCampaignsDataSource.refresh(getSelectedChips(res[this.integrationType].Campaigns));
      this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
      this.chipsParametersDataSource.refresh(getSelectedChips(res[this.integrationType].Parameters));
      this.ByEventTimeDataSource.refresh(_.cloneDeep(res[this.integrationType].ByEventTime));
      this.ClickTypeDataSource.refresh(_.cloneDeep(res[this.integrationType].ClickType));
      this.SpendingFilterDataSource.refresh(_.cloneDeep(res[this.integrationType].SpendingFilter));
      this.showCampaigns = res[this.integrationType].Campaigns && res[this.integrationType].Campaigns.length > 0;
      const selectedSourceType = res[this.integrationType].SourceType.find((e: ListItemModel): boolean => e.selected);
      this.showExtraSettings = selectedSourceType && selectedSourceType.id === this.MainSourceId;
      this.setRelatedDataSources([this.SourceDataSource, this.ParametersDataSource]);
      this.settingsService.initCache();

      setTimeout((): void => {
        this.isLoaded = true;
      }, 1000);
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onApplySource($event: ListItemModel): void {
    if (!this.settingsService.setSourceType($event)) {
      return;
    }

    this.ParametersDataSource.setLoading(true);
    this.refreshRelatedDataSources();

    setTimeout((): void => {
      this.showExtraSettings = $event && $event.id === this.MainSourceId ? $event.selected : false;
    }, 0);

    this.settingsService
      .getParameters($event)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(_.cloneDeep(res));
          this.chipsParametersDataSource.refresh(getSelectedChips(res));
        }
      });
  }

  public onCloseParametersChip(e: ChipsModel): void {
    this.ParametersDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
    setTimeout((): void => {
      this.settingsService.setDataToCache();
    });
  }

  public onCloseCampaignsChip(e: ChipsModel): void {
    this.CampaignsDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
  }

  public onApplyParameters($event: Array<ListItemModel>): void {
    this.onApplyParams($event, this.chipsParametersDataSource);
    this.settingsService.setDataToCache();
  }

  public onApplyCampaigns($event: Array<ListItemModel>): void {
    this.settingsService.setSelectedCampaigns($event);

    const chipsDs = $event.map<ChipsModel>((item: ListItemModel): ChipsModel => {
      return { title: item.name, type: item.group, id: item.id, order: 0 };
    });

    this.chipsCampaignsDataSource.refresh(chipsDs);
  }

  public onApplyByEventTime($event: ListItemModel): void {
    this.settingsService.setApplyByEventTime($event);
  }

  public onApplyClickType($event: ListItemModel): void {
    this.settingsService.setClickType($event);
  }

  public onApplySpendingFilter($event: ListItemModel): void {
    this.settingsService.setSpendingFilter($event);
  }
}
