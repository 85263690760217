import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { ColumnMappingDataSource } from '../../models/DataSource/ColumnMappingDataSource';
import { Observable } from 'rxjs';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { SortableOptions } from 'sortablejs';

@Component({
  selector: 'app-renta-file-column-mapping',
  templateUrl: './renta-file-column-mapping.component.html',
  styleUrls: ['./renta-file-column-mapping.component.scss'],
})
export class RentaFileColumnMappingComponent implements OnInit {
  @Input()
  public ColumnMappingDataSource: ColumnMappingDataSource;

  @Output()
  public OnDelete: EventEmitter<ListItemModel> = new EventEmitter<ListItemModel>();

  public faGrip: IconDefinition = faGripVertical;

  public columnTypes: Array<ListItemModel>;

  public selectedColumns: Observable<Array<ListItemModel>>;

  public listOptions: SortableOptions;

  constructor() {}

  public ngOnInit(): void {
    this.listOptions = {
      handle: '.handle',
      onUpdate: (__: any): void => {
        this.ColumnMappingDataSource.sort();
      },
    };

    this.selectedColumns = this.ColumnMappingDataSource.connect();
  }

  public onApplyColumn($event: ListItemModel): void {
    this.ColumnMappingDataSource.updateItem($event);
  }

  public onDeleteColumn($event: ListItemModel): void {
    this.OnDelete.emit($event);
    this.ColumnMappingDataSource.deleteItem($event);
  }
}
