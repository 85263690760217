<div class="setting-row-wrapper p-m-0">
  <app-renta-dropdown *ngIf="isLoaded; else loadingSetting"
                      [RentaDropdownSource]="SourceDataSource"
                      [class.disabled]="isDataSourceLoading"
                      (OnApply)="onApplySource($event)"></app-renta-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-chips *ngIf="isLoaded"
                   [DataSource]="chipsCampaignsDataSource"
                   (OnDeleteChip)="onCloseCampaignsChip($event)"></app-renta-chips>
</div>
<div class="setting-row-wrapper" *ngIf="showCampaigns">
  <app-renta-multiselect-dropdown
    *ngIf="isLoaded; else loadingSetting"
    (OnApply)="onApplyCampaigns($event)"
    [RentaMultiselectDropdownSource]="CampaignsDataSource"
    [hotRefresh]="false">
  </app-renta-multiselect-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-chips *ngIf="isLoaded"
                   [DataSource]="chipsParametersDataSource"
                   [class.disabled]="isDataSourceLoading"
                   (OnDeleteChip)="onCloseParametersChip($event)"></app-renta-chips>
</div>
<div class="setting-row-wrapper">
  <app-renta-multiselect-dropdown
    *ngIf="isLoaded; else loadingSetting"
    (OnApply)="onApplyParameters($event)"
    [RentaMultiselectDropdownSource]="ParametersDataSource"
    [class.disabled]="isDataSourceLoading"
    [hotRefresh]="true">
  </app-renta-multiselect-dropdown>
</div>
<div class="setting-row-wrapper" *ngIf="showExtraSettings">
  <app-renta-dropdown *ngIf="isLoaded; else loadingSetting"
                      [RentaDropdownSource]="ByEventTimeDataSource"
                      (OnApply)="onApplyByEventTime($event)"></app-renta-dropdown>
</div>
<div class="setting-row-wrapper" *ngIf="showExtraSettings">
  <app-renta-dropdown *ngIf="isLoaded; else loadingSetting"
                      [RentaDropdownSource]="ClickTypeDataSource"
                      (OnApply)="onApplyClickType($event)"></app-renta-dropdown>
</div>
<div class="setting-row-wrapper" *ngIf="showExtraSettings">
  <app-renta-dropdown *ngIf="isLoaded; else loadingSetting"
                      [RentaDropdownSource]="SpendingFilterDataSource"
                      (OnApply)="onApplySpendingFilter($event)"></app-renta-dropdown>
</div>
<ng-template #loadingSetting>
  <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
  <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
</ng-template>
