import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { RentaChipsDataSource } from '../../../../../shared/models/DataSource/RentaChipsDataSource';
import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { RentaMultiselectDropdownDataSource } from '../../../../../shared/models/DataSource/RentaMultiselectDropdownDataSource';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { CriteoSettingsService } from '../../../../services/integrationSettings/criteo-settings.service';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { ChipsModel } from '../../../../../shared/models/ChipsModel';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { getSelectedChips } from '../../../../../../helpers/helpers';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';

@Component({
  templateUrl: './criteo-settings.component.html',
  styleUrls: ['../common-settings.scss'],
})
export class CriteoSettingsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;
  public CostsCurrencyDataSource: RentaDropdownDataSource;
  public chipsParametersDataSource: RentaChipsDataSource;
  public ParametersDataSource: RentaMultiselectDropdownDataSource;

  constructor(private settingsService: CriteoSettingsService) {
    super(IntegrationTypeEnum.criteo);
    this.isLoaded = false;
  }

  public ngOnInit(): void {
    this.CostsCurrencyDataSource = new RentaDropdownDataSource('Costs Currency', []);
    this.ParametersDataSource = new RentaMultiselectDropdownDataSource('Parameters', []);
    this.chipsParametersDataSource = new RentaChipsDataSource('Selected Parameters', []);

    this.connectParamsChipsDS(this.chipsParametersDataSource, this.settingsService);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
      this.CostsCurrencyDataSource.setLoading(res);
      this.chipsParametersDataSource.setLoading(res);
      this.ParametersDataSource.setLoading(res);
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      if (res[this.integrationType].BaseCurrencies) {
        this.CostsCurrencyDataSource.refresh(_.cloneDeep(res[this.integrationType].BaseCurrencies));
      }

      if (res[this.integrationType].Parameters) {
        this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
        const chipsParamsDs = getSelectedChips(res[this.integrationType].Parameters);
        this.chipsParametersDataSource.refresh(chipsParamsDs);
      }

      this.settingsService.initCache();

      setTimeout((): void => {
        this.isLoaded = true;
      }, 1000);
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onApplyCostsCurrency($event: ListItemModel): void {
    this.settingsService.setBaseCurrencies($event);
  }

  public onCloseChip(e: ChipsModel): void {
    this.ParametersDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
    setTimeout((): void => {
      this.settingsService.setDataToCache();
    });
  }

  public onApplyParameters($event: Array<ListItemModel>): void {
    this.onApplyParams($event, this.chipsParametersDataSource);
    this.settingsService.setDataToCache();
  }
}
