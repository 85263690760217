import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { IconPositionEnum } from './DataSourceModels/RentaInputDataSourceModel';
import { RentaInputDataSource } from './RentaInputDataSource';
import { RentaDropdownDataSource } from './RentaDropdownDataSource';
import { KeyValueDropdownRowDataSource } from './KeyValueDropdownRowDataSource';

export class KeyValueDropdownDataSource {
  public error: string;
  public keyName: string;
  public valueName: string;

  private sourceData: Array<{ parameter: string, value: string }>;
  private sourceDropdownData: Array<ListItemModel>;
  private dataSource: BehaviorSubject<Array<KeyValueDropdownRowDataSource>> = new BehaviorSubject<Array<KeyValueDropdownRowDataSource>>(null);
  private isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private canEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(keyName: string, valueName: string, dataSource: Array<{ parameter: string, value: string }>, dropdownData: Array<ListItemModel>) {
    this.keyName = keyName;
    this.valueName = valueName;
    this.sourceData = dataSource;
    this.sourceDropdownData = dropdownData;
    this.canEdit.next(this.sourceDropdownData.length > 0);
    this.isValid.next(this.sourceData.length !== 0);
    this.dataSource.next(this.prepareDataSource(this.sourceData));
    this.dataSource.asObservable().subscribe((__: any): void => {
      this.checkValidation();
    });
  }

  public connect(): Observable<Array<KeyValueDropdownRowDataSource>> {
    return this.dataSource.asObservable();
  }

  public checkValidation(): boolean {
    const collection = this.dataSource.getValue();
    let isValid = collection.every((item: KeyValueDropdownRowDataSource): boolean => item.isValidRow());
    if (isValid) {
      const items = collection.map((i: KeyValueDropdownRowDataSource): { parameter: string, value: string } => {
        return i.getSelectedKeyValueRow();
      });

      for (let i = 0; i < collection.length; i++) {
        const kv = collection[i].getSelectedKeyValueRow();
        const data = items.filter((item: { parameter: string, value: string }): boolean => kv.parameter === item.parameter);
        if (data.length > 1) {
          collection[i].setErrorRow('Parameters must be unique');
          isValid = false;
        } else {
          collection[i].setErrorRow(null);
        }
      }
    }

    this.isValid.next(isValid && this.sourceDropdownData.length > 0);
    return isValid;
  }

  public isDataSourceValid(): Observable<boolean> {
    return this.isValid.asObservable();
  }

  public isCanEdit(): Observable<boolean> {
    return this.canEdit.asObservable();
  }

  public addEmptyKeyValueRow(): void {
    const ds = this.dataSource.getValue();
    ds.push(this.getDataSourceItem(ds.length, { parameter: '', value: '' }));
    this.dataSource.next(ds);
  }

  public removeKeyValueRow(index: number): void {
    const ds = this.dataSource.getValue();
    if (ds.length > index) {
      ds.splice(index, 1);
    }
    if (ds.length === 0) {
      this.isValid.next(true);
    }

    this.dataSource.next(ds);
  }

  public getSelectedData(): Array<{ parameter: string, value: string }> {
    return this.dataSource.getValue()
      .filter((item: KeyValueDropdownRowDataSource): boolean => item.isValidRow())
      .map((item: KeyValueDropdownRowDataSource): { parameter: string, value: string } => {
        return item.getSelectedKeyValueRow();
      });
  }

  public setError(error: string): void {
    this.error = error;
  }

  public refresh(dataSource: Array<{ parameter: string, value: string }>, dropdownData: Array<ListItemModel>): void {
    if (dropdownData.length === 0) {
      this.canEdit.next(false);
      this.isValid.next(false);
      return;
    }
    this.canEdit.next(true);
    this.sourceData = dataSource;
    this.sourceDropdownData = dropdownData;

    const newDs = this.prepareDataSource(this.sourceData);
    this.dataSource.next(newDs);
  }

  private prepareDataSource(array: Array<{ parameter: string, value: string }>): Array<KeyValueDropdownRowDataSource> {
    const selectedVals = this.dataSource.getValue()?.map(m => m.getSelectedKeyValueRow()).filter(f => _.some(f.parameter)) || [];
    const filteredDs = array.filter(f => !selectedVals.some(s => s.parameter === f.parameter));
    const resDs = filteredDs.concat(selectedVals);
    return resDs.map((item: { parameter: string, value: string }, indx: number): KeyValueDropdownRowDataSource => this.getDataSourceItem(indx, item));
  }

  private getDataSourceItem(indx: number, item: { parameter: string, value: string }): KeyValueDropdownRowDataSource {
    if ((_.isNil(item.value) || item.value.length === 0) && this.sourceDropdownData.length > 0) {
      item.value = this.sourceDropdownData[0].id;
    }
    const dropdownDs = this.sourceDropdownData.map((dropdownItem: ListItemModel): ListItemModel => {
      return { ...dropdownItem, selected: dropdownItem.id === item.value };
    });

    const inputDs = {
      value: item.parameter,
      iconOptions: {
        position: IconPositionEnum.none
      },
      placeholder: 'Key',
      disabled: this.sourceDropdownData.length <= 0
    };

    return new KeyValueDropdownRowDataSource(new RentaInputDataSource('Key' + indx, inputDs), new RentaDropdownDataSource('Value' + indx, dropdownDs));
  }
}
