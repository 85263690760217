import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { RentaDropdownDataSource } from '../../../../models/DataSource/RentaDropdownDataSource';

@Component({
  selector: 'app-column-mapping-row',
  templateUrl: './column-mapping-row.component.html',
  styleUrls: ['./column-mapping-row.component.scss'],
})
export class ColumnMappingRowComponent implements OnInit {
  @Output()
  public OnApplyColumnType: EventEmitter<ListItemModel> = new EventEmitter<ListItemModel>();

  @Output()
  public OnDeleteColumnType: EventEmitter<ListItemModel> = new EventEmitter<ListItemModel>();

  @Input()
  public ColumnInfo: { id: string; name: string; type: string };

  @Input()
  public ColumnTypesDataSource: RentaDropdownDataSource;
  constructor() {}

  public ngOnInit(): void {}

  public onApplyColumnType($event: ListItemModel): void {
    const type = $event.id;
    const alreadySelectedType = this.ColumnInfo.type;
    if (alreadySelectedType === type) {
      return;
    }

    this.ColumnInfo = { ...this.ColumnInfo, type };

    this.OnApplyColumnType.emit(this.ColumnInfo);
  }

  public delete(): void {
    this.OnDeleteColumnType.emit(this.ColumnInfo);
  }
}
