<div class='p-d-flex p-flex-column width-max'>
  <div *ngFor='let item of CollectionDataSource | async; let i = index' class="width-max p-mb-2">
    <app-renta-key-value-row
    [Id]='i'
    [KeyValueDataSource]='item'
    [WithDelete]='true'
    (onDelete)='onDeleteItem(i)'>
    </app-renta-key-value-row>
  </div>
  <div class='p-mt-2'>
  <p-button styleClass="renta-btn-secondary" (onClick)="onAdd()" [label]="'ADD FIELD'">
  </p-button>
  </div>
</div>
