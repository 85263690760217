import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RentaInputDataSource } from '../../models/DataSource/RentaInputDataSource';
import { RentaInputStringArrayDataSource } from '../../models/DataSource/RentaInputStringArrayDataSource';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-renta-input-string-array',
  templateUrl: './renta-input-string-array.component.html',
  styleUrls: ['../internal/base-settings-styles.css']
})
export class RentaInputStringArrayComponent implements OnInit {
  @Input()
  public RentaInputStringArrayDataSource: RentaInputStringArrayDataSource;

  @Input()
  public HideName: boolean = false;

  @Output()
  public OnApply: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

  public ds: Observable<Array<RentaInputDataSource>>;

  constructor() { }

  public ngOnInit(): void {
    this.ds = this.RentaInputStringArrayDataSource.connect();
    if (this.RentaInputStringArrayDataSource.getSelectedData().length === 0){
      this.RentaInputStringArrayDataSource.addEmptyRow();
    }

  }

  public onValueChange(e: {name: string, value: string}): void {
    this.RentaInputStringArrayDataSource.onValueChange(e.name, e.value);
    this.OnApply.emit(this.RentaInputStringArrayDataSource.getSelectedData());
  }

  public onDeleteRow($event: string): void {
    this.RentaInputStringArrayDataSource.removeItem($event);
    this.OnApply.emit(this.RentaInputStringArrayDataSource.getSelectedData());
  }

  public onAdd(): void {
    this.RentaInputStringArrayDataSource.addEmptyRow();
  }
}
