<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="mr-b-5">
    <app-renta-error-message [errorMessage]="error"
                             *ngIf="error"></app-renta-error-message>
  </div>
  <label for="siteName" class="p-d-none"></label>
  <input id="siteName"
         class="p-inputtext-lg auth-dialog-input"
         type="text"
         placeholder="Site name"
         pInputText
         appTextInputTrim
         [(ngModel)]="siteName"
         [disabled]="loading">
  <label for="siteId" class="p-d-none"></label>
  <input id="siteId"
         class="p-inputtext-lg auth-dialog-input dialog-input-margin"
         type="text"
         placeholder="Site ID"
         pInputText
         appTextInputTrim
         [(ngModel)]="siteId"
         [disabled]="loading">
  <label for="clientApiId" class="p-d-none"></label>
  <input id="clientApiId"
         class="p-inputtext-lg auth-dialog-input dialog-input-margin"
         type="password"
         placeholder="Client API ID"
         pInputText
         appTextInputTrim
         [(ngModel)]="clientApiId"
         [disabled]="loading">
</div>
