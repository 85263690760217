import { DataSourceBase } from './DataSourceBase';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import * as _ from 'lodash';

export class RentaDropdownDataSource extends DataSourceBase<Array<ListItemModel>> {
  public error: string;
  public name: string;
  private filterString: string = '';
  private selected: ListItemModel = null;
  private removeSelected: boolean = false;

  constructor(name: string, dataSource: Array<ListItemModel>, removeSelected: boolean = false) {
    super(dataSource);
    this.name = name;
    this.removeSelected = removeSelected;
  }

  public selectItem(item: ListItemModel): void {
    if (item.id === this.selected?.id) {
      return;
    }

    this.selected = item;
    this.error = null;
    this.sourceData.forEach((f: ListItemModel): boolean => (f.selected = item.id === f.id));
  }

  public filter(filterStr: string): void {
    this.filterString = filterStr;
    this.updateDataSource();
  }

  public getSelectedItem(): ListItemModel {
    return this.sourceData.find((f: ListItemModel): boolean => f.selected);
  }

  public getCollection(): Array<ListItemModel> {
    return _.cloneDeep(this.sourceData);
  }

  public setError(error: string): void {
    this.error = error;
  }

  protected updateDataSource(): void {
    const data =
      this.filterString !== null && this.filterString !== undefined && this.filterString.length > 0
        ? this.sourceData.filter(
          (f: ListItemModel): boolean =>
            (typeof f.id === 'string' && f.id.toLowerCase().includes(this.filterString.toLowerCase())) ||
            (typeof f.name === 'string' && f.name.toLowerCase().includes(this.filterString.toLowerCase())) ||
            (typeof f.additionalInfo === 'object' && f.additionalInfo.some(info => info.includes(this.filterString)))
        )
        : this.sourceData;

    this.dataSource.next(data);
    this.selected = data.find(f => f.selected);
  }
}
