import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[OnEnter]',
})
export class OnEnterDirective {
  @Output()
  public OnEnter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: any): void {
    if (event.key === 'Enter') {
      this.OnEnter.emit(true);
    }
  }
}
