<div class="view-filter width-max">
  <div class="view-filter-container p-d-flex">
    <div class="filter-input-area width-max">
      <input
        class="renta-input-base"
        placeholder="Filter"
        type="text"
        pInputText
        [disabled]="true"
        [(ngModel)]="placeholder">
    </div>
    <div class="view-buttons p-d-flex">
      <p-button
        [disabled]="standby || !canEdit"
        styleClass="renta-btn-secondary"
        (onClick)="edit()">
        <i class="pi pi-pencil"></i>
      </p-button>
      <p-button
        [disabled]="standby"
        styleClass="renta-btn-secondary"
        (onClick)="delete()">
        <i class="pi pi-trash"></i>
      </p-button>
    </div>
  </div>
</div>
