import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { RentaChipsDataSource } from '../../../../../shared/models/DataSource/RentaChipsDataSource';
import { RentaMultiselectDropdownDataSource } from '../../../../../shared/models/DataSource/RentaMultiselectDropdownDataSource';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { VkSettingsService } from '../../../../services/integrationSettings/vk-settings.service';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { ChipsModel } from '../../../../../shared/models/ChipsModel';
import { delay } from 'rxjs/operators';
import * as _ from 'lodash';
import { getSelectedChips } from '../../../../../../helpers/helpers';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';

@Component({
  templateUrl: './vk-settings.component.html',
  styleUrls: ['../common-settings.scss']
})
export class VkSettingsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;
  public AccountDataSource: RentaDropdownDataSource;
  public chipsClientsDataSource: RentaChipsDataSource;
  public ClientsDataSource: RentaMultiselectDropdownDataSource;
  public SourceDataSource: RentaDropdownDataSource;
  public chipsParametersDataSource: RentaChipsDataSource;
  public ParametersDataSource: RentaMultiselectDropdownDataSource;
  public isShowClients: boolean;

  constructor(private settingsService: VkSettingsService) {
    super(IntegrationTypeEnum.vk_ads);
    this.isLoaded = false;
  }

  public ngOnInit(): void {
    this.AccountDataSource = new RentaDropdownDataSource('Account', []);
    this.chipsClientsDataSource = new RentaChipsDataSource('Selected clients', []);
    this.ClientsDataSource = new RentaMultiselectDropdownDataSource('Clients', []);
    this.SourceDataSource = new RentaDropdownDataSource('Source', []);
    this.chipsParametersDataSource = new RentaChipsDataSource('Selected parameters', []);
    this.ParametersDataSource = new RentaMultiselectDropdownDataSource('Parameters', []);

    this.chipsClientsDataSource.connect().subscribe((res: Array<ChipsModel>): void => {
      const selectedClients = res.map<ListItemModel>((m: ChipsModel): ListItemModel => {
        return { id: m.id, name: m.title, group: m.type };
      });

      this.settingsService.setSelectedClients(selectedClients);
    });

    this.connectParamsChipsDS(this.chipsParametersDataSource, this.settingsService);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
      this.AccountDataSource.setLoading(res);
      this.chipsClientsDataSource.setLoading(res);
      this.ClientsDataSource.setLoading(res);
      this.SourceDataSource.setLoading(res);
      this.chipsParametersDataSource.setLoading(res);
      this.ParametersDataSource.setLoading(res);
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      if (res[this.integrationType].Account) {
        this.AccountDataSource.refresh(_.cloneDeep(res[this.integrationType].Account));
      }

      this.isShowClients = res[this.integrationType].Clients && res[this.integrationType].Clients.length > 0;

      if (this.isShowClients) {
        this.ClientsDataSource.refresh(_.cloneDeep(res[this.integrationType].Clients));
        const chipsClientsDs = getSelectedChips(res[this.integrationType].Clients);
        this.chipsClientsDataSource.refresh(chipsClientsDs);
      }

      if (res[this.integrationType].SourceType) {
        this.SourceDataSource.refresh(_.cloneDeep(res[this.integrationType].SourceType));
      }

      if (res[this.integrationType].Parameters) {
        this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
        const chipsParamsDs = getSelectedChips(res[this.integrationType].Parameters);
        this.chipsParametersDataSource.refresh(chipsParamsDs);
      }

      this.settingsService.initCache();
      this.setRelatedDataSources([this.SourceDataSource, this.ParametersDataSource]);

      setTimeout((): void => {
        this.isLoaded = true;
      }, 1000);
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onApplyAccount($event: ListItemModel): void {
    if (!this.settingsService.setAccount($event)) {
      return;
    }
    this.ClientsDataSource.setLoading(true);
    this.settingsService
      .getClients($event.id)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ClientsDataSource.setLoading(false);
        this.isShowClients = !_.isNil(res) && res.length > 0;

        if (this.isShowClients) {
          this.ClientsDataSource.refresh(_.cloneDeep(res));
          this.chipsClientsDataSource.refresh(getSelectedChips(res));
        }
      });
  }

  public onCloseChip(e: ChipsModel): void {
    this.ParametersDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
    setTimeout((): void => {
      this.settingsService.setDataToCache();
    });
  }

  public onCloseSelectedClientsChip(e: ChipsModel): void {
    this.ClientsDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
  }

  public onApplyClients(selectedClients: Array<ListItemModel>): void {
    this.settingsService.setSelectedClients(selectedClients);
    this.chipsClientsDataSource.refresh(getSelectedChips(selectedClients));
  }

  public onApplyParameters($event: Array<ListItemModel>): void {
    this.onApplyParams($event, this.chipsParametersDataSource);
    this.settingsService.setDataToCache();
  }

  public onApplySource($event: ListItemModel): void {
    if (!this.settingsService.setSourceType($event)) {
      return;
    }
    this.ParametersDataSource.setLoading(true);
    this.refreshRelatedDataSources();
    this.settingsService
      .getParameters($event)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(_.cloneDeep(res));
          this.chipsParametersDataSource.refresh(getSelectedChips(res));
        }
      });
  }
}
