<div class="input-elem-container">
  <div class="input-show-block p-d-flex p-align-center">
    <div class="input-text p-d-flex p-align-center">
      <span *ngIf="text.length > 0">{{text}}</span>
      <span *ngIf="text.length === 0">{{placeholder}}</span>
    </div>
    <div class="input-btn" *ngIf="isEditable">
      <div class="edit-btn p-d-flex p-align-center p-justify-center"
           (click)="activatePlaceholder()">
        <i class="pi" [ngClass]="btnIcon"></i>
      </div>
    </div>
  </div>
</div>

