import {Injectable} from '@angular/core';

import {map} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';

import {UserInfo} from '../models/user/UserInfo';
import {RentaApiService} from './renta-api.service';
import {ApiResponse} from '../models/common/ApiResponse`T';
import {ErrorMessage} from '../models/common/ErrorMessage';
import {Subscription, SubscriptionState} from '../models/user/Subscription';
import * as _ from 'lodash';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userInfo: UserInfo;
  private isAuth: BehaviorSubject<boolean>;

  constructor(private readonly rentaApiService: RentaApiService, private readonly storageService: StorageService) {
    this.userInfo = null;
    this.isAuth = new BehaviorSubject<boolean>(false);
  }

  public signUp(email: string, password: string, name: string, recapchaToken: string): Observable<ApiResponse<UserInfo>> {
    return this.rentaApiService
      .signUp(email, password, name, recapchaToken);
  }

  public forgotPassword(email: string, recapchaToken: string): Observable<string> {
    return this.rentaApiService
      .forgotPassword(email, recapchaToken)
      .pipe(map((m: ApiResponse<{}>): string => (m.baseResponse.status ? null : m.baseResponse.errors[0].userMessage)));
  }

  public connectToAuth(): Observable<boolean> {
    return this.isAuth.asObservable();
  }

  public getUserId(): string {
    return this.userInfo.userId;
  }

  public getUserSubscription(): Subscription {
    return this.userInfo.subscription;
  }

  public isSubscriptionExpired(): boolean {
    return (
      this.userInfo.subscription.state === SubscriptionState.Expired ||
      // just for test
      !_.isNil(localStorage.getItem('expired'))
    );
  }

  public signIn(email: string, password: string, recapchaToken: string, isSignUp: boolean): Observable<string> {
    return this.rentaApiService.signIn(email, password, recapchaToken, isSignUp).pipe(
      map((response: ApiResponse<{}>): string => {
        if (response.baseResponse.status === true) {
          return null;
        }

        return response.baseResponse.errors.map((item: ErrorMessage): string => item.userMessage).join(',');
      })
    );
  }

  public logOut(): void {
    this.rentaApiService.signOut().subscribe((res: ApiResponse<{}>): void => {
      if (res.baseResponse.status) {
        this.storageService.cleanAll();
        window.location.reload();
      }
    });
  }

  public expiredTokenLogOut(): void {
    this.storageService.cleanAll();
    window.location.reload();
  }

  public isAuthenticated(scope: string = 'renta_api'): Observable<boolean> {
    return this.isAuthInternal(scope);
  }

  public getUserToken(): string {
    return this.userInfo.token.token_type + ' ' + this.userInfo.token.access_token;
  }

  public validateResetPasswordCode(code: string): Observable<boolean> {
    return this.rentaApiService.validateResetPasswordCode(code).pipe(map((m: ApiResponse<{}>): boolean => m.baseResponse.status));
  }

  public resetPassword(password: string, code: string): Observable<string> {
    return this.rentaApiService
      .resetPassword(password, code)
      .pipe(map((m: ApiResponse<{}>): string => (m.baseResponse.status ? null : m.baseResponse.errors[0].userMessage)));
  }

  private isAuthInternal(scope: string): Observable<boolean> {
    this.userInfo = this.storageService.localGetItem("userInfo");
    if (this.userInfo === null) {
      return this.rentaApiService.getUserInfo().pipe(
        map((response: ApiResponse<UserInfo>): boolean => {
          if (response?.baseResponse?.status === true && response?.result !== null && response?.result?.token?.scope?.includes(scope)) {
            this.userInfo = response.result;
            this.storageService.localUpdateItem("userInfo", response.result);
            this.isAuth.next(true);
            return true;
          }

          this.isAuth.next(false);
          return false;
        })
      );
    } else {
      this.isAuth.next(true);
      return this.isAuth.asObservable();
    }
  }
}
