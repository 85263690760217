import { IntegrationMetadataBase } from '../../../../models/Integration/IntegrationsMeta/IntegrationMetadataBase';
import { IEtlSettingsService } from './IEtlSettingsService';
import { MemoryCacheService } from '../../../../services/memory-cache.service';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { SelectedDateRangeViewModel } from '../../../../models/viewModels/SelectedDateRangeViewModel';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { ApiResponse } from '../../../../models/common/ApiResponse`T';
import { filter, map, tap } from 'rxjs/operators';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { SettingsServiceBase } from './SettingsServiceBase';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { RentaApiService } from '../../../../services/renta-api.service';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { transliterate } from '../../../../helpers/helpers';

export abstract class EtlSettingsServiceBase<TMeta extends IntegrationMetadataBase> extends SettingsServiceBase<TMeta> implements IEtlSettingsService {
  public memoryCacheService: MemoryCacheService = new MemoryCacheService();
  protected showOverwritePeriod: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected memoryCacheKey: string;
  protected abstract tablePrefix: string;
  private noDateRangeIntegrations: Array<string>;

  protected constructor(
    public integrationInfoData: IntegrationInfoViewModel,
    protected readonly rentaApiService: RentaApiService,
    protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.setDefaultValues();
  }

  public isETL(): boolean {
    return true;
  }

  public loadSettings(): Observable<IntegrationSettings> {
    if (this.loading.getValue() === false) {
      this.loading.next(true);
      if (this.editMode) {
        this.rentaApiService
          .loadIntegration(this.getWizardRequestData())
          .pipe(
            map<any, IntegrationSettings>((res: any): IntegrationSettings => {
              this.meta = res.meta;
              return this.mapIntegrationSettings(res, this.meta.tableName, this.meta.integrationName);
            })
          )
          .subscribe((res: IntegrationSettings): void => {
            this.initSelectedSettings(res);
            this.loading.next(false);
            this.sourceIntegrationSettings.next(res);
          });
      } else {

        this.rentaApiService
          .initIntegration(this.getWizardRequestData())
          .pipe(
            map<any, IntegrationSettings>((res: any): IntegrationSettings => {
              this.meta = res.meta;
              const tableName = transliterate(
                `${this.tablePrefix} ${this.integrationInfoData.integrationSourceName} ${new Date().valueOf()}`
              );
              return this.mapIntegrationSettings(res, tableName, this.integrationInfoData.integrationSourceType.title);
            })
          )
          .subscribe((res: IntegrationSettings): void => {
            // todo: проверить это во всех интеграциях, при создании
            // this.initSelectedSettings(res);
            this.loading.next(false);
            this.sourceIntegrationSettings.next(res);
          });
      }
    }

    return this.sourceIntegrationSettings.asObservable().pipe(filter((result: IntegrationSettings): boolean => result !== null));
  }

  public isShowOverwritePeriod(): Observable<boolean> {
    return this.showOverwritePeriod.asObservable();
  }

  public isShowDateRange(): boolean {
    return !_.includes(this.noDateRangeIntegrations, this.integrationTypeEnum);
  }

  public setDateRange(selectedDateRange: SelectedDateRangeViewModel): void {
    if (_.isNil(selectedDateRange)) {
      return;
    }

    this.selectedIntegrationSettings.Date = {
      startDate: selectedDateRange.from,
      endDate: selectedDateRange.to
    };

    this.selectedIntegrationSettings.DateRange = [
      {
        id: selectedDateRange.dateRangeEnumValue + '',
        name: selectedDateRange.dateRangeEnumTitle
      }
    ];
  }

  public setUpdateTime(selectedSchedule: ListItemModel): void {
    if (_.isNil(selectedSchedule)) {
      return;
    }

    this.selectedIntegrationSettings.Schedule = [selectedSchedule];
  }

  public setOverwritePeriod(selectedOverwritePeriod: ListItemModel): void {
    if (_.isNil(selectedOverwritePeriod)) {
      return;
    }

    this.selectedIntegrationSettings.OverwritePeriod = [selectedOverwritePeriod];
  }

  public setSelectedParameters(selectedParameters: Array<ListItemModel>): void {
    if (_.isNil(selectedParameters)) {
      return;
    }

    if (this.meta && this.meta.overwritePeriodKeys && this.meta.overwritePeriodKeys.length > 0) {
      const overwritePeriodKeyIndex = selectedParameters.findIndex((f: ListItemModel): boolean =>
        this.meta.overwritePeriodKeys.includes(f.id)
      );
      this.showOverwritePeriod.next(overwritePeriodKeyIndex > -1);
    }

    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [...selectedParameters];
  }

  public getCurrentParameters(url: string, body: any): Observable<Array<ListItemModel>> {
    return this.rentaApiService.httpClient.post<ApiResponse<{ parameters: Array<ListItemModel> }>>(url, body).pipe(
        map((res: ApiResponse<{ parameters: Array<ListItemModel> }>): Array<ListItemModel> => {
        return _.isNil(res?.result?.parameters) ? [] : res.result.parameters;
      })
    );
  }

  public compareParametersWithCacheData(data: Array<ListItemModel>): void {
    const cache = this.memoryCacheService.getDataFromCache(this.memoryCacheKey);

    if (_.isNil(cache)) {
      return;
    }

    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = cache.parameters || [];

    if (_.isNil(cache.parameters) || cache.parameters.length === 0) {
      return;
    }

    const tmpParams: Array<ListItemModel> = [];
    data.forEach((f: ListItemModel): void => {
      const selected = cache.parameters.find((p: ListItemModel): boolean => p.id === f.id);
      f.selected = !_.isNil(selected);

      if (f.selected) {
        tmpParams.push(selected);
      }

    });

    if (!this.editMode) {
      cache.parameters = tmpParams;
      this.memoryCacheService.setDataToCache(this.memoryCacheKey, cache);
    }
  }

  public compareFiltersWithCacheData(data: Array<ListItemModel>): void {
    const cache = this.memoryCacheService.getDataFromCache(this.memoryCacheKey);

    if (_.isNil(cache)) {
      return;
    }

    this.selectedIntegrationSettings[this.integrationTypeEnum].Filters = cache.filters;

    if (_.isNil(cache.filters) || cache.filters.length === 0) {
      return;
    }

    const tmpFilters: Array<ListItemModel> = [];
    data.map((f: ListItemModel): ListItemModel => {
      f.selected = false;

      cache.filters.filter((p: any): void => {
        if (f.id === p.id) {
          f.selected = true;
          f.value = p.value;
          f.operators = p.operators;
          tmpFilters.push(p);
        }
      });

      return f;
    });

    if (!this.editMode) {
      cache.filters = tmpFilters;
      this.memoryCacheService.setDataToCache(this.memoryCacheKey, cache);
    }
  }

  public setDataToCache(): void {
    if (_.isNil(this.memoryCacheService.getDataFromCache(this.memoryCacheKey))) {
      return;
    }

    this.memoryCacheService.setDataToCache(this.memoryCacheKey, {
      parameters: this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters,
      filters: this.selectedIntegrationSettings[this.integrationTypeEnum].Filters
    });
  }

  public getDataFromCache(): any {
    this.memoryCacheService.getDataFromCache(this.memoryCacheKey);
  }

  public deleteDataFromCache(): void {
    this.memoryCacheService.deleteDataInCache(this.memoryCacheKey);
  }

  public initCache(): void {
    this.memoryCacheService.initCache(this.memoryCacheKey, {
      parameters: this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters,
      filters: this.selectedIntegrationSettings[this.integrationTypeEnum].Filters
    });
  }

  public getSelectedSettings(): IntegrationSettings {
    if (!this.showOverwritePeriod.getValue()) {
      this.selectedIntegrationSettings.OverwritePeriod = null;
    }

    return this.selectedIntegrationSettings;
  }

  protected setDefaultValues(): void {
    this.noDateRangeIntegrations = [];
    this.noDateRangeIntegrations.push(IntegrationTypeEnum.mySql);
    this.noDateRangeIntegrations.push(IntegrationTypeEnum.postgreSql);
    this.noDateRangeIntegrations.push(IntegrationTypeEnum.activeCampaign);
    this.noDateRangeIntegrations.push(IntegrationTypeEnum.salesforce);
    this.noDateRangeIntegrations.push(IntegrationTypeEnum.file);
  }

  protected initSelectedCommonSettings(initialSettings: IntegrationSettings, isRefreshSettings: boolean): void {
    this.selectedIntegrationSettings.TableName = initialSettings.TableName;
    this.selectedIntegrationSettings.Name = initialSettings.Name;
    this.selectedIntegrationSettings.OverwritePeriod = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings.OverwritePeriod)
      ? initialSettings.OverwritePeriod.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings.OverwritePeriod[0].id === f.id)
      : initialSettings.OverwritePeriod.filter((f: ListItemModel): boolean => f.selected);
    this.selectedIntegrationSettings.DateRange = isRefreshSettings
      ? initialSettings.DateRange.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings.DateRange[0].id === f.id)
      : initialSettings.DateRange.filter((f: ListItemModel): boolean => f.selected);
    this.selectedIntegrationSettings.Date = isRefreshSettings
      ? initialSettings.Date = this.selectedIntegrationSettings.Date
      : initialSettings.Date;
    this.selectedIntegrationSettings.Schedule = isRefreshSettings
      ? initialSettings.Schedule.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings.Schedule[0].id === f.id)
      : initialSettings.Schedule.filter((f: ListItemModel): boolean => f.selected);
  }
}
