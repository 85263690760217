import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-hook-modal',
  templateUrl: './web-hook-modal.component.html',
})
export class WebHookModalComponent implements OnInit {

  @Input()
  public error: string;

  @Input()
  public name: string;

  @Input()
  public loading: boolean;

  constructor() {}

  public ngOnInit(): void {}

}
