<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="mr-b-5">
    <app-renta-error-message [errorMessage]="error"
                             *ngIf="error"></app-renta-error-message>
  </div>
  <label for="name" class="p-d-none"></label>
  <input id="name"
         class="p-inputtext-lg auth-dialog-input"
         type="text"
         placeholder="Name"
         pInputText
         appTextInputTrim
         [(ngModel)]="name"
         [disabled]="loading">
</div>
