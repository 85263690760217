<div class="integration-settings-container p-d-flex p-flex-column p-p-6">
<div class="setting-row-wrapper p-m-0">
  <div *ngIf="isLoaded; else loadingSetting">
    <app-renta-input [RentaInputSource]="HitsTableNameDataSource"
                     [IsTransliterate]='true'
                     [additionalInfo]="'Specify the table name. This table will receive all of your data in real time.'"
                     (OnApply)="onApplyHitsTableName($event)"></app-renta-input>
  </div>
</div>

<div class='p-d-flex width-max switcher-row setting-row-wrapper'>
  <div class='p-d-flex p-flex-column description'>
    <span class='renta-font-normal p-text-bold'>Сollect session data</span>
    <span class='renta-font-small renta-subtext-color'>A session table will be created in your data warehouse.</span>
  </div>
  <div class='p-d-flex p-jc-end p-ai-center switcher'>
    <app-renta-switcher
      [Disabled]='!isLoaded'
      [IsChecked]='useSessionsTable'
      (OnSwitcherChange)='onSwitcherChange($event)'
    ></app-renta-switcher>
  </div>
</div>

<div class="setting-row-wrapper" *ngIf='useSessionsTable' style='margin-top: 12px;'>
  <div *ngIf="isLoaded; else loadingSetting">
    <app-renta-input [RentaInputSource]="SessionsTableNameDataSource"
                     [IsShowName]='false'
                     [IsTransliterate]='true'
                     (OnApply)="onApplySessionsTableName($event)"></app-renta-input>
  </div>
</div>
</div>
<ng-template #loadingSetting>
  <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
  <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
</ng-template>
