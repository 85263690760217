import { Directive, HostBinding, OnInit } from '@angular/core';
import { AppGlobalPreloaderService } from '../../../services/app-global-preloader.service';

@Directive({
  selector: '[appDontTouch]',
})
export class DontTouchDirective implements OnInit {
  @HostBinding('class')
  private elementClass: string;
  private readonly originalClass: string;

  constructor(private readonly appGlobalPreloaderService: AppGlobalPreloaderService) {
    this.originalClass = this.elementClass;
  }

  public ngOnInit(): void {
    this.appGlobalPreloaderService.connectToGlobalPreloader().subscribe((value: boolean): void => {
      if (value) {
        this.elementClass = 'dont-touch';
      } else {
        this.elementClass = this.originalClass;
      }
    });
  }
}
