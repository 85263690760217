import { RentaInputDataSource } from './RentaInputDataSource';
import { IconPositionEnum, RentaInputDataSourceModel } from './DataSourceModels/RentaInputDataSourceModel';

export class RentaKeyValueRowDataSource {
  public KeyDataSource: RentaInputDataSource;
  public ValueDataSource: RentaInputDataSource;

  constructor(key: string, value: string) {
    const keyDs: RentaInputDataSourceModel = {
      value: key, disabled: false, placeholder: "Name", iconOptions: {position: IconPositionEnum.none}
    };

    const valueDs: RentaInputDataSourceModel = {
      value, disabled: false, placeholder: "Value", iconOptions: {position: IconPositionEnum.none}
    }

    this.KeyDataSource = new RentaInputDataSource("Key", keyDs);
    this.ValueDataSource = new RentaInputDataSource("Value", valueDs);
  }
}
