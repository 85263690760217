import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UserService} from '../services/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly userService: UserService,
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
    });

    if (req.url.includes('/auth/') && !req.url.includes('/auth/update-password') && !req.url.includes('/auth/sign-out')) {
      return next.handle(req);
    }

    const userToken = this.userService.getUserToken();

    if (userToken) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', userToken),
      });
      return next.handle(authReq).pipe(
        catchError((error: HttpErrorResponse) => {
          this.handleUnauthorizedError(error);
          return throwError(error);
        })
      );
    }
  }

  private handleUnauthorizedError(error: HttpErrorResponse): void {
    if (error.status === 401) {
      this.userService.expiredTokenLogOut()
    }
  }
}
