import { Component, OnDestroy, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';

import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { RentaChipsDataSource } from '../../../../../shared/models/DataSource/RentaChipsDataSource';
import {
  RentaMultiselectDropdownDataSource
} from '../../../../../shared/models/DataSource/RentaMultiselectDropdownDataSource';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { getSelectedChips } from '../../../../../../helpers/helpers';
import { XAdsSettingsService } from '../../../../services/integrationSettings/x-ads-settings.service';
import { ChipsModel } from '../../../../../shared/models/ChipsModel';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';

@Component({
  templateUrl: './x-ads-settings.component.html',
  styleUrls: ['../common-settings.scss']
})
export class XAdsSettingsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;
  public ReportTypesDataSource: RentaDropdownDataSource;
  public AttributionDataSource: RentaDropdownDataSource;
  public BindToDataSource: RentaDropdownDataSource;
  public chipsParametersDataSource: RentaChipsDataSource;
  public ParametersDataSource: RentaMultiselectDropdownDataSource;
  public UniqueOnlyDataSource: RentaDropdownDataSource;

  constructor(private settingsService: XAdsSettingsService) {
    super(IntegrationTypeEnum.x_ads);
    this.isLoaded = false;
  }

  public ngOnInit(): void {
    this.AttributionDataSource = new RentaDropdownDataSource('Ad account', []);
    this.ReportTypesDataSource = new RentaDropdownDataSource('Report type', []);
    this.chipsParametersDataSource = new RentaChipsDataSource('Selected Dimensions & Metrics', []);
    this.ParametersDataSource = new RentaMultiselectDropdownDataSource('Dimensions & Metrics', []);
    this.BindToDataSource = new RentaDropdownDataSource('Ad placement', []);
    this.UniqueOnlyDataSource = new RentaDropdownDataSource('Report segmentation type', []);

    this.connectParamsChipsDS(this.chipsParametersDataSource, this.settingsService);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
      this.ReportTypesDataSource.setLoading(res);
      this.AttributionDataSource.setLoading(res);
      this.BindToDataSource.setLoading(res);
      this.chipsParametersDataSource.setLoading(res);
      this.ParametersDataSource.setLoading(res);
      this.UniqueOnlyDataSource.setLoading(res);
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      this.ReportTypesDataSource.refresh(_.cloneDeep(res[this.integrationType].ReportTypes));
      this.AttributionDataSource.refresh(_.cloneDeep(res[this.integrationType].Attribution));
      this.BindToDataSource.refresh(_.cloneDeep(res[this.integrationType].BindTo));
      this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
      const chipsParamsDs = getSelectedChips(res[this.integrationType].Parameters);
      this.chipsParametersDataSource.refresh(chipsParamsDs);
      this.UniqueOnlyDataSource.refresh(_.cloneDeep(res[this.integrationType].UniqueOnly));
      this.setRelatedDataSources([this.ReportTypesDataSource, this.ParametersDataSource]);
      this.settingsService.initCache();

      // TODO: remove timeout
      setTimeout((): void => {
        this.isLoaded = true;
      }, 1000);
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onCloseChip(e: ChipsModel): void {
    this.ParametersDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
    setTimeout((): void => {
      this.settingsService.setDataToCache();
    });
  }

  public onApplyReportType($event: ListItemModel): void {
    if (!this.settingsService.setReportTypes($event)) {
      return;
    }
    this.ParametersDataSource.setLoading(true);
    this.refreshRelatedDataSources();
    this.settingsService
      .getParameters($event)
      .pipe(debounceTime(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(_.cloneDeep(res));
          this.chipsParametersDataSource.refresh(getSelectedChips(res));
        }
      });
  }

  public onApplyAttribution($event: ListItemModel): void {
    this.settingsService.setSettingHandler($event, 'Attribution');
  }

  public onApplyBindTo($event: ListItemModel): void {
    this.settingsService.setSettingHandler($event, 'BindTo');
  }

  public onApplyParameters($event: Array<ListItemModel>): void {
    this.onApplyParams($event, this.chipsParametersDataSource);
    this.settingsService.setDataToCache();
  }

  public onApplyUniqueOnly($event: ListItemModel): void {
    this.settingsService.setSettingHandler($event, 'UniqueOnly');
  }
}
