import { Component, Input, OnInit } from '@angular/core';
import { StepperItem } from '../../models/StepperItem';
import { StepperBehaviorEnum } from '../../models/StepperBehavionEnum';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css'],
})
export class StepperComponent implements OnInit {
  @Input()
  public StepItems: StepperItem[];

  @Input()
  public ReadOnly: boolean;

  @Input()
  public Behavior: StepperBehaviorEnum = null;

  @Input()
  public set selectedIndex(val: number) {
    if (val >= this.StepItems.length || val === this.selectedStepIndex) {
      return;
    }

    this.setSelectedStep(val);
  }

  private selectedStepIndex: number = -1;

  constructor() {}

  public ngOnInit(): void {
    if (this.selectedStepIndex === -1) {
      this.setSelectedStep(0);
    }

    if (this.Behavior === null) {
      this.Behavior = StepperBehaviorEnum.oneWay;
    }
  }

  public stepClick(index: number): void {
    if (this.Behavior === StepperBehaviorEnum.oneWay) {
      return;
    }

    this.setSelectedStep(index);
  }

  private setSelectedStep(index: number): void {
    this.selectedStepIndex = index;
    for (let i = 0; i < this.StepItems.length; i++) {
      if (index > i) {
        switch (this.Behavior) {
          case StepperBehaviorEnum.oneWay:
            this.StepItems[i].stepPassed = true;
            this.StepItems[i].disabled = true;
            this.StepItems[i].active = false;
            break;
          case StepperBehaviorEnum.twoWay:
            this.StepItems[i].stepPassed = null;
            this.StepItems[i].disabled = false;
            this.StepItems[i].active = false;
            break;
          case StepperBehaviorEnum.consistently:
            this.StepItems[i].stepPassed = true;
            this.StepItems[i].disabled = false;
            this.StepItems[i].active = false;
            break;
        }
      }
      if (index === i) {
        switch (this.Behavior) {
          case StepperBehaviorEnum.oneWay:
            this.StepItems[i].stepPassed = false;
            this.StepItems[i].disabled = false;
            this.StepItems[i].active = true;
            break;
          case StepperBehaviorEnum.twoWay:
            this.StepItems[i].stepPassed = null;
            this.StepItems[i].disabled = false;
            this.StepItems[i].active = true;
            break;
          case StepperBehaviorEnum.consistently:
            this.StepItems[i].stepPassed = false;
            this.StepItems[i].disabled = false;
            this.StepItems[i].active = true;
            break;
        }
      }
      if (index < i) {
        switch (this.Behavior) {
          case StepperBehaviorEnum.oneWay:
            this.StepItems[i].stepPassed = false;
            this.StepItems[i].disabled = true;
            this.StepItems[i].active = false;
            break;
          case StepperBehaviorEnum.twoWay:
            this.StepItems[i].stepPassed = null;
            this.StepItems[i].disabled = false;
            this.StepItems[i].active = false;
            break;
          case StepperBehaviorEnum.consistently:
            this.StepItems[i].stepPassed = false;
            this.StepItems[i].disabled = true;
            this.StepItems[i].active = false;
            break;
        }
      }
    }
    this.StepItems[index].command();
  }
}
