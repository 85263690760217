<div class="tab-dropdown-container p-d-flex p-align-center width-max">
  <div class="tabs-block">
    <p-tabView styleClass="tabview-custom"
               [(activeIndex)]="selectedIndex"
               (onChange)="tabChanged()">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div class="integration-icon">
            <span>All</span>
          </div>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel *ngFor="let integration of tabIntegrations">
        <ng-template pTemplate="header">
          <div class="integration-icon" pTooltip="{{integration.title}}" tooltipPosition="top">
            <img class="integration-icon width-max" [src]="integration.icon" alt="">
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
  <div class="dropdown-block">
    <p-dropdown *ngIf="dropdownIntegrations.length > 0"
                [options]="dropdownIntegrations"
                [(ngModel)]="selectedDropdownIntegration"
                (onChange)="dropdownChanged()">
      <ng-template pTemplate="selectedItem"></ng-template>
      <ng-template let-integration pTemplate="item">
        <div class="integration-icon" pTooltip="{{integration.title}}">
          <img class="integration-icon width-max" [src]="integration.icon" alt="">
        </div>
      </ng-template>
    </p-dropdown>
  </div>
</div>
