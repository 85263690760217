<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="mr-b-5">
    <app-renta-error-message [errorMessage]="error" *ngIf="error"></app-renta-error-message>
  </div>

  <label for="workspaceName" class="p-d-none"></label>
  <input
    id="workspaceName"
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    appTextInputTrim
    pInputText
    placeholder="Workspace name"
    type="text"
    [(ngModel)]="CustomerioWorkspaceName"
    [disabled]="loading"
  />

  <label for="apiKey" class="p-d-none"></label>
  <input
    id="apiKey"
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    appTextInputTrim
    pInputText
    placeholder="API key"
    type="text"
    [(ngModel)]="CustomerioWorkspaceApiKey"
    [disabled]="loading"
  />

  <div class="width-max p-mt-3 p-mb-2 customerio-region-wrapper">
    <app-renta-dropdown
      [maxHeight]="120"
      [ShowName]="false"
      [CanSearch]="true"
      [RentaDropdownSource]="RegionDataSource"
      [placeholder]="'Workspace region'"
      (OnApply)="onApplyRegion($event)"
    ></app-renta-dropdown>
  </div>

</div>
