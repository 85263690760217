<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="mr-b-5">
    <app-renta-error-message [errorMessage]="error" *ngIf="error"></app-renta-error-message>
  </div>
  <div class="p-d-flex p-justify-between">
    <label for="ServerHostname" class="p-d-none"></label>
    <input
      id="ServerHostname"
      class="p-inputtext-lg auth-dialog-input server-name-input"
      appTextInputTrim
      pInputText
      placeholder="Hostname"
      type="text"
      [(ngModel)]="ServerHostname"
      [disabled]="loading"
    />
    <label for="ServerPort" class="p-d-none"></label>
    <input
      id="ServerPort"
      class="p-inputtext-lg auth-dialog-input server-port-input"
      appTextInputTrim
      pInputText
      placeholder="Port"
      [disabled]="loading"
      type="text"
      [(ngModel)]="ServerPort"
    />
  </div>
  <label for="DatabaseName" class="p-d-none"></label>
  <input
    id="DatabaseName"
    type="text"
    appTextInputTrim
    pInputText
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    placeholder="Database name"
    [(ngModel)]="DatabaseName"
    [disabled]="loading"
  />
  <input
    id="DatabaseSchema"
    type="text"
    appTextInputTrim
    pInputText
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    placeholder="Database schema"
    [(ngModel)]="DatabaseSchema"
    [disabled]="loading"
  />
  <label for="DatabaseUser" class="p-d-none"></label>
  <input
    id="DatabaseUser"
    type="text"
    appTextInputTrim
    pInputText
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    placeholder="Database user"
    [(ngModel)]="DatabaseUser"
    [disabled]="loading"
  />
  <label for="DatabasePassword" class="p-d-none"></label>
  <input
    id="DatabasePassword"
    type="password"
    appTextInputTrim
    pInputText
    class="p-inputtext-lg auth-dialog-input dialog-input-margin"
    placeholder="Database password"
    [(ngModel)]="DatabasePassword"
    [disabled]="loading"
  />
  <span class="ip-connection-info ap-ta-center renta-font-normal">
    You should allow connection from next IP: 13.94.215.246, 116.203.213.108
  </span>
  <a href="https://renta.im/docs/destination/amazon_redshift" class="renta-href check-dock" target="_blank">Check
    documentation for
    details</a>
</div>
