import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RentaIntegrationListFilterDataSource } from '../../../shared/models/DataSource/RentaIntegrationListFilterDataSource';
import { IntegrationSourceModel } from '../../../../models/Integration/IntegrationSourceModel';

@Component({
  selector: 'app-integrations-list-filter',
  templateUrl: './integrations-list-filter.component.html',
  styleUrls: ['./integrations-list-filter.component.scss']
})
export class IntegrationsListFilterComponent {
  @Input()
  public RentaIntegrationListFilterSource: RentaIntegrationListFilterDataSource;

  @Output()
  public OnApplyIntegrationFilter: EventEmitter<IntegrationSourceModel> = new EventEmitter<IntegrationSourceModel>();

  constructor() {
  }

  public onApplyIntegrationFilter($event: IntegrationSourceModel): void {
    this.OnApplyIntegrationFilter.emit($event);
  }
}
