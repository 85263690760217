import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { RentaApiService } from '../../../../services/renta-api.service';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { YandexLogsMeta } from '../../../../models/Integration/IntegrationsMeta/YandexLogs';
import { mergeMap } from 'rxjs/operators';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { EtlSettingsServiceBase } from './EtlSettingsServiceBase';

@Injectable()
export class YandexLogsSettingsService extends EtlSettingsServiceBase<YandexLogsMeta> {
  protected tablePrefix: string = 'ym_logs';
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.yandex_metrica_logs_api;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      Counter: null,
      SourceType: null,
      Parameters: null
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  constructor(public integrationInfoData: IntegrationInfoViewModel, protected readonly rentaApiService: RentaApiService,
              protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.memoryCacheKey = `tablePrefix-${IntegrationTypeEnum.yandex_metrica_logs_api}`;
  }

  public setSourceType(selectedSourceType: ListItemModel): boolean {
    if (_.isNil(selectedSourceType) || _.some(this.selectedSettings.SourceType, selectedSourceType)) {
      return false;
    }

    this.selectedSettings.SourceType = [selectedSourceType];
    return true;
  }

  public getParameters(selectedSourceType: ListItemModel): Observable<Array<ListItemModel>> {
    const sourceType: string = _.isNil(selectedSourceType) ? _.first(this.selectedSettings.SourceType)?.id : selectedSourceType.id;

    if (_.isNil(sourceType)) {
      return of<[]>([]);
    }

    const url: string = this.meta.parametersUrl.replace('{sourceTypeId}', sourceType);
    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };

    return this.getCurrentParameters(url, body);
  }

  public setSelectedCounter(selectedCounter: ListItemModel): void {
    if (_.isNil(selectedCounter)) {
      return;
    }

    this.selectedSettings.Counter = [selectedCounter];
  }

  protected mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: response.dateRange || [],
      Date: response.date || null,
      Schedule: response.schedule || [],
      OverwritePeriod: response.overwritePeriod || [],
      [this.integrationTypeEnum]: {
        Counter: response.counters || [],
        SourceType: response.sourceTypes || [],
        Parameters: response.parameters || []
      },
      Name: response.name || defaultIntegrationName,
      TableName: response.tableName || defaultTableName
    };
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].Counter = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [];
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings, isRefresh: boolean = false): void {
    const isRefreshSettings = this.editMode && isRefresh;
    this.selectedIntegrationSettings.Id = initialSettings.Id;
    this.selectedSettings.Counter =  isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].Counter[0])
      ? initialSettings[this.integrationTypeEnum].Counter.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].Counter[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].Counter.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.SourceType = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType[0])
      ? initialSettings[this.integrationTypeEnum].SourceType.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].SourceType.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );

    this.initSelectedCommonSettings(initialSettings, isRefreshSettings);
  }

  protected refreshParameters(): Observable<any> {
    return this.rentaApiService.initIntegration(this.getWizardRequestData())
      .pipe(
        mergeMap((res: any): Observable<IntegrationSettings> => {
          this.meta = res.meta;
          res = this.mapIntegrationSettings(res, this.selectedIntegrationSettings.TableName, this.selectedIntegrationSettings.Name);
          const selectedSourceType = this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType[0] ||
            res[this.integrationTypeEnum].SourceType.find((f: ListItemModel): boolean => f.selected);

          return (_.isNil(selectedSourceType) || _.isNil(res[this.integrationTypeEnum].SourceType.find((f: ListItemModel): boolean => f.id === selectedSourceType.id)))
            ? of(res)
            : this.getParameters(selectedSourceType).pipe(
              mergeMap((params: Array<ListItemModel>): Observable<IntegrationSettings> => {
                res[this.integrationTypeEnum].Parameters = params;
                this.compareParametersWithCacheData(params);
                return of(res);
              })
            );
        })
      );
  }
}
