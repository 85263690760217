<div class="avatar">
  <div *ngIf="!loading; else loadingAvatar" class="avatar-image p-d-flex p-align-center p-justify-center">
    <img [src]="imgUrl" *ngIf="imgUrl" alt="ava image">
    <div class="avatar-stub p-d-flex p-justify-center p-align-center width-max"
         *ngIf="!imgUrl"
         [style]="{background: 'linear-gradient(120deg, '+bgColor1+', 60%, '+bgColor2+')'}">
      <span class="renta-font-normal">{{avaSymbol}}</span>
    </div>
  </div>
</div>
<ng-template #loadingAvatar>
  <p-skeleton height="100%" borderRadius="100%"></p-skeleton>
</ng-template>

