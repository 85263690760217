import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { RentaDropdownDataSource } from '../../models/DataSource/RentaDropdownDataSource';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ALL_COUNTRIES } from '../../../manage/modules/billing/models/constants';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import * as _ from 'lodash';
import { PaymentCardFormModel } from '../../models/PaymentCardFormModel';

const STRIPE_STYLES = {
  base: {
    iconColor: '#666EE8',
    color: 'rgba(0,0,0,0.7)',
    fontWeight: 'normal',
    fontFamily: '"Lato", sans-serif',
    fontSize: '17px',
    lineHeight: '20px',
    '::placeholder': {
      color: 'rgba(0, 0, 0, 0.35)',
      fontSize: '17px',
      lineHeight: '20px',
    },
  },
};

@Component({
  selector: 'app-renta-payment-card',
  templateUrl: './renta-payment-card.component.html',
  styleUrls: ['./renta-payment-card.component.scss'],
})
export class RentaPaymentCardComponent implements OnInit {
  @ViewChild(StripeCardComponent)
  public card: StripeCardComponent;

  @Input()
  public disableInputs: boolean = false;

  @Output()
  public OnFormChange: EventEmitter<PaymentCardFormModel> = new EventEmitter<PaymentCardFormModel>();

  public cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: STRIPE_STYLES,
  };
  public elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };
  public countriesDataSource: RentaDropdownDataSource;
  public country: string = '';
  public city: string = '';
  public zip: string = '';
  public streetAddress: string = '';
  public unit: string = '';
  public companyName: string = '';
  public isCardFocus: boolean = false;
  public isCardTouched: boolean = false;
  public isCardError: boolean = true;
  public billingAddress: FormGroup;
  public isCountryInvalid: boolean = false;
  private onFormValid: boolean = false;

  constructor(private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.billingAddress = this.fb.group({
      country: ['', [Validators.required]],
      city: ['', [Validators.required]],
      zip: ['', [Validators.required]],
    });

    this.billingAddress.valueChanges.subscribe((form: any): void => {
      this.city = form.city;
      this.zip = form.zip;
      this.onFormValid = this.billingAddress.valid;
      this.onFormChange();
    });

    const countries = ALL_COUNTRIES.map((item: { name: string; code: string }): ListItemModel => {
      return { id: item.code, name: item.name };
    });

    this.countriesDataSource = new RentaDropdownDataSource('name', countries);
  }

  public onFormChange(): void {
    const form: PaymentCardFormModel = {
      isValid: !this.isCardError && this.onFormValid,
      stripeCardElement: this.card?.element,
      country: this.country,
      city: this.city,
      postal_code: this.zip,
      unit: this.unit,
      line1: this.streetAddress,
      companyName: this.companyName,
    };
    this.OnFormChange.emit(form);
  }

  public onChange(ev: StripeCardElementChangeEvent): void {
    this.isCardError = !(_.isNil(ev.error) && ev.complete);
    this.onFormChange();
  }

  public onApplyCountry(selectedCountry: ListItemModel): void {
    this.country = selectedCountry.id;
    this.onFormChange();
  }

  public onCardFocus(isFocused: boolean): void {
    if (!isFocused) {
      this.isCardTouched = true;
    }

    this.isCardFocus = isFocused;
  }

  public onBlurCountries(): void {
    setTimeout((): void => {
      this.isCountryInvalid = this.country.length === 0;
    });
  }
}
