import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { delay, filter, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { RentaApiService } from './renta-api.service';
import { ProfileModel, TimeZoneModel } from '../modules/shared/models/ProfileModel';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private email: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private allTimezones: Array<TimeZoneModel>;
  private name: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private selectedTimezone: BehaviorSubject<TimeZoneModel> = new BehaviorSubject<TimeZoneModel>(null);
  private isOauth: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private avatarLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private avatarUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private readonly rentaApiService: RentaApiService) {
  }

  public InitProfile(): void {
    if (!_.isNil(this.email.getValue())) {
      return;
    }

    this.avatarLoading.next(true);
    this.rentaApiService.getProfile().subscribe((response: ProfileModel): void => {
      this.avatarUrl.next(response.iconUrl);
      this.email.next(response.email);
      this.name.next(response.userName);
      this.allTimezones = response.timezones;
      this.isOauth.next(response.isGoogleOAuth);

      const tmpSelectedTimeZone = _.find(response.timezones, (f: TimeZoneModel): boolean => f.selected);

      if (_.isNil(tmpSelectedTimeZone)) {
        throw new Error('No selected TimeZone on backend part');
      }

      this.selectedTimezone.next(tmpSelectedTimeZone);
      this.avatarUrl.subscribe((__: string): void => {
        setTimeout((): void => {
          this.avatarLoading.next(false);
        }, 1000);
      });
    });
  }

  public getAvatarUrl(): Observable<string> {
    return this.avatarUrl.asObservable();
  }

  public getAvatarLoading(): Observable<boolean> {
    return this.avatarLoading.asObservable();
  }

  public updateAvatar(image: string): Observable<any> {
    return this.rentaApiService.getProfile().pipe(
      tap((response: ProfileModel) => {
        this.avatarUrl.next(response.iconUrl);
      })
    );
  }

  public getEmail(): Observable<string> {
    return this.email.asObservable();
  }

  public getName(): Observable<string> {
    return this.name.asObservable();
  }

  public getAllTimezones(): Array<TimeZoneModel> {
    return this.allTimezones;
  }

  public getSelectedTimezone(): Observable<TimeZoneModel> {
    return this.selectedTimezone.asObservable();
  }

  public getGoogleOAuth(): Observable<boolean> {
    return this.isOauth.asObservable().pipe(filter((f: boolean): boolean => f != null));
  }

  public updateAvatarUrl(body: FormData): Observable<string> {
    this.avatarLoading.next(true);
    return this.rentaApiService.updateProfileImage(body).pipe(
      delay(250),
      tap((res: string): void => !_.isNil(res) && this.avatarUrl.next(res))
    );
  }

  public updateName(name: string): Observable<boolean> {
    return this.rentaApiService.updateProfile({ userName: name }).pipe(
      delay(250),
      tap((res: boolean): void => res && this.name.next(name))
    );
  }

  public updateTimeZone(timeZone: TimeZoneModel): void {
    if (this.selectedTimezone.getValue().id === timeZone.id) {
      return;
    }

    this.rentaApiService
      .updateProfile({ timezones: [timeZone] })
      .pipe(delay(250))
      .subscribe((__: {}): void => {
        this.allTimezones.forEach((tz: TimeZoneModel): void => {
          tz.selected = tz.id === timeZone.id;
        });

        this.selectedTimezone.next(timeZone);
      });
  }
}
