import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { RentaDropdownDataSource } from '../../models/DataSource/RentaDropdownDataSource';
import { OverlayDropdownDirective } from '../../directives/overlay-dropdown.directive';
import * as _ from 'lodash';

@Component({
  selector: 'app-renta-dropdown',
  templateUrl: './renta-dropdown.component.html',
  styleUrls: ['../internal/base-settings-styles.css', './renta-dropdown.component.css'],
})
export class RentaDropdownComponent implements OnInit {
  public defaultPlaceHolder: string = 'More data source in the list';

  @Input()
  public maxHeight: number = 320;

  @Input()
  public CanSearch: boolean = true;

  @Input()
  public Invalid: boolean = false;

  @Input()
  public ShowName: boolean = true;

  @Input()
  public placeholder: string;

  @Input()
  public additionalInfo: string;

  @Input()
  public toolTipInfo: string;

  @Input()
  public ExtendedDescription: boolean = true;

  @Input()
  public RentaDropdownSource: RentaDropdownDataSource;

  @Output()
  public OnApply: EventEmitter<ListItemModel> = new EventEmitter<ListItemModel>();

  @Output()
  public OnBlur: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(OverlayDropdownDirective)
  public directive: OverlayDropdownDirective = null;

  public ds: Observable<Array<ListItemModel>> = null;

  public isOpen: boolean = false;
  public searchString: string;

  private selectedItem: ListItemModel = null

  constructor() {}

  public onFocus(isFocus: boolean): void {
    this.isOpen = isFocus;
    if (!this.isOpen) {
      this.OnBlur.emit(null);
      this.searchString = '';
      this.RentaDropdownSource.filter('');
    }
  }

  public ngOnInit(): void {
    this.ds = this.RentaDropdownSource.connect();
    this.ds.subscribe((__: Array<ListItemModel>): void => {
      const selected = this.RentaDropdownSource.getSelectedItem();

      if (selected === null || selected === undefined || this.selectedItem?.id === selected.id) {
        return;
      }

      this.selectedItem = selected;
      this.OnApply.emit(selected);
    });
  }

  public onSelectListItem(item: ListItemModel): void {
    this.RentaDropdownSource.selectItem(item);
    this.directive.ToggleOverlay(false);
  }

  public getId(name: string): string {
    return name.replace(/ /g, '_') + 'DataSource';
  }

  public getPlaceholder(): string {
    if (!_.isNil(this.placeholder) && this.placeholder.length > 0) {
      this.defaultPlaceHolder = this.placeholder;
    }

    if (this.isOpen && this.CanSearch) {
      return '';
    }

    const selectedItem = this.RentaDropdownSource.getSelectedItem();
    return selectedItem ? selectedItem.name : this.defaultPlaceHolder;
  }
}
