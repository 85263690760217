<div class="p-d-flex width-max">
  <div class="p-ml-2 width-max">
    <label for="{{ ColumnInfo.id }}" class="p-d-none"></label>
    <input
      id="{{ ColumnInfo.id }}"
      class="p-inputtext-lg column-type-input"
      type="text"
      pInputText
      appTextInputTrim
      [(ngModel)]="ColumnInfo.name"
      [disabled]="true"
    />
  </div>
  <div class="p-ml-2 width-max">
    <app-renta-dropdown
      (OnApply)="onApplyColumnType($event)"
      [CanSearch]="false"
      [ShowName]="false"
      [placeholder]="'Types'"
      [RentaDropdownSource]="ColumnTypesDataSource"
    ></app-renta-dropdown>
  </div>
  <div class="p-ml-2">
    <p-button styleClass="renta-btn-secondary" (onClick)="delete()">
      <i class="pi pi-trash"></i>
    </p-button>
  </div>
</div>
