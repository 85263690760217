import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { delay, tap } from 'rxjs/operators';

import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { IntegrationSelectBaseComponent } from '../../../shared/components/internal/integration-select-base/integration-select-base.component';
import { CreateIntegrationService } from '../../services/create-integration.service';
import { IntegrationToken } from '../../../../models/Integration/IntegrationToken';
import { IntegrationSourceModel } from '../../../../models/Integration/IntegrationSourceModel';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { UserService } from '../../../../services/user.service';
import { ProfileService } from '../../../../services/profile.service';

@Component({
  selector: 'app-integration-source',
  templateUrl: './integration-source.component.html',
  styleUrls: [
    '../../../shared/components/internal/integration-select-base/integration-select-base.css',
    './integration-source.component.css',
  ],
})
export class IntegrationSourceComponent extends IntegrationSelectBaseComponent implements OnInit, OnDestroy {
  @Output()
  public OnDataLoading: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor(
    protected readonly createIntegrationService: CreateIntegrationService,
    protected readonly userService: UserService,
    protected readonly profileService: ProfileService,
    public readonly modalDialogService: RentaModalsService
  ) {
    super(modalDialogService, createIntegrationService, userService, profileService);
  }

  public ngOnDestroy(): void {
    this.emailSubscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.OnDataLoading.emit(true);
    this.emailSubscription = this.profileService.getEmail().subscribe(email => {
      this.userEmail = email;

      if (this.userEmail !== null) {
        (window as any).renta.page('Data sources list', {
          email: this.userEmail,
          userId: this.userService.getUserId()
        });
      }
    });

    this.createIntegrationService.getAvailableIntegrationSource().subscribe((res: Array<IntegrationSourceModel>): void => {
      this.OnDataLoading.emit(false);
      this.integrations = res.filter((f: IntegrationSourceModel): boolean => f.available);
      this.availableIntegrations = this.integrations;
      this.accordionActiveState = new Array<boolean>(this.integrations.length).fill(false);
    });
  }

  public onTabOpen($event: any): void {
    this.refresh($event.index, false);
  }

  public filterIntegrations(integration: IntegrationSourceModel): boolean {
    return integration.available;
  }

  protected refresh(index: number, isTokenAdded: boolean): void {
    const integrationToLoad = this.availableIntegrations[index];
    if (isTokenAdded){
      (window as any).renta.track('Source added', {
        source: integrationToLoad.title,
        userId: this.userService.getUserId(),
        email: this.userEmail,
      });
    }

    if (integrationToLoad.accounts !== 0 || integrationToLoad.tokens === null) {
      this.availableIntegrations[index].tokens = this.createIntegrationService.getIntegrationTokens(integrationToLoad.integrationType).pipe(
        delay(3000),
        tap((res: Array<IntegrationToken>): void => {
          this.accordionActiveState[index] = res.length !== 0;
          this.availableIntegrations[index].accounts = res.length;
          this.availableIntegrations[index].oAuthUrl = res.length > 0 ? res[0].oAuthUrl : null;

          if (IntegrationSourceComponent.isYandexIntegration(this.availableIntegrations[index])) {
            this.setYandexAccountsAmount(this.availableIntegrations[index].accounts);
          }
        }),
      );
    }
  }

  private setYandexAccountsAmount(accountsAmount: number): void {
    this.availableIntegrations.find((integration: IntegrationSourceModel): void => {
      if (IntegrationSourceComponent.isYandexIntegration(integration)) {
        integration.accounts = accountsAmount;
      }
    });
  }

  private static isYandexIntegration(integration: IntegrationSourceModel): boolean {
    return (
      integration.integrationType === IntegrationTypeEnum.yandex_metrica_logs_api ||
      integration.integrationType === IntegrationTypeEnum.yandex_direct ||
      integration.integrationType === IntegrationTypeEnum.yandexMarket ||
      integration.integrationType === IntegrationTypeEnum.yandex_metrica ||
      integration.integrationType === IntegrationTypeEnum.appmetrica
    );
  }
}
