<div class="input-elem-container">
  <div class="input-edit-block p-d-flex p-align-center p-pr-0">
    <div class="input-edit p-d-flex">
      <input type="text"
             class="p-d-flex"
             [(ngModel)]="textContent"
             [placeholder]="textPlaceholder"
             (OnEnter)="btnSave()">
    </div>
    <div class="input-btn p-d-flex p-align-center p-justify-end">
      <div class="preloader-container" *ngIf="loading" @fade>
        <i class="drop-down-icon pi pi-spin pi-spinner"></i>
      </div>
      <button pButton type="button" label="cancel" *ngIf="!loading"
              class="p-button-secondary p-button-sm"
              (click)="btnCancel()"></button>
      <button pButton type="button" label="save" *ngIf="!loading"
              class="p-button-sm"
              (click)="btnSave()"></button>
    </div>
  </div>
</div>
