import { AfterViewInit, Component, OnInit } from '@angular/core';
import { IntegrationSettingsComponent } from './integration-settings.component';
import { IntegrationService } from '../../services/integration.service';
import { SettingsComponentFactoryResolver } from '../../services/settingsComponentFactory';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { AppGlobalPreloaderService } from '../../../../services/app-global-preloader.service';
import { IntegrationEditInfo } from '../../../../models/Integration/IntegrationEditInfo';
import { UserService } from '../../../../services/user.service';
import {ProfileService} from "../../../../services/profile.service";

@Component({
  selector: 'app-duplicate-integration',
  templateUrl: './integration-settings.component.html',
  providers: [SettingsComponentFactoryResolver, IntegrationService],
  styleUrls: ['../internal/integration-settings/common-settings.scss', './integration-settings.component.css'],
})
export class DuplicateIntegrationComponent extends IntegrationSettingsComponent implements OnInit, AfterViewInit {
  public editIntegrationTitle: string;

  constructor(
    protected readonly integrationService: IntegrationService,
    protected readonly settingsComponentFactoryResolver: SettingsComponentFactoryResolver,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly appGlobalPreloaderService: AppGlobalPreloaderService,
    protected readonly userService: UserService,
    protected readonly profileService: ProfileService
  ) {
    super(integrationService, settingsComponentFactoryResolver, router, activatedRoute, appGlobalPreloaderService, userService, profileService);
    this.saveButton = 'Duplicate Integration';
  }

  public ngOnInit(): void {
    this.createMode = false;

    this.activatedRoute.data.subscribe((data: Data): void => {
      const integrationEditInfo = data?.integrationEditInfo as IntegrationEditInfo;
      if (!integrationEditInfo) {
        // TODO: some error with of integration data
        return;
      }
      this.editIntegrationTitle = integrationEditInfo.integrationType.title;
      this.integrationService.setIntegrationId(integrationEditInfo.integrationId, true);
      this.integrationService.setSource(integrationEditInfo.integrationType, integrationEditInfo.sourceTokenId, null);
      this.integrationService.setDestination(integrationEditInfo.destinationType, integrationEditInfo.destinationTokenId, null);
    });
  }

  public getPageTitle(): string {
    return `Duplicate ${this.editIntegrationTitle} integration`;
  }

  public getPageSubtitle(): string {
    return `Here you can duplicate your ${this.editIntegrationTitle} integration.`;
  }
}
