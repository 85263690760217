<div class="p-d-flex width-max">
  <div class="width-max">
    <app-renta-input
      [IsTransliterate]='false'
      [IsShowName]='false'
      (OnApply)='onValueChange($event)'
      [RentaInputSource]='RentaInputSource'
    ></app-renta-input>
  </div>
  <div class="p-ml-2">
    <p-button styleClass="renta-btn-secondary" (onClick)="delete()">
      <i class="pi pi-minus"></i>
    </p-button>
  </div>
</div>
