import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { map, mergeMap, tap } from 'rxjs/operators';

import { RentaApiService } from '../../../../services/renta-api.service';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { ApiResponse } from '../../../../models/common/ApiResponse`T';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { VkMeta } from '../../../../models/Integration/IntegrationsMeta/VkMeta';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { EtlSettingsServiceBase } from './EtlSettingsServiceBase';

@Injectable()
export class VkSettingsService extends EtlSettingsServiceBase<VkMeta> {
  protected tablePrefix: string = 'vk';
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.vk_ads;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      Account: null,
      Clients: null,
      SourceType: null,
      Parameters: null
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  constructor(public integrationInfoData: IntegrationInfoViewModel, protected readonly rentaApiService: RentaApiService,
              protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.memoryCacheKey = `tablePrefix-${IntegrationTypeEnum.vk_ads}`;
  }

  public setAccount(selectedAccount: ListItemModel): boolean {
    if (_.isNil(selectedAccount) || _.some(this.selectedSettings.Account, selectedAccount)) {
      return false;
    }

    this.selectedSettings.Account = [selectedAccount];
    return true;
  }

  public setSourceType(selectedSourceType: ListItemModel): boolean {
    if (_.isNil(selectedSourceType) || _.some(this.selectedSettings.SourceType, selectedSourceType)) {
      return false;
    }

    this.selectedSettings.SourceType = [selectedSourceType];
    return true;
  }

  public getClients(accountId: string): Observable<Array<ListItemModel>> {
    if (_.isNil(accountId)) {
      return of<[]>([]);
    }

    const url: string = this.meta.clientsUrl.replace('{accountId}', accountId);
    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };

    return this.getCurrentClients(url, body);
  }

  public getParameters(selectedSourceType: ListItemModel): Observable<Array<ListItemModel>> {
    const sourceType = _.isNil(selectedSourceType) ? _.first(this.selectedSettings.SourceType)?.id : selectedSourceType.id;

    if (_.isNil(sourceType) ) {
      return of<[]>([]);
    }

    const url: string = this.meta.parametersUrl.replace('{sourceTypeId}', sourceType).replace('{periodTypeId}', null);
    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };
    return this.getCurrentParameters(url, body);
  }

  public setSelectedClients(selectedClients: Array<ListItemModel>): void {
    if (_.isNil(selectedClients)) {
      return;
    }

    this.selectedSettings.Clients = selectedClients;
  }

  protected mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: response.dateRange || [],
      Date: response.date || null,
      Schedule: response.schedule || [],
      OverwritePeriod: response.overwritePeriod || [],
      [this.integrationTypeEnum]: {
        Account: response.accounts || [],
        Clients: response.clients || [],
        SourceType: response.sourceTypes || [],
        Parameters: response.parameters || []
      },
      Name: response.name || defaultIntegrationName,
      TableName: response.tableName || defaultTableName
    };
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].Account = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Clients = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [];
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings, isRefresh: boolean = false): void {
    const isRefreshSettings = this.editMode && isRefresh;
    this.selectedIntegrationSettings.Id = initialSettings.Id;
    this.selectedSettings.Account = isRefreshSettings && !_.isNil(_.first(this.selectedIntegrationSettings[this.integrationTypeEnum].Account))
      ? initialSettings[this.integrationTypeEnum].Account.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].Account[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].Account.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Clients = isRefreshSettings && _.some(this.selectedSettings.Clients)
      ? initialSettings[this.integrationTypeEnum].Clients.filter((f: ListItemModel): boolean => !_.isNil(this.selectedSettings.Clients.find((ff: ListItemModel): boolean => ff.id === f.id)))
      : initialSettings[this.integrationTypeEnum].Clients.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.SourceType = isRefreshSettings && !_.isNil(_.first(this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType))
      ? initialSettings[this.integrationTypeEnum].SourceType.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].SourceType.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );

    this.initSelectedCommonSettings(initialSettings, isRefreshSettings);
  }

  protected refreshParameters(): Observable<any> {
    return this.rentaApiService.initIntegration(this.getWizardRequestData())
      .pipe(
        mergeMap((res: any): Observable<IntegrationSettings> => {
          this.meta = res.meta;
          res = this.mapIntegrationSettings(res, this.selectedIntegrationSettings.TableName, this.selectedIntegrationSettings.Name);
          const selectedAccount = _.first(this.selectedIntegrationSettings[this.integrationTypeEnum].Account) ||
            res[this.integrationTypeEnum].Account.find((f: ListItemModel): boolean => f.selected);

          const selectedSourceType = _.first(this.selectedIntegrationSettings[this.integrationTypeEnum].SourceType) ||
            res[this.integrationTypeEnum].SourceType.find((f: ListItemModel): boolean => f.selected);

          return ((_.isNil(selectedAccount) || _.isNil(res[this.integrationTypeEnum].Account.find((f: ListItemModel): boolean => f.id === selectedAccount.id)))
            ? of([])
            : this.getClients(selectedAccount.id)).pipe(
              mergeMap((clients: Array<ListItemModel>): Observable<IntegrationSettings> => {
                res[this.integrationTypeEnum].Clients = clients;
                return (_.isNil(selectedSourceType) || _.isNil(res[this.integrationTypeEnum].SourceType.find((f: ListItemModel): boolean => f.id === selectedSourceType.id))
                )
                  ? of(res)
                  : this.getParameters(selectedSourceType).pipe(
                    mergeMap((params: Array<ListItemModel>): Observable<IntegrationSettings> => {
                      res[this.integrationTypeEnum].Parameters = params;
                      this.compareParametersWithCacheData(params);
                      return of(res);
                    })
                  );
              })
            );
        }),
      );
  }

  protected getCurrentClients(url: string, body: any): Observable<Array<ListItemModel>> {
    return this.rentaApiService.httpClient.post<ApiResponse<{ clients: Array<ListItemModel> }>>(url, body).pipe(
      tap((res: ApiResponse<{ clients: Array<ListItemModel> }>): void => console.log(res)),
      map((res: ApiResponse<{ clients: Array<ListItemModel> }>): Array<ListItemModel> => {
        const tmpClients = [];
        res.result.clients.forEach((item: ListItemModel): void => {
          const index = this.selectedSettings.Clients.findIndex((f: ListItemModel): boolean => f.id === item.id);
          if (index > -1) {
            item.selected = true;
            tmpClients.push(item);
          }
        });
        this.selectedSettings.Clients = tmpClients;
        return res.result.clients;
      })
    );
  }
}
