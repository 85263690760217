import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { YandexDirectSettingsService } from '../../../../services/integrationSettings/yandex-direct-settings.service';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { RentaChipsDataSource } from '../../../../../shared/models/DataSource/RentaChipsDataSource';
import { RentaMultiselectDropdownDataSource } from '../../../../../shared/models/DataSource/RentaMultiselectDropdownDataSource';
import { RentaSelectionOptionDataSource } from '../../../../../shared/models/DataSource/RentaSelectionOptionDataSource';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import * as _ from 'lodash';
import { getSelectedChips } from '../../../../../../helpers/helpers';
import { ChipsModel } from '../../../../../shared/models/ChipsModel';
import { delay } from 'rxjs/operators';
import { SelectionOption } from 'src/app/models/Integration/IntegrationSelectionOption';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';

@Component({
  templateUrl: './yandex-direct.component.html',
  styleUrls: ['../common-settings.scss']
})
export class YandexDirectComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;
  public isShowClients: boolean;
  public ReportTypeDataSource: RentaDropdownDataSource;
  public chipsClientsDataSource: RentaChipsDataSource;
  public ClientsDataSource: RentaMultiselectDropdownDataSource;
  public chipsParametersDataSource: RentaChipsDataSource;
  public ParametersDataSource: RentaMultiselectDropdownDataSource;

  public includeVatDataSource: RentaSelectionOptionDataSource;
  public includeDiscountDataSource: RentaSelectionOptionDataSource;
  public isShowExtraSettings: boolean;

  constructor(private settingsService: YandexDirectSettingsService) {
    super(IntegrationTypeEnum.yandex_direct);
    this.isLoaded = false;
  }

  public ngOnInit(): void {
    this.chipsClientsDataSource = new RentaChipsDataSource('Selected agency accounts', null);
    this.ClientsDataSource = new RentaMultiselectDropdownDataSource('Agency accounts', null);
    this.ReportTypeDataSource = new RentaDropdownDataSource('Report type', null);
    this.chipsParametersDataSource = new RentaChipsDataSource('Selected parameters', null);
    this.ParametersDataSource = new RentaMultiselectDropdownDataSource('Parameters', null);
    this.includeVatDataSource = new RentaSelectionOptionDataSource('Include Vat', null);
    this.includeDiscountDataSource = new RentaSelectionOptionDataSource('Include Discount', null);

    this.chipsClientsDataSource.connect().subscribe((res: Array<ChipsModel>): void => {
      if (!this.isShowClients) {
        return;
      }

      const selectedClients = res.map<ListItemModel>((m: ChipsModel): ListItemModel => {
        return { id: m.id, name: m.title, group: m.type };
      });

      if (this.settingsService.setSelectedClients(selectedClients)) {
        this.reloadParameters(null, null);
      }
    });

    this.connectParamsChipsDS(this.chipsParametersDataSource, this.settingsService);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
      this.chipsClientsDataSource.setLoading(res);
      this.ClientsDataSource.setLoading(res);
      this.ReportTypeDataSource.setLoading(res);
      this.chipsParametersDataSource.setLoading(res);
      this.ParametersDataSource.setLoading(res);
      this.includeVatDataSource.setLoading(res);
      this.includeDiscountDataSource.setLoading(res);
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      const selectedReportType = res[this.integrationType].ReportTypes.find((f: ListItemModel): boolean => f.selected);
      this.isShowClients = res[this.integrationType].Clients.length > 0;
      this.isShowExtraSettings = selectedReportType.id !== '8';

      this.ReportTypeDataSource.refresh(_.cloneDeep(res[this.integrationType].ReportTypes));

      this.ClientsDataSource.refresh(_.cloneDeep(res[this.integrationType].Clients));
      const chipsClientsDs = getSelectedChips(res[this.integrationType].Clients);
      this.chipsClientsDataSource.refresh(chipsClientsDs);

      this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
      const chipsParamsDs = getSelectedChips(res[this.integrationType].Parameters);
      this.chipsParametersDataSource.refresh(chipsParamsDs);

      this.includeVatDataSource.refresh(res[this.integrationType].IncludeVat);
      this.includeDiscountDataSource.refresh(res[this.integrationType].IncludeDiscount);

      this.settingsService.initCache();

      // TODO: надо пересмотреть логику этого isLoaded, криво работает, настройки не прогружаются одновременно
      setTimeout((): void => {
        this.isLoaded = true;
      }, 1000);
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onCloseSelectedClientsChip(e: ChipsModel): void {
    this.ClientsDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
  }

  public onApplyClients(selectedClients: Array<ListItemModel>): void {
    if (!this.settingsService.setSelectedClients(selectedClients)) {
      return;
    }

    this.chipsClientsDataSource.refresh(getSelectedChips(selectedClients));
    this.reloadParameters(null, selectedClients);
  }

  public onApplyParameters($event: Array<ListItemModel>): void {
    this.onApplyParams($event, this.chipsParametersDataSource);
    this.settingsService.setDataToCache();
  }

  public onCloseChip(e: ChipsModel): void {
    this.ParametersDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
    setTimeout((): void => {
      this.settingsService.setDataToCache();
    });
  }

  public onApplyReportType($event: ListItemModel): void {
    this.isShowExtraSettings = $event.id !== '8';
    if (!this.settingsService.setReportType($event)) {
      return;
    }
    this.ParametersDataSource.setLoading(true);
    this.reloadParameters($event, null);
  }

  public onApplyIncludeVat($event: SelectionOption): void {
    this.settingsService.setSelectedIncludeVat($event);
  }

  public onApplyIncludeDiscount($event: SelectionOption): void {
    this.settingsService.setSelectedIncludeDiscount($event);
  }

  private reloadParameters(reportType: ListItemModel, clients: Array<ListItemModel>): void {
    this.ParametersDataSource.setLoading(true);
    this.settingsService
      .getParameters(reportType, clients)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(_.cloneDeep(res));
          this.chipsParametersDataSource.refresh(getSelectedChips(res));
        }
      });
  }
}
