<p-tabView [(activeIndex)]="activeTab.index"
           (activeIndexChange)="tabChanged({selected: $event} )"
           *ngIf="DropdownDataSource.groups.length > 0; else noFoundContent">
  <p-tabPanel [headerStyleClass]="getClass(groupModel.groupName)" header="{{groupModel.groupName}}"
              *ngFor="let groupModel of DropdownDataSource.groups; let i = index">
    <div class="panel-content-container p-d-flex p-flex-column" *ngIf="i === activeTab.index && !selectedTabActive">
      <cdk-virtual-scroll-viewport autosize class="viewport">
        <div *cdkVirtualFor="let groupData of DropdownDataSource.groupData[groupModel.groupName]" class="item">
          <app-dropdown-item
            [ListItem]="groupData"
            (OnClickListItem)="onClickListItem(groupData)"
            [Multi]="true"
            [Extended]="true"
          >
          </app-dropdown-item>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </p-tabPanel>
  <p-tabPanel *ngIf="(selectedDataSource | async).length > 0">
    <ng-template pTemplate="header">
      <span>Selected</span>
      <p-badge class="mr-l-5 mr-b-5" [value]="(selectedDataSource | async).length.toString()"></p-badge>
    </ng-template>
    <div class="panel-content-container p-d-flex p-flex-column">
      <app-dropdown-item
        *ngFor="let selectedItem of selectedDataSource | async"
        [ListItem]="selectedItem"
        (OnClickListItem)="onClickListItem(selectedItem)"
        [Multi]="true"
        [Extended]="true"
      >
      </app-dropdown-item>
    </div>
  </p-tabPanel>
  <div class="btn-container p-d-flex p-justify-between p-ai-center"
       *ngIf="showButtons && DropdownDataSource.groups.length > 0">
    <div class="dropdown-btn-wrapper" *ngIf="!selectedTabActive">
      <p-button (onClick)="onCheckAll()" styleClass="p-button dropdown-btn"
                label="Check All">
      </p-button>
    </div>
    <div class="dropdown-btn-wrapper">
      <p-button (onClick)="onUncheckAll()" styleClass="p-button dropdown-btn"
                label="Uncheck All">
      </p-button>
    </div>
    <div class="dropdown-btn-wrapper">
      <p-button (onClick)="onApply()" styleClass="p-button dropdown-btn"
                label="Apply">
      </p-button>
    </div>
  </div>
</p-tabView>
<ng-template #noFoundContent>
  <div class="no-content">
    <i class="drop-down-icon pi pi-search"></i>
    <div class="text-container">
      <span>Nothing has been found on this keywords.</span>
      <span>Try something else or check the spelling.</span>
    </div>
  </div>
</ng-template>
