<app-filter-view
  *ngIf="!filterModel.editMode"
  [SelectedFilter]="SelectedFilter"
  [standby]="filterModel.source.loading"
  (ApplyEdit)="editFilter()"
  (ApplyDelete)="deleteFilter()"
  [canEdit]='canEdit'>
</app-filter-view>
<app-filter-edit
  *ngIf="filterModel.editMode"
  [Filters]="FiltersSource"
  [Selected]="SelectedFilter"
  (ApplySave)="saveFilter($event)"
  (ApplyCancel)="cancel()">
</app-filter-edit>
