<div class="p-d-flex p-justify-center p-col integrations-list">
  <div class="integrations-list-wrapper error-mini-view p-d-flex p-dir-col p-align-center">
    <div class="p-d-flex p-justify-center p-align-center p-dir-col">
      <span class="integrations-list-title renta-font-big">Pipelines</span>
      <span class="integrations-list-title-info renta-font-normal">Your existing data pipelines</span>
    </div>
    <div class="preloader-container" *ngIf="isLoading">
      <i class="drop-down-icon pi pi-spin pi-spinner"></i>
    </div>
    <div class="expired-subscription-label-wrapper p-d-flex p-ai-center width-max p-m-3" *ngIf="!isLoading">
      <div class="expired-subscription-label-container p-d-flex width-max p-ai-center p-jc-between" *ngIf="isShowExpirationLabel">
        <span class="renta-text-color p-d-flex p-ai-center expired-subscription-label">
          <fa-icon [icon]="faExclamationIcon" class="icon-size"></fa-icon>
          <span class="renta-font-small p-text-center expired-subscription-text">Your subscription has been expired.</span>
        </span>
        <p-button styleClass="upgrade-plan-btn renta-btn-primary" (click)="upgradePlan()">
          <div class="p-d-flex p-ai-center width-max">
            <span class="renta-font-small renta-text-white p-text-bold">UPGRADE PLAN</span>
          </div>
        </p-button>
      </div>
    </div>
    <div class="integrations-list-search-container width-max" *ngIf="!isLoading">
      <label for="integration-search"></label>
      <span class="p-input-icon-left">
        <i class="renta-ic search-icon" [ngClass]="focusInput || hoverInput ? 'renta-ic-search-active' : 'renta-ic-search'"></i>
        <label for="integration-search"></label>
        <input
          id="integration-search"
          class="integration-search-input"
          type="text"
          pInputText
          appTextInputTrim
          [(ngModel)]="searchInput"
          placeholder="Search"
          autocomplete="off"
          (ngModelChange)="searchChange($event)"
          (mouseenter)="hoverInput = true"
          (mouseleave)="hoverInput = false"
          (focusin)="focusInput = true"
          (focusout)="focusInput = false"
        />
      </span>
    </div>
    <div class="integrations-list-filters-container p-d-flex p-justify-end p-ai-center width-max" *ngIf="!isLoading">
      <app-integrations-list-filter
        class="width-max"
        [RentaIntegrationListFilterSource]="IntegrationListFilterSource"
        (OnApplyIntegrationFilter)="applyIntegrationFilter($event)"
      >
      </app-integrations-list-filter>
      <div class="add-new-wrapper">
        <p-button styleClass="renta-btn-primary" (click)="addNewIntegration()" label="+ ADD"></p-button>
      </div>
    </div>
    <div class="integrations-list-container width-max" [hidden]="isLoading">
      <p-accordion
        [multiple]="true"
        *ngIf="integrations && integrations.length > 0; else notFound"
        (onOpen)="onDetailsOpen($event)"
        (onClose)="onDetailsClose($event)"
      >
        <p-accordionTab
          *ngFor="let integration of integrations; let i = index"
          id="AccordionIndex-{{ i }}"
          [class.single]="integrations.length === 1"
          [cache]="false"
        >
          <ng-template pTemplate="header">
            <div class="header-wrapper width-max p-d-flex p-align-center" id="{{ integration.id }}">
              <app-renta-switcher
                [Disabled]="switcherDisabledMap[integration.id] || isSubscriptionExpired"
                [IsChecked]="integration.enabled"
                (OnSwitcherChange)="stateChange({ integrationId: integration.id, integrationType: integration.integrationType, event: $event })">
              </app-renta-switcher>
              <div class="integration-icon-container p-d-flex p-align-center f-grow-1">
                <div class="integration-icon">
                  <img class="integration-icon width-max" [src]="integration.icon" alt="" />
                </div>
              </div>
              <div class="integration-info-container info-left-col p-d-flex p-justify-start f-grow-1">
                <div class="integration-info-block p-d-flex p-flex-column width-max f-grow-1">
                  <div class="integration-info-title">
                    <span class="renta-font-small">{{ integration.title }}</span>
                  </div>
                  <div class="integration-info-subtitle">
                    <span class="renta-font-small">{{ integration.sourceTitle }}</span>
                  </div>
                </div>
              </div>
              <div class="integration-icon-container p-d-flex p-align-center f-grow-1">
                <div class="integration-icon">
                  <img class="integration-icon width-max" [src]="integration.destinationIcon" alt="" />
                </div>
              </div>
              <div class="integration-info-container info-right-col p-d-flex f-grow-1">
                <div class="integration-info-block p-d-flex p-flex-column width-max f-grow-1">
                  <div class="integration-info p-d-flex" style="min-height: 19px">
                    <div class="integration-info-title">
                      <span class="renta-font-small">{{ integration.tableName }}</span>
                    </div>
                    <div class="integration-icon-block" [hidden]='integration.tableName === null || integration.tableName === undefined'>
                      <i
                        class="pi pi-clone mr-l-5 copy"
                        (click)="onCopy({ originalEvent: $event, text: integration.tableName, subject: 'table' })"
                      ></i>
                    </div>
                  </div>
                  <div
                    class="integration-info"
                    *ngIf="(integration.progress === 0 || integration.progress >= 100 || integration.progress === null || integration.progress === undefined) && integration.error === null"
                  >
                    <div class="integration-info-subtitle">
                      <span class="renta-font-small">{{ integration.lastUpdate }}</span>
                    </div>
                  </div>
                  <div
                    class="integration-info preloader-block"
                    *ngIf="integration.progress > 0 && integration.progress < 100 && integration.error === null"
                  >
                    <div class="preloader-cover p-d-flex p-align-center">
                      <p-progressBar
                        class="width-max"
                        mode="indeterminate"
                        [style]="{ width: '150px', height: '6px', borderRadius: '4px' }"
                      ></p-progressBar>
<!--                      <span>{{ integration.progress }}%</span>-->
                    </div>
                  </div>
                  <div class="error-container p-pt-1 width-max p-d-flex" *ngIf="integration.error !== null">
                    <div class="wrapper">
                      <app-renta-error-message [shortVersion]="true" [errorMessage]="integration.error"></app-renta-error-message>
                    </div>
                    <div class="integration-icon-block error-btn-copy p-d-flex p-align-center">
                      <i class="pi pi-clone mr-l-5 copy"
                        (click)="onCopy({ originalEvent: $event, text: integration.error, subject: 'error' })"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="more-actions-container p-d-flex p-align-center p-justify-start f-grow-1">
                <i
                  class="pi pi-ellipsis-h p-d-flex p-align-center p-justify-center"
                  appMenuPopup
                  [menuPopupSource]="getMenuPopupModel(integration)"
                  (closeMenuPopup)="onCloseMoreActions()"
                  (click)="onClickMoreActions($event, integration)"
                ></i>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <div class="integration-additional-info-container">
              <div class="integration-additional-info-wrapper" [@inOutAnimation] *ngIf="integration.additionalInfo">
                <div class="p-d-flex" *ngFor="let item of integration.additionalInfo | keyvalue">
                  <div class="integration-additional-info key width-max f-grow-1 p-d-flex">
                    <span class="integration-additional-info-text key renta-font-small">{{ item.key }}:</span>
                  </div>
                  <div class="integration-additional-info value width-max f-grow-1 p-d-flex">
                    <span class="integration-additional-info-text value renta-font-small">{{ item.value }}</span>
                  </div>
                </div>
              </div>
              <div *ngIf="!integration.additionalInfo" [@inAnimation] class="integration-additional-info-wrapper">
                <div *ngFor="let item of [].constructor(10); let i = index">
                  <div class="integration-additional-info">
                    <p-skeleton height="14px" width="{{ i % 2 !== 0 ? '100%' : '80%' }}" borderRadius="8px"></p-skeleton>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</div>
<ng-template #notFound>
  <div class="integration-not-found-msg-container p-d-flex p-justify-center width-max">
    <div class="msg-content p-d-flex p-flex-column p-align-center">
      <div class="msg-image">
        <img src="./assets/icons/search-engine-optimization.png" alt="not found image" />
      </div>
      <div class="msg-text">
        <span class="renta-font-normal">Nothing found on this keywords</span>
      </div>
    </div>
  </div>
</ng-template>
