import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { animate, group, query, style, transition, trigger } from '@angular/animations';

import { StepperItem } from '../../../shared/models/StepperItem';
import { IntegrationSourceModel } from '../../../../models/Integration/IntegrationSourceModel';
import { CreateIntegrationService, CreationState } from '../../services/create-integration.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

const left = [
  query(':enter, :leave', style({ position: 'fixed', width: '44%' }), { optional: true }),
  group([
    query(
      ':enter',
      [
        style({ transform: 'translateX(-55%)', opacity: '0.1' }),
        animate('.3s ease-out', style({ transform: 'translateX(0%)', opacity: '1' }))
      ],
      { optional: true }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0%)', opacity: '0.7' }),
        animate('.3s ease-out', style({ transform: 'translateX(65%)', opacity: '0.1' }))
      ],
      { optional: true }
    )
  ])
];

const right = [
  query(':enter, :leave', style({ position: 'fixed', width: '45%' }), { optional: true }),
  group([
    query(
      ':enter',
      [
        style({ transform: 'translateX(100%)', opacity: '0.1' }),
        animate('.3s ease-out', style({ transform: 'translateX(0%)', opacity: '1' }))
      ],
      {
        optional: true
      }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0%)', opacity: '0.7' }),
        animate('.3s ease-out', style({ transform: 'translateX(-55%)', opacity: '0.1' }))
      ],
      {
        optional: true
      }
    )
  ])
];

@Component({
  selector: 'app-integration-create',
  templateUrl: './integration-create.component.html',
  styleUrls: ['./integration-create.component.css'],
  providers: [CreateIntegrationService],
  animations: [
    trigger('disableAnim', [transition(':enter', [])]),
    trigger('animSlider', [transition(':increment', right), transition(':decrement', left)])
  ]
})
export class IntegrationCreateComponent implements OnInit, OnDestroy {
  public placeholder: string;
  public steps: StepperItem[];
  public activeStep: number;
  public overflow: string;
  public searchInput: string;
  public focusInput: boolean;
  public dataSourceLoading: boolean = false;
  public hoverInput: boolean;
  public sourceType: Observable<IntegrationSourceModel> = null;

  @ViewChild('searchElement')
  public searchElement: ElementRef;

  private routeSubscription: Subscription;

  constructor(
    private createIntegrationService: CreateIntegrationService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {
  }

  public ngOnInit(): void {
    this.steps = [
      {
        text: 'Source',
        command: (): void => {
          this.searchInput = '';
          this.placeholder = 'Search for Service to Integrate';
          this.createIntegrationService.setSource(null, null, null);
          this.router.navigate(['source'], { relativeTo: this.activatedRoute }).then();
        },
        active: false,
        disabled: false,
        stepPassed: false
      },
      {
        text: 'Destination',
        command: (): void => {
          this.searchInput = '';
          this.placeholder = 'Search for Destination';
          this.createIntegrationService.setDestination(null, null, null);
          this.router.navigate(['destination'], { relativeTo: this.activatedRoute }).then();
        },
        active: false,
        disabled: true,
        stepPassed: false
      },
      {
        text: 'Settings',
        command: (): void => {
          this.router.navigate(['settings'], { relativeTo: this.activatedRoute }).then();
        },
        active: false,
        disabled: true,
        stepPassed: false
      }
    ];

    this.routeSubscription = this.router.events
      .pipe(filter((event: any): boolean => event instanceof NavigationEnd))
      .subscribe((__: any): void => {
        const segments = this.router.getCurrentNavigation().extractedUrl.root.children.primary.segments;

        if (!segments) {
          return;
        }

        const activeSegment = segments[segments.length - 1];
        const stepToActive = this.steps.findIndex((f: StepperItem): boolean => f.text.toLowerCase() === activeSegment.path && !f.active);
        if (stepToActive === -1) {
          return;
        }

        if (stepToActive < this.activeStep) {
          this.activeStep = stepToActive;
        } else {
        }
      });

    this.createIntegrationService.checkState().subscribe((state: CreationState): void => {
      if (this.activeStep === state) {
        return;
      }

      this.activeStep = state;
    });

    this.sourceType = this.createIntegrationService.getIntegrationType();
  }

  public onNext(): void {
    if (this.activeStep !== 2) {
      this.activeStep++;
    }
  }

  public onSelectSourceToken($event: {
    integrationType: IntegrationSourceModel;
    integrationTokenId: string;
    integrationTokenName: string;
  }): void {
    this.createIntegrationService.setSource($event.integrationType, $event.integrationTokenId, $event.integrationTokenName);
  }

  public onSelectDestinationToken($event: {
    integrationType: IntegrationSourceModel;
    integrationTokenId: string;
    integrationTokenName: string;
  }): void {
    this.createIntegrationService.setDestination($event.integrationType, $event.integrationTokenId, $event.integrationTokenName);
  }

  public onDataLoading(event: boolean): void {
    this.dataSourceLoading = event;
  }

  public ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
}
