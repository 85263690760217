<div class="integration-token-row p-d-flex p-ai-center" (click)="onAddToken($event)">
  <div class="dash-container">
    <div class="vertical-token-dash"></div>
    <div class="vertical-token-dash"></div>
  </div>
  <div class="integration-token-icon-container">
    <div class="integration-token-icon integration-token-icon-add p-d-flex p-jc-center p-ai-center">
      <span class="integration-token-icon-text renta-font-normal">+</span>
    </div>
  </div>
  <div class="integration-token-info p-d-flex p-flex-column">
    <span class="integration-token-title renta-font-normal">{{Title}}</span>
  </div>
  <div class="integration-token-btn">
  </div>
  <div class="integration-token-select-icon">
    <i class="pi pi-fw pi-chevron-right"></i>
  </div>
</div>
