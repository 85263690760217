import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-calltouch-auth',
  templateUrl: './calltouch-auth.component.html',
  encapsulation: ViewEncapsulation.None,
  })
export class CalltouchAuthComponent {
  @Input()
  public error: string;

  @Input()
  public siteId: string;

  @Input()
  public counterId: string;

  @Input()
  public accessToken: string;

  @Input()
  public loading: boolean;
}
