import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-esputnik',
  templateUrl: './esputnik-auth.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class EsputnikAuthComponent implements OnInit {
  @Input()
  public error: string;

  @Input()
  public email: string;

  @Input()
  public password: string;

  @Input()
  public loading: boolean;

  constructor() { }

  public ngOnInit(): void {
  }
}
