import { DataSourceBase } from './DataSourceBase';
import { IntegrationSelectionOption } from '../../../../models/Integration/IntegrationSelectionOption';

export class RentaSelectionOptionDataSource extends DataSourceBase<IntegrationSelectionOption> {
  public name: string;

  constructor(name: string, ds: IntegrationSelectionOption) {
    super(ds);
    this.name = name;
  }
}
