import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { RentaChipsDataSource } from '../../models/DataSource/RentaChipsDataSource';
import { Observable } from 'rxjs';
import { ChipsModel } from '../../models/ChipsModel';
import { SortableOptions } from 'sortablejs';

@Component({
  selector: 'app-renta-chips',
  templateUrl: './renta-chips.component.html',
  styleUrls: ['../internal/base-settings-styles.css', './renta-chips.component.css'],
})
export class RentaChipsComponent implements OnInit {
  public sortableOptions: SortableOptions;

  @Input()
  public DataSource: RentaChipsDataSource;

  @Output()
  public OnDeleteChip: EventEmitter<ChipsModel> = new EventEmitter<ChipsModel>();

  public ds: Observable<Array<ChipsModel>> = null;

  constructor() {}

  public ngOnInit(): void {
    this.ds = this.DataSource.connect();
    this.sortableOptions = {
      onUpdate: (event: any): void => {
        this.DataSource.sort(event.oldIndex, event.newIndex);
      },
    };
  }

  public getClass(parameterType: string): string {
    switch (parameterType) {
      case 'Dimensions':
        return 'dimension-background';
      // FEATURE 655
      case 'Goals':
      case 'Metrics':
        return 'metric-background';
      default:
        return 'default-background';
    }
  }

  public closeChip(chip: ChipsModel): void {
    this.OnDeleteChip.emit(chip);
    this.DataSource.RemoveChip(chip);
  }
}
