import { BehaviorSubject, Observable } from 'rxjs';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import * as _ from 'lodash';
import { RentaDropdownDataSource } from './RentaDropdownDataSource';

export class ColumnMappingDataSource {
  private sourceData: Array<ListItemModel>;
  private dataSource: BehaviorSubject<Array<ListItemModel>> = new BehaviorSubject<Array<ListItemModel>>(null);

  constructor(dataSource: Array<ListItemModel>) {
    this.sourceData = dataSource;
  }

  public connect(): Observable<Array<ListItemModel>> {
    this.updateDataSource();
    return this.dataSource.asObservable();
  }

  public updateItem(item: ListItemModel): void {
    const indexToUpdate = this.sourceData.findIndex((f: ListItemModel): boolean => f.id === item.id);
    if (indexToUpdate === -1) {
      return;
    }
    this.sourceData[indexToUpdate].type = item.type;
    this.updateDataSource();
  }

  public refresh(data: Array<ListItemModel> = null): void {
    const newData = [];
    for (let i = 0; i < data.length; i++) {
      let existing = this.sourceData.find((f: ListItemModel): boolean => f.id === data[i].id);
      if (_.isNil(existing)) {
        const types = this.getTypes('string');
        data[i].additionalData = new RentaDropdownDataSource('', types);
        data[i].type = types.find((f: ListItemModel): boolean => f.selected).id;
        existing = data[i];
      }
      newData.push(existing);
    }
    this.sourceData = newData;
    this.updateDataSource();
  }

  public getTypes(selectedType: string): Array<ListItemModel> {
    const types = [
      { id: 'string', name: 'string', selected: false },
      { id: 'integer', name: 'number', selected: false },
      { id: 'float', name: 'float', selected: false },
      { id: 'date', name: 'date', selected: false },
      { id: 'datetime', name: 'datetime', selected: false },
    ];
    types.forEach((f: ListItemModel): void => {
      f.selected = f.id === selectedType;
    });
    return types;
  }

  public deleteItem(item: ListItemModel): void {
    const indexToDelete = this.sourceData.findIndex((f: ListItemModel): boolean => f.id === item.id);
    if (indexToDelete === -1) {
      return;
    }

    this.sourceData.splice(indexToDelete, 1);
    this.updateDataSource();
  }

  public sort(): void {
    this.updateDataSource();
  }

  private updateDataSource(): void {
    this.dataSource.next(this.sourceData);
  }
}
