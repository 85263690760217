<div class="p-d-flex p-justify-center p-col create-integration-container"
     [style.overflow-y]="overflow">
  <div class="create-integration-wrapper p-d-flex p-dir-col p-align-center">
    <div class="p-d-flex p-dir-col create-integration-stepper">
      <span class="ap-ta-center create-source-title renta-font-big" *ngIf="activeStep === 0">Choose the data source to create a new pipeline</span>
      <span class="ap-ta-center create-source-title renta-font-big" *ngIf="activeStep === 1">Select a destination to sync with {{(sourceType | async)?.title || '' }}</span>
      <span class="ap-ta-center create-source-title renta-font-big" *ngIf="activeStep > 1">Create a new {{(sourceType | async)?.title || '' }} data pipeline</span>
      <div class="steps-container"
           [@animSlider]="activeStep">

        <div class="steps-wrapper">
          <app-stepper [StepItems]="steps"
                       [Behavior]="2"
                       [@disableAnim]
                       [selectedIndex]="activeStep"></app-stepper>
        </div>
      </div>
    </div>
    <div class="preloader-container" *ngIf="dataSourceLoading">
      <i class="drop-down-icon pi pi-spin pi-spinner"></i>
    </div>
    <div class="create-integration-step-view-container"
         [hidden]="dataSourceLoading"
         [@animSlider]="activeStep">
      <div *ngIf="activeStep === 0" class="step-wrapper">
        <ng-container *ngTemplateOutlet="searchInputBlock"></ng-container>
        <app-integration-source [search]="searchInput"
                                (OnDataLoading)="onDataLoading($event)"
                                (OnSelectToken)="onSelectSourceToken($event)"></app-integration-source>
      </div>

      <div *ngIf="activeStep === 1" class="step-wrapper">
        <ng-container *ngTemplateOutlet="searchInputBlock"></ng-container>
        <div *ngIf='sourceType | async'>
          <app-integration-destination [search]="searchInput"
                                       [sourceType]='sourceType'
                                       (OnDataLoading)="onDataLoading($event)"
                                       (OnSelectToken)="onSelectDestinationToken($event)"></app-integration-destination>
        </div>
      </div>
      <div *ngIf="activeStep === 2" class="step-wrapper">
        <app-create-integration-settings></app-create-integration-settings>
      </div>
    </div>
    </div>
  </div>
<ng-template #searchInputBlock>
  <div class="search-input">
      <span class="p-input-icon-left">
           <i class="renta-ic search-icon"
              [ngClass]="focusInput || hoverInput ? 'renta-ic-search-active' : 'renta-ic-search'"></i>
        <label for="integration-search"></label>
           <input #searchElement id="integration-search" class="integration-search-input"
                  type="text" pInputText
                  [(ngModel)]="searchInput"
                  placeholder="Search"
                  (mouseenter)="hoverInput = true"
                  (mouseleave)="hoverInput = false"
                  (focusin)="focusInput = true"
                  (focusout)="focusInput = false"/>
        </span>
  </div>
</ng-template>
