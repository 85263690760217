import { Injectable } from '@angular/core';
import { IntegrationSettings, IntegrationSettingsBase } from '../../../models/Integration/IntegrationSettings';
import { IntegrationTypeEnum } from '../../../models/common/IntegrationTypeEnum';
import { Observable, of } from 'rxjs';
import { ErrorMessage } from '../../../models/common/ErrorMessage';
import { IntegrationInfoViewModel } from '../../../models/viewModels/CreateIntegrationViewModel';
import { IntegrationToken } from '../../../models/Integration/IntegrationToken';
import { RentaApiService } from '../../../services/renta-api.service';
import { delay, first, map, switchMap } from 'rxjs/operators';
import { ApiResponse } from '../../../models/common/ApiResponse`T';
import { RentaModalsService } from '../../shared/services/renta-modals.service';
import { isNullOrEmpty } from '../../../helpers/helpers';
import { IntegrationSourceModel } from '../../../models/Integration/IntegrationSourceModel';

@Injectable()
export class IntegrationService {
  protected integrationInfoViewModel: IntegrationInfoViewModel = new IntegrationInfoViewModel();
  private isDuplicate: boolean = false;

  constructor(public readonly rentaApiService: RentaApiService, public readonly modalDialogService: RentaModalsService) {
  }

  public saveIntegration(integrationSettings: IntegrationSettings, settingsName: string, withStart: boolean = true): Observable<Array<ErrorMessage>> {
    const body = this.getBody(integrationSettings, settingsName);
    return this.rentaApiService.saveIntegration(this.integrationInfoViewModel.integrationSourceType.integrationType, body).pipe(
      delay(500), switchMap<ApiResponse<{
        integrationId: string
      }>, Observable<Array<ErrorMessage>>>((saveResult: ApiResponse<{
        integrationId: string
      }>): Observable<Array<ErrorMessage>> => {
        if (saveResult.baseResponse.status === false) {
          return of<Array<ErrorMessage>>(this.transformSettingsErrors(saveResult.baseResponse.errors));
        }

        if (!withStart) {
          return of<Array<ErrorMessage>>(null);
        }

        return this.modalDialogService
          .showCreateIntegrationDialog(this.integrationInfoViewModel.integrationSourceType, this.getActionType(), saveResult.baseResponse.status)
          .onClose.pipe(first(), map<boolean, Array<ErrorMessage>>((closeModalInfoResult: boolean): Array<ErrorMessage> => {
            if (!closeModalInfoResult) {
              return this.transformSettingsErrors(saveResult.baseResponse.errors);
            }
            return null;
          }));

        // todo: move it
        // this.createIntegrationInfo = new CreateIntegrationViewModel();
        // sessionStorage.setItem('createIntegrationInfo', JSON.stringify(this.createIntegrationInfo));
      }));
  }

  public getIntegrationInfo(): IntegrationInfoViewModel {
    return this.integrationInfoViewModel;
  }

  public setDestination(integrationType: IntegrationSourceModel, integrationToken: string, integrationName: string): void {
    this.integrationInfoViewModel.integrationDestinationType = integrationType;
    this.integrationInfoViewModel.integrationDestinationToken = integrationToken;
    this.integrationInfoViewModel.integrationDestinationName = integrationName;
  }

  public setSource(integrationType: IntegrationSourceModel, integrationToken: string, integrationName: string): void {
    this.integrationInfoViewModel.integrationSourceType = integrationType;
    this.integrationInfoViewModel.integrationSourceToken = integrationToken;
    this.integrationInfoViewModel.integrationSourceName = integrationName;
  }

  public setIntegrationId(integrationId: string, isDuplicate: boolean): void {
    this.integrationInfoViewModel.integrationId = integrationId;
    this.isDuplicate = isDuplicate;
  }

  public getIntegrationTokens(integrationType: IntegrationTypeEnum): Observable<Array<IntegrationToken>> {
    return this.rentaApiService.getIntegrationTokens(integrationType);
  }

  public getAvailableIntegrationDestinations(sourceType: IntegrationTypeEnum): Observable<Array<IntegrationSourceModel>> {
    return this.rentaApiService.getAvailableIntegrationDestinations(sourceType);
  }

  private getBody(integrationSettings: IntegrationSettings, settingsName: string): {} {
    const copy = {};
    const prefix = 'selected';
    for (const prop of Object.keys(new IntegrationSettingsBase())) {
      copy[prefix + prop] = integrationSettings[prop];
    }

    for (const prop of Object.keys(integrationSettings[settingsName])) {
      copy[prefix + prop] = integrationSettings[settingsName][prop];
    }

    const integrationData = Object.assign({}, copy);
    const integrationId = this.isDuplicate ? undefined : this.integrationInfoViewModel.integrationId;

    const sourceTokenId = this.integrationInfoViewModel.integrationSourceToken;
    const destinationTokenId = this.integrationInfoViewModel.integrationDestinationToken;
    const sourceTokenType = this.integrationInfoViewModel.integrationSourceType.integrationType;
    const destinationTokenType = this.integrationInfoViewModel.integrationDestinationType.integrationType;

    return {
      integrationId,
      integrationData,
      sourceTokenId,
      destinationTokenId,
      sourceTokenType,
      destinationTokenType
    };
  }

  private transformSettingsErrors(errors: Array<ErrorMessage>): Array<ErrorMessage> {
    const result = new Array<ErrorMessage>();

    for (const error of errors) {
      const newErrorName = error.message.replace(/selected/ig, '');
      const existingError = result.find((f: ErrorMessage): boolean => f.message === newErrorName);

      if (existingError !== null && existingError !== undefined) {
        existingError.userMessage += ', ' + error.userMessage;
      } else {
        result.push({ message: newErrorName, userMessage: error.userMessage });
      }
    }

    return result;
  }

  private getActionType(): string {
    if (this.isDuplicate) {
      return 'duplicated';
    }

    if (isNullOrEmpty(this.integrationInfoViewModel.integrationId)) {
      return 'created';
    }

    if (!isNullOrEmpty(this.integrationInfoViewModel.integrationId)) {
      return 'updated';
    }

    return 'saved';
  }
}
