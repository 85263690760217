import { Injectable } from '@angular/core';
import { StorageService } from "./storage.service";
import { UserInfo } from "../models/user/UserInfo";

@Injectable({
  providedIn: 'root',
})
export class UserDataIntercomService {
  private readonly userData: { email: string, userId: string } = { email: '', userId: '' };
  private readonly userInfo: UserInfo;

  constructor(private readonly storageService: StorageService) {
    try {
      this.userInfo = this.storageService.localGetItem("userInfo");
      if (this.userInfo) {
        const { userEmail, userId } = this.userInfo;

        if ((window as any).dataLayer) {
          (window as any).dataLayer.push({
            email: userEmail,
            userId,
          });
        } else {
          console.warn('dataLayer is not defined');
        }
      }
    } catch (error) {
      console.error('Error getting user info:', error);
    }
  }

  public setUserData(email: string, userId: string): void {
    this.userData.email = email;
    this.userData.userId = userId;

    if ((window as any).dataLayer) {
      (window as any).dataLayer.push(this.userData);
    } else {
      console.warn('dataLayer is not defined');
    }
  }

  private updateIntercom(email: string, userId: string): void {
    if ((window as any).Intercom) {
      (window as any).Intercom('update', {
        email,
        user_id: userId
      });
    } else {
      console.warn('Intercom is not available on the window object.');
    }
  }
}
