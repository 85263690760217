import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RentaPlaceholderInputDataSource } from '../../models/DataSource/RentaPlaceholderInputDataSource';
import { Observable } from 'rxjs';
import { PlaceholderInputDataSourceModel } from '../../models/DataSource/DataSourceModels/PlaceholderInputDataSourceModel';

@Component({
  selector: 'app-renta-placeholder-input',
  templateUrl: './renta-placeholder-input.component.html',
  styleUrls: ['./renta-placeholder-input.component.scss'],
})
export class RentaPlaceholderInputComponent implements OnInit {
  @Input()
  public RentaPlaceholderInputSource: RentaPlaceholderInputDataSource;

  @Output()
  public OnSave: EventEmitter<string> = new EventEmitter<string>();

  public ds: Observable<PlaceholderInputDataSourceModel>;

  constructor() {}

  public ngOnInit(): void {
    this.ds = this.RentaPlaceholderInputSource.connect();
  }

  public editInput(): void {
    this.RentaPlaceholderInputSource.setEditMode(true);
  }

  public edit(event: string): void {
    this.OnSave.emit(event);
  }

  public cancelEdit(): void {
    this.RentaPlaceholderInputSource.setEditMode(false);
  }
}
