import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { PaymentCardFormModel } from '../../../../models/PaymentCardFormModel';
import { StripeService } from 'ngx-stripe';
import { ProfileService } from '../../../../../../services/profile.service';
import { StripeError, Token } from '@stripe/stripe-js';
import { first } from 'rxjs/operators';
import { BillingService } from '../../../../../manage/modules/billing/services/billing.service';

@Component({
  selector: 'app-payment-card-modal',
  templateUrl: './payment-card-modal.component.html',
  styleUrls: ['./payment-card-modal.component.scss'],
})
export class PaymentCardModalComponent implements OnInit {
  public disableSubmit: boolean = true;
  public loading: boolean = false;
  public error: string;
  private paymentCard: PaymentCardFormModel;
  private email: string;

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly stripeService: StripeService,
    private readonly profileService: ProfileService,
    private readonly billingService: BillingService
  ) {}

  public ngOnInit(): void {
    this.profileService
      .getEmail()
      .pipe(first())
      .subscribe((email: string): void => {
        this.email = email;
      });
  }

  public onFormChange($event: PaymentCardFormModel): void {
    this.paymentCard = $event;
    this.disableSubmit = !$event.isValid;
  }

  public onCancel(): void {
    if (this.loading) {
      return;
    }
    this.ref.close(null);
  }

  public onConfirm(): void {
    if (this.loading || this.disableSubmit) {
      return;
    }
    this.loading = true;
    const { country, city, line1, postal_code, unit, companyName } = this.paymentCard;

    this.stripeService
      .createToken(this.paymentCard.stripeCardElement, {
        address_line1: line1,
        address_zip: postal_code,
        address_city: city,
        address_country: country,
        name: this.email,
      })
      .subscribe((result: { token?: Token; error?: StripeError }): void => {
        if (result.token) {
          this.billingService.updateInvoicesData({country, city, zip: postal_code, unit, address: line1, companyName })
            .subscribe(__ => this.ref.close(result.token));
        } else if (result.error) {
          this.error = result.error.message;
          this.loading = false;
        }
      });
  }
}
