<div class="renta-dialog-block-container">
  <div class="progress-bar-container" *ngIf="loading">
    <div class="progress-bar">
      <p-progressBar mode="indeterminate" styleClass="auth-progress"></p-progressBar>
    </div>
  </div>
  <div class="dialog-header-block p-d-flex p-flex-column p-align-center">
    <span class="title">Verify Password</span>
    <span class="renta-font-normal">For your security, please enter your password for {{ userEmail }}</span>
    <app-renta-error-message [errorMessage]="errorMsg" *ngIf="errorMsg"></app-renta-error-message>
  </div>
  <div class="dialog-content-block">
    <div class="verify-password-content-dialog">
      <input
        class="renta-input-base"
        type="password"
        placeholder="Your current password"
        (keyup)="passwordsChangeHandler()"
        [(ngModel)]="currentPassword"
        [disabled]="loading"
      />
      <input
        class="renta-input-base"
        type="password"
        placeholder="Your new password"
        (keyup)="passwordsChangeHandler()"
        [(ngModel)]="newPassword"
        [disabled]="loading"
      />
      <input
        class="renta-input-base"
        type="password"
        placeholder="Confirm your new password"
        (keyup)="passwordsChangeHandler()"
        [(ngModel)]="confirmNewPassword"
        [disabled]="loading"
      />
    </div>
  </div>
  <div class="dialog-footer-block">
    <div class="btn-container p-d-flex p-align-center p-justify-end">
      <button
        pButton
        pRipple
        type="button"
        [disabled]="loading"
        label="cancel"
        (click)="cancelPasswordDialog()"
        class="p-button-secondary"
      ></button>
      <button pButton pRipple type="button" [disabled]="disabledBtn || loading" label="confirm" (click)="savePasswordDialog()"></button>
    </div>
  </div>
</div>
