<div class="setting-row-wrapper p-m-0">
  <app-renta-dropdown
    *ngIf="isLoaded; else loadingSetting"
    [RentaDropdownSource]="AccountDataSource"
    [class.disabled]="isDataSourceLoading"
    [toolTipInfo]="'An account is your access point for Analytics.'"
    (OnApply)="onApplyAccount($event)"></app-renta-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-dropdown
    *ngIf="isLoaded; else loadingSetting"
    [RentaDropdownSource]="PropertyDataSource"
    [class.disabled]="isDataSourceLoading"
    [toolTipInfo]="'A property is a website, mobile application, or device.'"
    (OnApply)="onApplyProperty($event)"></app-renta-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-chips
    *ngIf="isLoaded; else loadingSetting"
    [DataSource]="chipsParametersDataSource"
    (OnDeleteChip)="onCloseChip($event)"></app-renta-chips>
</div>
<div class="setting-row-wrapper">
  <app-renta-multiselect-dropdown
    *ngIf="isLoaded; else loadingSetting"
    (OnApply)="onApplyParameters($event)"
    [RentaMultiselectDropdownSource]="ParametersDataSource"
    [class.disabled]="isDataSourceLoading"
    [additionalInfo]="'Specify the parameters required for uploading.'"
    [toolTipInfo]="'Parameters contains the list of parameters and metrics available for export.'"
    [hotRefresh]="true">
  </app-renta-multiselect-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-setting-filters
    *ngIf="isLoaded; else loadingSetting"
    (OnApply)="onApplyFilters($event)"
    [RentaSettingsFiltersSource]="FiltersDataSource"
    [class.disabled]="isDataSourceLoading">
  </app-renta-setting-filters>
</div>

<ng-template #loadingSetting>
  <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
  <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
</ng-template>
