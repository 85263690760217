import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { RentaSelectionOptionDataSource } from '../../models/DataSource/RentaSelectionOptionDataSource';
import { Observable } from 'rxjs';
import { IntegrationSelectionOption, SelectionOption } from '../../../../models/Integration/IntegrationSelectionOption';

@Component({
  selector: 'app-renta-selection',
  templateUrl: './renta-selection.component.html',
  styleUrls: ['../internal/base-settings-styles.css', './renta-selection.component.css'],
})
export class RentaSelectionComponent implements OnInit {
  @Input()
  public RentaSelectionOptionDataSource: RentaSelectionOptionDataSource;

  @Input()
  public toolTipInfo: string;
  @Input()
  public additionalInfo: string;

  @Output()
  public OnApply: EventEmitter<SelectionOption> = new EventEmitter<SelectionOption>();

  public selectionDataSourceModel: Observable<IntegrationSelectionOption>;

  constructor() {}

  public ngOnInit(): void {
    this.selectionDataSourceModel = this.RentaSelectionOptionDataSource.connect();
    this.selectionDataSourceModel.subscribe((res: IntegrationSelectionOption): void =>
      this.OnApply.emit(res.values.find((f: SelectionOption): boolean => f.value === res.selected))
    );
  }

  public onChange($event: any): void {
    this.OnApply.emit($event.option);
  }
}
