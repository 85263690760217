<div class='setting-row-wrapper p-m-0'>
  <app-renta-chips
    *ngIf='isLoaded'
    [DataSource]='chipsProfilesDataSource'
    [class.disabled]='isDataSourceLoading'
    (OnDeleteChip)='onCloseProfilesChip($event)'
  ></app-renta-chips>
</div>
<div class='setting-row-wrapper'>
  <app-renta-multiselect-dropdown
    *ngIf='isLoaded; else loadingSetting'
    (OnApply)='onApplyProfiles($event)'
    [RentaMultiselectDropdownSource]='ProfilesDataSource'
    [class.disabled]='isDataSourceLoading'
    [hotRefresh]='true'>
  </app-renta-multiselect-dropdown>
</div>
<div class='setting-row-wrapper'>
  <app-renta-dropdown
    *ngIf='isLoaded; else loadingSetting'
    [RentaDropdownSource]='ReportTypeDataSource'
    [class.disabled]='isDataSourceLoading'
    (OnApply)='onApplyReportType($event)'
  ></app-renta-dropdown>
</div>
<div class='setting-row-wrapper'>
  <app-renta-dropdown
    *ngIf='isLoaded; else loadingSetting'
    [RentaDropdownSource]='SourceTypeDataSource'
    [class.disabled]='isDataSourceLoading'
    (OnApply)='onApplySourceType($event)'
  ></app-renta-dropdown>
</div>
<div *ngIf='showPosts'>
  <div class='setting-row-wrapper'>
    <app-renta-chips
      *ngIf='isLoaded'
      [DataSource]='chipsPostsDataSource'
      [class.disabled]='isDataSourceLoading'
      (OnDeleteChip)='onClosePostsChip($event)'
    ></app-renta-chips>
  </div>
  <div class='setting-row-wrapper'>
    <app-renta-multiselect-dropdown
      *ngIf='isLoaded; else loadingSetting'
      (OnApply)='onApplyPosts($event)'
      [RentaMultiselectDropdownSource]='PostsDataSource'
      [class.disabled]='isDataSourceLoading'
      [hotRefresh]='true'>
    </app-renta-multiselect-dropdown>
  </div>
</div>
<div class='setting-row-wrapper'>
  <app-renta-chips
    *ngIf='isLoaded'
    [DataSource]='chipsParametersDataSource'
    [class.disabled]='isDataSourceLoading'
    (OnDeleteChip)='onCloseChip($event)'
  ></app-renta-chips>
</div>
<div class='setting-row-wrapper'>
  <app-renta-multiselect-dropdown
    *ngIf='isLoaded; else loadingSetting'
    (OnApply)='onApplyParameters($event)'
    [RentaMultiselectDropdownSource]='ParametersDataSource'
    [class.disabled]='isDataSourceLoading'
    [hotRefresh]='true'>
  </app-renta-multiselect-dropdown>
</div>


<ng-template #loadingSetting>
  <p-skeleton styleClass='p-mb-2' width='120px' height='24px' borderRadius='8px'></p-skeleton>
  <p-skeleton styleClass='p-mb-3' height='37px' borderRadius='8px'></p-skeleton>
</ng-template>
