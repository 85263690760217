import { BehaviorSubject, Observable } from 'rxjs';
import { RentaKeyValueRowDataSource } from './RentaKeyValueRowDataSource';

export class KeyValueCollectionDataSource {
  private sourceData: Array<{key: string, value: string}>;
  private dataSource: BehaviorSubject<Array<RentaKeyValueRowDataSource>> = new BehaviorSubject([]);

  constructor(private ds: Array<{key: string, value: string}>){
    this.sourceData = ds;
  }
  public getCollection(): Array<{key: string, value: string}> {
    return this.dataSource.getValue().map((item: RentaKeyValueRowDataSource): {key: string, value: string} => {
      return {key: item.KeyDataSource.getValue(), value: item.ValueDataSource.getValue() }
    });
  }

  public connect(): Observable<Array<RentaKeyValueRowDataSource>> {
    return this.dataSource.asObservable();
  }

  public addKeyValueRow(row: {key: string, value: string}): void {
    const ds = this.dataSource.getValue();
    ds.push(new RentaKeyValueRowDataSource(row.key, row.value));
    this.dataSource.next(ds);
  }


  public addEmptyKeyValueRow(): void {
    const ds = this.dataSource.getValue();
    ds.push(new RentaKeyValueRowDataSource('', ''));
    this.dataSource.next(ds);
  }

  public removeKeyValueRow(index: number): void {
    const ds = this.dataSource.getValue();
    if (ds.length > index) {
      ds.splice(index, 1);
    }
    this.dataSource.next(ds);
  }

  public refresh(ds: Array<{key: string, value: string}>): void  {
    if (ds !== null){
      this.sourceData = ds;
    }

    this.dataSource.next(this.sourceData.map((item: {key: string, value: string}): RentaKeyValueRowDataSource => new RentaKeyValueRowDataSource(item.key, item.value)));
  }
}
