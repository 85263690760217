<p-progressBar mode="indeterminate" styleClass="change-card-progress" *ngIf="loading"></p-progressBar>
<div class="payment-modal-wrapper">
  <div class="p-d-flex p-dir-col width-max">
    <span class="renta-font-big p-text-bold p-mb-1 p-text-center">Payment Details</span>
    <span class="renta-font-normal renta-subtext-color p-text-center">Here you can change your payment details</span>
  </div>
  <div class="p-my-4">
    <app-renta-error-message [errorMessage]="error" *ngIf="error"></app-renta-error-message>
    <app-renta-payment-card [disableInputs]="loading" (OnFormChange)="onFormChange($event)"></app-renta-payment-card>
  </div>
  <div class="p-mt-1 p-d-flex width-max p-jc-center">
    <p-button styleClass="card-btn cancel" (click)="onCancel()" label="cancel" [disabled]="loading"></p-button>
    <p-button styleClass="card-btn confirm" (click)="onConfirm()" label="Confirm" [disabled]="disableSubmit || loading"></p-button>
  </div>
</div>
