import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';

import { RentaApiService } from '../../../../services/renta-api.service';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { AmoCrmMeta } from '../../../../models/Integration/IntegrationsMeta/AmoCrmMeta';
import { mergeMap } from 'rxjs/operators';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { EtlSettingsServiceBase } from './EtlSettingsServiceBase';

@Injectable()
export class AmoCrmSettingsService extends EtlSettingsServiceBase<AmoCrmMeta> {
  protected tablePrefix: string = 'amoCRM';
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.amocrm;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      ReportTypes: null,
      Parameters: null
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  constructor(
    public integrationInfoData: IntegrationInfoViewModel,
    protected readonly rentaApiService: RentaApiService,
    protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.memoryCacheKey = `tablePrefix-${IntegrationTypeEnum.amocrm}`;
  }

  public isShowDateRange(): boolean {
    return false;
  }

  public setReportType(selectedReportType: ListItemModel): boolean {
    if (_.isNil(selectedReportType) || _.some(this.selectedSettings.ReportTypes, selectedReportType)) {
      return false;
    }

    this.selectedSettings.ReportTypes = [selectedReportType];
    return true;
  }

  public getParameters(selectedReportType: ListItemModel): Observable<Array<ListItemModel>> {
    const reportType: string = _.isNil(selectedReportType) ? _.first(this.selectedSettings.ReportTypes)?.id : selectedReportType.id;

    if (_.isNil(reportType)) {
      return of<[]>([]);
    }

    const url: string = this.meta.parametersUrl.replace('{reportTypeId}', reportType);
    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };

    return this.getCurrentParameters(url, body);
  }

  protected mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: response.dateRange || [],
      Date: response.date || null,
      Schedule: response.schedule || [],
      OverwritePeriod: response.overwritePeriod || [],
      [this.integrationTypeEnum]: {
        ReportTypes: response.reportTypes || [],
        Parameters: response.parameters || []
      },
      Name: response.name || defaultIntegrationName,
      TableName: response.tableName || defaultTableName
    };
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [];
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings, isRefresh: boolean = false): void {
    const isRefreshSettings = this.editMode && isRefresh;
    this.selectedIntegrationSettings.Id = initialSettings.Id;

    this.selectedSettings.ReportTypes = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0])
      ? initialSettings[this.integrationTypeEnum].ReportTypes.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].ReportTypes.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );

    this.initSelectedCommonSettings(initialSettings, isRefreshSettings);
  }

  protected refreshParameters(): Observable<any> {
    return this.rentaApiService.initIntegration(this.getWizardRequestData())
      .pipe(
        mergeMap((res: any): Observable<IntegrationSettings> => {
          this.meta = res.meta;
          res = this.mapIntegrationSettings(res, this.selectedIntegrationSettings.TableName, this.selectedIntegrationSettings.Name);
          const selectedReportTypes = this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0] ||
            res[this.integrationTypeEnum].ReportTypes.find((f: ListItemModel): boolean => f.selected);

          return (_.isNil(selectedReportTypes) || _.isNil(res[this.integrationTypeEnum].ReportTypes.find((f: ListItemModel): boolean => f.id === selectedReportTypes.id)))
            ? of(res)
            : this.getParameters(selectedReportTypes).pipe(
              mergeMap((params: Array<ListItemModel>): Observable<IntegrationSettings> => {
                res[this.integrationTypeEnum].Parameters = params;
                this.compareParametersWithCacheData(params);
                return of(res);
              })
            );
        })
      );
  }
}
