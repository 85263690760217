import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';
import { FilesSettingsService } from '../../../../services/integrationSettings/files-settings.service';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { RentaMultiselectDropdownDataSource } from '../../../../../shared/models/DataSource/RentaMultiselectDropdownDataSource';
import * as _ from 'lodash';
import { ColumnMappingDataSource } from '../../../../../shared/models/DataSource/ColumnMappingDataSource';

@Component({
  selector: 'app-files-settings',
  templateUrl: './files-settings.component.html',
  styleUrls: ['../common-settings.scss'],
})
export class FilesSettingsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;
  public ParametersDataSource: RentaMultiselectDropdownDataSource;
  public columnsDataSource: ColumnMappingDataSource;

  constructor(private settingsService: FilesSettingsService) {
    super(IntegrationTypeEnum.file);
  }

  public ngOnInit(): void {
    this.ParametersDataSource = new RentaMultiselectDropdownDataSource('Parameters', []);
    this.columnsDataSource = new ColumnMappingDataSource([]);
    this.columnsDataSource.connect().subscribe((res: Array<ListItemModel>): void => {
      this.settingsService.setSelectedParameters(
        res.map((item: ListItemModel): ListItemModel => {
          return { id: item.id, name: item.name, type: item.type };
        })
      );
    });

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.clearErrors.subscribe((__: Array<ErrorMessage>): void => {
      this.clearErrors();
    });

    this.settingsService.connectToLoading().subscribe((res: boolean): void => {
      this.isLoaded = !res;
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
      this.columnsDataSource.refresh(res[this.integrationType].Parameters.filter((f: ListItemModel): boolean => f.selected));
      });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onApplyParameters($event: Array<ListItemModel>): void {
    this.columnsDataSource.refresh($event);
  }

  public onDeleteSelectedColumn(e: ListItemModel): void {
    this.ParametersDataSource.applyUncheck(e);
  }
}
