<div
  id="extendedListItem"
  class="p-d-flex p-py-3 p-px-2 extended-list-item-wrapper"
  [class.selected-item]="ListItem.selected && Multi !== true"
  title="{{ ListItem.description }}"
  (click)="listItemClick($event)"
>
  <p-checkbox
    *ngIf="Multi === true"
    name="groupname"
    (onChange)="onChangeListItem($event)"
    [ngModel]="ListItem.selected"
    [binary]="true"
    class="list-item-checkbox"
  ></p-checkbox>
  <div class="list-item-text-wrapper p-d-flex p-flex-column">
    <span class="renta-font-small renta-text-color">{{ ListItem.name }}</span>
    <div *ngIf="Extended" class="additional-info p-d-flex p-flex-column">
      <span *ngFor="let info of ListItem.additionalInfo" class="renta-font-subtext renta-placeholder-color">{{ info }}</span>
    </div>
  </div>
</div>
