import { IntegrationSourceModel } from '../Integration/IntegrationSourceModel';

export class IntegrationInfoViewModel {
  public integrationId: string;
  public integrationSourceType: IntegrationSourceModel = null;
  public integrationDestinationType: IntegrationSourceModel = null;
  public integrationSourceToken: string = null;
  public integrationDestinationToken: string = null;
  public integrationSourceName: string = null;
  public integrationDestinationName: string = null;
}

export class CreateIntegrationViewModel extends IntegrationInfoViewModel {
  public settings: {} = null;
}
