import { Injectable } from '@angular/core';
import { SettingsServiceBase } from './SettingsServiceBase';
import { WarehouseAddonMeta } from '../../../../models/Integration/IntegrationsMeta/WarehouseAddonMeta';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { RentaApiService } from '../../../../services/renta-api.service';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { Observable, of } from 'rxjs';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { WebhookAddonMeta } from '../../../../models/Integration/IntegrationsMeta/WebhookAddonMeta';
import { filter, map } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable()
export class WarehouseAddonSettingsService extends SettingsServiceBase<WarehouseAddonMeta> {
  public meta: WebhookAddonMeta;
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.warehouseAddon;

  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      HitsTableName: null,
      SessionsTableName: null,
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };
  private useSessionsTable: boolean;
  constructor(
    public integrationInfoData: IntegrationInfoViewModel,
    protected readonly rentaApiService: RentaApiService,
    protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService)
  }

  public isETL(): boolean {
    return false;
  }

  public loadSettings(): Observable<IntegrationSettings> {
    if (this.loading.getValue() === false) {
      this.loading.next(true);
      this.rentaApiService.initIntegration(this.getWizardRequestData()).pipe(
        map<any, IntegrationSettings>((res: any): IntegrationSettings => {
          this.meta = res.meta;
          return this.mapIntegrationSettings(res, this.meta.integrationName || '');
        }),
      ).subscribe((res: IntegrationSettings): void => {
        this.initSelectedSettings(res);
        this.loading.next(false);
        this.sourceIntegrationSettings.next(res);
      });
    }

    return this.sourceIntegrationSettings.asObservable().pipe(filter((result: IntegrationSettings): boolean => result !== null));
  }

  public setHitsTableName(hitsTableName: string): void {
    this.selectedSettings.HitsTableName = hitsTableName;
  }

  public getSelectedSettings(): IntegrationSettings {
    if (!this.useSessionsTable) {
      this.selectedIntegrationSettings[this.integrationTypeEnum].SessionsTableName = null;
    }
    return this.selectedIntegrationSettings;
  }

  public setSessionsTableName(sessionsTableName: string): void {
    this.selectedSettings.SessionsTableName = sessionsTableName;
  }

  public setUseSessionsTable(val: boolean): void {
    this.useSessionsTable = val;
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].HitsTableName = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].SessionsTableName = null;
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings): void {
    this.selectedIntegrationSettings.Id = initialSettings.Id;
    this.selectedSettings.HitsTableName = initialSettings[this.integrationTypeEnum].HitsTableName;
    this.selectedSettings.SessionsTableName = initialSettings[this.integrationTypeEnum].SessionsTableName;
    this.useSessionsTable = _.some(this.selectedSettings.SessionsTableName);
    this.selectedIntegrationSettings.Name = initialSettings.Name;

    this.selectedIntegrationSettings.TableName = null;
    this.selectedIntegrationSettings.OverwritePeriod  = null;
    this.selectedIntegrationSettings.DateRange = null;
    this.selectedIntegrationSettings.Date = null;
    this.selectedIntegrationSettings.Schedule = null;
    this.selectedIntegrationSettings.DateRange = null;
  }

  protected mapIntegrationSettings(response: any, integrationName: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: [],
      Date: null,
      Schedule: [],
      OverwritePeriod: [],
      [this.integrationTypeEnum]: {
        HitsTableName: response.hitsTable || null,
        SessionsTableName: response.sessionsTable || null,
      },
      Name: integrationName,
      TableName: null
    };
  }

  protected refreshParameters(): Observable<any> {
    return of<{}>(null);
  }
}
