import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-iikocloud-auth',
  templateUrl: './iikocloud-auth.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class IikocloudAuthComponent {
  @Input()
  public error: string;

  @Input()
  public sourceName: string;

  @Input()
  public login: string;

  @Input()
  public loading: boolean;
}
