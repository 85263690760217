<ul class="step-container p-d-flex">
  <li class="step-item p-d-flex p-jc-center p-flex-lg-wrap p-col" *ngFor="let stepItem of StepItems; let i = index">
    <a class="step-presentation p-d-inline-flex p-flex-column p-ai-center" (click)="stepClick(i)"
       [class.selected-step]="stepItem.active"
       [class.disabled-step]="stepItem.disabled"
       [class.passed-step]="stepItem.stepPassed">
      <div class="step-number-style p-d-flex p-jc-center p-ai-center">
        <span class="step-number-font renta-font-small" *ngIf="!stepItem.stepPassed">{{i + 1}}</span>
        <i *ngIf="stepItem.stepPassed" class="renta-ic renta-ic-check"></i>
      </div>

      <span class="step-description"
            [class.selected-step-description]="stepItem.active"
            [class.disabled-step-description]="stepItem.disabled">{{stepItem.text}}</span>
    </a>
  </li>
</ul>
