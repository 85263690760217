import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { YandexDirectMeta } from '../../../../models/Integration/IntegrationsMeta/YandexDirectMeta';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { RentaApiService } from '../../../../services/renta-api.service';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { SelectionOption } from 'src/app/models/Integration/IntegrationSelectionOption';
import { mergeMap } from 'rxjs/operators';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { EtlSettingsServiceBase } from './EtlSettingsServiceBase';

@Injectable()
export class YandexDirectSettingsService extends EtlSettingsServiceBase<YandexDirectMeta> {
  protected tablePrefix: string = 'yd';
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.yandex_direct;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      ReportTypes: null,
      Clients: [],
      Parameters: null,
      IncludeVat: null,
      IncludeDiscount: null
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  constructor(public integrationInfoData: IntegrationInfoViewModel, protected readonly rentaApiService: RentaApiService,
              protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.memoryCacheKey = `tablePrefix-${IntegrationTypeEnum.yandex_direct}`;
  }

  public setSelectedClients(selectedClients: ListItemModel[]): boolean {
    if (_.isNil(selectedClients)) {
      return false;
    }

    if (selectedClients.length === 0) {
      this.selectedSettings.Clients = [];
      return true;
    }

    const clientsToAdd = selectedClients.filter(
      (obj: ListItemModel): boolean => this.selectedSettings.Clients.findIndex((i: ListItemModel): boolean => i.id === obj.id) === -1
    );

    if (clientsToAdd.length !== 0) {
      this.selectedSettings.Clients = [...this.selectedSettings.Clients, ...clientsToAdd];
      return true;
    }

    const clientsToRemove = this.selectedSettings.Clients.filter(
      (obj: ListItemModel): boolean => selectedClients.findIndex((i: ListItemModel): boolean => i.id === obj.id) === -1
    );

    if (clientsToRemove.length !== 0) {
      this.selectedSettings.Clients = [...selectedClients];
      return true;
    }

    return false;
  }

  public setReportType(selectedReportType: ListItemModel): boolean {
    if (_.isNil(selectedReportType) || _.some(this.selectedSettings.ReportTypes, selectedReportType)) {
      return false;
    }

    this.selectedSettings.ReportTypes = [selectedReportType];
    return true;
  }

  public getParameters(reportType: ListItemModel, clients: Array<ListItemModel>): Observable<Array<ListItemModel>> {
    const reportTypeId = _.isNil(reportType) ? _.first(this.selectedSettings.ReportTypes)?.id : reportType.id;

    let selectedClients = [];
    if (reportTypeId !== '8') {
      selectedClients = _.isNil(clients) ? this.selectedSettings.Clients || [] : clients;
    }

    if (_.isNil(reportTypeId) || (_.isNil(selectedClients) && reportTypeId !== '8')) {
      return of<[]>([]);
    }

    const url: string = this.meta.parametersUrl.replace('{reportTypeId}', reportTypeId);
    const body = {
      sourceTokenId: this.integrationInfoData.integrationSourceToken,
      selectedClients
    };

    return this.getCurrentParameters(url, body);
  }

  public setSelectedIncludeVat($event: SelectionOption): void {
    if (_.isNil($event)) {
      return;
    }

    this.selectedSettings.IncludeVat = $event.value;
  }

  public setSelectedIncludeDiscount($event: SelectionOption): void {
    if (_.isNil($event)) {
      return;
    }

    this.selectedSettings.IncludeDiscount = $event.value;
  }

  protected mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: response.dateRange || [],
      Date: response.date || null,
      Schedule: response.schedule || [],
      OverwritePeriod: response.overwritePeriod || [],
      [this.integrationTypeEnum]: {
        ReportTypes: response.reportTypes || [],
        Clients: response.clients || [],
        Parameters: response.parameters || [],
        IncludeVat: response.includeVat || null,
        IncludeDiscount: response.includeDiscount || null
      },
      Name: response.name || defaultIntegrationName,
      TableName: response.tableName || defaultTableName
    };
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].ReportType = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Clients = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].IncludeVat = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].IncludeDiscount = [];
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings, isRefresh: boolean = false): void {
    const isRefreshSettings = this.editMode && isRefresh;
    this.selectedIntegrationSettings.Id = initialSettings.Id;
    this.selectedSettings.ReportTypes = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0]) ?
      initialSettings[this.integrationTypeEnum].ReportTypes.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0].id === f.id) :
      initialSettings[this.integrationTypeEnum].ReportTypes.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Clients  = isRefreshSettings && _.some(this.selectedSettings.Clients)
      ? initialSettings[this.integrationTypeEnum].Clients.filter((f: ListItemModel): boolean => !_.isNil(this.selectedSettings.Clients.find((ff: ListItemModel): boolean => ff.id === f.id)))
      : initialSettings[this.integrationTypeEnum].Clients.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.IncludeDiscount = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].IncludeDiscount) ?
      this.selectedIntegrationSettings[this.integrationTypeEnum].IncludeDiscount.selected :
      initialSettings[this.integrationTypeEnum].IncludeDiscount.selected;

    this.selectedSettings.IncludeVat = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].IncludeVat) ?
      this.selectedIntegrationSettings[this.integrationTypeEnum].IncludeVat.selected :
      initialSettings[this.integrationTypeEnum].IncludeVat.selected;


    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );


    this.initSelectedCommonSettings(initialSettings, isRefreshSettings);
  }

  protected refreshParameters(): Observable<any> {
    return this.rentaApiService.initIntegration(this.getWizardRequestData())
      .pipe(
        mergeMap((res: any): Observable<IntegrationSettings> => {
          this.meta = res.meta;
          res = this.mapIntegrationSettings(res, this.selectedIntegrationSettings.TableName, this.selectedIntegrationSettings.Name);
          const selectedReportType = this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0] ||
            res[this.integrationTypeEnum].ReportTypes.find((f: ListItemModel): boolean => f.selected);

          const selectedClients = this.selectedIntegrationSettings[this.integrationTypeEnum].Clients ||
            res[this.integrationTypeEnum].Clients.find((f: ListItemModel): boolean => f.selected);

          return (_.isNil(selectedReportType) || _.isNil(res[this.integrationTypeEnum].ReportTypes.find((f: ListItemModel): boolean => f.id === selectedReportType.id))
            ? of(res)
            : this.getParameters(selectedReportType, selectedClients).pipe(
              mergeMap((params: Array<ListItemModel>): Observable<IntegrationSettings> => {
                res[this.integrationTypeEnum].Parameters = params;
                this.compareParametersWithCacheData(params);
                return of(res);
              })
            ));
        })
      );
  }
}
