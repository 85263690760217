import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { map, mergeMap, tap } from 'rxjs/operators';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { RentaApiService } from '../../../../services/renta-api.service';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { Dv360Meta } from '../../../../models/Integration/IntegrationsMeta/Dv360Meta';
import { ApiResponse } from '../../../../models/common/ApiResponse`T';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { EtlSettingsServiceBase } from './EtlSettingsServiceBase';

@Injectable()
export class Dv360SettingsService extends EtlSettingsServiceBase<Dv360Meta> {
  protected tablePrefix: string = 'dv360';
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.dv360;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      Account: null,
      Clients: null,
      Parameters: null,
      Filters: null
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  constructor(
    public integrationInfoData: IntegrationInfoViewModel,
    protected readonly rentaApiService: RentaApiService,
    protected readonly rentaModalsService: RentaModalsService
  ) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.memoryCacheKey = `tablePrefix-${IntegrationTypeEnum.dv360}`;
  }

  public getParameters(): Observable<Array<ListItemModel>> {
    const url: string = this.meta.parametersUrl;
    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };
    return this.getCurrentParameters(url, body);
  }

  public getClients(accountId: string): Observable<Array<ListItemModel>> {
    if (_.isNil(accountId)) {
      return of<[]>([]);
    }

    const url: string = this.meta.clientsUrl.replace('{accountId}', accountId);
    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };

    return this.rentaApiService.httpClient.post<ApiResponse<{ clients: Array<ListItemModel> }>>(url, body).pipe(
      tap((res: ApiResponse<{ clients: Array<ListItemModel> }>): void => console.log(res)),
      map((res: ApiResponse<{ clients: Array<ListItemModel> }>): Array<ListItemModel> => {
        const tmpClients = [];
        res.result.clients.forEach((item: ListItemModel): void => {
          const index = this.selectedSettings.Clients.findIndex((f: ListItemModel): boolean => f.id === item.id);
          if (index > -1) {
            item.selected = true;
            tmpClients.push(item);
          }
        });
        this.selectedSettings.Clients = tmpClients;
        return res.result.clients;
      })
    );
  }

  public setAccount(selectedAccount: ListItemModel): boolean {
    if (_.isNil(selectedAccount) || _.some(this.selectedSettings.Account, selectedAccount)) {
      return false;
    }

    this.selectedSettings.Account = [selectedAccount];
    return true;
  }

  public setSelectedClients(selectedClients: Array<ListItemModel>): void {
    if (_.isNil(selectedClients)) {
      return;
    }

    this.selectedSettings.Clients = selectedClients;
  }

  public setFilters(selectedFilters: Array<ListItemModel>): boolean {
    if (_.isNil(selectedFilters)) {
      return false;
    }

    this.selectedSettings.Filters = selectedFilters;
    return true;
  }

  protected mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: response.dateRange || [],
      Date: response.date || null,
      Schedule: response.schedule || [],
      OverwritePeriod: response.overwritePeriod || [],
      [this.integrationTypeEnum]: {
        Account: response.accounts || [],
        Clients: response.clients || [],
        Parameters: response.parameters || [],
        Filters: response.filters || []
      },
      Name: response.name || defaultIntegrationName,
      TableName: response.tableName || defaultTableName
    };
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].Account = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Clients = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Filters = [];
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings, isRefresh: boolean = false): void {
    const isRefreshSettings = this.editMode && isRefresh;
    this.selectedIntegrationSettings.Id = initialSettings.Id;
    this.selectedSettings.Account = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].Account[0])
      ? initialSettings[this.integrationTypeEnum].Account.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].Account[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].Account.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Clients = isRefreshSettings && _.some(this.selectedSettings.Clients)
      ? initialSettings[this.integrationTypeEnum].Clients.filter((f: ListItemModel): boolean => !_.isNil(this.selectedSettings.Clients.find((ff: ListItemModel): boolean => ff.id === f.id)))
      : initialSettings[this.integrationTypeEnum].Clients.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.Filters = initialSettings[this.integrationTypeEnum].Filters.filter((f: ListItemModel): boolean => f.selected);

    this.initSelectedCommonSettings(initialSettings, isRefreshSettings);
  }

  protected refreshParameters(): Observable<any> {
    return this.rentaApiService.initIntegration(this.getWizardRequestData()).pipe(
      mergeMap((res: any): Observable<IntegrationSettings> => {

        this.meta = res.meta;
        res = this.mapIntegrationSettings(res, this.selectedIntegrationSettings.TableName, this.selectedIntegrationSettings.Name);
        const selectedAccount = this.selectedIntegrationSettings[this.integrationTypeEnum].Account[0] || res[this.integrationTypeEnum].Account.find((item: ListItemModel): boolean => item.selected);

        this.compareParametersWithCacheData(res[this.integrationTypeEnum].Parameters);
        this.compareFiltersWithCacheData(res[this.integrationTypeEnum].Filters);
        return _.isNil(selectedAccount) ||
        _.isNil(res[this.integrationTypeEnum].Account.find((f: ListItemModel): boolean => f.id === selectedAccount.id))
          ? of(res)
          : this.getClients(selectedAccount.id).pipe(
            mergeMap((clients: Array<ListItemModel>): Observable<IntegrationSettings> => {
              res[this.integrationTypeEnum].Clients = clients;
              return of(res);
            })
          );
      })
    );
  }
}
