<div class="p-d-flex p-flex-column width-max height-max">
  <div
    class="payment-card-input card-number p-my-2"
    [class.p-disabled]="disableInputs"
    [class.focused]="isCardFocus"
    [class.invalid-input]="isCardTouched && isCardError"
  >
    <ngx-stripe-card
      (focus)="onCardFocus(true)"
      (blur)="onCardFocus(false)"
      (change)="onChange($event)"
      [options]="cardOptions"
      [elementsOptions]="elementsOptions"
    ></ngx-stripe-card>
  </div>
  <form [formGroup]="billingAddress">
    <div class="p-d-flex width-max p-jc-between p-my-2">
      <div class="countries-drop-down" [class.p-disabled]="disableInputs">
        <input type="text" formControlName="country" [(ngModel)]="country" class="p-d-none" />
        <app-renta-dropdown
          [RentaDropdownSource]="countriesDataSource"
          [ShowName]="false"
          [placeholder]="'Countries'"
          (OnApply)="onApplyCountry($event)"
          (OnBlur)="onBlurCountries()"
          [Invalid]="isCountryInvalid"
          [maxHeight]="180"
        ></app-renta-dropdown>
      </div>
      <div class="p-px-2">
        <label for="city" class="p-d-none"></label>
        <input
          id="city"
          type="text"
          pInputText
          [class.invalid-input]="billingAddress.get('city').errors && billingAddress.get('city').touched"
          class="payment-card-input"
          placeholder="City"
          formControlName="city"

          [class.p-disabled]="disableInputs"
        />
      </div>
      <div class="state-input">
        <label for="zip" class="p-d-none"></label>
        <input
          id="zip"
          pInputText
          type="text"
          [class.invalid-input]="billingAddress.get('zip').errors && billingAddress.get('zip').touched"
          class="payment-card-input"
          placeholder="Zip"
          formControlName="zip"

          [class.p-disabled]="disableInputs"
        />
      </div>
    </div>
  </form>
  <div class="p-my-2">
    <label for="streetAddress" class="p-d-none"></label>
    <input
      id="streetAddress"
      type="text"
      pInputText
      appTextInputTrim
      class="payment-card-input"
      [(ngModel)]="streetAddress"
      placeholder="Street Address"
      [class.p-disabled]="disableInputs"
      (blur)="onFormChange()"
    />
  </div>
  <div class="p-my-2">
    <label for="unit" class="p-d-none"></label>
    <input
      id="unit"
      type="text"
      pInputText
      appTextInputTrim
      class="payment-card-input"
      [(ngModel)]="unit"
      placeholder="Apt, unit, suite, etc. (optional)"
      [class.p-disabled]="disableInputs"
      (blur)="onFormChange()"
    />
  </div>
  <div class="p-mt-2">
    <label for="companyName" class="p-d-none"></label>
    <input
      id="companyName"
      type="text"
      pInputText
      appTextInputTrim
      class="payment-card-input"
      [(ngModel)]="companyName"
      placeholder="Company Name"
      [class.p-disabled]="disableInputs"
      (blur)="onFormChange()"
    />
  </div>
</div>
