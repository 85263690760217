import { Component, Input, OnInit } from '@angular/core';
import { RentaDropdownDataSource } from '../../../../models/DataSource/RentaDropdownDataSource';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';

@Component({
  selector: 'app-customerio-auth',
  templateUrl: './customerio-auth.component.html',
  styleUrls: ['./customerio-auth.component.scss']
})
export class CustomerioAuthComponent implements OnInit {

  @Input()
  public error: string;

  @Input()
  public loading: boolean;

  @Input()
  public CustomerioWorkspaceName: string;

  @Input()
  public CustomerioWorkspaceApiKey: string;

  @Input()
  public CustomerioWorkspaceRegion: string;

  public RegionDataSource: RentaDropdownDataSource;

  constructor() {}

  public ngOnInit(): void {
    const regionOptions: ListItemModel[] = [
      { id: 'eu', name: 'EU' },
      { id: 'us', name: 'US' }
    ];

    // Инициализация RentaDropdownDataSource с фиксированными регионами
    this.RegionDataSource = new RentaDropdownDataSource('Region', regionOptions);

    // Устанавливаем значение по умолчанию для CustomerioWorkspaceRegion
    if (!this.CustomerioWorkspaceRegion) {
      this.CustomerioWorkspaceRegion = regionOptions[0].id;
    }
  }

  public onApplyRegion(event: ListItemModel): void {
    this.CustomerioWorkspaceRegion = event.id;
  }
}
