<div class="setting-row-wrapper p-m-0">
  <app-renta-dropdown *ngIf="isLoaded; else loadingSetting"
                      [RentaDropdownSource]="AccountDataSource"
                      [additionalInfo]="'Specify the ad account that has required for uploading data.'"
                      (OnApply)="onApplyAccount($event)"></app-renta-dropdown>
</div>
<div class="setting-row-wrapper" *ngIf="isShowClients">
  <app-renta-chips *ngIf="isLoaded"
                   [DataSource]="chipsClientsDataSource"
                   (OnDeleteChip)="onCloseSelectedClientsChip($event)"></app-renta-chips>
</div>
<div class="setting-row-wrapper" *ngIf="isShowClients">
  <app-renta-multiselect-dropdown
    *ngIf="isLoaded; else loadingSetting"
    (OnApply)="onApplyClients($event)"
    [RentaMultiselectDropdownSource]="ClientsDataSource"
    [hotRefresh]="false">
  </app-renta-multiselect-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-dropdown *ngIf="isLoaded; else loadingSetting"
                      [RentaDropdownSource]="SourceDataSource"
                      [toolTipInfo]="'Source consists of the unique set of parameters and metrics available for uploading to DWH. Each Source has its own parameters and metrics collection.'"
                      [class.disabled]="isDataSourceLoading"
                      (OnApply)="onApplySource($event)"></app-renta-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-chips *ngIf="isLoaded"
                   [DataSource]="chipsParametersDataSource"
                   [class.disabled]="isDataSourceLoading"
                   (OnDeleteChip)="onCloseChip($event)"></app-renta-chips>
</div>
<div class="setting-row-wrapper">
  <app-renta-multiselect-dropdown
    *ngIf="isLoaded; else loadingSetting"
    (OnApply)="onApplyParameters($event)"
    [RentaMultiselectDropdownSource]="ParametersDataSource"
    [class.disabled]="isDataSourceLoading"
    [toolTipInfo]="'Parameters contains the list of parameters and metrics available for export.'"
    [additionalInfo]="'Specify the parameters required for uploading.'"
    [hotRefresh]="true">
  </app-renta-multiselect-dropdown>
</div>

<ng-template #loadingSetting>
  <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
  <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
</ng-template>
