import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { RentaApiService } from '../../../../services/renta-api.service';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { mergeMap } from 'rxjs/operators';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { FilesMeta } from '../../../../models/Integration/IntegrationsMeta/FilesMeta';
import { EtlSettingsServiceBase } from './EtlSettingsServiceBase';

@Injectable()
export class FilesSettingsService extends EtlSettingsServiceBase<FilesMeta> {
  protected tablePrefix: string = 'fl';
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.file;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      Parameters: null,
      Types: null,
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null,
  };

  constructor(
    public integrationInfoData: IntegrationInfoViewModel,
    protected readonly rentaApiService: RentaApiService,
    protected readonly rentaModalsService: RentaModalsService
  ) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.memoryCacheKey = `${this.tablePrefix}-${IntegrationTypeEnum.file}`;
  }

  protected mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: response.dateRange || [],
      Date: response.date || null,
      Schedule: response.schedule || [],
      OverwritePeriod: response.overwritePeriod || [],
      [this.integrationTypeEnum]: {
        Parameters: response.parameters || [],
        Types: response.types || [],
       },
      Name: response.name || defaultIntegrationName,
      TableName: response.tableName || defaultTableName,
    };
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Types = [];
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings, isRefresh: boolean = false): void {
    const isRefreshSettings = this.editMode && isRefresh;
    this.selectedIntegrationSettings.Id = initialSettings.Id;

    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.Types = initialSettings[this.integrationTypeEnum].Types.filter((f: ListItemModel): boolean => f.selected);
    this.initSelectedCommonSettings(initialSettings, isRefreshSettings);
  }

  protected refreshParameters(): Observable<any> {
    return this.rentaApiService.initIntegration(this.getWizardRequestData()).pipe(
      mergeMap((res: any): Observable<IntegrationSettings> => {
        this.meta = res.meta;
        res = this.mapIntegrationSettings(res, this.selectedIntegrationSettings.TableName, this.selectedIntegrationSettings.Name);
        this.compareParametersWithCacheData(res[this.integrationTypeEnum].Parameters);
        return of(res);
      })
    );
  }
}
