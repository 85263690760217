<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="mr-b-5">
    <app-renta-error-message [errorMessage]="error"
                             *ngIf="error"></app-renta-error-message>
  </div>
  <label for="sourceName" class="p-d-none"></label>
  <input id="sourceName"
         class="p-inputtext-lg auth-dialog-input"
         type="text"
         placeholder="Source name"
         pInputText
         appTextInputTrim
         [(ngModel)]="sourceName"
         [disabled]="loading">
  <label for="login" class="p-d-none"></label>
  <input id="login"
         class="p-inputtext-lg auth-dialog-input dialog-input-margin"
         type="text"
         placeholder="Cloud login"
         pInputText
         appTextInputTrim
         [(ngModel)]="login"
         [disabled]="loading">
</div>
