import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProfileService } from '../../../../../../services/profile.service';
import { dataURItoBlob } from '../../../../../../helpers/helpers';

@Component({
  selector: 'app-cropping-image-modal',
  templateUrl: './cropping-image-modal.component.html',
  styleUrls: ['./cropping-image-modal.component.scss'],
})
export class CroppingImageModalComponent implements OnInit {
  @Output()
  public OnCancel: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public OnSave: EventEmitter<boolean> = new EventEmitter<boolean>();

  public loading: boolean;
  public errorMsg: string;
  public imgSourceUrl: ArrayBuffer;
  private imgCroppResult: ArrayBuffer;

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig,
    private readonly profileService: ProfileService
  ) {
    this.imgSourceUrl = this.config.data.image;
  }

  public ngOnInit(): void {}

  public getCropImage(event: any): void {
    this.imgCroppResult = event;
  }

  public cancelHandler(): void {
    this.ref.close(false);
  }

  public saveHandler(): void {
    const img = dataURItoBlob(this.imgCroppResult);
    const form = new FormData();
    form.append('uploadedFile', img);
    this.loading = true;
    this.profileService.updateAvatarUrl(form).subscribe((__: string): void => {
      this.loading = false;
      this.ref.close(true);
    });
  }
}
