import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RentaDropdownDataSource } from '../../../models/DataSource/RentaDropdownDataSource';
import { Observable } from 'rxjs';
import { ListItemModel } from '../../../../../models/Integration/ListItemModel';
import { KeyValueDropdownsRowDataSource } from '../../../models/DataSource/KeyValueDropdownsRowDataSource';

@Component({
  selector: 'app-renta-key-value-dropdowns-row',
  templateUrl: './renta-key-value-dropdowns-row.component.html',
  styleUrls: ['../../internal/base-settings-styles.css']
})
export class RentaKeyValueDropdownsRowComponent implements OnInit {
  @Input()
  public KeyValueDropdownRowDataSource: KeyValueDropdownsRowDataSource;

  @Input()
  public KeyName: string;

  @Input()
  public ValueName: string;

  @Input()
  public Id: string | number;

  @Input()
  public CanRemove: boolean;

  @Output()
  public OnDelete: EventEmitter<string | number> = new EventEmitter<string | number>();

  @Output()
  public OnValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public Disabled: boolean = false;

  public dataSource: Observable<{ key: RentaDropdownDataSource, value: RentaDropdownDataSource }>;

  private isValidKey: boolean;
  private isValidValue: boolean;

  constructor() {
  }

  public ngOnInit(): void {
    this.dataSource = this.KeyValueDropdownRowDataSource.connect();
  }

  public delete(): void {
    this.OnDelete.emit(this.Id);
  }

  public onApplyKey(item: ListItemModel): void{
    this.isValidKey = true;
    this.OnValidChange.emit(this.isValidKey && this.isValidValue)
  }

  public onApplyValue(item: ListItemModel): void{
    this.isValidValue = true;
    this.OnValidChange.emit(this.isValidKey && this.isValidValue)
  }
}
