import { BehaviorSubject, Observable } from 'rxjs';
import { RentaInputDataSource } from './RentaInputDataSource';
import * as _ from 'lodash';
import { IconPositionEnum } from './DataSourceModels/RentaInputDataSourceModel';

export class RentaInputStringArrayDataSource {
  public name: string;
  public error: string;
  public isCanAdd: boolean;


  private sourceData: Array<string>;
  private dataSource: BehaviorSubject<Array<RentaInputDataSource>> = new BehaviorSubject<Array<RentaInputDataSource>>(null);
  private readonly validationAction: (values: Array<string>) => Array<string>;
  private readonly inputPlaceholders: string

  constructor(name: string, dataSource: Array<string>, inputPlaceholders: string, validationAction: (values: Array<string>) => Array<string>) {
    this.refreshDataSource(dataSource);
    this.name = name;
    this.validationAction = validationAction;
    this.isCanAdd = false;
    this.inputPlaceholders = inputPlaceholders;
  }

  public connect(): Observable<Array<RentaInputDataSource>> {
    return this.dataSource.asObservable();
  }

  public getSelectedData(): Array<string> {
    return this.dataSource.getValue().map(item => item.getValue());
  }

  public onValueChange(name: string, value: string): boolean {
    const res = this.validateDataSource();
    this.isCanAdd = res;
    return res;
  }

  public removeItem(inputName: string): void {
    const ds = this.dataSource.getValue();
    const itemIndex = ds.findIndex(f => f.name === inputName);

    if (itemIndex > -1){
      ds.splice(itemIndex, 1);
    }

    this.dataSource.next(ds);
    this.isCanAdd = this.validateDataSource();
  }

  public addEmptyRow(): void {
    const ds = this.dataSource.getValue();
    ds.push(this.getInputDs(_.uniqueId(), ''))
    this.dataSource.next(ds);
    this.isCanAdd = false;
  }

  public refresh(dataSource: Array<string>): void {
    this.error = null;
    if (dataSource !== null) {
      this.sourceData = dataSource;
    }

    this.refreshDataSource();
    if (dataSource.length > 0){
      setTimeout(() => this.isCanAdd = this.validateDataSource());
    } else {
      this.isCanAdd = false;
    }

  }

  private refreshDataSource(ds: Array<string> = null): void {
    if (ds === null) {
      this.dataSource.next(this.getDataSource(this.sourceData));
    }
  }

  private getDataSource(ds: Array<string>): Array<RentaInputDataSource> {
    const res = new Array<RentaInputDataSource>();
    for (const item of ds || []) {
      res.push(this.getInputDs(_.uniqueId(), item));
    }

    return res;
  }

  private getInputDs(dsName: string, value: string): RentaInputDataSource {
    return new RentaInputDataSource(dsName, {
      value,
      iconOptions: {position: IconPositionEnum.none},
      placeholder: this.inputPlaceholders,
      disabled: false
    })
  }

  private validateDataSource(): boolean {
    if (this.validationAction) {
      const ds = this.dataSource.getValue();
      const error = this.validationAction(ds.map(item => item.getValue()));
      error.forEach((item, index) => {
        ds[index].setError(item);
      });

      if (error.filter(f => f !== null).length > 0){
        return false;
      }
    }

    return true;
  }
}
