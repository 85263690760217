import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomDateRangeViewModelEnum } from '../../../models/DataSource/RentaDateRangeDataSource';
import { Observable } from 'rxjs';
import { DateRangeDataSourceModel } from '../../../models/DataSource/DataSourceModels/DateRangeDataSourceModel';
import * as _ from 'lodash';

interface CalendarHeaderOption {
  title: string;
  value: CustomDateRangeViewModelEnum;
  selected: boolean;
}

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss']
})
export class CalendarHeaderComponent implements OnInit, AfterViewChecked {
  @Input()
  public dataSource: Observable<DateRangeDataSourceModel>;

  @Input()
  public dateFrom: string;

  @Input()
  public dateTo: string;

  @Input()
  public dateRangeType: string;

  @Output()
  public changeToDate: EventEmitter<CustomDateRangeViewModelEnum> = new EventEmitter<CustomDateRangeViewModelEnum>();

  public dateRangeOptions: Array<CalendarHeaderOption>;
  public selectedDateRange: string;
  public highlightDate: boolean = true;

  constructor() {
  }

  public ngOnInit(): void {
    this.dataSource.subscribe((res: DateRangeDataSourceModel): void => {
      this.highlightDate = !_.isNil(res.dateFromTitle) && !_.isNil(res.dateToTitle);
    });

    this.dateRangeOptions = [
      {
        title: 'Custom',
        value: CustomDateRangeViewModelEnum.custom,
        selected: false
      },
      {
        title: 'Today',
        value: CustomDateRangeViewModelEnum.untilToday,
        selected: false
      },
      {
        title: 'Yesterday',
        value: CustomDateRangeViewModelEnum.untilYesterday,
        selected: false
      }
    ];

    this.setSelectedOption(this.dateTo);
  }

  public ngAfterViewChecked(): void {
    if (this.dateTo !== this.selectedDateRange) {
      this.selectedDateRange = this.dateTo;
      this.setSelectedOption(this.dateTo);
    }
  }

  public changeDateRange(event: CalendarHeaderOption): void {
    this.setSelectedOption(event.value);
    this.changeToDate.emit(event.value);
  }

  private unSelectAllOptions(): void {
    this.dateRangeOptions.forEach((elem: CalendarHeaderOption): void => {
      elem.selected = false;
    });
  }

  private setSelectedOption(selected: CustomDateRangeViewModelEnum | string): void {
    this.unSelectAllOptions();

    if (selected === 'Today' || selected === 'Until Today') {
      this.dateRangeOptions[1].selected = true;
    } else if (selected === 'Yesterday' || selected === 'Until Yesterday') {
      this.dateRangeOptions[2].selected = true;
    } else {
      this.dateRangeOptions[0].selected = true;
    }
  }
}
