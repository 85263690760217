import { Injectable } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from '../../../environments/environment';
import { IntegrationNotificationService } from './services/integration-notification.service';
import { UserNotificationService } from './services/user-notification.service';

import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})

export class SignalrService {

  private static getConnection(sessionId: string): HubConnection {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const accessToken = userInfo?.token?.access_token;

    return new HubConnectionBuilder()
      .withUrl(`${environment.baseUrl}${environment.rtnConnect}?sessionId=${sessionId}`,
        {
          accessTokenFactory: () => accessToken,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets
        })
      .withAutomaticReconnect([0, 2000, 10000, 30000]) // Configure automatic reconnection
      // .configureLogging(LogLevel.Debug)
      .build();
  }

  public sessionId: string;
  private hubConnection: HubConnection;

  constructor(
    private integrationNotificationService: IntegrationNotificationService,
    private userNotificationService: UserNotificationService
  ) {
    this.sessionId = uuidv4();
  }

  public connect(): void {
    this.startConnection();
    this.addListeners();
  }

  private startConnection(): void {
    this.hubConnection = SignalrService.getConnection(this.sessionId);

    this.hubConnection
      .start()
      //  .then(() => console.log('SignalR connection started'))
      .catch(err => this.handleConnectionError(err));

    this.hubConnection.onclose(error => {
      this.handleConnectionError(error);
      setTimeout(() => this.startConnection(), 5000); // Attempt to reconnect after 5 seconds
    });

    this.hubConnection.onreconnecting(error => {
      console.warn('SignalR reconnecting: ', error);
    });

    this.hubConnection.onreconnected(connectionId => {
      // console.log('SignalR reconnected, connection ID: ', connectionId);
    });

    // Handle tab close or page refresh
    window.addEventListener('beforeunload', () => {
      this.hubConnection.stop().then(() => console.log('Connection closed on page unload'));
    });
  }

  private handleConnectionError(error: any): void {
    console.error('Connection error: ', error);

    if (error.message === 'Invalid payload') {
      // Specific handling for 'Invalid payload' error
      console.error('Received invalid payload from server.');
    } else {
      // General error handling
      setTimeout(() => this.startConnection(), 5000); // Reconnect after 5 seconds
    }
  }

  private addListeners(): void {
    this.hubConnection.on('IntegrationEvent', (data: any): void => {
      this.integrationNotificationService.onEmit(data);

    });

    this.hubConnection.on('UserEvent', (data: any): void => {
      this.userNotificationService.onEmit(data);

    });

    // Adding a handler for invalid messages
    this.hubConnection.on('InvalidPayload', (error: any) => {
      console.error('Invalid Payload received: ', error);
    });
  }
}
