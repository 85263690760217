import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-criteo-auth',
  templateUrl: './criteo-auth.component.html',
  styleUrls: ['./criteo-auth.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CriteoAuthComponent implements OnInit {
  @Input()
  public error: string;

  @Input()
  public name: string;

  @Input()
  public clientId: string;

  @Input()
  public clientSecret: string;

  @Input()
  public loading: boolean;

  constructor() { }

  public ngOnInit(): void {
  }
}
