import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortKeys'
})
export class SortKeysPipe implements PipeTransform {
  public transform(value: { [key: string]: any } | null | undefined, order: 'asc' | 'desc' = 'asc'): { key: string, value: any }[] {
    if (!value) {
      return [];
    }

    const sortedKeys = Object.keys(value).sort((a, b) => {
      if (order === 'asc') {
        return new Date(a).getTime() - new Date(b).getTime();
      } else {
        return new Date(b).getTime() - new Date(a).getTime();
      }
    });

    return sortedKeys.map(key => ({ key, value: value[key] }));
  }
}
