<div class="auth-wrapper p-d-flex p-flex-column p-ai-center p-jc-center" *ngIf="resetPasswordState === ResetPasswordState.resetFrom && loaded">
  <div class="auth-form p-d-flex p-flex-column">
    <p-progressBar mode="indeterminate" styleClass="auth-progress" *ngIf="resetInProgress"></p-progressBar>
    <div class="auth-title-container">
      <span class="auth-title renta-font-big">Reset Password</span>
      <span *ngIf="errorMessage !== null || !isFormValid()" class="p-invalid ap-ta-center">{{errorMessage}}</span>
      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()"  class="p-flex-column p-mt-5">
        <div class="auth-row-container">
         <span class="p-input-icon-left">
            <i class="auth-ic renta-ic renta-ic-auth-password input-ic"></i>
           <label for="newPassword" class="p-d-none"></label>
            <input id="newPassword" autocomplete="off" type="password" placeholder="Password"
                   class="p-inputtext p-fluid renta-font-normal auth-input" pInputText
                   [class.p-invalid]="(resetPasswordForm.get('newPassword').invalid || resetPasswordForm.invalid) && submitted"
                   formControlName="newPassword">
          </span>
        </div>
        <div class="auth-row-container">
         <span class="p-input-icon-left">
            <i class="auth-ic renta-ic renta-ic-auth-password input-ic"></i>
           <label for="confirmPassword" class="p-d-none"></label>
            <input id="confirmPassword" autocomplete="off" type="password" placeholder="Confirm Password"
                   class="p-inputtext p-fluid renta-font-normal auth-input" pInputText
                   [class.p-invalid]="(resetPasswordForm.get('confirmPassword').invalid || resetPasswordForm.invalid) && submitted"
                   formControlName="confirmPassword">
          </span>
        </div>
        <div class="auth-btn-container p-d-flex p-flex-column p-mt-5">
          <p-button [disabled]="resetInProgress" label="Reset Password" iconPos="left"
                    styleClass="auth-btn-wrapper renta-color uppercase" type="submit"></p-button>
        </div>
      </form >
    </div>
  </div>
</div>

<div class="auth-wrapper p-d-flex p-flex-column p-ai-center p-jc-center" *ngIf="resetPasswordState !== ResetPasswordState.resetFrom && loaded">
  <app-checkmark [Success]="resetPasswordState !== ResetPasswordState.wrongCode" [Title]="checkmarkTitle" [TitleDescription]="checkmarkDescription"></app-checkmark>
</div>
