import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { delay } from 'rxjs/operators';

import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { RentaChipsDataSource } from '../../../../../shared/models/DataSource/RentaChipsDataSource';
import { RentaMultiselectDropdownDataSource } from '../../../../../shared/models/DataSource/RentaMultiselectDropdownDataSource';
import { GoogleAdsSettingsService } from '../../../../services/integrationSettings/google-ads-settings.service';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { ChipsModel } from '../../../../../shared/models/ChipsModel';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { getSelectedChips } from '../../../../../../helpers/helpers';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';

@Component({
  templateUrl: './google-ads-settings.component.html',
  styleUrls: ['../common-settings.scss']
})
export class GoogleAdsSettingsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;
  public CustomerAccountsDataSource: RentaMultiselectDropdownDataSource;
  public chipsCustomerAccountsDataSource: RentaChipsDataSource;
  public ReportTypeDataSource: RentaDropdownDataSource;
  public ParametersDataSource: RentaMultiselectDropdownDataSource;
  public chipsParametersDataSource: RentaChipsDataSource;

  constructor(private settingsService: GoogleAdsSettingsService) {
    super(IntegrationTypeEnum.google_ads);
    this.isLoaded = false;
  }

  public ngOnInit(): void {
    this.CustomerAccountsDataSource = new RentaMultiselectDropdownDataSource('Customer accounts', []);
    this.ReportTypeDataSource = new RentaDropdownDataSource('Report type', []);
    this.ParametersDataSource = new RentaMultiselectDropdownDataSource('Parameters', []);
    this.chipsCustomerAccountsDataSource = new RentaChipsDataSource('Selected Customer Accounts', []);
    this.chipsParametersDataSource = new RentaChipsDataSource('Selected Parameters', []);

    this.chipsCustomerAccountsDataSource.connect().subscribe((res: Array<ChipsModel>): void => {
      const selectedAccounts = res.map<ListItemModel>((m: ChipsModel): ListItemModel => {
        return {id: m.id, name: m.title, group: m.type};
      });

      this.settingsService.setSelectedCustomerAccounts(selectedAccounts);
    });

    this.connectParamsChipsDS(this.chipsParametersDataSource, this.settingsService);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
      this.CustomerAccountsDataSource.setLoading(res);
      this.ReportTypeDataSource.setLoading(res);
      this.ParametersDataSource.setLoading(res);
      this.chipsCustomerAccountsDataSource.setLoading(res);
      this.chipsParametersDataSource.setLoading(res);
      this.refreshRelatedDataSources();
    });

    this.settingsService.connectToLoading().subscribe((res: boolean): void => {
      this.isLoaded = !res;
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      if (res[this.integrationType].CustomerAccounts) {
        this.CustomerAccountsDataSource.refresh(_.cloneDeep(res[this.integrationType].CustomerAccounts));
        const chipsAccountsDs = getSelectedChips(res[this.integrationType].CustomerAccounts);
        this.chipsCustomerAccountsDataSource.refresh(chipsAccountsDs);
      }

      if (res[this.integrationType].ReportTypes) {
        this.ReportTypeDataSource.refresh(_.cloneDeep(res[this.integrationType].ReportTypes));
      }

      if (res[this.integrationType].Parameters) {
        this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
        const chipsParamsDs = getSelectedChips(res[this.integrationType].Parameters);
        this.chipsParametersDataSource.refresh(chipsParamsDs);
      }

      this.settingsService.initCache();
      this.setRelatedDataSources([this.ReportTypeDataSource, this.ParametersDataSource]);

      setTimeout((): void => {
        this.isLoaded = true;
      }, 1000);
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onApplyReportType($event: ListItemModel): void {
    if (!this.settingsService.setReportType($event)) {
      return;
    }
    this.ParametersDataSource.setLoading(true);
    this.refreshRelatedDataSources();
    this.settingsService
      .getParameters($event)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {

        this.ParametersDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(_.cloneDeep(res));
          this.chipsParametersDataSource.refresh(getSelectedChips(res));
        }
      });
  }

  public onCloseCustomerAccountsChip(e: ChipsModel): void {
    this.CustomerAccountsDataSource.applyUncheck({id: e.id, group: e.type, name: e.title});
  }

  public onCloseChip(e: ChipsModel): void {
    this.ParametersDataSource.applyUncheck({id: e.id, group: e.type, name: e.title});
    setTimeout((): void => {
      this.settingsService.setDataToCache();
    });
  }

  public onApplyCustomerAccounts(event: Array<ListItemModel>): void {
    if (_.isNil(event)) {
      return;
    }

    const chipsDs = event.map<ChipsModel>((item: ListItemModel): ChipsModel => {
      return {title: item.name, type: item.group, id: item.id, order: 0};
    });

    this.chipsCustomerAccountsDataSource.refresh(chipsDs);
  }

  public onApplyParameters($event: Array<ListItemModel>): void {
    this.onApplyParams($event, this.chipsParametersDataSource);
    this.settingsService.setDataToCache();
  }
}
