import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import Cropper from 'cropperjs';
import CropEvent = Cropper.CropEvent;

@Component({
  selector: 'app-cropper-block',
  templateUrl: './cropper-block.component.html',
  styleUrls: ['./cropper-block.component.scss'],
})
export class CropperBlockComponent implements OnInit, AfterViewInit {
  @Input()
  public imgSource: string | ArrayBuffer;

  @Input()
  public width: string;

  @Input()
  public height: string;

  @ViewChild('cropperContainer')
  public cropperElement: ElementRef;

  @ViewChild('image', { static: false })
  public imageElement: ElementRef;

  @Output()
  public cropImageHandler: EventEmitter<any> = new EventEmitter<any>();

  public imageDestination: string;
  private cropper: Cropper;
  private imgType: string;

  constructor(private renderer: Renderer2) {}

  public ngOnInit(): void {
    this.imgType = 'image/jpg';
  }

  public ngAfterViewInit(): void {
    if (!this.imgSource) {
      return;
    }

    this.cropper = new Cropper(this.imageElement.nativeElement, {
      scalable: false,
      background: false,
      aspectRatio: 1,
      crop: (__: CropEvent<HTMLImageElement>): void => {
        const canvas = this.cropper.getCroppedCanvas();
        this.imageDestination = canvas.toDataURL(this.imgType);
        this.cropImageHandler.emit(this.imageDestination);
      },
    });

    this.renderer.setStyle(this.cropperElement.nativeElement, 'width', this.width + 'px');
    this.renderer.setStyle(this.cropperElement.nativeElement, 'height', this.height + 'px');
  }
}
