import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlSegment } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AppGuardService implements CanActivate, CanLoad {
  constructor(public auth: UserService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkAccess();
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.checkAccess();
  }

  public checkAccess(): Observable<boolean> {
    return this.auth.isAuthenticated().pipe(
      map((r: boolean): boolean => {
        if (r === true) {
          return true;
        }

        this.router.navigate(['auth']).then();
        return false;
      })
    );
  }
}
