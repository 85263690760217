import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-my-sql-auth',
  templateUrl: './my-sql-auth.component.html',
  styleUrls: ['./my-sql-auth.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MySqlAuthComponent implements OnInit {
  @Input()
  public error: string;

  @Input()
  public ip: string;

  @Input()
  public port: string;

  @Input()
  public login: string;

  @Input()
  public password: string;

  @Input()
  public loading: boolean;

  @Input()
  public dialogTitleInfo: string;

  constructor() {
    this.dialogTitleInfo = 'Create your custom destination by entering access credentials to your MySql Server';
  }

  public ngOnInit(): void {}
}
