import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StepsModule } from 'primeng/steps';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { InputTextModule } from 'primeng/inputtext';

import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SlideMenuModule } from 'primeng/slidemenu';
import { BlockUIModule } from 'primeng/blockui';

import { IntegrationOverviewComponent} from '../../integration-overview/integration-overview.component';
import { SortKeysPipe} from '../../integration-overview/pipes/sort-keys.pipe';
import { FormatDatePipe} from '../../integration-overview/pipes/format-date.pipe';

import { SharedModule } from '../shared/shared.module';
import { IntegrationsComponent } from './integrations.component';
import { IntegrationsRoutingModule } from './integrations-routing.module';
import { IntegrationsListComponent } from './components/integrations-list/integrations-list.component';
import { IntegrationCreateComponent } from './components/integration-create/integration-create.component';
import { IntegrationSourceComponent } from './components/integration-source/integration-source.component';
import { IntegrationDestinationComponent } from './components/integration-destination/integration-destination.component';
import { CriteoSettingsComponent } from './components/internal/integration-settings/criteo-settings/criteo-settings.component';
import { ScheduleSettingsComponent } from './components/internal/integration-settings/schedule-settings/schedule-settings.component';
import { IntegrationListService } from './services/integration-list.service';
import { GaSettingsComponent } from './components/internal/integration-settings/ga-settings/ga-settings.component';
import { PanelModule } from 'primeng/panel';
import { IntegrationInfoComponent } from './components/internal/integration-settings/integration-info/integration-info.component';
import { EditIntegrationSettingsComponent } from './components/integration-settings/edit-integration-settings.component';
import { CreateIntegrationSettingsComponent } from './components/integration-settings/create-integration-settings.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GoogleAdsSettingsComponent } from './components/internal/integration-settings/google-ads-settings/google-ads-settings.component';
import { TikTokAdsSettingsComponent } from './components/internal/integration-settings/tiktok-ads-settings/tiktok-ads-settings.component';
import { IikoWebSettingsComponent } from './components/internal/integration-settings/iiko-web-settings/iiko-web-settings.component';
import { CalltouchSettingsComponent} from './components/internal/integration-settings/calltouch-settings/calltouch-settings.component';
import { CustomerioSettingsComponent} from './components/internal/integration-settings/customerio-settings/customerio-settings.component';
import { IikoSettingsComponent } from './components/internal/integration-settings/iiko-settings/iiko-settings.component';
import { AdwordsSettingsComponent } from './components/internal/integration-settings/adwords-settings/adwords-settings.component';
import { FbAdsSettingsComponent } from './components/internal/integration-settings/fb-ads-settings/fb-ads-settings.component';
import { YandexLogsSettingsComponent } from './components/internal/integration-settings/yandex-logs-settings/yandex-logs-settings.component';
import { MyTargetSettingsComponent } from './components/internal/integration-settings/my-target-settings/my-target-settings.component';
import { VkSettingsComponent } from './components/internal/integration-settings/vk-settings/vk-settings.component';
import { Dv360SettingsComponent } from './components/internal/integration-settings/dv360-settings/dv360-settings.component';
import { YandexMetricaSettingsComponent } from './components/internal/integration-settings/yandex-metrica/yandex-metrica-settings.component';
import { AppMetricaSettingsComponent } from './components/internal/integration-settings/app-metrica/app-metrica-settings.component';
import { AppMetricaLogsApiSettingsComponent } from './components/internal/integration-settings/app-metrica-logs-api/app-metrica-logs-api-settings.component';
import { LinkedInSettingsComponent } from './components/internal/integration-settings/linkedin-settings/linkedin-settings.component';
import { YandexDirectComponent } from './components/internal/integration-settings/yandex-direct/yandex-direct.component';
import { YandexMarketSettingsComponent } from './components/internal/integration-settings/yandex-market-settings/yandex-market-settings.component';
import { AmoCrmSettingsComponent } from './components/internal/integration-settings/amo-crm-settings/amo-crm-settings.component';
import { KommoSettingsComponent } from './components/internal/integration-settings/kommo-settings/kommo-settings.component';
import { XAdsSettingsComponent } from './components/internal/integration-settings/x-ads-settings/x-ads-settings.component';
import { EsputnikSettingsComponent } from './components/internal/integration-settings/esputnik-settings/esputnik-settings.component';
import { AmplitudeSettingsComponent } from './components/internal/integration-settings/amplitude-settings/amplitude-settings.component';
import { ActiveCampaignSettingsComponent } from './components/internal/integration-settings/active-campaign-settings/active-campaign-settings.component';
import { BingAdsSettingsComponent } from './components/internal/integration-settings/bing-ads-settings/bing-ads-settings.component';
import { MySqlSettingsComponent } from './components/internal/integration-settings/my-sql-settings/my-sql-settings.component';
import { GetintentSettingsComponent } from './components/internal/integration-settings/getintent-settings/getintent-settings.component';
import { PostgreSqlSettingsComponent } from './components/internal/integration-settings/postgre-sql-settings/postgre-sql-settings.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { SocialBakersSettingsComponent } from './components/internal/integration-settings/social-bakers-settings/social-bakers-settings.component';
import { IntegrationsListFilterComponent } from './components/integrations-list-filter/integrations-list-filter.component';
import { SourceDestinationSettingsComponent } from './components/internal/integration-settings/source-destination-settings/source-destination-settings.component';
import { SalesForceSettingsComponent } from './components/internal/integration-settings/sales-force-settings/sales-force-settings.component';
import { FilesSettingsComponent } from './components/internal/integration-settings/files-settings/files-settings.component';
import { DuplicateIntegrationComponent } from './components/integration-settings/duplicate-integration.component';
import { StreamingSourceComponent } from './components/addon-token-settings/streaming-source/streaming-source.component';
import { WebhookDestinationComponent } from './components/addon-token-settings/webhook-destination/webhook-destination.component';
import { WebhookAddonSettingsComponent } from './components/internal/integration-settings/webhook-addon-settings/webhook-addon-settings.component';
import { WarehouseAddonSettingsComponent } from './components/internal/integration-settings/warehouse-addon-settings/warehouse-addon-settings.component';
import { FbAdsAddonSettingsComponent } from './components/internal/integration-settings/fb-ads-addon-settings/fb-ads-addon-settings.component';

import {
  GoogleSheetsComponent
} from './components/internal/integration-settings/google-sheets-settings/google-sheets.component';
import { InputNumberModule } from 'primeng/inputnumber';

@NgModule({
  declarations: [
    IntegrationOverviewComponent,
    SortKeysPipe,
    FormatDatePipe,
    IntegrationsComponent,
    IntegrationsListComponent,
    IntegrationCreateComponent,
    IntegrationSourceComponent,
    IntegrationDestinationComponent,
    CriteoSettingsComponent,
    ScheduleSettingsComponent,
    GaSettingsComponent,
    GoogleSheetsComponent,
    IntegrationInfoComponent,
    EditIntegrationSettingsComponent,
    CreateIntegrationSettingsComponent,
    AdwordsSettingsComponent,
    GoogleAdsSettingsComponent,
    TikTokAdsSettingsComponent,
    IikoWebSettingsComponent,
    CalltouchSettingsComponent,
    CustomerioSettingsComponent,
    IikoSettingsComponent,
    FbAdsSettingsComponent,
    YandexLogsSettingsComponent,
    YandexMarketSettingsComponent,
    MyTargetSettingsComponent,
    VkSettingsComponent,
    Dv360SettingsComponent,
    AmoCrmSettingsComponent,
    KommoSettingsComponent,
    YandexMetricaSettingsComponent,
    AppMetricaSettingsComponent,
    AppMetricaLogsApiSettingsComponent,
    LinkedInSettingsComponent,
    YandexDirectComponent,
    MySqlSettingsComponent,
    XAdsSettingsComponent,
    EsputnikSettingsComponent,
    AmplitudeSettingsComponent,
    ActiveCampaignSettingsComponent,
    BingAdsSettingsComponent,
    GetintentSettingsComponent,
    PostgreSqlSettingsComponent,
    SocialBakersSettingsComponent,
    IntegrationsListFilterComponent,
    SourceDestinationSettingsComponent,
    SalesForceSettingsComponent,
    FilesSettingsComponent,
    DuplicateIntegrationComponent,
    StreamingSourceComponent,
    WebhookDestinationComponent,
    WebhookAddonSettingsComponent,
    WarehouseAddonSettingsComponent,
    FbAdsAddonSettingsComponent,
  ],
  providers: [IntegrationListService],
  imports: [
    CommonModule,
    IntegrationsRoutingModule,
    ReactiveFormsModule,
    StepsModule,
    FormsModule,
    AccordionModule,
    InputTextModule,
    ButtonModule,
    TabViewModule,
    CheckboxModule,
    SelectButtonModule,
    SkeletonModule,
    InputSwitchModule,
    SlideMenuModule,
    BlockUIModule,
    SharedModule.forRoot(),
    PanelModule,
    FontAwesomeModule,
    ProgressBarModule,
    InputNumberModule
  ],
  exports: [],
})
export class IntegrationsModule {}
