import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { RentaDropdownDataSource } from './RentaDropdownDataSource';
import { KeyValueDropdownsRowDataSource } from './KeyValueDropdownsRowDataSource';

export class KeyValueDropdownsDataSource {
  public error: string;
  public keyName: string;
  public valueName: string;

  private sourceData: Array<{ key: string, value: string }>;
  private sourceKeyDropdownData: Array<ListItemModel>;
  private sourceValueDropdownData: Array<ListItemModel>;
  private dataSource: BehaviorSubject<Array<KeyValueDropdownsRowDataSource>> = new BehaviorSubject<Array<KeyValueDropdownsRowDataSource>>(null);

  constructor(keyName: string, valueName: string, dataSource: Array<{ key: string, value: string }>, dropdownKeyData: Array<ListItemModel>, dropdownValueData: Array<ListItemModel>) {
    this.keyName = keyName;
    this.valueName = valueName;
    this.sourceData = dataSource;
    this.sourceKeyDropdownData = dropdownKeyData;
    this.sourceValueDropdownData = dropdownValueData;
    this.dataSource.next(this.prepareDataSource(this.sourceData));
  }

  public connect(): Observable<Array<KeyValueDropdownsRowDataSource>> {
    return this.dataSource.asObservable();
  }

  public addEmptyKeyValueRow(): void {
    const ds = this.dataSource.getValue();

    const selectedData = this.getSelectedData();
    const keyData =  _.cloneDeep(this.sourceKeyDropdownData.filter(f => !_.some(selectedData, (item) => item.key === f.id)));
    const valueData = _.cloneDeep(this.sourceValueDropdownData.filter(f => !_.some(selectedData, (item) => item.value === f.id)));

    ds.push(this.getDataSourceItem(ds.length, { key: '', value: '' }, keyData, valueData));
    this.dataSource.next(ds);
  }

  public removeKeyValueRow(index: number): void {
    const ds = this.dataSource.getValue();
    if (ds.length > index) {
      ds.splice(index, 1);
    }

    this.dataSource.next(ds);
  }

  public getSelectedData(): Array<{ key: string, value: string }> {
    return this.dataSource.getValue()
      .map((item: KeyValueDropdownsRowDataSource): { key: string, value: string } => {
        return item.getSelectedKeyValueRow();
      });
  }

  public setError(error: string): void {
    this.error = error;
  }

  public refresh(dataSource: Array<{ key: string, value: string }>, dropdownKeyData: Array<ListItemModel>, dropdownValueData: Array<ListItemModel>): void {
    if (dropdownKeyData.length === 0 || dropdownValueData.length === 0) {
      return;
    }

    this.sourceData = dataSource;

    this.sourceKeyDropdownData = dropdownKeyData;
    this.sourceValueDropdownData = dropdownValueData;

    const newDs = this.prepareDataSource(this.sourceData);
    this.dataSource.next(newDs);
  }

  private prepareDataSource(array: Array<{ key: string, value: string }>): Array<KeyValueDropdownsRowDataSource> {
    const selectedVals = this.dataSource.getValue()?.map(m => m.getSelectedKeyValueRow()).filter(f => _.some(f.key)) || [];
    const filteredDs = array.filter(f => !selectedVals.some(s => s.key === f.key));
    const resDs = filteredDs.concat(selectedVals);
    const result = [];

    let keyData =  _.cloneDeep(this.sourceKeyDropdownData);
    let valueData = _.cloneDeep(this.sourceValueDropdownData);

    for (let i = 0; i < resDs.length; i++) {
      const item = this.getDataSourceItem(i, resDs[i], keyData, valueData);
      result.push(item);
      keyData = keyData.filter(f => f.id !== resDs[i].key);
      valueData = valueData.filter(f => f.id !== resDs[i].value);
    }

    return result;
  }

  private getDataSourceItem(indx: number, item: { key: string, value: string }, keyData: Array<ListItemModel>, valueData: Array<ListItemModel>): KeyValueDropdownsRowDataSource {
    const dropdowKeynDs = keyData.map((dropdownItem: ListItemModel): ListItemModel => {
      return { ...dropdownItem, selected: dropdownItem.id === item.key };
    });

    const dropdowValuenDs = valueData.map((dropdownItem: ListItemModel): ListItemModel => {
      return { ...dropdownItem, selected: dropdownItem.id === item.value };
    });

    return new KeyValueDropdownsRowDataSource(new RentaDropdownDataSource('Key' + indx, dropdowKeynDs), new RentaDropdownDataSource('Value' + indx, dropdowValuenDs));
  }
}
