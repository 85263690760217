export const environment = {
  envs: {
    production: false,
    local: true,
    development: false,
  },
  baseUrl: 'https://app.renta.im',
  signInUrl: 'https://app.renta.im',
  baseApiUrl: 'https://app.renta.im/api',
  googleAuth: '/api/auth/google/oauth',
  getAvailableSource: '/source/available',
  getAvailableDestination: '/destination/available',
  signIn: '/auth/sign-in',
  signUp: '/auth/sign-up',
  forgotPassword: '/auth/forgot-password',
  validateResetPassword: '/auth/validate-reset-password',
  resetPassword: '/auth/reset-password',
  getUserInfo: '/auth/state',
  signOut: '/auth/sign-out',
  integrationTokens: '/tokens/',
  integrationWizard: '/{integrationGroup}/{integrationType}/wizard',
  integrationAction: '/{integrationGroup}/',
  integrationsList: '/integrations',
  integrationsStart: '/{integrationGroup}/{integrationType}/start/',
  getStorages: '/{integrationType}/projectids/',
  getStorageTokens: '/{integrationType}/{projectId}/databases/',
  integrationData: '/{integrationGroup}/',
  rtnConnect: '/hub/integration',
  profile: '/profile',
  profileImage: '/profile/image',
  updatePassword: '/auth/update-password',
  billing: '/billing',
  notificationHub: '/notification_hub',
  checkout: '/billing/subscription',
  cancelSubscription: '/billing/subscription',
  renewSubscription: '/billing/subscription',
  invoices: '/billing/invoice',
  payInvoice: '/billing/invoice/{id}',
  createPaymentMethod: '/billing/payment-method',
  stripe_key_pbc: 'pk_test_51IGnjMB4pMAKnuRyf846oSX5QZCNss6BRRnVDUuUHgoDGMeBw3ZzooxxMGJzFnHqkCfJ2vEaFKut0ETKOfwF6yYA00XXgVslrv',
  googleRecapchaSiteKey: '6LdzZgMfAAAAAG2vid3SJhTZzUu933O0Ad9msCYf',
  paddle: {
    sandbox: false,
    vendorId: 170800,
  },
  streaming: {
    edit: '/tokens/javascript_sdk/{id}',
    editWebhook: '/tokens/webhook/{id}',
    initialize: '/tokens/javascript_sdk/initialize',
    checkDns: '/tokens/javascript_sdk/dns',
  }
};

