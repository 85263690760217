import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-add-token-row-template',
  templateUrl: './add-token-row-template.component.html',
  styleUrls: ['../token-row-styles.scss', './add-token-row-template.component.css']
})
export class AddTokenRowTemplateComponent {
  @Input()
  public Title: string;

  @Output()
  public OnAddToken: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor() {
  }

  public onAddToken(mouseEvent: MouseEvent): void {
    this.OnAddToken.emit(mouseEvent);
  }
}
