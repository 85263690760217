import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IntegrationSourceModel } from '../../../../../../models/Integration/IntegrationSourceModel';

@Component({
  selector: 'app-base-modal-dialog',
  templateUrl: './base-modal-dialog.component.html',
  styleUrls: ['./base-modal-dialog.component.css'],
})
export class BaseModalDialogComponent {
  public integration: IntegrationSourceModel;

  public checkMarkInfo: { success: boolean; title: string; info: string };

  constructor(private readonly ref: DynamicDialogRef, private readonly config: DynamicDialogConfig) {
    this.integration = this.config.data.integrationInfo as IntegrationSourceModel;
    this.checkMarkInfo = this.config.data.result;
    this.checkMarkInfo = {
      success: this.config.data.result,
      info: this.config.data.result ? 'Success' : 'Fail',
      title: this.config.data.result ? `Integration ${this.config.data.actionType}!` : `Integration ${this.config.data.actionType} fail`,
    };

    setTimeout((): void => {
      this.ref.close(this.config.data.result);
    }, 1500);
  }
}
