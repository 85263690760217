<div class="popup-menu-wrapper" (click)="onClickHandler($event)" [ngClass]="menuPopupData.classList">
  <div #popupMenu class="popup-menu">
    <div class="header-container p-d-flex p-align-center" *ngIf="menuPopupData.headerComponent || menuPopupData.headerTitle">
      <div class="header">
        <div class="title p-d-flex p-align-center" *ngIf="menuPopupData.headerTitle && menuPopupData.headerTitle.length > 0">
          <span>{{ menuPopupData.headerTitle }}</span>
        </div>
        <div class="custom-item" *ngIf="menuPopupData.headerComponent">
          <ng-container #customHeader></ng-container>
        </div>
      </div>
    </div>
    <div class="menu-content p-d-flex p-flex-column">
      <div class="menu-item-container p-d-flex p-flex-column" [class.disabled]="menu.disabled" *ngFor="let menu of menuPopupData.menuItems">
        <div class="menu-item" *ngIf="isMenuItemStandart(menu)">
          <div class="standard-menu p-d-flex p-flex-row" [class.icon-hover-mode]="menu.showIconOnHover" (click)="onClick($event, menu)">
            <div class="menu-icon" *ngIf="menu.imageLeft">
              <img [src]="menu.imageLeft" alt="{{ menu.label }}" />
            </div>
            <div class="menu-icon" *ngIf="menu.iconLeft">
              <i class="p-menuitem-icon pi pi-fw ng-star-inserted" [ngClass]="menu.iconLeft"></i>
            </div>
            <div class="menu-title">
              <span>{{ menu.label }}</span>
            </div>
            <div class="menu-icon last" *ngIf="menu.imageRight">
              <img [src]="menu.imageRight" alt="{{ menu.label }}" />
            </div>
            <div class="menu-icon last" *ngIf="menu.iconRight">
              <i class="p-menuitem-icon pi pi-fw ng-star-inserted" [ngClass]="menu.iconRight"></i>
            </div>
          </div>
        </div>
        <div class="menu-item" *ngIf="isMenuItemCustom(menu)">
          <div class="custom-menu">
            <span>custom element</span>
          </div>
        </div>
        <div class="menu-item" *ngIf="isMenuItemDivider(menu)">
          <div class="menu-divider">
            <span></span>
          </div>
        </div>
        <div class="menu-item" *ngIf="isMenuItemTitle(menu)">
          <div class="title">
            <span>{{ menu.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="menu-pointer">
      <div class="pointer"></div>
    </div>
  </div>
</div>
