import { DataSourceBase } from './DataSourceBase';
import { ChipsModel } from '../ChipsModel';

function sortChipsPredicate(a: ChipsModel, b: ChipsModel): number {
  if (a.order > b.order) {
    return 1;
  }
  if (a.order < b.order) {
    return -1;
  }
  return 0;
}

export class RentaChipsDataSource extends DataSourceBase<Array<ChipsModel>> {
  public name: string;

  constructor(name: string, dataSource: Array<ChipsModel>) {
    super(dataSource);
    this.name = name;
  }

  public sort(oldIndex: number, newIndex: number): void {
    // newIndex <- bug when drag to end line with single chips in line
    newIndex = newIndex >= this.sourceData.length ? this.sourceData.length - 1 : newIndex;

    this.sourceData[oldIndex].order = newIndex;
    this.sourceData[newIndex].order = oldIndex;
    this.refresh();
  }

  public refresh(dataSource: Array<ChipsModel> = null): void {
    if (dataSource === null) {
      this.updateDataSource();
      return;
    }

    const sd = this.sourceData || [];
    let lastCount = sd.length;

    for (let i = 0; i < dataSource.length; i++) {
      const indexToAdd = sd.findIndex((f: ChipsModel): boolean => f.id === dataSource[i].id);
      if (indexToAdd === -1) {
        dataSource[i].order = lastCount;
        lastCount++;
      } else {
        dataSource[i].order = indexToAdd;
      }
    }

    dataSource.sort(sortChipsPredicate);
    this.sourceData = dataSource;
    this.updateDataSource();
  }

  public RemoveChip(chip: ChipsModel): void {
    const indexToRemove = this.sourceData.findIndex((f: ChipsModel): boolean => f.id === chip.id);
    if (indexToRemove === -1) {
      return;
    }

    this.sourceData.splice(indexToRemove, 1);
    this.updateDataSource();
  }

  protected updateDataSource(): void {
    this.dataSource.next(this.sourceData);
  }
}
