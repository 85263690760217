import { DataSourceBase } from './DataSourceBase';
import * as _ from 'lodash';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { BehaviorSubject } from 'rxjs';

export interface FilterModel {
  id: number;
  source: RentaSettingsFiltersDataSource;
  selected: ListItemModel;
  editMode: boolean;
}

export class RentaSettingsFiltersDataSource extends DataSourceBase<Array<ListItemModel>> {
  public error: string;
  public name: string;
  public selectedFilters: Array<FilterModel> = [];
  public canEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(name: string, dataSource: Array<ListItemModel>) {
    super(dataSource);
    this.name = name;
  }

  public getCollection(): Array<ListItemModel> {
    return this.sourceData;
  }

  public getCollectionCopy(): Array<ListItemModel> {
    return this.sourceData.map((elem: ListItemModel): ListItemModel => {
      return Object.assign({}, elem);
    });
  }

  public updateItem(item: ListItemModel): void {
    const filters = this.getCollection().map((elem: ListItemModel): ListItemModel => {
      return elem.id === item.id ? item : elem;
    });

    this.refresh(filters);
  }

  public getSelectedItems(): Array<ListItemModel> {
    return this.sourceData.filter((f: ListItemModel): boolean => f.selected);
  }

  public setError(error: string): void {
    this.error = error;
  }

  public setCanEdit(canEdit: boolean): void {
    this.canEdit.next(canEdit);
  }

  public setLoading(isLoading: boolean): void {
    if (isLoading === super.loading) {
      return;
    }

    super.setLoading(isLoading);
  }

  public refresh(dataSource: Array<ListItemModel> = null): void {
    super.refresh(dataSource);

    this.removeUnfinishedFilters();
    if (_.isNil(dataSource) || dataSource.length === 0) {
      this.selectedFilters = [];
      return;
    }
    this.updateSelectedFilters();
    this.selectedFilters = [];

    this.getSelectedItems().forEach((f: ListItemModel): void => {
      this.addFilter(f);
    });
  }

  public addFilter(selectedFilter: ListItemModel = null): void {
    const filter: FilterModel = {
      id: this.selectedFilters.length,
      source: this,
      selected: selectedFilter,
      editMode: _.isNil(selectedFilter),
    };

    this.selectedFilters.push(filter);
  }

  public applyFilter(filterModel: FilterModel): void {
    this.addToSelectedFilters(filterModel);
    this.updateItem(filterModel.selected);
  }

  public deleteFilter(filterModel: FilterModel): void {
    this.deleteFromSelectedFilters(filterModel);
    this.updateItem(filterModel.selected);
  }

  private updateSelectedFilters(): void {
    if (_.isNil(this.selectedFilters) || this.selectedFilters.length === 0) {
      return;
    }

    let hasChanges: boolean = false;
    const updatedCollection = this.getCollection().map((elem: ListItemModel): ListItemModel => {
      const selectedFilter = this.selectedFilters.find((f: FilterModel): FilterModel => {
        if (f.selected.id === elem.id && !_.isEqual(f.selected, elem)) {
          hasChanges = true;
          return f;
        }
      });

      return _.isNil(selectedFilter) ? elem : selectedFilter.selected;
    });

    if (hasChanges) {
      this.refresh(updatedCollection);
    }
  }

  private addToSelectedFilters(filterModel: FilterModel): void {
    this.selectedFilters.forEach((filter: FilterModel, index: number): void => {
      if (filter.id === filterModel.id) {
        this.selectedFilters[index] = filterModel;
      }
    });
  }

  private deleteFromSelectedFilters(filterModel: FilterModel): void {
    this.selectedFilters.forEach((filter: FilterModel, index: number): void => {
      if (filter.id === filterModel.id) {
        this.selectedFilters.splice(index, 1);
      }
    });
  }

  private removeUnfinishedFilters(): void {
    this.selectedFilters.forEach((filter: FilterModel, index: number): void => {
      if (_.isNil(filter.selected) || _.isNil(filter.selected.id)) {
        this.selectedFilters.splice(index, 1);
      }
    });

    super.setLoading(false);
  }
}
