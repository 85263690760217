import { RentaDropdownDataSource } from './RentaDropdownDataSource';
import { BehaviorSubject, Observable } from 'rxjs';

export class KeyValueDropdownsRowDataSource {
  private ds: {key: RentaDropdownDataSource, value: RentaDropdownDataSource};
  private dataSource: BehaviorSubject<{ key: RentaDropdownDataSource, value: RentaDropdownDataSource }> = new BehaviorSubject<{key: RentaDropdownDataSource; value: RentaDropdownDataSource}>(null);
  constructor(key: RentaDropdownDataSource, value: RentaDropdownDataSource){
    this.ds = {key, value };
    this.dataSource.next(this.ds);
  }

  public connect(): Observable<{ key: RentaDropdownDataSource, value: RentaDropdownDataSource }> {
    return this.dataSource.asObservable();
  }

  public getSelectedKeyValueRow(): { key: string, value: string } {
    const ds = this.dataSource.getValue();
    return {key: ds.key.getSelectedItem()?.id, value: ds.value.getSelectedItem()?.id}
  }
}
