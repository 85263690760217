import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IntegrationsListComponent } from './components/integrations-list/integrations-list.component';
import { IntegrationCreateComponent } from './components/integration-create/integration-create.component';
import { IntegrationSourceComponent } from './components/integration-source/integration-source.component';
import { IntegrationDestinationComponent } from './components/integration-destination/integration-destination.component';
import { EditIntegrationGuard } from '../../services/edit-integration.guard';
import { CreateIntegrationSettingsComponent } from './components/integration-settings/create-integration-settings.component';
import { EditIntegrationSettingsComponent } from './components/integration-settings/edit-integration-settings.component';
import { BillingGuardGuard } from '../../services/billing-guard.guard';
import { DuplicateIntegrationComponent } from './components/integration-settings/duplicate-integration.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: IntegrationsListComponent },
      {
        path: 'create',
        component: IntegrationCreateComponent,
        canActivateChild: [BillingGuardGuard],
        canActivate: [BillingGuardGuard],
        children: [
          { path: '', redirectTo: 'source', pathMatch: 'full' },
          { path: 'source', component: IntegrationSourceComponent },
          { path: 'destination', component: IntegrationDestinationComponent },
          { path: 'settings', component: CreateIntegrationSettingsComponent },
        ],
      },
    ],
  },
  { path: ':id/duplicate', component: DuplicateIntegrationComponent, canActivate: [EditIntegrationGuard, BillingGuardGuard] },
  { path: ':id', component: EditIntegrationSettingsComponent, canActivate: [EditIntegrationGuard, BillingGuardGuard] },
  { path: '**', redirectTo: 'integrations/list', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IntegrationsRoutingModule {}
