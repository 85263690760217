import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
})
export class AuthComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userService: UserService
  ) {}

  public ngOnInit(): void {
    const tree = this.router.parseUrl(this.router.url);
    const primary = tree.root.children[PRIMARY_OUTLET];
    const lastSegment = primary.segments[primary.segments.length - 1];
    if (lastSegment.path === 'reset-password' && tree.queryParams.code) {
      this.userService.validateResetPasswordCode(tree.queryParams.code).subscribe((res: boolean): void => {
        if (res === true) {
          this.router
            .navigate(['reset-password'], { queryParams: { resetCode: tree.queryParams.code }, relativeTo: this.activatedRoute })
            .then();
        } else {
          this.router.navigate(['reset-password'], { queryParams: { resetCode: 'invalid' }, relativeTo: this.activatedRoute }).then();
        }
      });
    } else {
      this.router.navigate(['sign-in'], { relativeTo: this.activatedRoute }).then();
    }
  }
}
