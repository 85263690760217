import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';

@Component({
  selector: 'app-dropdown-content',
  templateUrl: './dropdown-content.component.html',
  styleUrls: ['./dropdown-content.component.css']
})
export class DropdownContentComponent implements OnInit {
  @Input()
  public DataSource: Array<ListItemModel>;

  @Input()
  public ExtendedDescription: boolean;

  @Output()
  public OnClickListItem: EventEmitter<ListItemModel> = new EventEmitter<ListItemModel>();

  public constructor() {
  }

  public ngOnInit(): void {
  }

  public onClickListItem(item: ListItemModel): void {
    this.OnClickListItem.emit(item);
  }
}
