import { Component, OnInit } from '@angular/core';

import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { FbAdsAddonSettingsService } from '../../../../services/integrationSettings/fb-ads-addon-settings.service';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';
import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import * as _ from 'lodash';
import { RentaSettingsFiltersDataSource } from '../../../../../shared/models/DataSource/RentaSettingsFiltersDataSource';
import { SelectionOption } from '../../../../../../models/Integration/IntegrationSelectionOption';
import { RentaSelectionOptionDataSource } from '../../../../../shared/models/DataSource/RentaSelectionOptionDataSource';
import { RentaInputDataSource } from '../../../../../shared/models/DataSource/RentaInputDataSource';
import {
  IconPositionEnum,
  RentaInputDataSourceModel
} from '../../../../../shared/models/DataSource/DataSourceModels/RentaInputDataSourceModel';
import { KeyValueDropdownsDataSource } from '../../../../../shared/models/DataSource/KeyValueDropdownsDataSource';

@Component({
  selector: 'app-fb-ads-addon-settings',
  templateUrl: './fb-ads-addon-settings.component.html',
  styleUrls: ['../common-settings.scss', 'fb-ads-addon-settings.component.scss']
})
export class FbAdsAddonSettingsComponent extends SettingsBaseComponent implements OnInit {
  public isLoaded: boolean;
  public isDebugMode: boolean;
  public AccountsDataSource: RentaDropdownDataSource;
  public FbPixelDataSource: RentaDropdownDataSource;

  public EventTypesDataSource: RentaDropdownDataSource;
  public EventNameDataSource: RentaDropdownDataSource;
  public FiltersDataSource: RentaSettingsFiltersDataSource;
  public isShowEventNames: boolean = false;

  public FacebookPixelEventNameDataSource: RentaDropdownDataSource;
  public ActionSourceDataSource: RentaDropdownDataSource;
  public OutputDataSource: KeyValueDropdownsDataSource;

  public debugModeDataSource: RentaSelectionOptionDataSource;
  public TestEventCodeDataSource: RentaInputDataSource;

  constructor(private settingsService: FbAdsAddonSettingsService) {
    super(IntegrationTypeEnum.fbAdsAddon);
  }

  public ngOnInit(): void {
    this.AccountsDataSource = new RentaDropdownDataSource('Ad account', null);
    this.FbPixelDataSource = new RentaDropdownDataSource('Facebook Pixel Name', null);

    this.EventTypesDataSource = new RentaDropdownDataSource('Event Type', null);
    this.EventNameDataSource = new RentaDropdownDataSource('Event Name', null);
    this.FiltersDataSource = new RentaSettingsFiltersDataSource('Filters', []);

    this.FacebookPixelEventNameDataSource = new RentaDropdownDataSource('Facebook Pixel event name', null);
    this.ActionSourceDataSource = new RentaDropdownDataSource('Action Source', null);
    this.OutputDataSource = new KeyValueDropdownsDataSource('Javascript event values', 'Facebook CAPI parameters', [], [], []);

    this.debugModeDataSource = new RentaSelectionOptionDataSource('Debug mode', null);
    this.TestEventCodeDataSource = new RentaInputDataSource('Test Event Code', null);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToLoading().subscribe((res: boolean): void => {
      this.isLoaded = !res;
    });

    this.isLoaded = false;

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      if (res[this.integrationType].Accounts) {
        this.AccountsDataSource.refresh(_.cloneDeep(res[this.integrationType].Accounts));
      }

      if (res[this.integrationType].FbPixel) {
        this.FbPixelDataSource.refresh(_.cloneDeep(res[this.integrationType].FbPixel));
      }

      if (res[this.integrationType].EventTypes) {
        this.EventTypesDataSource.refresh(_.cloneDeep(res[this.integrationType].EventTypes));
      }

      if (res[this.integrationType].EventNames) {
        this.EventNameDataSource.refresh(_.cloneDeep(res[this.integrationType].EventNames));
      }

      if (res[this.integrationType].Filters) {
        this.FiltersDataSource.refresh(res[this.integrationType].Filters);
      }

      if (res[this.integrationType].FacebookPixelEventName) {
        this.FacebookPixelEventNameDataSource.refresh(_.cloneDeep(res[this.integrationType].FacebookPixelEventName));
      }

      if (res[this.integrationType].ActionSource) {
        this.ActionSourceDataSource.refresh(res[this.integrationType].ActionSource);
      }

      if (res[this.integrationType].Parameters && res[this.integrationType].Properties) {
        this.OutputDataSource.refresh(res[this.integrationType].Output.map(item => ({key: item.value, value: item.parameter })),
          res[this.integrationType].Parameters, res[this.integrationType].Properties);
      }

      this.isDebugMode = !_.isNil(res[this.integrationType].TestEventCode);
      this.debugModeDataSource.refresh({selected: this.isDebugMode ? 'Yes' : 'No', values: [{value: 'Yes', label: 'Yes'}, {value: 'No', label: 'No'}]})

      const testEventCode: RentaInputDataSourceModel = {
        value: res[this.integrationType].TestEventCode || '',
        iconOptions: {
          position: IconPositionEnum.right
        },
        disabled: false,
        placeholder: 'Set the test event code'
      };

      this.TestEventCodeDataSource.refresh(testEventCode);

      this.isLoaded = true;
    });
  }

  public onApplyDebugMode($event: SelectionOption): void {
    this.isDebugMode = $event.value === 'Yes';
    this.settingsService.setTestEventCode(null);
  }

  public onAccountApply($event: ListItemModel): void {
    if (!this.settingsService.setAccount($event)) {
      return;
    }
    this.settingsService.getFbPixel($event).subscribe((res: Array<ListItemModel>): void => {
      this.FbPixelDataSource.refresh(res);
    });
  }

  public onFbPixelApply($event: ListItemModel): void {
    this.settingsService.setFbPixel($event)
  }

  public onEventNameApply($event: ListItemModel): void {
    if (!this.settingsService.setEventName($event)) {
      return;
    }

    const eventType = this.EventTypesDataSource.getSelectedItem();
    this.getParametersData(eventType, $event);
  }

  public onPixelEventNameApply($event: ListItemModel): void {
    this.settingsService.setPixelEventName($event)
  }

  public onActionSourceApply($event: ListItemModel): void {
    this.settingsService.setActionSource($event)
  }

  public onApplyCollection($event: Array<{ key: string; value: string }>): void {
    this.settingsService.setEventParameters($event);
  }

  public onApplyTestEventCode($event: string): void {
    this.settingsService.setTestEventCode($event);
  }

  public onEventTypeApply($event: ListItemModel): void {
    this.isShowEventNames = $event.id !== 'page' && $event.id !== 'identify';
    if (!this.settingsService.setEventType($event)) {
      return;
    }

    if (this.isShowEventNames) {
      this.settingsService.getEventNames($event).subscribe((res: Array<ListItemModel>): void => {
        this.EventNameDataSource.refresh(res);
        this.getParametersData($event);
      });
    } else {
      this.getParametersData($event);
    }
  }

  public onApplyFilters($event: Array<ListItemModel>): void {
    this.settingsService.setFilters($event);
  }

  public onRefreshEventNames(): void {
    if (this.isShowEventNames) {
      const selectedEventType = this.EventTypesDataSource.getSelectedItem();
      this.settingsService.getEventNames(selectedEventType).subscribe((res: Array<ListItemModel>): void => {
        this.EventNameDataSource.refresh(res);
      });
    }
  }

  private getParametersData(selectedEventType: ListItemModel, selectedEventName?: ListItemModel): void {
    this.settingsService.getEventParametersAndProperties(selectedEventType, selectedEventName).subscribe((e: {keys: Array<ListItemModel>, values: Array<ListItemModel>}): void => {
      this.OutputDataSource.refresh([{key: '', value: ''}], e.values || [], e.keys);
      this.settingsService.getEventFilters(selectedEventType, selectedEventName).subscribe((fRes: Array<ListItemModel>): void => {
        this.FiltersDataSource.refresh(fRes);
        this.FiltersDataSource.setCanEdit(fRes.length > 0);
      });
    });
  }
}
