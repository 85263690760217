import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeTrigger } from '../../../../animations/fade.animation';

@Component({
  selector: 'app-renta-action-input',
  templateUrl: './renta-action-input.component.html',
  styleUrls: ['./renta-action-input.component.scss'],
  animations: [fadeTrigger],
})
export class RentaActionInputComponent implements OnInit {
  @Input()
  public textContent: string;

  @Input()
  public textPlaceholder: string;

  @Input()
  public loading: boolean;

  @Output()
  public cancelHandler: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public saveHandler: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  public ngOnInit(): void {
    this.setDefaultValue();
  }

  public btnCancel(): void {
    this.cancelHandler.emit(false);
  }

  public btnSave(): void {
    this.saveHandler.emit(this.textContent);
  }

  private setDefaultValue(): void {
    this.textPlaceholder = this.textPlaceholder || 'enter eny text';
  }
}
