<div class='p-d-flex p-flex-column width-max'>
  <div class="setting-title-wrapper" *ngIf='!HideName'>
    <span class="renta-font-normal setting-title-font">{{RentaInputStringArrayDataSource.name}}</span>
  </div>
  <div *ngFor="let row of ds | async" class="width-max p-mb-2">
    <app-renta-input-string-row
      [RentaInputSource]='row'
      (onDelete)='onDeleteRow($event)'
      (onValueChanged)='onValueChange($event)'
    ></app-renta-input-string-row>
  </div>
  <div class='p-mt-2 add-btn-container p-d-flex width-max'>
    <p-button styleClass="renta-btn-secondary" (onClick)="onAdd()" [disabled]='!RentaInputStringArrayDataSource.isCanAdd' [label]="'ADD DOMAIN'">
    </p-button>
  </div>

  <app-renta-error-message [errorMessage]="RentaInputStringArrayDataSource.error"
                           *ngIf="RentaInputStringArrayDataSource.error"></app-renta-error-message>
</div>
