import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { RentaApiService } from '../../../../services/renta-api.service';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';

import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { WebhookAddonMeta } from '../../../../models/Integration/IntegrationsMeta/WebhookAddonMeta';
import { SettingsServiceBase } from './SettingsServiceBase';
import { JsSdkAddonMeta } from '../../../../models/Integration/IntegrationsMeta/JsSdkAddonMeta';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { environment } from '../../../../../environments/environment';
import { ApiResponse } from '../../../../models/common/ApiResponse`T';
import { IntegrationSourceModel } from '../../../../models/Integration/IntegrationSourceModel';

import { DWH, AddOn } from '../../../../constants/integration-types.constants';


@Injectable()
export class JsSdkSettingsService extends SettingsServiceBase<JsSdkAddonMeta> {
  public meta: WebhookAddonMeta;
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.javascript_sdk;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      EventTypes: null,
      HitsTableName: null,
      SessionsTableName: null,
      EventNames: null,
      Parameters: null,
      Filters: null,
      Output: null
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  protected tablePrefix: string;
  private useSessionsTable: boolean;

  constructor(
    public integrationInfoData: IntegrationInfoViewModel,
    protected readonly rentaApiService: RentaApiService,
    protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
  }

  public isETL(): boolean {
    return false;
  }

  public loadSettings(): Observable<IntegrationSettings> {
    if (this.loading.getValue() === false) {
      this.loading.next(true);
      this.rentaApiService.initIntegration(this.getWizardRequestData()).pipe(
        map<any, IntegrationSettings>((res: any): IntegrationSettings => {
          this.meta = res.meta;
          return this.mapIntegrationSettings(res, this.meta.integrationName || '');
        })
      ).subscribe((res: IntegrationSettings): void => {
          this.initSelectedSettings(res);
          this.loading.next(false);
          this.sourceIntegrationSettings.next(res);

          // if EventTypes then it is Addon
          if (this.selectedSettings.EventTypes.length) {
            this.getEventParameters(this.selectedSettings.EventTypes[0], this.selectedSettings.EventNames[0])
              .subscribe(resParameters => {
                res[this.integrationTypeEnum].Parameters = resParameters;
                this.loading.next(false);
                this.sourceIntegrationSettings.next(res);
              });
          }
        }
      );
    }

    return this.sourceIntegrationSettings.asObservable().pipe(filter((result: IntegrationSettings): boolean => result !== null));
  }

  public setHitsTableName(hitsTableName: string): void {
    this.selectedSettings.HitsTableName = hitsTableName;
  }

  public getSelectedSettings(): IntegrationSettings {
    if (!this.useSessionsTable) {
      this.selectedIntegrationSettings[this.integrationTypeEnum].SessionsTableName = null;
    }
    return this.selectedIntegrationSettings;
  }

  public setSessionsTableName(sessionsTableName: string): void {
    this.selectedSettings.SessionsTableName = sessionsTableName;
  }

  public setUseSessionsTable(val: boolean): void {
    this.useSessionsTable = val;
  }

  public setEventType(selectedEventType: ListItemModel): boolean {
    if (_.isNil(selectedEventType) || _.some(this.selectedSettings.EventTypes, selectedEventType)) {
      return false;
    }

    this.selectedSettings.EventTypes = [selectedEventType];
    return true;
  }

  public setEventName(selectedEventName: ListItemModel): boolean {
    if (_.isNil(selectedEventName) || _.some(this.selectedSettings.EventTypes, selectedEventName)) {
      return false;
    }

    this.selectedSettings.EventNames = [selectedEventName];
    return true;
  }

  public setEventParameters(eventParameters: Array<{ parameter: string, value: string }>): boolean {
    if (_.isNil(eventParameters)) {
      return false;
    }

    this.selectedSettings.Output = eventParameters;
    return true;
  }

  public getEventFilters(selectedEventType: ListItemModel, selectedEventName?: ListItemModel): Observable<Array<ListItemModel>> {
    const req = this.getParametersFiltersRequestData(true, selectedEventType, selectedEventName);
    return this.getParametersFiltersData(req.url, req.body).pipe(map((res: {
      parameters: Array<ListItemModel>,
      filters: Array<ListItemModel>
    }): ListItemModel[] => res.filters));
  }

  public getEventParameters(selectedEventType: ListItemModel, selectedEventName?: ListItemModel): Observable<Array<ListItemModel>> {
    const req = this.getParametersFiltersRequestData(false, selectedEventType, selectedEventName);
    return this.getParametersFiltersData(req.url, req.body).pipe(map((res: {
      parameters: Array<ListItemModel>,
      filters: Array<ListItemModel>
    }): ListItemModel[] => res.parameters));
  }

  public setFilters(selectedFilters: Array<ListItemModel>): boolean {
    if (_.isNil(selectedFilters)) {
      return false;
    }

    this.selectedSettings.Filters = selectedFilters;
    return true;
  }

  public getEventNames(selectedEventType: ListItemModel): Observable<Array<ListItemModel>> {
    const eventType: string = selectedEventType.id;

    if (_.isNil(eventType)) {
      return of<[]>([]);
    }

    const relativeUrl: string = this.meta.eventNamesUrl.replace('{eventType}', eventType);
    const fullUrl: string = `${environment.baseUrl}${relativeUrl}`;

    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };
    return this.rentaApiService.httpClient
      .post<ApiResponse<{ eventNames: Array<ListItemModel> }>>(fullUrl, body)
      .pipe(
        map((res: ApiResponse<{ eventNames: Array<ListItemModel> }>): Array<ListItemModel> => res.result.eventNames)
      );
  }

  public setIntegrationName(name: string): void {
    this.selectedIntegrationSettings.Name = name;
  }

  public connectToSourceDestinationLoading(): Observable<boolean> {
    return of<boolean>(false);
  }

  public setTableName(tableName: string): void {
    console.error('NOT USE \'setTableName\' in webhook addon settings');
  }

  public getAvailableIntegrationDestinations(): Observable<Array<ListItemModel>> {
    return this.rentaApiService.getAvailableIntegrationDestinations(this.integrationTypeEnum)
      .pipe(
        map((sourceModels: Array<IntegrationSourceModel>): Array<ListItemModel> => {
          this.availableDestinations = this.filterIntegrationDestinations(sourceModels);
          return this.availableDestinations.filter((f: IntegrationSourceModel): boolean => f.available).map((t: IntegrationSourceModel): ListItemModel => {
            return {
              id: t.integrationType,
              name: t.title,
              selected: t.integrationType === this.integrationInfoData.integrationDestinationType?.integrationType
            };
          });
        })
      );
  }


  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].EventTypes = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].EventNames = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].Output = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].Filters = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].HitsTableName = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].SessionsTableName = null;
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings): void {
    this.selectedIntegrationSettings.Id = initialSettings.Id;
    this.selectedSettings.HitsTableName = initialSettings[this.integrationTypeEnum].HitsTableName;
    this.selectedSettings.SessionsTableName = initialSettings[this.integrationTypeEnum].SessionsTableName;
    this.useSessionsTable = _.some(this.selectedSettings.SessionsTableName);

    this.selectedSettings.EventTypes = initialSettings[this.integrationTypeEnum].EventTypes.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.EventNames = initialSettings[this.integrationTypeEnum].EventNames.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.Filters = initialSettings[this.integrationTypeEnum].Filters.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );


    this.selectedIntegrationSettings.Name = initialSettings.Name;

    this.selectedIntegrationSettings.TableName = null;
    this.selectedIntegrationSettings.OverwritePeriod = null;
    this.selectedIntegrationSettings.DateRange = null;
    this.selectedIntegrationSettings.Date = null;
    this.selectedIntegrationSettings.Schedule = null;
    this.selectedIntegrationSettings.DateRange = null;
  }

  protected mapIntegrationSettings(response: any, integrationName: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: [],
      Date: null,
      Schedule: [],
      OverwritePeriod: [],
      [this.integrationTypeEnum]: {
        HitsTableName: response.hitsTable || null,
        SessionsTableName: response.sessionsTable || null,
        EventTypes: response.eventTypes || [],
        EventNames: response.eventNames || [],
        Parameters: response.parameters || [],
        Output: response.output || [],
        Filters: response.filters || []
      },
      Name: integrationName,
      TableName: null
    };
  }

  protected refreshParameters(): Observable<any> {
    return of<{}>(null);
  }

  private filterIntegrationDestinations(sourceModels: Array<IntegrationSourceModel>): IntegrationSourceModel[] {
    const currentIntegrationType = this.integrationInfoData.integrationDestinationType?.integrationType;
    const isCurrentDWH = DWH.includes(currentIntegrationType);
    const isCurrentAddOn = AddOn.includes(currentIntegrationType);

    if (isCurrentDWH) {
      return sourceModels.filter((item) => DWH.includes(item.integrationType) && item.available);
    }

    if (isCurrentAddOn) {
      return sourceModels.filter((item) => AddOn.includes(item.integrationType) && item.available);
    }

    return sourceModels;
  }

  private getParametersFiltersRequestData(forFilters: boolean, selectedEventType: ListItemModel, selectedEventName?: ListItemModel): {
    url: string,
    body: any
  } {
    const metaUrl = forFilters ? this.meta.filtersUrl : this.meta.parametersUrl;

    let url: string = metaUrl.replace('{eventType}', selectedEventType.id);

    if (_.isNil(selectedEventName)) {
      url = url.replace('{eventName}', '').slice(0, -1);
    } else {
      url = url.replace('{eventName}', selectedEventName?.id || '');
    }

    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };
    return { url, body };
  }

  private getParametersFiltersData(url: string, body: any): Observable<{
    parameters: Array<ListItemModel>,
    filters: Array<ListItemModel>
  }> {
    const apiUrl = environment.baseUrl + `${url}`;

    return this.rentaApiService.httpClient
      .post<ApiResponse<{ parameters: Array<ListItemModel>, filters: Array<ListItemModel> }>>(apiUrl, body)
      .pipe(
        map((res: ApiResponse<{ parameters: Array<ListItemModel>, filters: Array<ListItemModel> }>): {
          parameters: Array<ListItemModel>,
          filters: Array<ListItemModel>
        } => res.result),
        catchError((error: any) => {
          console.error('Error in getParametersFiltersData:', error);

          return of({ parameters: [], filters: [] });
        })
      );
  }
}
