import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { RentaApiService } from '../../../../services/renta-api.service';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { SalesForceMeta } from '../../../../models/Integration/IntegrationsMeta/SalesForceMeta';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { EtlSettingsServiceBase } from './EtlSettingsServiceBase';

@Injectable()
export class SalesForceSettingsService extends EtlSettingsServiceBase<SalesForceMeta> {
  protected tablePrefix: string = 'sf';
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.salesforce;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      Parameters: null
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  constructor(
    public integrationInfoData: IntegrationInfoViewModel,
    protected readonly rentaApiService: RentaApiService,
    protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.memoryCacheKey = `tablePrefix-${IntegrationTypeEnum.salesforce}`;
  }

  public getParameters(selectedSourceType: ListItemModel): Observable<Array<ListItemModel>> {
    const sourceType: string = _.isNil(selectedSourceType) ? _.first(this.selectedSettings.SourceType)?.id : selectedSourceType.id;

    if (_.isNil(sourceType)) {
      return of<[]>();
    }

    const url: string = this.meta.parametersUrl;
    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };

    return this.getCurrentParameters(url, body);
  }

  public setParameter(elem: ListItemModel): void {
    if (_.isNil(elem) || _.some(this.selectedSettings.Parameters, elem)) {
      return;
    }

    this.selectedSettings.Parameters = [elem];
  }

  protected mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: response.dateRange || [],
      Schedule: response.schedule || [],
      OverwritePeriod: response.overwritePeriod || [],
      [this.integrationTypeEnum]: {
        Parameters: response.parameters || []
      },
      Name: response.name || defaultIntegrationName,
      TableName: response.tableName || defaultTableName
    };
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [];
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings, isRefresh: boolean = false): void {
    const isRefreshSettings = this.editMode && isRefresh;
    this.selectedIntegrationSettings.Id = initialSettings.Id;
    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );

    this.initSelectedCommonSettings(initialSettings, isRefreshSettings);
  }

  protected refreshParameters(): Observable<any> {
    return this.rentaApiService.initIntegration(this.getWizardRequestData())
      .pipe(
        mergeMap((res: any): Observable<IntegrationSettings> => {
          this.meta = res.meta;
          res = this.mapIntegrationSettings(res, this.selectedIntegrationSettings.TableName, this.selectedIntegrationSettings.Name);
          this.compareParametersWithCacheData(res[this.integrationTypeEnum].Parameters);
          return of(res);
        })
      );
  }
}
