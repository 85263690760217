<div @fade *ngIf="UIRefresh">
  <div class="auth-wrapper p-d-flex p-flex-column p-ai-center p-jc-center" *ngIf="!showSuccessCheckmark">
    <div class="auth-form p-d-flex p-flex-column">
      <p-progressBar mode="indeterminate" styleClass="auth-progress" *ngIf="authInProgress"></p-progressBar>
      <div class="auth-title-container">
        <span class="auth-title renta-font-big">{{ submitText }}</span>
      </div>
      <div class="error-msg-block p-d-flex p-justify-center" *ngIf="errorMessage">
        <app-renta-error-message [errorMessage]="errorMessage"></app-renta-error-message>
      </div>
      <form [formGroup]="userCredentials" (ngSubmit)="onSubmit()" class="p-flex-column">
        <div class="auth-row-container" *ngIf="authState === AuthState.SignUp">
          <span class="p-input-icon-left">
            <i class="auth-ic renta-ic renta-ic-login-user input-ic"></i>
            <label for="Name" class="p-d-none"></label>
            <input
              id="Name"
              autocomplete="off"
              type="text"
              placeholder="Name"
              class="p-inputtext p-fluid renta-font-normal auth-input"
              pInputText
              [class.p-invalid]="userCredentials.get('name').invalid && submitted"
              formControlName="name"
            />
          </span>
        </div>
        <div class="auth-row-container">
          <span class="p-input-icon-left">
            <i class="auth-ic renta-ic renta-ic-login-email input-ic"></i>
            <label for="email" class="p-d-none"></label>
            <input
              id="email"
              autocomplete="off"
              type="email"
              placeholder="Email"
              class="p-inputtext p-fluid renta-font-normal auth-input"
              pInputText
              [class.p-invalid]="userCredentials.get('email').invalid && submitted"
              formControlName="email"
            />
          </span>
        </div>

        <div class="auth-row-container" *ngIf="authState !== AuthState.ForgotPassword">
          <span class="p-input-icon-left">
            <i class="auth-ic renta-ic renta-ic-auth-password input-ic"></i>
            <label for="password" class="p-d-none"></label>
            <input
              id="password"
              autocomplete="off"
              type="password"
              placeholder="Password"
              class="p-inputtext p-fluid renta-font-normal auth-input"
              pInputText
              [class.p-invalid]="userCredentials.get('password').invalid && submitted"
              formControlName="password"
            />
          </span>
        </div>

        <span class="auth-info renta-font-normal forgot-password-wrapper p-mt-1 p-mb-3" *ngIf="authState !== AuthState.ForgotPassword">
          <a
            *ngIf="authState === AuthState.SignIn"
            [routerLink]=""
            (click)="toForgotPassword()"
            [class.p-disabled]="authInProgress"
            class="renta-href"
            >Forgot password?</a
          >
        </span>
        <div class="auth-btn-container p-d-flex p-flex-column">
          <p-button
            [disabled]="authInProgress"
            label="{{ submitText }}"
            iconPos="left"
            styleClass="auth-btn-wrapper renta-color uppercase"
            type="submit"
          ></p-button>
          <div class="terms-wrapper">
            <span class="auth-info renta-font-normal p-mb-2 p-text-center" *ngIf="authState === AuthState.SignUp"
              >By signing up you agree with our
              <a href="https://renta.im/docs/legal/terms-of-service-agreement/" class="renta-href" [class.p-disabled]="authInProgress"
                >Terms of Service</a
              ></span
            >
          </div>
        </div>
        <div class="auth-btn-container p-d-flex p-flex-column">
          <p-button
            [disabled]="authInProgress"
            styleClass="p-button-outlined auth-btn-wrapper google-btn"
            iconPos="middle"
            label="{{ authState !== AuthState.ForgotPassword ? submitText : 'Sign In' }} with Google"
            (onClick)="onSubmitGoogle()"
          >
            <span class="auth-ic ic-google-btn"> <i class="renta-ic renta-ic-google-auth"></i> </span>
          </p-button>
        </div>
      </form>
    </div>
    <div class="height-anim-fix p-d-flex p-flex-column p-align-center">
      <span *ngIf="authState !== AuthState.SignUp" class="auth-info p-mt-4 renta-font-normal under-form-info"
        >Don’t have an account yet?
        <a [routerLink]="" [class.p-disabled]="authInProgress" (click)="toSignUp()" class="renta-href">Sign Up</a></span
      >
      <span *ngIf="authState !== AuthState.SignIn" class="auth-info p-mt-4 renta-font-normal under-form-info"
        >Already have an account?
        <a [routerLink]="" [class.p-disabled]="authInProgress" (click)="toSignIn()" class="renta-href"> Log In!</a></span
      >
    </div>
  </div>
  <div class="auth-wrapper p-d-flex p-flex-column p-ai-center p-jc-center" *ngIf="showSuccessCheckmark">
    <app-checkmark [Success]="true" [Title]="checkmarkTitle" [TitleDescription]="checkmarkDescription"></app-checkmark>
  </div>
</div>
