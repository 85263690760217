<div class="p-d-flex width-max">
  <div class="width-max">
    <app-renta-input [RentaInputSource]='KeyValueDataSource.KeyDataSource'
                     (OnApply)='onApplyKey($event)'></app-renta-input>
  </div>
  <div class="p-ml-2 width-max">
    <app-renta-input [RentaInputSource]='KeyValueDataSource.ValueDataSource'
                     (OnApply)='onApplyValue($event)'></app-renta-input>
  </div>
  <div class="p-ml-2 p-as-end" *ngIf='WithDelete'>
    <p-button styleClass="renta-btn-secondary" (onClick)="delete()">
      <i class="pi pi-minus"></i>
    </p-button>
  </div>
</div>
