import { Injectable } from '@angular/core';
import { RentaApiService } from '../../../services/renta-api.service';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { map, filter } from 'rxjs/operators';

import { ApiResponse } from '../../../models/common/ApiResponse`T';
import { IntegrationListModel } from '../../../models/Integration/IntegrationListModel';
import { IntegrationInfoData } from '../../../models/Integration/integrationInfoData';
import { IntegrationSourceModel } from '../../../models/Integration/IntegrationSourceModel';
import { StorageService } from '../../../services/storage.service';

@Injectable()
export class IntegrationListService {
  private filteredIntegrationList: BehaviorSubject<Array<IntegrationListModel>> = new BehaviorSubject<Array<IntegrationListModel>>(null);
  private integrationListSource: Array<IntegrationListModel>;
  private integrationList: BehaviorSubject<Array<IntegrationListModel>> = new BehaviorSubject<Array<IntegrationListModel>>(null);
  private availableSource: Array<IntegrationSourceModel>;
  private selectedFilterStorageKey: string = 'IntegrationListSelectedFilter';

  constructor(protected readonly rentaApiService: RentaApiService, public readonly storageService: StorageService) {
  }

  public connectToIntegrationList(): Observable<Array<IntegrationListModel>> {
    return this.filteredIntegrationList.asObservable().pipe(
      filter((f: Array<IntegrationListModel>): boolean => !_.isNil(f)));
  }

  public connectToIntegrationListSource(): Observable<Array<IntegrationListModel>> {
    return this.integrationList.asObservable();
  }

  public getAvailableSource(): Array<IntegrationSourceModel> {
    return this.availableSource;
  }

  public getSelectedFilter(): IntegrationSourceModel {
    return this.storageService.localGetItem(this.selectedFilterStorageKey);
  }

  public getIntegrationInfo(integrationId: string, integrationType: string): Observable<IntegrationInfoData> {
    return this.rentaApiService.getIntegrationInfo(integrationId, integrationType);
  }

  public getIntegrations(): Observable<Array<IntegrationListModel>> {
    const integrationList = this.rentaApiService.integrationsList();
    const availableSource = this.rentaApiService.getAvailableIntegrationSource(false);
    const availableDestination = this.rentaApiService.getAvailableIntegrationDestinations(null, false);

    return forkJoin({ integrationList, availableSource, availableDestination }).pipe(
      map<any, Array<IntegrationListModel>>((data: any): Array<IntegrationListModel> => {
        const res = new Array<IntegrationListModel>();
        for (const item of data.integrationList) {
          res.push({
            title: item.title,
            integrationType: item.integrationType,
            id: item.id,
            enabled: item.enabled,
            icon: data.availableSource.find((f: IntegrationSourceModel): boolean => f.integrationType === item.integrationType)?.icon || '',
            destinationIcon: data.availableDestination.find((f: IntegrationSourceModel): boolean => f.integrationType === item.destinationType)?.icon || '',
            sourceTitle: data.availableSource.find((f: IntegrationSourceModel): boolean => f.integrationType === item.integrationType)?.title || '',
            lastUpdate: item.lastUpdate,
            progress: item.progress,
            tableName: item.tableName,
            additionalInfo: null,
            error: item.error || null
          });
        }

        this.availableSource = data.availableSource;
        this.integrationListSource = res;
        this.integrationList.next(this.integrationListSource);
        this.filteredIntegrationList.next(res);
        return res;
      })
    );
  }

  public setSelectedFilter(selected: IntegrationSourceModel): void {
    this.storageService.localUpdateItem(this.selectedFilterStorageKey, selected);
  }

  public filterIntegrations(text: string): void {
    const search = !_.isNil(text) ? text.toLowerCase() : null;
    const selectedFilter = this.getSelectedFilter();

    if (_.isNil(search) && _.isNil(selectedFilter)) {
      this.filteredIntegrationList.next(this.integrationListSource);
      return;
    }

    const filteredIntegrations = this.integrationListSource.filter((f: IntegrationListModel): boolean => {
      let integration: IntegrationListModel;

      if (_.isNil(selectedFilter)) {
        integration = f;
      } else if (selectedFilter.integrationType === f.integrationType) {
        integration = f;
      }

      if (_.isNil(search) && !_.isNil(integration)) {
        return true;
      }

      return (
        !_.isNil(integration) &&
        (integration.tableName?.toLowerCase().includes(search) ||
          integration.integrationType?.toLowerCase().includes(search) ||
          integration.sourceTitle?.toLowerCase().includes(search) ||
          integration.title?.toLowerCase().includes(search) ||
          integration.id === search)
      );
    });

    this.filteredIntegrationList.next(filteredIntegrations);
  }

  public deleteIntegration(integrationId: string, integrationType: string): void {
    this.rentaApiService.deleteIntegration(integrationId, integrationType).subscribe((): void => {
      this.integrationListSource = this.integrationListSource.filter((f: IntegrationListModel): boolean => f.id !== integrationId);
      this.integrationList.next(this.integrationListSource);
      this.filteredIntegrationList.next(
        this.filteredIntegrationList.getValue().filter((f: IntegrationListModel): boolean => f.id !== integrationId)
      );
    });
  }

  public refreshIntegration(integrationId: string, integrationType: string): Observable<boolean> {
    return this.rentaApiService
      .refreshIntegration(integrationId, integrationType)
      .pipe(map<ApiResponse<any>, boolean>((res: ApiResponse<any>): boolean => res.baseResponse.status));
  }

  public runIntegration(integrationId: string, integrationType: string): Observable<boolean> {
    return this.rentaApiService
      .runIntegration(integrationId, integrationType)
      .pipe(map<ApiResponse<any>, boolean>((res: ApiResponse<any>): boolean => res.baseResponse.status));
  }

  public changeEnable(integrationId: string, integrationType: string, checked: boolean): Observable<boolean> {
    return this.rentaApiService.updateIntegration(integrationId, integrationType, { enable: checked });
  }
}
