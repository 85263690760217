import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { animate, style, transition, trigger } from '@angular/animations';
import { delay, first } from 'rxjs/operators';
import * as _ from 'lodash';

import { MessageService } from 'primeng/api';
import { SlideMenu } from 'primeng/slidemenu';

import { IntegrationListModel } from '../../../../models/Integration/IntegrationListModel';
import { IntegrationListService } from '../../services/integration-list.service';
import { GbuttonHorizontalEnum } from '../../../shared/models/GbuttonHorizontalEnum';
import { MenuPopupModel } from '../../../shared/models/MenuPopupModel';
import { IntegrationInfoData } from '../../../../models/Integration/integrationInfoData';
import { IntegrationNotificationService } from '../../../../services/signalR/services/integration-notification.service';
import { IntegrationEventType } from '../../../../services/signalR/models/IntegrationEventType';
import { IntegrationEvent } from '../../../../services/signalR/models/events/integration/IntegrationEvent';
import { Subscription } from 'rxjs';
import {
  RentaIntegrationListFilterDataSource
} from '../../../shared/models/DataSource/RentaIntegrationListFilterDataSource';
import { IntegrationSourceModel } from '../../../../models/Integration/IntegrationSourceModel';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { ConfirmationModalModel } from '../../../../models/Integration/ConfirmationModalModel';
import { UserService } from '../../../../services/user.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { ProfileService } from '../../../../services/profile.service';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';

@Component({
  selector: 'app-integrations-list',
  templateUrl: './integrations-list.component.html',
  styleUrls: ['./integrations-list.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 178, opacity: 0 }),
        animate(
          '0.2s ease-out',
          style({
            height: '*',
            opacity: 1
          })
        )
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate(
          '0.2s ease-out',
          style({
            height: 178,
            opacity: 0
          })
        )
      ])
    ]),
    trigger('inAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate(
          '0.2s ease-out',
          style({
            height: '*',
            opacity: 1
          })
        )
      ]),
      transition(':leave', [style({ height: 0, opacity: 0 })])
    ])
  ]
})
export class IntegrationsListComponent implements OnInit, OnDestroy {
  @ViewChild('moreActionsMenu')
  public moreActionsSlideMenu: SlideMenu;

  public searchInput: string;
  public focusInput: boolean;
  public hoverInput: boolean;
  public integrations: Array<IntegrationListModel> = null;
  public selectedIntegration: IntegrationListModel;
  public menuPopupModel: MenuPopupModel = {};
  public isLoading: boolean = true;
  public IntegrationListFilterSource: RentaIntegrationListFilterDataSource;
  public isShowExpirationLabel: boolean = false;
  public switcherDisabledMap: { [key: string]: boolean } = {};
  public faExclamationIcon: IconDefinition = faExclamationCircle;

  public get isSubscriptionExpired(): boolean {
    return this.userService.isSubscriptionExpired();
  }

  private eventSubscription: Subscription;
  private emailSubscription: Subscription;
  private userEmail: string;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly integrationService: IntegrationListService,
    private readonly clipboard: Clipboard,
    private readonly renderer: Renderer2,
    private readonly integrationNotificationService: IntegrationNotificationService,
    private readonly rentaModalsService: RentaModalsService,
    private readonly userService: UserService,
    private messageService: MessageService,
    private elemRef: ElementRef,
    protected readonly profileService: ProfileService
  ) {
  }

  public ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
    this.emailSubscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.setPopupMenu();
    this.IntegrationListFilterSource = new RentaIntegrationListFilterDataSource(this.integrationService.getSelectedFilter());
    sessionStorage.removeItem('integrationInfoViewModel');
    this.emailSubscription = this.profileService.getEmail().subscribe((email) => {
      this.userEmail = email;

      if (this.userEmail !== null) {
        (window as any).renta.page('Integrations list', {
          email: this.userEmail,
          userId: this.userService.getUserId()
        });
      }
    });

    this.integrationService.connectToIntegrationList().subscribe((res: Array<IntegrationListModel>): void => {
      this.integrations = res;
    });

    this.integrationService.connectToIntegrationListSource().subscribe((res: Array<IntegrationListModel>): void => {
      this.IntegrationListFilterSource.refreshIntegrationList(res);
    });

    this.integrationService
      .getIntegrations()
      .pipe(delay(1000))
      .subscribe((res: Array<IntegrationListModel>): void => {
        this.isLoading = false;

        if (res.length === 0) {
          this.router.navigate(['create'], { relativeTo: this.activatedRoute.parent }).then();
          return;
        }
        this.isShowExpirationLabel = this.userService.isSubscriptionExpired();
        this.IntegrationListFilterSource.refreshAvailableSource(this.integrationService.getAvailableSource());
      });

    this.eventSubscription = this.integrationNotificationService
      .onEvent<IntegrationEvent>(IntegrationEventType.IntegrationProgressUpdated)
      .subscribe((message: IntegrationEvent): void => {
        if (_.isNil(this.integrations)) {
          return;
        }

        const updatedIntegration = this.integrations.find((f: IntegrationListModel): boolean => f.id === message.id);
        if (_.isNil(updatedIntegration)) {
          return;
        }

        updatedIntegration.progress = message.progress;
        if (!_.isNil(updatedIntegration.additionalInfo)) {
          updatedIntegration.additionalInfo['Amount of data'] = message.downloadedNumberRows + '';
        }
      });
  }

  public stateChange(e: {
    integrationId: string;
    integrationType: string;
    event: { originalEvent: PointerEvent; checked: boolean };
  }): void {
    e.event.originalEvent.preventDefault();
    e.event.originalEvent.stopPropagation();

    this.switcherDisabledMap[e.integrationId] = true;
    this.integrationService.changeEnable(e.integrationId, e.integrationType, e.event.checked).subscribe((res: boolean): void => {
      if (!res) {
        return;
      }
      this.integrations.find((f: IntegrationListModel): boolean => f.id === e.integrationId).enabled = e.event.checked;
      this.switcherDisabledMap[e.integrationId] = false;
    });
  }

  public onCopy(e: { originalEvent: MouseEvent; text: string; subject: string }): void {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
    this.clipboard.copy(e.text);
    this.messageService.add({
      key: 'notificationToast',
      severity: 'success',
      summary: `Integration ${e.subject} copied`
    });
  }

  public onClickMoreActions(event: MouseEvent, integration: IntegrationListModel): void {
    this.selectedIntegration = integration;
  }

  public onCloseMoreActions(): void {
    this.selectedIntegration = null;
  }

  public addNewIntegration(): void {
    this.router.navigate(['create'], { relativeTo: this.activatedRoute.parent }).then();
  }

  public upgradePlan(): void {
    this.isLoading = true;
    this.router.navigate(['manage', 'billing']).then();
  }

  public onDetailsClose(event: { originalEvent: MouseEvent; index: number }): void {
    this.setContentTmpHeight(event.index);
    setTimeout((): void => (this.integrations[event.index].additionalInfo = null), 50);
  }

  public onDetailsOpen(event: { originalEvent: MouseEvent; index: number }): void {
    setTimeout((): void => {
      this.integrationService
        .getIntegrationInfo(this.integrations[event.index].id, this.integrations[event.index].integrationType)
        .pipe(delay(1000), first())
        .subscribe((res: IntegrationInfoData): any => {
          this.integrations[event.index].additionalInfo = res.additionalInfo;
        });
    });
  }

  public refreshHandler(): void {
    this.integrationService.refreshIntegration(this.selectedIntegration.id, this.selectedIntegration.integrationType).subscribe();
  }

  public runHandler(): void {
    this.integrationService.runIntegration(this.selectedIntegration.id, this.selectedIntegration.integrationType).subscribe();
  }

  public editHandler(): void {
    this.router.navigate(['integrations', this.selectedIntegration.id], { state: { integrationType: this.selectedIntegration.integrationType } }).then();
  }

  public duplicateHandler(): void {
    this.router.navigate(['integrations', this.selectedIntegration.id, 'duplicate'], { state: { integrationType: this.selectedIntegration.integrationType } }).then();
  }

  public removeHandler(selectedIntegration: ConfirmationModalModel): void {
    this.integrationService.deleteIntegration(selectedIntegration.id, selectedIntegration.data);
  }

  public setContentTmpHeight(id: number): void {
    const elem = this.elemRef.nativeElement.querySelector(`#AccordionIndex-${id} .p-accordion-content`);
    this.renderer.setStyle(elem, 'height', elem.offsetHeight + 'px');

    setTimeout((): void => {
      this.renderer.setStyle(elem, 'height', 'auto');
    });
  }

  public applyIntegrationFilter(event: IntegrationSourceModel): void {
    this.integrationService.setSelectedFilter(event);
    this.integrationService.filterIntegrations(this.searchInput);
  }

  public searchChange($event: string): void {
    this.integrationService.filterIntegrations($event);
  }

  public getMenuPopupModel(integration: IntegrationListModel): MenuPopupModel {
    this.menuPopupModel.menuItems = [
      {
        iconLeft: 'pi-pencil',
        label: 'Edit',
        disabled: this.userService.isSubscriptionExpired(),
        command: (): void => {
          this.editHandler();
        }
      },
      {
        iconLeft: 'pi-clone',
        label: 'Duplicate',
        disabled: this.userService.isSubscriptionExpired(),
        command: (): void => {
          this.duplicateHandler();
        }
      },
      {
        iconLeft: 'pi-trash',
        label: 'Remove',
        command: (): void => {
          const selected: ConfirmationModalModel = {
            id: this.selectedIntegration.id,
            icon: this.selectedIntegration.icon,
            title: 'Delete Integration.',
            subtitle: 'You sure you want to delete integration?',
            description: `"${this.selectedIntegration.title}"`,
            data: this.selectedIntegration.integrationType
          };

          this.rentaModalsService
            .showConfirmationModal(selected)
            .onClose.pipe(first())
            .subscribe((res: boolean): void => {
              if (res) {
                this.removeHandler(selected);
              }
            });
        }
      }
    ];

    // TODO: clarify for others streaming items.(addons)
    if (integration.integrationType !== IntegrationTypeEnum.javascript_sdk) {
      const runMenuItem = {
        iconLeft: 'pi-play',
        label: 'Run',
        disabled: this.userService.isSubscriptionExpired(),
        command: (): void => {
          this.runHandler();
        }
      };

      const refreshMenuItem = {
        iconLeft: 'pi-refresh',
        label: 'Full refresh',
        disabled: this.userService.isSubscriptionExpired(),
        command: (): void => {
          this.refreshHandler();
        }
      };
      this.menuPopupModel.menuItems.splice(0, 0, runMenuItem);
      this.menuPopupModel.menuItems.splice(1, 0, refreshMenuItem);
    }

    return this.menuPopupModel;
  }

  private setPopupMenu(): void {
    this.menuPopupModel.classList = 'top-right more-action-popup-menu anime-appear';
    this.menuPopupModel.horizontal = GbuttonHorizontalEnum.left;
    this.menuPopupModel.top = 16;
    this.menuPopupModel.left = 5;
  }
}
