import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RentaApiService } from './renta-api.service';
import { map, tap } from 'rxjs/operators';
import { IntegrationEditInfo } from '../models/Integration/IntegrationEditInfo';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class EditIntegrationGuard implements CanActivate {
  constructor(private apiService: RentaApiService, private router: Router) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const integrationId = route.paramMap.get('id');
    const navigation = this.router.getCurrentNavigation();
    const navigationState = navigation.extras.state as {
      integrationType: string,
    };

    if (!integrationId || _.isNil(navigationState?.integrationType)) {
      return of<boolean>(false);
    }

    return this.apiService.getIntegrationTokenData(integrationId, navigationState?.integrationType).pipe(
      tap<IntegrationEditInfo>((res: IntegrationEditInfo): any => {
        route.data = { ...route.data, integrationEditInfo: res };
      }),
      map<IntegrationEditInfo, boolean>((res: IntegrationEditInfo): boolean => {
        return res !== null;
      })
    );
  }
}
