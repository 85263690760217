import { Component, OnDestroy, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';
import * as _ from 'lodash';

import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { RentaChipsDataSource } from '../../../../../shared/models/DataSource/RentaChipsDataSource';
import { RentaMultiselectDropdownDataSource } from '../../../../../shared/models/DataSource/RentaMultiselectDropdownDataSource';
import { GaSettingsService } from '../../../../services/integrationSettings/ga-settings.service';
import { ChipsModel } from '../../../../../shared/models/ChipsModel';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { getSelectedChips } from '../../../../../../helpers/helpers';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';
import { RentaSettingsFiltersDataSource } from '../../../../../shared/models/DataSource/RentaSettingsFiltersDataSource';

@Component({
  templateUrl: './ga-settings.component.html',
  styleUrls: ['../common-settings.scss'],
})
export class GaSettingsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean = true;
  public AccountDataSource: RentaDropdownDataSource;
  public PropertyDataSource: RentaDropdownDataSource;
  public chipsParametersDataSource: RentaChipsDataSource;
  public ParametersDataSource: RentaMultiselectDropdownDataSource;
  public FiltersDataSource: RentaSettingsFiltersDataSource;

  constructor(private settingsService: GaSettingsService) {
    super(IntegrationTypeEnum.ga);
  }

  public ngOnInit(): void {
    this.AccountDataSource = new RentaDropdownDataSource('Account', []);
    this.PropertyDataSource = new RentaDropdownDataSource('Property', []);
    this.ParametersDataSource = new RentaMultiselectDropdownDataSource('Metrics and Dimensions', []);
    this.chipsParametersDataSource = new RentaChipsDataSource('Selected Parameters', []);
    this.FiltersDataSource = new RentaSettingsFiltersDataSource('Filters', []);

    this.connectParamsChipsDS(this.chipsParametersDataSource, this.settingsService);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
        this.AccountDataSource.setLoading(res);
        this.PropertyDataSource.setLoading(res);
        this.ParametersDataSource.setLoading(res);
        this.chipsParametersDataSource.setLoading(res);
        this.FiltersDataSource.setLoading(res);
        this.refreshRelatedDataSources();
    });

    this.settingsService.clearErrors.subscribe((__: Array<ErrorMessage>): void => {
      this.clearErrors();
    });

    this.settingsService.connectToLoading().subscribe((res: boolean): void => {
      this.isLoaded = !res;
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      this.AccountDataSource.refresh(_.cloneDeep(res[this.integrationType].Account));
      this.PropertyDataSource.refresh(_.cloneDeep(res[this.integrationType].Property));
      this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
      const chipsParamsDs = getSelectedChips(res[this.integrationType].Parameters);
      this.chipsParametersDataSource.refresh(chipsParamsDs);
      this.FiltersDataSource.refresh(_.cloneDeep(res[this.integrationType].Filters));
      this.settingsService.initCache();

      this.setRelatedDataSources([
        this.AccountDataSource,
        this.PropertyDataSource,
        this.ParametersDataSource,
        this.FiltersDataSource,
      ]);
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onApplyAccount($event: ListItemModel): void {
    if (!this.settingsService.setAccount($event)) {
      return;
    }
    this.PropertyDataSource.setLoading(true);
    this.refreshRelatedDataSources();
    this.settingsService
      .getProperties($event)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.PropertyDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.PropertyDataSource.refresh(_.cloneDeep(res));
        }
      });
  }

  public onApplyProperty($event: ListItemModel): void {
    if (!this.settingsService.setProperty($event)) {
      return;
    }
    this.ParametersDataSource.setLoading(true);
    this.FiltersDataSource.setLoading(true);
    this.refreshRelatedDataSources();

    this.settingsService
      .getParameters()
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(res);
          this.chipsParametersDataSource.refresh(getSelectedChips(res));

          this.settingsService.getFilters().subscribe((response: Array<ListItemModel>): void => {
            this.FiltersDataSource.setLoading(false);
            this.refreshRelatedDataSources();
            if (response.length > 0) {
              this.settingsService.compareFiltersWithCacheData(response);
              this.FiltersDataSource.refresh(response);
            }
          });
        }
      });
  }


  public onCloseChip(e: ChipsModel): void {
    this.ParametersDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
    setTimeout((): void => {
      this.settingsService.setDataToCache();
    });
  }

  public onApplyParameters($event: Array<ListItemModel>): void {
    this.onApplyParams($event, this.chipsParametersDataSource);
    this.settingsService.setDataToCache();
  }

  public onApplyFilters($event: Array<ListItemModel>): void {
    this.settingsService.setFilters($event);
    this.settingsService.setDataToCache();
  }
}
