import { RentaInputDataSource } from './RentaInputDataSource';
import { RentaDropdownDataSource } from './RentaDropdownDataSource';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

export class KeyValueDropdownRowDataSource {
  private ds: {key: RentaInputDataSource, values: RentaDropdownDataSource};
  private dataSource: BehaviorSubject<{ key: RentaInputDataSource, values: RentaDropdownDataSource }> = new BehaviorSubject<{key: RentaInputDataSource; values: RentaDropdownDataSource}>(null);
  constructor(key: RentaInputDataSource, values: RentaDropdownDataSource){
    this.ds = {key, values };
    this.dataSource.next(this.ds);
  }

  public connect(): Observable<{ key: RentaInputDataSource, values: RentaDropdownDataSource }> {
    return this.dataSource.asObservable();
  }

  public setKey(key: string): void {
    const ds = this.dataSource.getValue();
    ds.key.refreshValue(key);
    this.dataSource.next(ds);
  }

  public getSelectedKeyValueRow(): { parameter: string, value: string } {
    const ds = this.dataSource.getValue();
    return {parameter: ds.key.getValue(), value: ds.values.getSelectedItem()?.id}
  }

  public isValidRow(): boolean {
    const row = this.getSelectedKeyValueRow();
    return _.some(row.value) && _.some(row.parameter);
  }

  public setErrorRow(message: string): void {
    const ds = this.dataSource.getValue();
    ds.key.setError(message);
    this.dataSource.next(ds);
  }
}
