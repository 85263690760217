<div class="integration-source-container integration-destination-container p-d-flex p-flex-column p-justify-center">
  <p-accordion [multiple]="true"
               *ngIf="availableIntegrations && availableIntegrations.length > 0; else notFound"
               (onOpen)="onTabOpen($event)">
    <p-accordionTab *ngFor="let integration of availableIntegrations; let i = index"
                    (click)="headerClick({index: i, event:$event})"
                    [(selected)]="accordionActiveState[i]">
      <ng-template pTemplate="header">
        <app-header-row-template [Title]="integration.title" [IconSrc]="integration.icon"></app-header-row-template>
      </ng-template>
      <ng-template pTemplate="content">
        <ng-container *ngIf="integration.tokens | async; let list else loading;">
          <div class="integration-token-wrapper" [class.disabled-curtain]="isDisabled">
            <div class="disabled-curtain-container"></div>
            <div class="integration-token-row-container">
              <app-add-token-row-template [Title]="'Add ' + integration.title + ' destination'"
                                          (OnAddToken)="onAddToken({index: i, event:$event})"></app-add-token-row-template>
            </div>
            <app-integration-token-row-template
              class="integration-token-row-container error-mini-view"
              *ngFor="let integrationToken of list; let tokenInd = index"
              [TokenViewModel]="{
                additionalInfo: integrationToken.additionalInfo,
                error: integrationToken.error,
                title: integrationToken.title,
                tokenId: integrationToken.id,
                icon: integrationToken.icon,
                tokenType: integration.integrationType.toString(),
                isLoading: false
              }"
              [IsLast]="tokenInd !== list.length - 1"
              [CanEdit]='canEdit(integration)'
              (OnEdit)="onEditToken($event, i, integration, integrationToken)"
              (OnRemove)="onRemoveToken($event, i, integration, integrationToken)"
              (OnClick)="selectAccount({
                integration: integration,
                integrationToken: $event,
                integrationIndex: i
              })">
            </app-integration-token-row-template>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #loading>
        <div class="integration-token-wrapper">
          <app-integration-token-rows-loading-template
            class="integration-token-row-container"
            *ngFor="let tmp of [].constructor(integration.accounts + 1)">
          </app-integration-token-rows-loading-template>
        </div>
      </ng-template>
    </p-accordionTab>
  </p-accordion>
</div>
<ng-template #notFound>
  <div class="integration-not-found-msg-container p-d-flex p-justify-center width-max">
    <div class="msg-content p-d-flex p-flex-column p-align-center">
      <div class="msg-image">
        <img src="./assets/icons/search-engine-optimization.png" alt="not found image" />
      </div>
      <div class="msg-text">
        <span class="renta-font-normal">Nothing found on this keywords</span>
      </div>
    </div>
  </div>
</ng-template>

