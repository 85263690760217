<div class="renta-dialog-block-container">
  <div class="progress-bar-container" *ngIf="loading">
    <div class="progress-bar">
      <p-progressBar mode="indeterminate" styleClass="auth-progress"></p-progressBar>
    </div>
  </div>
  <div class="dialog-header-block p-d-flex p-flex-column p-align-center">
    <span class="title">Set Profile Picture</span>
    <app-renta-error-message [errorMessage]="errorMsg" *ngIf="errorMsg"></app-renta-error-message>
  </div>
  <div class="dialog-content-block">
    <app-cropper-block
      [imgSource]="imgSourceUrl"
      [height]="'256'"
      (cropImageHandler)="getCropImage($event)"></app-cropper-block>
  </div>
  <div class="dialog-footer-block">
    <div class="btn-container p-d-flex p-align-center p-justify-end">
      <button pButton pRipple type="button" label="cancel" (click)="cancelHandler()"
              class="p-button-secondary"></button>
      <button pButton pRipple type="button"
              [class.disabled]="loading"
              label="confirm" (click)="saveHandler()"></button>
    </div>
  </div>
</div>
