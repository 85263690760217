import { animate, state, style, transition, trigger } from '@angular/animations';

const interval = 160;
const space = 30;

export const slideTopTrigger = trigger('slideTop', [
  state('void', style({ transform: `translateY(${space}px)` })),
  transition(':enter, :leave', [animate(interval)]),
]);

export const slideBottomTrigger = trigger('slideBottom', [
  state('void', style({ transform: `translateY(${-space}px)` })),
  transition(':enter, :leave', [animate(interval)]),
]);

export const slideLefttTrigger = trigger('slideLeft', [
  state('void', style({ transform: `translateX(${space}px)` })),
  transition(':enter, :leave', [animate(interval)]),
]);

export const slideRightTrigger = trigger('slideRight', [
  state('void', style({ transform: `translateX(-${space}px)` })),
  transition(':enter, :leave', [animate(interval)]),
]);

export const slideInOut = trigger('slideInOut', [
  transition(':enter', [
    style({transform: 'translateY(-100%)'}),
    animate('200ms ease-in', style({transform: 'translateY(0%)'}))
  ]),
  transition(':leave', [
    animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
  ])
]);
