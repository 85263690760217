import * as _ from 'lodash';
import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  forwardRef,
  TemplateRef,
  Input,
  OnInit
} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { DateRangeDataSourceModel } from '../../../models/DataSource/DataSourceModels/DateRangeDataSourceModel';
import { CustomDateRangeViewModelEnum } from '../../../models/DataSource/RentaDateRangeDataSource';

export const AP_CALENDAR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef((): any => ExtendedPrimeCalendarComponent),
  multi: true
};

interface CustomDateModel {
  title: string;
  value: string;
}

@Component({
  selector: 'app-extended-prime-calendar',
  template: `
    <span
      #container
      [ngClass]="{ 'p-calendar': true, 'p-calendar-w-btn': showIcon, 'p-calendar-timeonly': timeOnly }"
      [ngStyle]="style"
      [class]="styleClass"
      class="p-input-icon-right"
    >
      <i class="drop-down-icon pi pi-calendar"></i>
      <ng-template [ngIf]="!inline">
        <input
          #inputfield
          type="text"
          [attr.id]="inputId"
          [attr.name]="name"
          [attr.required]="required"
          [attr.aria-required]="required"
          [(ngModel)]="inputValue"
          (focus)="onInputFocus($event)"
          (keydown)="onInputKeydown($event)"
          (click)="onInputClick()"
          [readonly]="readonlyInput"
          (input)="onUserInput($event)"
          [ngStyle]="inputStyle"
          [class]="inputStyleClass"
          [placeholder]="placeholder || ''"
          [disabled]="disabled"
          [attr.tabindex]="tabindex"
          [attr.inputmode]="touchUI ? 'off' : null"
          [ngClass]="'p-inputtext p-component dropdown-search-input'"
          autocomplete="off"
          [attr.aria-labelledby]="ariaLabelledBy"
        /><button
        type="button"
        [icon]="icon"
        pButton
        pRipple
        *ngIf="showIcon"
        (click)="onButtonClick($event, inputfield)"
        class="p-datepicker-trigger"
        [disabled]="disabled"
        tabindex="0"
      ></button>
      </ng-template>
      <div
        #contentWrapper
        [class]="panelStyleClass"
        [ngStyle]="panelStyle"
        [ngClass]="{
          'p-datepicker p-component': true,
          'p-datepicker-inline': inline,
          'p-disabled': disabled,
          'p-datepicker-timeonly': timeOnly,
          'p-datepicker-multiple-month': this.numberOfMonths > 1,
          'p-datepicker-monthpicker': view === 'month',
          'p-datepicker-touch-ui': touchUI
        }"
        [@overlayAnimation]="
          touchUI
            ? {
                value: 'visibleTouchUI',
                params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
              }
            : { value: 'visible', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }
        "
        [@.disabled]="inline === true"
        (@overlayAnimation.start)="onOverlayAnimationStart($event)"
        (@overlayAnimation.done)="onOverlayAnimationDone($event)"
        *ngIf="inline || overlayVisible"
      >
        <ng-content select="p-header"></ng-content>
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
        <ng-container *ngIf="!timeOnly">
          <div class="p-datepicker-group-container">
            <div class="p-datepicker-group" *ngFor="let month of months; let i = index">
              <ng-content select=".ap-datepicker-header"></ng-content>
              <ng-container *ngTemplateOutlet="calendarHeader"></ng-container>
              <div class="p-datepicker-header">
                <button
                  (keydown)="onContainerButtonKeydown($event)"
                  class="p-datepicker-prev p-link"
                  (click)="onPrevButtonClick($event)"
                  *ngIf="i === 0"
                  type="button"
                  pRipple
                >
                  <span class="p-datepicker-prev-icon pi pi-chevron-left"></span>
                </button>
                <div
                  class="p-datepicker-title custom-p-datepicker-title p-d-flex p-align-center p-justify-center width-max"
                  *ngIf="customNavigationMode"
                >
                  <div class="title-container" *ngIf="!monthNavigator && view !== 'month'">
                    <span class="p-datepicker-month">{{ getTranslation('monthNames')[month.month] }}</span>
                    <span class="p-datepicker-year"
                          *ngIf="!yearNavigator">{{ view === 'month' ? currentYear : month.year }}</span>
                  </div>
                  <div class="custom-month-navigation-container mr-r-5"
                       *ngIf="monthNavigator && view !== 'month' && numberOfMonths === 1">
                    <p-dropdown
                      [options]="convertMonthModel(getTranslation('monthNames'), month.month)"
                      [(ngModel)]="selectedMonth"
                      optionLabel="title"
                      optionValue="value"
                      (ngModelChange)="onMonthDropdownChange(selectedMonth)"
                    ></p-dropdown>
                  </div>
                  <div class="custom-year-navigation-container mr-l-5" *ngIf="yearNavigator && numberOfMonths === 1">
                    <p-dropdown
                      [options]="convertYearModel(yearOptions, currentYear)"
                      [(ngModel)]="selectedYear"
                      optionLabel="title"
                      optionValue="title"
                      (ngModelChange)="onYearDropdownChange(selectedYear)"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="p-datepicker-title" *ngIf="!customNavigationMode">
                  <span class="p-datepicker-month" *ngIf="!monthNavigator && view !== 'month'">{{
                    getTranslation('monthNames')[month.month]
                    }}</span>
                  <select
                    tabindex="0"
                    class="p-datepicker-month"
                    *ngIf="monthNavigator && view !== 'month' && numberOfMonths === 1"
                    (change)="onMonthDropdownChange($event.target.value)"
                  >
                    <option
                      [value]="i"
                      *ngFor="let monthName of getTranslation('monthNames'); let i = index"
                      [selected]="i === month.month"
                    >
                      {{ monthName }}
                    </option>
                  </select>
                  <select
                    tabindex="0"
                    class="p-datepicker-year"
                    *ngIf="yearNavigator && numberOfMonths === 1"
                    (change)="onYearDropdownChange($event.target.value)"
                  >
                    <option [value]="year" *ngFor="let year of yearOptions"
                            [selected]="year === currentYear">{{ year }}</option>
                  </select>
                  <span class="p-datepicker-year"
                        *ngIf="!yearNavigator">{{ view === 'month' ? currentYear : month.year }}</span>
                </div>
                <button
                  (keydown)="onContainerButtonKeydown($event)"
                  class="p-datepicker-next p-link"
                  (click)="onNextButtonClick($event)"
                  *ngIf="numberOfMonths === 1 ? true : i === numberOfMonths - 1"
                  type="button"
                  pRipple
                >
                  <span class="p-datepicker-next-icon pi pi-chevron-right"></span>
                </button>
              </div>
              <div class="p-datepicker-calendar-container" *ngIf="view === 'date'">
                <table class="p-datepicker-calendar">
                  <thead>
                    <tr>
                      <th *ngIf="showWeek" class="p-datepicker-weekheader p-disabled">
                        <span>{{ getTranslation('weekHeader') }}</span>
                      </th>
                      <th scope="col" *ngFor="let weekDay of weekDays; let begin = first; let end = last">
                        <span>{{ weekDay }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let week of month.dates; let j = index">
                      <td *ngIf="showWeek" class="p-datepicker-weeknumber">
                        <span>
                          {{ month.weekNumbers[j] }}
                        </span>
                      </td>
                      <td
                        *ngFor="let date of week; let indexOfelement = index"
                        [ngClass]="{
                          'p-datepicker-other-month': date.otherMonth,
                          'p-datepicker-today': date.today,
                          'selected-date-between': isSelected(date),
                          'selected-date-first': isFirstSelected(date),
                          'selected-date-last': isLastSelected(date),
                          'selected-date-only': isFirstSelected(date) && isLastSelected(date)
                        }"
                      >
                        <ng-container *ngIf="date.otherMonth ? showOtherMonths : true">
                          <span
                            [ngClass]="{
                              'p-highlight': isSelected(date),
                              'p-disabled': !date.selectable
                            }"
                            (click)="onDateSelect($event, date)"
                            draggable="false"
                            (keydown)="onDateCellKeydown($event, date, i)"
                            pRipple
                          >
                            <ng-container *ngIf="!dateTemplate">{{ date.day }}</ng-container>
                            <ng-container *ngTemplateOutlet="dateTemplate; context: { $implicit: date }"></ng-container>
                          </span>
                        </ng-container>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="p-monthpicker" *ngIf="view === 'month'">
            <span
              *ngFor="let m of monthPickerValues; let i = index"
              (click)="onMonthSelect($event, i)"
              (keydown)="onMonthCellKeydown($event, i)"
              class="p-monthpicker-month"
              [ngClass]="{ 'p-highlight': isMonthSelected(i), 'p-disabled': !isSelectable(1, i, this.currentYear, false) }"
              pRipple
            >
              {{ m }}
            </span>
          </div>
        </ng-container>
        <div class="p-timepicker" *ngIf="showTime || timeOnly">
          <div class="p-hour-picker">
            <button
              class="p-link"
              type="button"
              (keydown)="onContainerButtonKeydown($event)"
              (keydown.enter)="incrementHour($event)"
              (mousedown)="onTimePickerElementMouseDown($event, 0, 1)"
              (mouseup)="onTimePickerElementMouseUp($event)"
              (mouseout)="onTimePickerElementMouseOut($event)"
              pRipple
            >
              <span class="pi pi-chevron-up"></span>
            </button>
            <span><ng-container *ngIf="currentHour < 10">0</ng-container>
              {{ currentHour }}</span>
            <button
              class="p-link"
              type="button"
              (keydown)="onContainerButtonKeydown($event)"
              (keydown.enter)="decrementHour($event)"
              (mousedown)="onTimePickerElementMouseDown($event, 0, -1)"
              (mouseup)="onTimePickerElementMouseUp($event)"
              (mouseout)="onTimePickerElementMouseOut($event)"
              pRipple
            >
              <span class="pi pi-chevron-down"></span>
            </button>
          </div>
          <div class="p-separator">
            <span>{{ timeSeparator }}</span>
          </div>
          <div class="p-minute-picker">
            <button
              class="p-link"
              type="button"
              (keydown)="onContainerButtonKeydown($event)"
              (keydown.enter)="incrementMinute($event)"
              (mousedown)="onTimePickerElementMouseDown($event, 1, 1)"
              (mouseup)="onTimePickerElementMouseUp($event)"
              (mouseout)="onTimePickerElementMouseOut($event)"
              pRipple
            >
              <span class="pi pi-chevron-up"></span>
            </button>
            <span><ng-container *ngIf="currentMinute < 10">0</ng-container>
              {{ currentMinute }}</span>
            <button
              class="p-link"
              type="button"
              (keydown)="onContainerButtonKeydown($event)"
              (keydown.enter)="decrementMinute($event)"
              (mousedown)="onTimePickerElementMouseDown($event, 1, -1)"
              (mouseup)="onTimePickerElementMouseUp($event)"
              (mouseout)="onTimePickerElementMouseOut($event)"
              pRipple
            >
              <span class="pi pi-chevron-down"></span>
            </button>
          </div>
          <div class="p-separator" *ngIf="showSeconds">
            <span>{{ timeSeparator }}</span>
          </div>
          <div class="p-second-picker" *ngIf="showSeconds">
            <button
              class="p-link"
              type="button"
              (keydown)="onContainerButtonKeydown($event)"
              (keydown.enter)="incrementSecond($event)"
              (mousedown)="onTimePickerElementMouseDown($event, 2, 1)"
              (mouseup)="onTimePickerElementMouseUp($event)"
              (mouseout)="onTimePickerElementMouseOut($event)"
              pRipple
            >
              <span class="pi pi-chevron-up"></span>
            </button>
            <span><ng-container *ngIf="currentSecond < 10">0</ng-container>
              {{ currentSecond }}</span>
            <button
              class="p-link"
              type="button"
              (keydown)="onContainerButtonKeydown($event)"
              (keydown.enter)="decrementSecond($event)"
              (mousedown)="onTimePickerElementMouseDown($event, 2, -1)"
              (mouseup)="onTimePickerElementMouseUp($event)"
              (mouseout)="onTimePickerElementMouseOut($event)"
              pRipple
            >
              <span class="pi pi-chevron-down"></span>
            </button>
          </div>
          <div class="p-ampm-picker" *ngIf="hourFormat == '12'">
            <button
              class="p-link"
              type="button"
              (keydown)="onContainerButtonKeydown($event)"
              (click)="toggleAMPM($event)"
              (keydown.enter)="toggleAMPM($event)"
              pRipple
            >
              <span class="pi pi-chevron-up"></span>
            </button>
            <span>{{ pm ? 'PM' : 'AM' }}</span>
            <button
              class="p-link"
              type="button"
              (keydown)="onContainerButtonKeydown($event)"
              (click)="toggleAMPM($event)"
              (keydown.enter)="toggleAMPM($event)"
              pRipple
            >
              <span class="pi pi-chevron-down"></span>
            </button>
          </div>
        </div>
        <div class="p-datepicker-buttonbar" *ngIf="showButtonBar">
          <button
            type="button"
            [label]="getTranslation('today')"
            (keydown)="onContainerButtonKeydown($event)"
            (click)="onTodayButtonClick($event)"
            pButton
            pRipple
            [ngClass]="[todayButtonStyleClass]"
          ></button>
          <button
            type="button"
            [label]="getTranslation('clear')"
            (keydown)="onContainerButtonKeydown($event)"
            (click)="onClearButtonClick($event)"
            pButton
            pRipple
            [ngClass]="[clearButtonStyleClass]"
          ></button>
        </div>
        <ng-content select="p-footer"></ng-content>
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </div>
    </span>
  `,
  animations: [
    trigger('overlayAnimation', [
      state(
        'visibleTouchUI',
        style({
          transform: 'translate(-50%,-50%)',
          opacity: 1
        })
      ),
      transition('void => visible', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate('{{showTransitionParams}}', style({ opacity: 1, transform: '*' }))
      ]),
      transition('visible => void', [animate('{{hideTransitionParams}}', style({ opacity: 0 }))]),
      transition('void => visibleTouchUI', [
        style({ opacity: 0, transform: 'translate3d(-50%, -40%, 0) scale(0.9)' }),
        animate('{{showTransitionParams}}')
      ]),
      transition('visibleTouchUI => void', [
        animate(
          '{{hideTransitionParams}}',
          style({
            opacity: 0,
            transform: 'translate3d(-50%, -40%, 0) scale(0.9)'
          })
        )
      ])
    ])
  ],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    '[class.p-inputwrapper-filled]': 'filled',
    '[class.p-inputwrapper-focus]': 'focus'
  },
  providers: [AP_CALENDAR_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./extended-prime-calendar.component.scss', './prime-calendar.component.scss', '../base-settings-styles.css']
})
export class ExtendedPrimeCalendarComponent extends Calendar implements OnInit {
  @Input()
  public dataSource: Observable<DateRangeDataSourceModel>;

  @Input()
  public calendarHeader: TemplateRef<any>;

  @Input()
  public untilTodayDateMode: boolean = false;

  @Input()
  public untilYesterdayDateMode: boolean = false;

  @Input()
  public customNavigationMode: boolean = false;

  public selectedMonth: string;
  public selectedYear: string;
  public inputValue: string;

  public ngOnInit(): void {
    this.dataSource.subscribe((res: DateRangeDataSourceModel): void => {
      if (res.DateRangeEnum !== CustomDateRangeViewModelEnum.custom &&
        res.DateRangeEnum !== CustomDateRangeViewModelEnum.untilToday &&
        res.DateRangeEnum !== CustomDateRangeViewModelEnum.untilYesterday) {
        this.inputValue = res.DateRangeEnum;
        return;
      }

      if (res.DateRangeEnum === CustomDateRangeViewModelEnum.untilToday ||
        res.DateRangeEnum === CustomDateRangeViewModelEnum.untilYesterday) {
        this.inputValue = `${moment(res.range[0]).format('DD.MM.yyyy')} - ${res.DateRangeEnum}`;
        return;
      }

      this.inputValue = `${moment(res.range[0]).format('DD.MM.yyyy')} - ${moment(res.range[1]).format('DD.MM.yyyy')}`;
    });
  }

  public convertMonthToDropdownDataSourceModel(months: Array<string>, month: number): Array<any> {
    return months.map((date: string, idx: number): any => {
      const tmpDate = {
        id: idx.toString(),
        name: date.toString(),
        selected: false
      };

      if (month.toString() === idx.toString()) {
        tmpDate.selected = true;
      }

      return tmpDate;
    });
  }

  public convertMonthModel(months: Array<string>, month: number): Array<CustomDateModel> {
    return months.map((date: string, idx: number): CustomDateModel => {
      const tmpDate: CustomDateModel = {
        title: date.toString(),
        value: idx.toString()
      };

      if (month.toString() === idx.toString()) {
        this.selectedMonth = month.toString();
      }

      return tmpDate;
    });
  }

  public convertYearModel(years: Array<number>, year: number): Array<CustomDateModel> {
    return years.map((date: number, idx: number): CustomDateModel => {
      const tmpDate: CustomDateModel = {
        title: date.toString(),
        value: idx.toString()
      };

      if (year.toString() === date.toString()) {
        this.selectedYear = date.toString();
      }

      return tmpDate;
    });
  }

  public selectDate(date: any): void {
    super.selectDate(date);

    if (!this.value[1]) {
      if (this.untilTodayDateMode) {
        this.value[1] = moment().toDate();
      }
      if (this.untilYesterdayDateMode) {
        this.value[1] = moment({}).add(-1, 'day').toDate();
      }
    }
  }

  public isFirstSelected(date: any): boolean {
    if (!this.isSelected(date)) {
      return false;
    }

    return date.day === this.value[0].getDate() && date.month === this.value[0].getMonth() && date.year === this.value[0].getFullYear();
  }

  public isLastSelected(date: any): boolean {
    if (!this.isSelected(date) || _.isNil(this.value[1])) {
      return false;
    }

    return date.day === this.value[1].getDate() && date.month === this.value[1].getMonth() && date.year === this.value[1].getFullYear();
  }

  public bindScrollListener(): void {
    return;
  }
}
