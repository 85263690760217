import { DataSourceBase } from './DataSourceBase';
import { RentaInputDataSourceModel } from './DataSourceModels/RentaInputDataSourceModel';

export class RentaInputDataSource extends DataSourceBase<RentaInputDataSourceModel> {
  public name: string;

  constructor(name: string, dataSource: RentaInputDataSourceModel) {
    super(dataSource);
    this.name = name;
  }

  public getValue(): string {
    const a = this.dataSource.getValue()?.value;
    return this.dataSource.getValue()?.value;
  }

  public setError(error: string): void {
    this.error = error;
  }

  public refreshValue(value: string): void {
    const tmp = this.dataSource.getValue();
    tmp.value = value;
    this.sourceData = tmp;
    this.dataSource.next(this.sourceData);
  }

  public setDisabled(isDisabled: boolean): void {
    const ds = this.dataSource.getValue();
    ds.disabled = isDisabled;
    this.refresh(ds);
  }
}
