import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { RentaApiService } from '../../../../services/renta-api.service';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { WebhookAddonMeta } from '../../../../models/Integration/IntegrationsMeta/WebhookAddonMeta';
import * as _ from 'lodash';
import { ApiResponse } from '../../../../models/common/ApiResponse`T';
import { SettingsServiceBase } from './SettingsServiceBase';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class WebhookAddonSettingsService extends SettingsServiceBase<WebhookAddonMeta>{
  public meta: WebhookAddonMeta;
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.webhookAddon;

  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      EventTypes: null,
      EventNames: null,
      Parameters: null,
      Output: null,
      Filters: null
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  constructor(
    public integrationInfoData: IntegrationInfoViewModel,
    protected readonly rentaApiService: RentaApiService,
    protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService, )
  }
  public isETL(): boolean {
    return false;
  }
  public loadSettings(): Observable<IntegrationSettings> {
    if (this.loading.getValue() === false) {
      this.loading.next(true);
      this.rentaApiService.initIntegration(this.getWizardRequestData()).pipe(
        map<any, IntegrationSettings>((res: any): IntegrationSettings => {
          this.meta = res.meta;
          return this.mapIntegrationSettings(res, this.meta.integrationName || '');
        }),
      ).subscribe((res: IntegrationSettings): void => {
        this.initSelectedSettings(res);
        // TODO: UPDATE IT WITH DATA 'Parameters' FROM BACK-END
        if (!this.editMode){
          this.loading.next(false);
          this.sourceIntegrationSettings.next(res);
          return;
        }

        this.getEventParameters(this.selectedSettings.EventTypes[0], this.selectedSettings.EventNames[0])
          .subscribe(resParameters => {
          res[this.integrationTypeEnum].Parameters = resParameters;
          this.loading.next(false);
          this.sourceIntegrationSettings.next(res);
        });
      });
    }

    return this.sourceIntegrationSettings.asObservable().pipe(filter((result: IntegrationSettings): boolean => result !== null));
  }

  public setEventType(selectedEventType: ListItemModel): boolean {
    if (_.isNil(selectedEventType) || _.some(this.selectedSettings.EventTypes, selectedEventType)) {
      return false;
    }

    this.selectedSettings.EventTypes = [selectedEventType];
    return true;
  }

  public setEventName(selectedEventName: ListItemModel): boolean {
    if (_.isNil(selectedEventName) || _.some(this.selectedSettings.EventTypes, selectedEventName)) {
      return false;
    }

    this.selectedSettings.EventNames = [selectedEventName];
    return true;
  }

  public setEventParameters(eventParameters: Array<{ parameter: string, value: string }>): boolean {
    if (_.isNil(eventParameters)) {
      return false;
    }

    this.selectedSettings.Output = eventParameters;
    return true;
  }

  public getEventFilters(selectedEventType: ListItemModel, selectedEventName?: ListItemModel): Observable<Array<ListItemModel>> {
    const req = this.getParametersFiltersRequestData(true, selectedEventType, selectedEventName);
    return this.getParametersFiltersData(req.url, req.body).pipe(map((res: { parameters: Array<ListItemModel>, filters: Array<ListItemModel> }): ListItemModel[] => res.filters));
  }

  public getEventParameters(selectedEventType: ListItemModel, selectedEventName?: ListItemModel): Observable<Array<ListItemModel>> {
    const req = this.getParametersFiltersRequestData(false, selectedEventType, selectedEventName);
    return this.getParametersFiltersData(req.url, req.body).pipe(map((res: { parameters: Array<ListItemModel>, filters: Array<ListItemModel> }): ListItemModel[] => res.parameters));
  }

  public setFilters(selectedFilters: Array<ListItemModel>): boolean {
    if (_.isNil(selectedFilters)) {
      return false;
    }

    this.selectedSettings.Filters = selectedFilters;
    return true;
  }

  public getEventNames(selectedEventType: ListItemModel): Observable<Array<ListItemModel>> {
    const eventType: string = selectedEventType.id;

    if (_.isNil(eventType)) {
      return of<[]>([]);
    }

    const relativeUrl: string = this.meta.eventNamesUrl.replace('{eventType}', eventType);
    const fullUrl: string = `${environment.baseUrl}${relativeUrl}`;

    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };
    return this.rentaApiService.httpClient
      .post<ApiResponse<{ eventNames: Array<ListItemModel> }>>(fullUrl, body)
      .pipe(
        map((res: ApiResponse<{ eventNames: Array<ListItemModel> }>): Array<ListItemModel> => res.result.eventNames)
      );
  }

  public setIntegrationName(name: string): void {
    this.selectedIntegrationSettings.Name = name;
  }

  public connectToSourceDestinationLoading(): Observable<boolean> {
    return of<boolean>(false);
  }

  public setTableName(tableName: string): void {
    console.error("NOT USE 'setTableName' in webhook addon settings")
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].EventTypes = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].EventNames = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].Output = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].Filters = null;
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings): void {
    this.selectedIntegrationSettings.Id = initialSettings.Id;
    this.selectedSettings.EventTypes = initialSettings[this.integrationTypeEnum].EventTypes.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.EventNames = initialSettings[this.integrationTypeEnum].EventNames.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.Filters = initialSettings[this.integrationTypeEnum].Filters.filter(
      (f: ListItemModel): boolean => f.selected
    );
    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );

    this.selectedSettings.Output = initialSettings[this.integrationTypeEnum].Output || [{parameter: '', value: ''}];

    this.selectedIntegrationSettings.Name = initialSettings.Name;

    this.selectedIntegrationSettings.TableName = null;
    this.selectedIntegrationSettings.OverwritePeriod  = null;
    this.selectedIntegrationSettings.DateRange = null;
    this.selectedIntegrationSettings.Date = null;
    this.selectedIntegrationSettings.Schedule = null;
    this.selectedIntegrationSettings.DateRange = null;
  }

  protected mapIntegrationSettings(response: any, integrationName: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: [],
      Date: null,
      Schedule: [],
      OverwritePeriod: [],
      [this.integrationTypeEnum]: {
        EventTypes: response.eventTypes || [],
        EventNames: response.eventNames || [],
        Parameters: response.parameters || [],
        Output: response.output || [],
        Filters: response.filters || []
      },
      Name: integrationName,
      TableName: null
    };
  }

  protected refreshParameters(): Observable<any> {
    return of<{}>(null);
  }

  private getParametersFiltersRequestData(forFilters: boolean, selectedEventType: ListItemModel, selectedEventName?: ListItemModel): {url: string, body: any} {
    const metaUrl = forFilters ? this.meta.filtersUrl : this.meta.parametersUrl;

    let url: string = metaUrl.replace('{eventType}', selectedEventType.id);

    if (_.isNil(selectedEventName)) {
      url = url.replace('{eventName}', '').slice(0, -1);
    } else {
      url = url.replace('{eventName}', selectedEventName?.id || '');
    }

    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };
    return { url, body };
  }

  private getParametersFiltersData(url: string, body: any): Observable<{ parameters: Array<ListItemModel>, filters: Array<ListItemModel> }> {
    const apiUrl = environment.baseUrl + `${url}`

    return this.rentaApiService.httpClient
      .post<ApiResponse<{ parameters: Array<ListItemModel>, filters: Array<ListItemModel> }>>(apiUrl, body)
      .pipe(
        tap((res: ApiResponse<{ parameters: Array<ListItemModel>, filters: Array<ListItemModel> }>): void => console.log('getParametersFiltersData', res)),
        map((res: ApiResponse<{ parameters: Array<ListItemModel>, filters: Array<ListItemModel> }>): { parameters: Array<ListItemModel>, filters: Array<ListItemModel> } => res.result),
        catchError((error: any) => {
          console.error('Error in getParametersFiltersData:', error);

          return of({ parameters: [], filters: [] });
        })
      );
  }

}
