<div class="p-d-flex width-max"  *ngIf='dataSource | async; let ds'>
  <div class="p-ml-1 width-max" [class.p-disabled]='Disabled'>
    <app-renta-dropdown
      [CanSearch]="true"
      [ShowName]="false"
      [placeholder]="'Value'"
      [RentaDropdownSource]="ds.values"
      (OnApply)='onApplyValue($event)'
    ></app-renta-dropdown>

  </div>
  <div class='mapping-arrow-ic p-as-center'>
    <i class="renta-ic renta-ic-arrow"></i>
  </div>
  <div class="p-ml-2 width-max" [class.p-disabled]='Disabled'>
    <app-renta-input
      (OnApply)='onApplyKey($event)'
      [IsShowName]='false'
      [IsTransliterate]='true'
      [RentaInputSource]='ds.key'
    ></app-renta-input>
  </div>
  <div class="p-ml-2">
    <p-button styleClass="renta-btn-secondary" (onClick)="delete()" [disabled]="!CanRemove">
      <i class="pi pi-minus"></i>
    </p-button>
  </div>
</div>
