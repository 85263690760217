import { Component, Input, OnInit, Output } from '@angular/core';
import { TokenViewModel } from '../../../../../models/viewModels/TokenViewModel';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-integration-token-row-template',
  templateUrl: './integration-token-row-template.component.html',
  styleUrls: ['../token-row-styles.scss', './integration-token-row-template.component.css'],
})
export class IntegrationTokenRowTemplateComponent implements OnInit {
  @Input()
  public IsLast: boolean;

  @Input()
  public CanRemove: boolean = true;

  @Input()
  public CanEdit: boolean = true;

  @Input()
  public TokenViewModel: TokenViewModel;

  @Output()
  public OnRemove: EventEmitter<{ originalEvent: MouseEvent; tokenModel: TokenViewModel }> = new EventEmitter<{
    originalEvent: MouseEvent;
    tokenModel: TokenViewModel;
  }>();

  @Output()
  public OnEdit: EventEmitter<{ originalEvent: MouseEvent; tokenModel: TokenViewModel }> = new EventEmitter<{
    originalEvent: MouseEvent;
    tokenModel: TokenViewModel;
  }>();

  @Output()
  public OnClick: EventEmitter<TokenViewModel> = new EventEmitter<TokenViewModel>();

  @Input()
  public IsDisabled: boolean;

  public readonly baseRowHeight: number = 64;
  public rowHeight: string;

  constructor() {}

  public ngOnInit(): void {
    if (this.TokenViewModel.additionalInfo) {
      this.rowHeight = this.baseRowHeight + (this.TokenViewModel.additionalInfo.length - 1) * 17 + 'px';
    }
  }

  public onRemove($event: MouseEvent): void {
    this.OnRemove.emit({ originalEvent: $event, tokenModel: this.TokenViewModel });
  }

  public onEdit($event: MouseEvent): void {
    this.OnEdit.emit({ originalEvent: $event, tokenModel: this.TokenViewModel });
  }

  public onClick($event: MouseEvent): void {
    if (this.IsDisabled) {
      $event.preventDefault();
      $event.stopPropagation();
      return;
    }

    this.OnClick.emit(this.TokenViewModel);
  }
}
