<div class="setting-row-wrapper" >
  <app-renta-multiselect-dropdown
    *ngIf="isLoaded; else loadingSetting"
    (OnApply)="onApplyParameters($event)"
    [RentaMultiselectDropdownSource]="ParametersDataSource"
    [class.disabled]="isDataSourceLoading"
    [hotRefresh]="true"
    [additionalInfo]="'Specify the column names. The first row in the document is defined as the column names.'"
  >
  </app-renta-multiselect-dropdown>
</div>

<div class="setting-row-wrapper width-max" >
  <app-renta-file-column-mapping
    [ColumnMappingDataSource]="columnsDataSource"
    (OnDelete)="onDeleteSelectedColumn($event)"
  ></app-renta-file-column-mapping>
</div>

<ng-template #loadingSetting>
  <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
  <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
</ng-template>
