<div class="calendar-header-container p-d-flex p-flex-column width-max">
  <div class="from-to-date-block p-d-flex width-max" *ngIf="!dateRangeType">
    <div class="block-column">
      <div class="control">
        <div class="control-title">
          <span class="renta-font-small">From:</span>
        </div>
        <div class="control-content" [class.highlight]="highlightDate">
          <input class="renta-font-small p-d-flex"
                 type="text"
                 [value]="dateFrom"
                 [class.disabled]="true">
        </div>
      </div>
    </div>
    <div class="block-column">
      <div class="control">
        <div class="control-title">
          <span class="renta-font-small">To:</span>
        </div>
        <div class="control-content">
          <p-dropdown [options]="dateRangeOptions"
                      [placeholder]="dateTo"
                      [class.highlight]="!highlightDate"
                      [group]="true">
            <ng-template let-group pTemplate="group">
              <div class="drd-element-container"
                   [class.selected-item]="group.selected"
                   (click)="changeDateRange(group)">
                <span class="renta-font-small">{{group.title}}</span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="range-date-block p-d-flex width-max" *ngIf="dateRangeType">
    <div class="block-column">
      <div class="control">
        <div class="control-title">
          <span class="renta-font-small">Tracking Data Range:</span>
        </div>
        <div class="control-content">
          <input type="text" [value]="dateRangeType" [class.disabled]="true">
        </div>
      </div>
    </div>
  </div>
</div>
