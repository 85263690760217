<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="mr-b-5">
    <app-renta-error-message [errorMessage]="error"
                             *ngIf="error"></app-renta-error-message>
  </div>
  <label for="name" class="p-d-none"></label>
  <input id="name" class="p-inputtext-lg auth-dialog-input" appTextInputTrim [(ngModel)]="name" pInputText placeholder="Name"
         type="text"
         [disabled]="loading">
  <label for="clientId" class="p-d-none"></label>
  <input id="clientId" class="p-inputtext-lg auth-dialog-input dialog-input-margin" appTextInputTrim [(ngModel)]="clientId" pInputText placeholder="Client Id"
         type="password"
         [disabled]="loading">
  <label for="clientSecret" class="p-d-none"></label>
  <input id="clientSecret" type="password" pInputText appTextInputTrim class="p-inputtext-lg auth-dialog-input dialog-input-margin" [(ngModel)]="clientSecret"
         placeholder="Client Secret"
         [disabled]="loading">
</div>
