import { Injectable } from '@angular/core';
import { IntegrationEvent } from '../models/events/integration/IntegrationEvent';
import { IntegrationEventType } from '../models/IntegrationEventType';
import { BehaviorSubject, Observable } from 'rxjs';
import { IntegrationCreated } from '../models/events/integration/IntegrationCreated';
import { IntegrationQueued } from '../models/events/integration/IntegrationQueued';
import { IntegrationUpdated } from '../models/events/integration/IntegrationUpdated';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class IntegrationNotificationService {
  private integrationEvents: { [key: string]: BehaviorSubject<any> } = {};

  constructor(private messageService: MessageService) {
    this.integrationEvents[IntegrationEventType.IntegrationError] = new BehaviorSubject<IntegrationEvent>(null);
    this.integrationEvents[IntegrationEventType.IntegrationEnabled] = new BehaviorSubject<IntegrationEvent>(null);
    this.integrationEvents[IntegrationEventType.IntegrationDeleted] = new BehaviorSubject<IntegrationEvent>(null);
    this.integrationEvents[IntegrationEventType.IntegrationStarted] = new BehaviorSubject<IntegrationEvent>(null);
    this.integrationEvents[IntegrationEventType.IntegrationStopped] = new BehaviorSubject<IntegrationEvent>(null);
    this.integrationEvents[IntegrationEventType.IntegrationFinished] = new BehaviorSubject<IntegrationEvent>(null);
    this.integrationEvents[IntegrationEventType.IntegrationDisabled] = new BehaviorSubject<IntegrationEvent>(null);
    this.integrationEvents[IntegrationEventType.IntegrationInitialized] = new BehaviorSubject<IntegrationEvent>(null);
    this.integrationEvents[IntegrationEventType.IntegrationTableDeleted] = new BehaviorSubject<IntegrationEvent>(null);
    this.integrationEvents[IntegrationEventType.IntegrationTableCreated] = new BehaviorSubject<IntegrationEvent>(null);
    this.integrationEvents[IntegrationEventType.IntegrationProgressUpdated] = new BehaviorSubject<IntegrationEvent>(null);
    this.integrationEvents[IntegrationEventType.IntegrationQueued] = new BehaviorSubject<IntegrationQueued>(null);
    this.integrationEvents[IntegrationEventType.IntegrationCreated] = new BehaviorSubject<IntegrationCreated>(null);
    this.integrationEvents[IntegrationEventType.IntegrationUpdated] = new BehaviorSubject<IntegrationUpdated>(null);
    this.integrationEvents[IntegrationEventType.IntegrationFinished] = new BehaviorSubject<IntegrationEvent>(null);
    this.integrationEvents[IntegrationEventType.IntegrationCanceled] = new BehaviorSubject<IntegrationEvent>(null);

  }

  public onEmit(eventMessage: any): void {
    const { eventType } = eventMessage;

    if (!Object.keys(this.integrationEvents).includes(eventType)) {
      return;
    }

    this.showNotification(eventType);

    this.integrationEvents[eventType].next(eventMessage);
  }

  public onEvent<T>(eventType: IntegrationEventType): Observable<T> {
    return this.integrationEvents[eventType].asObservable().pipe(filter((f: T): boolean => !_.isNil(f)));
  }

  private showNotification(eventType: string): void {
    switch (eventType) {
      case 'IntegrationEnabled':
        this.messageService.add({
          key: 'notificationToast',
          severity: 'success',
          summary: 'Integration enabled',
        });
        break;
      case 'IntegrationDisabled':
        this.messageService.add({
          key: 'notificationToast',
          severity: 'info',
          summary: 'Integration disabled',
        });
        break;
      case 'IntegrationQueued':
        this.messageService.add({
          key: 'notificationToast',
          severity: 'info',
          summary: 'Integration run queued',
        });
        break;
      case 'IntegrationStarted':
        this.messageService.add({
          key: 'notificationToast',
          severity: 'info',
          summary: 'Integration started',
        });
        break;
      case 'IntegrationFinished':
        this.messageService.add({
          key: 'notificationToast',
          severity: 'info',
          summary: 'Integration finished',
        });
        break;
      case 'IntegrationCanceled':
        this.messageService.add({
          key: 'notificationToast',
          severity: 'info',
          summary: 'Integration run canceled',
        });
        break;
      case 'IntegrationDeleted':
        this.messageService.add({
          key: 'notificationToast',
          severity: 'info',
          summary: 'Integration deleted',
        });
        break;
    }
  }
}
