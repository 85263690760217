import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appHoverChildIconClass]',
})
export class HoverChildIconClassDirective {
  @Input('appHoverChildIconClass')
  public hoverClass: string;

  public constructor(public elementRef: ElementRef) {}

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    const childRentaIcon = this.elementRef.nativeElement.querySelector('.renta-ic');
    childRentaIcon.classList.add(this.hoverClass);
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    const childRentaIcon = this.elementRef.nativeElement.querySelector('.renta-ic');
    childRentaIcon.classList.remove(this.hoverClass);
  }
}
