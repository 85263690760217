import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RentaInputDataSource } from '../../../../shared/models/DataSource/RentaInputDataSource';
import {
  IconPositionEnum,
  RentaInputDataSourceModel
} from '../../../../shared/models/DataSource/DataSourceModels/RentaInputDataSourceModel';
import { slideInOut } from '../../../../../animations/slide.animation';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'primeng/api';
import { RentaApiService } from '../../../../../services/renta-api.service';
import { delay, map, switchMap } from 'rxjs/operators';
import { IntegrationSourceModel } from '../../../../../models/Integration/IntegrationSourceModel';
import { SelectTokenModel } from '../../../../../models/Integration/SelectTokenModel';
import { isNullOrEmpty } from '../../../../../helpers/helpers';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';
import { IntegrationTypeEnum } from '../../../../../models/common/IntegrationTypeEnum';
import { ApiResponse } from '../../../../../models/common/ApiResponse`T';
import { RentaDropdownDataSource } from '../../../../shared/models/DataSource/RentaDropdownDataSource';
import { ListItemModel } from '../../../../../models/Integration/ListItemModel';
import { RentaInputStringArrayDataSource } from '../../../../shared/models/DataSource/RentaInputStringArrayDataSource';
import * as _ from 'lodash';

interface StreamingInitialize {
  id: string,
  ip: string,
  siteUrl?: string,
  customDomain?: string,
  defaultCustomDomain: string,
  useCustomDomain: boolean,
  excludedDomains: Array<string>,
  allowedDomains: Array<string>
}

const PROTOCOL_DATA = [
  {
    id: 'https://',
    name: 'https://',
    selected: true
  },
  {
    id: 'https://',
    name: 'https://'
  }
];

@Component({
  selector: 'app-streaming-source',
  templateUrl: './streaming-source.component.html',
  animations: [slideInOut],
  styleUrls: ['./streaming-source.component.scss', '../../internal/integration-settings/common-settings.scss', '../common-addon-tokens-modal.scss']
})
export class StreamingSourceComponent implements OnInit{
  @Output()
  public OnSelectToken: EventEmitter<SelectTokenModel> = new EventEmitter<SelectTokenModel>();

  @Input()
  public streamingType: IntegrationSourceModel;

  public SiteUrlDataSource: RentaInputDataSource;
  public ARecordDataSource: RentaInputDataSource;
  public IpAddressDataSource: RentaInputDataSource;
  public serverSideImplementation: boolean = false;
  public installingJavaScript: string;
  public isLoading: boolean;
  public isLoaded: boolean = false;
  public installingPlaceholder: string = 'Copy and paste the snippet below before your website’s closing </head> tag.';
  public ProtocolDataSource: RentaDropdownDataSource;
  public tokenId: string;
  public editTokenId: string;
  public useExcludedDomains: boolean = false;
  public ExcludedDomainsDataSource: RentaInputStringArrayDataSource;
  public useAllowedDomains: boolean = false;
  public AllowedDomainsDataSource: RentaInputStringArrayDataSource;

  private streamingId: string; // Новая переменная для хранения result.id
  private regexUrl: RegExp = /^(?:https?:\/\/)?([a-zA-Z0-9-]{0,61}[a-zA-Z0-9-](?:\.[a-zA-Z0-9-]{2,})+)/i;
  private siteUrlDataSource: string;
  private aRecordDataSource: string;
  private defaultARecordDataSource: string;
  private ipAddressDataSource: string;
  private protocolDataSource: string;
  private excludedDomains: string[];
  private allowedDomains: string[];

  constructor(private cdr: ChangeDetectorRef, private readonly config: DynamicDialogConfig, private readonly ref: DynamicDialogRef, protected readonly rentaApiService: RentaApiService, private readonly clipboard: Clipboard, private messageService: MessageService) {
    this.editTokenId = this.config.data.tokenId;
  }

  public ngOnInit(): void {
    this.SiteUrlDataSource = new RentaInputDataSource('1', null);
    this.ARecordDataSource = new RentaInputDataSource('Your custom domain', null);
    this.IpAddressDataSource = new RentaInputDataSource('Dedicated IP address', null);
    this.ExcludedDomainsDataSource = new RentaInputStringArrayDataSource('Referral Exclusion List', null, 'Set the exclusion domain', (vals) => this.validateExcludedDomains(vals))
    this.AllowedDomainsDataSource = new RentaInputStringArrayDataSource('Allowed Domains List', null, 'Set the allowed domain', (vals) => this.validateAllowedDomains(vals))
    this.isLoaded = false;

    this.ProtocolDataSource = new RentaDropdownDataSource('Site URL', PROTOCOL_DATA);

    this.getInitialData().pipe(delay(1500)).subscribe((result: StreamingInitialize): void => {
      this.tokenId = this.editTokenId || result.id;
      this.streamingId = result.id; // Сохраняем result.id в переменную
      this.serverSideImplementation = result.useCustomDomain;
      this.defaultARecordDataSource = result.defaultCustomDomain;
      this.installingJavaScript = StreamingSourceComponent.getInstallingJavaScript(result.id, result.defaultCustomDomain);
      this.excludedDomains = result.excludedDomains;
      this.allowedDomains = result.allowedDomains;
      this.useExcludedDomains = result.excludedDomains.length > 0;
      this.useAllowedDomains = result.allowedDomains.length > 0;
      let siteUrl = '';

      const siteUrlMatch = result.siteUrl?.trim().match(this.regexUrl);
      if (siteUrlMatch) {
        siteUrl = siteUrlMatch[1];
      }

      const siteUrlDataSource: RentaInputDataSourceModel = {
        value: siteUrl,
        iconOptions: {
          position: IconPositionEnum.none
        },
        placeholder: 'example.com',
        disabled: false
      };

      this.siteUrlDataSource = siteUrl;

      this.SiteUrlDataSource.refresh(siteUrlDataSource);

      const aRecordDataSource: RentaInputDataSourceModel = {
        value: result.customDomain || '',
        iconOptions: {
          position: IconPositionEnum.none
        },
        placeholder: 'cdp.example.com',
        disabled: false
      };

      this.aRecordDataSource = result.customDomain;

      this.ARecordDataSource.refresh(aRecordDataSource);

      const ipAddressDataSource: RentaInputDataSourceModel = {
        value: this.ipAddressDataSource = result.ip,
        iconOptions: {
          position: IconPositionEnum.none
        },
        disabled: true
      };

      this.ipAddressDataSource = result.ip;

      this.IpAddressDataSource.refresh(ipAddressDataSource);

      const pds = PROTOCOL_DATA.map((item: ListItemModel): ListItemModel => {
        return {
          id: item.id,
          name: item.name,
          selected: result.siteUrl?.includes(item.id) || false
        };
      });

      if (pds.every(i => !i.selected)) {
        pds.find(f => f.id === 'https://').selected = true;
      }
      this.protocolDataSource = pds.find(f => f.selected).id;

      this.ProtocolDataSource.refresh(pds);
      this.ExcludedDomainsDataSource.refresh(this.excludedDomains)
      this.AllowedDomainsDataSource.refresh(this.allowedDomains)
      this.isLoaded = true;
    });
  }

  public onApplyExcludedDomains($event: Array<string>): void {
    this.excludedDomains = $event;
  }

  public onApplyAllowedDomains($event: Array<string>): void {
    this.allowedDomains = $event;
  }

  public onApplySiteUrl($event: string): void {
    this.SiteUrlDataSource.setError(null);
    const matches = $event.trim().match(this.regexUrl);
    if (matches && matches[1]) {
      const siteUrlDataSource: RentaInputDataSourceModel = {
        value: matches[1],
        iconOptions: {
          position: IconPositionEnum.none
        },
        disabled: false
      };

      this.SiteUrlDataSource.refresh(siteUrlDataSource);
      this.siteUrlDataSource = matches[0];
      return;
    }
    this.siteUrlDataSource = '';
    this.SiteUrlDataSource.setError('Invalid URL');
  }

  public onApplyCustomDomain($event: string): void {
    this.ARecordDataSource.setError(null);
    const matches = $event.trim().match(this.regexUrl);
    if (matches && matches[1]) {
      const aRecordDataSource: RentaInputDataSourceModel = {
        value: matches[1],
        iconOptions: {
          position: IconPositionEnum.none
        },
        disabled: false
      };

      this.ARecordDataSource.refresh(aRecordDataSource);
      this.aRecordDataSource = matches[1];
      this.installingJavaScript = StreamingSourceComponent.getInstallingJavaScript(this.streamingId, this.serverSideImplementation ? this.aRecordDataSource : this.defaultARecordDataSource);
      this.cdr.detectChanges();

      return;
    }
    this.aRecordDataSource = '';
    this.ARecordDataSource.setError('Invalid A record');
  }

  public onCopyIpAddress(): void {
    this.clipboard.copy(this.ipAddressDataSource);
    this.messageService.add({
      key: 'notificationToast',
      severity: 'success',
      summary: `Ip Address copied`
    });
  }

  public onCopyCode(): void {
    this.installingJavaScript = StreamingSourceComponent.getInstallingJavaScript(this.streamingId, this.serverSideImplementation ? this.aRecordDataSource : this.defaultARecordDataSource);
    this.clipboard.copy(this.installingJavaScript);
    this.messageService.add({
      key: 'notificationToast',
      severity: 'success',
      summary: `JavaScript code copied`
    });
  }

  public onApplyProtocol($event: ListItemModel): void {
    this.protocolDataSource = $event.id.replace('https://', "").replace('http://', "");
  }

  public create(): void {
    let isValid = true;
    if (isNullOrEmpty(this.siteUrlDataSource)) {
      this.SiteUrlDataSource.setError('Invalid URL');
      isValid = false;
    }

    if (isNullOrEmpty(this.aRecordDataSource) && this.serverSideImplementation) {
      this.ARecordDataSource.setError('Invalid custom domain name');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    const body: any = {
      id: this.tokenId,
      name: this.protocolDataSource + this.siteUrlDataSource,
      dnsData: {
        ip: this.ipAddressDataSource,
        aRecord: this.serverSideImplementation ? this.aRecordDataSource : this.defaultARecordDataSource
      },
      excludedDomains: this.useExcludedDomains ? this.excludedDomains : null,
      allowedDomains: this.useAllowedDomains ? this.allowedDomains : null
    };

    this.isLoading = true;
    if (this.editTokenId) {
      this.rentaApiService.updateToken(IntegrationTypeEnum.javascript_sdk, this.tokenId, body).pipe(delay(1000)).subscribe((result: ApiResponse<{ id: string }>): void => {
        this.isLoading = false;
        if (result.baseResponse.status) {
          this.ref.close(true);
        } else {
          for (const error of result.baseResponse.errors) {
            if (this.hasOwnProperty(error.message + 'DataSource')) {
              this[error.message + 'DataSource'].setError(error.userMessage);
            }
          }
        }
      });
    } else {
      this.rentaApiService.saveToken(IntegrationTypeEnum.javascript_sdk, body).subscribe((result: ApiResponse<{ id: string }>): void => {
        this.isLoading = false;
        if (result.baseResponse.status) {
          this.ref.close(true);
        } else {
          for (const error of result.baseResponse.errors) {
            if (this.hasOwnProperty(error.message + 'DataSource')) {
              this[error.message + 'DataSource'].setError(error.userMessage);
            }
          }
        }
      });
    }
  }

  public onSwitcherChange($event: { originalEvent: PointerEvent, checked: boolean }): void {
    this.serverSideImplementation = $event.checked;
    this.installingJavaScript = StreamingSourceComponent.getInstallingJavaScript(this.streamingId, this.serverSideImplementation ? this.aRecordDataSource : this.defaultARecordDataSource);
  }

  public onClose(): void {
    this.ref.close(false);
  }

  public onSwitcherSessionTableChange($event: { originalEvent: PointerEvent; checked: boolean }): void {
    this.useExcludedDomains = $event.checked;
  }

  public onSwitcherAllowedDomainsChange($event: { originalEvent: PointerEvent; checked: boolean }): void { // Новый метод для обработки переключателя разрешенных доменов
    this.useAllowedDomains = $event.checked;
  }

  private getInitialData(): Observable<StreamingInitialize> {
    return this.rentaApiService.initializeStreaming().pipe(switchMap((response: any): Observable<StreamingInitialize> => {
      const res = {
        id: response.id,
        ip: response.ip,
        defaultCustomDomain: response.defaultARecord,
        customDomain: null,
        siteUrl: null,
        useCustomDomain: false,
        excludedDomains: [],
        allowedDomains: []
      };

      if (this.editTokenId) {
        return this.rentaApiService.getStreamingInfo(this.editTokenId).pipe(map((editResult: any): StreamingInitialize => {
          res.id = editResult.id;
          res.ip = editResult.dnsData.ip;
          res.customDomain = editResult.dnsData.aRecord;
          res.siteUrl = editResult.title;
          res.useCustomDomain = editResult.dnsData.aRecord !== response.defaultARecord;
          res.excludedDomains = editResult.excludedDomains;
          res.allowedDomains = editResult.allowedDomains;
          return res;
        }));
      }

      return of<StreamingInitialize>(res);
    }));
  }

  private static getInstallingJavaScript(streaming: string, customDomain: string): string {
    return `<script>
    !function(){var i="renta",renta=window[i]=window[i]||[];if(!renta.initialize){if(renta.invoked){window.console&&console.error&&console.error("Renta SDK snippet included twice.");}else{renta.invoked=true;renta.methods=["identify","group","track","page"];renta.factory=function(method){return function(){if(window[i].initialized)return window[i][method].apply(window[i],arguments);var args=Array.prototype.slice.call(arguments);args.unshift(method);renta.push(args);return renta;};};for(var n=0;n<renta.methods.length;n++){var key=renta.methods[n];renta[key]=renta.factory(key);}renta.environment=function(env){renta.env=env;};renta.load=function(key,domain){renta.writeKey=key;renta.collectURL=domain||'cdp.renta.im';var script=document.createElement("script");script.type="text/javascript";script.async=true;script.src="https://"+renta.collectURL+"/collect/v2/renta.min.js";var first=document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script,first);};renta.SNIPPET_VERSION="2.1";
      renta.load("${streaming}"${(customDomain && customDomain !== "cdp.renta.im") ? `, "${customDomain}"` : ""});
      renta.environment("prod");
      renta.page();
    }}}();
  </script>`;
  }

  private validateExcludedDomains(values: Array<string>): Array<string> {
    return values.reduce((filtered, option) => {
      const identical = values.filter(item => item === option);

      if (identical.length > 1) {
        filtered.push('Values must be unique');
      } else if (!_.some(option)) {
        filtered.push('Values must be not empty');
      } else {
        filtered.push(null);
      }

      return filtered;
    }, []);
  }

  private validateAllowedDomains(values: Array<string>): Array<string> { // Новый метод для проверки разрешенных доменов
    return values.reduce((filtered, option) => {
      const identical = values.filter(item => item === option);

      if (identical.length > 1) {
        filtered.push('Values must be unique');
      } else if (!_.some(option)) {
        filtered.push('Values must be not empty');
      } else {
        filtered.push(null);
      }

      return filtered;
    }, []);
  }
}
