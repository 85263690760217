<div class="integration-token-row p-d-flex p-ai-center" (click)="onClick($event)" [style.height]="rowHeight">
  <div class="dash-container">
    <div class="vertical-token-dash"></div>
    <div class="horizontal-token-dash"></div>
    <div *ngIf="IsLast" class="vertical-token-dash"></div>
  </div>
  <div class="integration-token-icon-container">
    <div class="integration-token-icon p-d-flex p-ai-center p-jc-center">
      <span class="integration-token-icon-text renta-font-normal">I</span>
    </div>
  </div>
  <div class="integration-token-info p-d-flex p-flex-column">
    <div class="integration-token-title-container">
      <span class="integration-token-title renta-font-normal">{{TokenViewModel.title}}</span>
    </div>
    <div class="p-d-flex p-flex-column" *ngIf="TokenViewModel.error === null">
      <span class="integration-token-expiration renta-font-small"
            *ngFor="let additionalInfo of TokenViewModel.additionalInfo">{{additionalInfo}}</span>
    </div>
    <div>
      <app-renta-error-message [errorMessage]="TokenViewModel.error"
                               *ngIf="TokenViewModel.error !== null"></app-renta-error-message>
    </div>
  </div>
  <div class="integration-token-btn">
    <div *ngIf="CanEdit" (click)="onEdit($event)">
      <i class="p-pt-2 edit-icon btn-icon pi pi-pencil"></i>
    </div>
  </div>
  <div class="integration-token-btn">
    <div *ngIf="CanRemove" (click)="onRemove($event)">
      <i class="btn-icon renta-ic-trash" *ngIf="!TokenViewModel.isLoading"></i>
    </div>
  </div>
  <div class="integration-token-select-icon">
    <i class="renta-ic-relogin" *ngIf="TokenViewModel.error !== null"></i>
    <i class="pi pi-fw pi-chevron-right" *ngIf="TokenViewModel.error === null && !TokenViewModel.isLoading"></i>
    <i class="pi pi-spin pi-spinner" *ngIf="TokenViewModel.isLoading"></i>
  </div>
</div>
