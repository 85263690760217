<div class="header-ava-container p-d-flex p-align-center">
  <div class="image-container">
    <div class="image p-d-flex p-align-center p-jc-center">
      <app-avatar-image-block
        [imgUrl]="avatarImg | async"
        [loading]="avatarLoading | async"
      ></app-avatar-image-block>
    </div>
  </div>
  <div class="info-container">
    <div class="info p-d-flex p-flex-md-column">
      <span class="info-name">{{userName | async}}</span>
      <span class="info-email">{{userEmail | async}}</span>
    </div>
  </div>
</div>

