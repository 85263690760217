import {
  AfterViewChecked,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import * as _ from 'lodash';

import { GbuttonHorizontalEnum } from '../models/GbuttonHorizontalEnum';
import { GbuttonVerticalEnum } from '../models/GbuttonVerticalEnum';
import { GbuttonModel } from '../models/GbuttonModel';

@Directive({
  selector: '[appGbutton]'
})
export class GbuttonDirective implements OnInit, AfterViewChecked {
  @Input()
  public gBtnData: GbuttonModel;

  @Input()
  public gBtnLoading: boolean = true;

  @Output()
  public GbtnClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  public gBtn: HTMLElement;
  private parentWidth: number;
  private parentHeight: number;
  private gBtnWidth: number;
  private gBtnHeight: number;

  constructor(private eRef: ElementRef, private renderer: Renderer2) {
  }

  public ngOnInit(): void {
    this.setDefault();
    this.create();
  }

  public ngAfterViewChecked(): void {
    this.setPosition();
    this.setGButtonVisibility();
  }

  @HostListener('window:resize', ['$event.target'])
  public onResize(): void {
    this.setPosition();
  }

  private create(): void {
    this.gBtn = this.renderer.createElement('div');
    const gBtnIcon = this.renderer.createElement('i');
    this.renderer.appendChild(this.gBtn, gBtnIcon);
    this.renderer.appendChild(this.eRef.nativeElement, this.gBtn);
    this.renderer.setStyle(this.eRef.nativeElement, 'position', 'relative');
    this.renderer.setStyle(this.eRef.nativeElement, 'min-height', '80px');
    this.renderer.addClass(this.gBtn, 'gbutton');
    this.renderer.addClass(gBtnIcon, 'pi');
    this.renderer.addClass(gBtnIcon, this.gBtnData.iconUrl);

    if (!_.isNil(this.gBtnData.uniqueClass) && this.gBtnData.uniqueClass.length > 0) {
      this.renderer.addClass(this.eRef.nativeElement, this.gBtnData.uniqueClass);
    }

    if (this.gBtnData.visible) {
      this.renderer.setStyle(this.gBtn, 'display', 'flex');
    } else {
      this.renderer.setStyle(this.gBtn, 'display', 'none');
    }

    this.renderer.listen(this.gBtn, 'click', (): void => {
      this.GbtnClick.emit(true);
    });
  }

  private setPosition(): void {
    if (!this.okToUpdatePosition()) {
      return;
    }

    if (!this.needToUpdatePosition()) {
      return;
    }

    this.parentWidth = this.eRef.nativeElement.getBoundingClientRect().width;
    this.parentHeight = this.eRef.nativeElement.getBoundingClientRect().height;
    this.gBtnWidth = this.gBtn.offsetWidth;
    this.gBtnHeight = this.gBtn.offsetHeight;

    if (this.gBtnData.horizontal === GbuttonHorizontalEnum.left) {
      this.renderer.setStyle(this.gBtn, 'left', '0');
    } else {
      this.renderer.setStyle(this.gBtn, 'left', `${this.parentWidth - this.gBtnWidth}px`);
    }

    if (this.gBtnData.vertical === GbuttonVerticalEnum.top) {
      this.renderer.setStyle(this.gBtn, 'top', '0');
    } else {
      this.renderer.setStyle(this.gBtn, 'top', `${this.parentHeight - this.gBtnHeight}px`);
    }

    this.renderer.setStyle(this.gBtn, 'opacity', '1');
  }

  private setGButtonVisibility(): void {
    if (this.gBtnLoading) {
      this.renderer.addClass(this.gBtn, 'hide-gbutton');
    } else {
      this.renderer.removeClass(this.gBtn, 'hide-gbutton');
    }
  }

  private needToUpdatePosition(): boolean {
    return !(
      this.parentWidth === this.eRef.nativeElement.getBoundingClientRect().width &&
      this.parentHeight === this.eRef.nativeElement.getBoundingClientRect().height &&
      this.gBtnWidth === this.gBtn.offsetWidth &&
      this.gBtnHeight === this.gBtn.offsetHeight
    );
  }

  private okToUpdatePosition(): boolean {
    return (
      this.eRef.nativeElement.getBoundingClientRect().width > 0 &&
      this.eRef.nativeElement.getBoundingClientRect().height > 0 &&
      this.gBtn.offsetWidth > 0 &&
      this.gBtn.offsetHeight > 0
    );
  }

  private setDefault(): void {
    if (_.isNil(this.gBtnData)) {
      this.gBtnData = {
        horizontal: GbuttonHorizontalEnum.left,
        vertical: GbuttonVerticalEnum.top,
        iconUrl: 'pi-pencil',
        visible: true
      };
    }

    this.gBtnData.horizontal = _.isNil(this.gBtnData.horizontal) ? GbuttonHorizontalEnum.left : this.gBtnData.horizontal;
    this.gBtnData.vertical = _.isNil(this.gBtnData.vertical) ? GbuttonVerticalEnum.top : this.gBtnData.vertical;
    this.gBtnData.iconUrl = !_.isNil(this.gBtnData.iconUrl) && this.gBtnData.iconUrl.length > 0 ? this.gBtnData.iconUrl : 'pi-pencil';
    this.gBtnData.visible = _.isNil(this.gBtnData.visible) ? true : this.gBtnData.visible;
  }
}
