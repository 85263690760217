<div class="setting-row-wrapper p-m-0">
  <app-renta-dropdown
    *ngIf="!(isLoaded | async); else loadingSetting"
    [RentaDropdownSource]="AccountDataSource"
    [class.disabled]="isDataSourceLoading"
    (OnApply)="onApplyAccount($event)"
  ></app-renta-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-chips
    *ngIf="!(isLoaded | async)"
    [DataSource]="chipsClientsDataSource"
    (OnDeleteChip)="onCloseSelectedClientsChip($event)"
  ></app-renta-chips>
</div>
<div class="setting-row-wrapper">
  <app-renta-multiselect-dropdown
    *ngIf="!(isLoaded | async); else loadingSetting"
    (OnApply)="onApplyClients($event)"
    [RentaMultiselectDropdownSource]="ClientsDataSource"
    [class.disabled]="isDataSourceLoading"
    [hotRefresh]="false"
  >
  </app-renta-multiselect-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-chips *ngIf="!(isLoaded | async)" [DataSource]="chipsParametersDataSource" (OnDeleteChip)="onCloseChip($event)"></app-renta-chips>
</div>
<div class="setting-row-wrapper">
  <app-renta-multiselect-dropdown
    *ngIf="!(isLoaded | async); else loadingSetting"
    (OnApply)="onApplyParameters($event)"
    [RentaMultiselectDropdownSource]="ParametersDataSource"
    [hotRefresh]="true"
  >
  </app-renta-multiselect-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-setting-filters
    *ngIf="!(isLoaded | async); else loadingSetting"
    (OnApply)="onApplyFilters($event)"
    [RentaSettingsFiltersSource]="FiltersDataSource"
    [class.disabled]="isDataSourceLoading"
  >
  </app-renta-setting-filters>
</div>
<ng-template #loadingSetting>
  <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
  <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
</ng-template>
