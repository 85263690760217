import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-amazon-redshift-auth',
  templateUrl: './amazon-redshift-auth.component.html',
  styleUrls: ['./amazon-redshift-auth.component.css']
})
export class AmazonRedshiftAuthComponent implements OnInit {

  @Input()
  public error: string;

  @Input()
  public ServerHostname: string;

  @Input()
  public ServerPort: string;

  @Input()
  public DatabaseUser: string;

  @Input()
  public DatabasePassword: string;

  @Input()
  public DatabaseName: string;

  @Input()
  public DatabaseSchema: string;

  @Input()
  public loading: boolean;

  constructor() {
  }

  public ngOnInit(): void {
  }
}
