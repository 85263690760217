export enum IntegrationEventType{
  IntegrationStarted = 'IntegrationStarted',
  IntegrationStopped = 'IntegrationStopped',
  IntegrationError = 'IntegrationError',
  IntegrationTableDeleted = 'IntegrationTableDeleted',
  IntegrationTableCreated = 'IntegrationTableCreated',
  IntegrationDeleted = 'IntegrationDeleted',
  IntegrationCreated = 'IntegrationCreated',
  IntegrationQueued = 'IntegrationQueued',
  IntegrationUpdated = 'IntegrationUpdated',
  IntegrationProgressUpdated = 'IntegrationProgressUpdated',
  IntegrationFinished = 'IntegrationFinished',
  IntegrationEnabled = 'IntegrationEnabled',
  IntegrationDisabled = 'IntegrationDisabled',
  IntegrationInitialized = 'IntegrationInitialized',
  IntegrationCanceled = 'IntegrationCanceled',
  IntegrationRunActionReceived = 'IntegrationRunActionReceived'
}
