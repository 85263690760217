<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="mr-b-5">
    <app-renta-error-message [errorMessage]="error"
                             *ngIf="error"></app-renta-error-message>
  </div>
  <label for="email" class="p-d-none"></label>
  <input id="email" class="p-inputtext-lg auth-dialog-input" appTextInputTrim [(ngModel)]="apiUrl" pInputText placeholder="API URL"
         type="text"
         [disabled]="loading">
  <label for="apiKey" class="p-d-none"></label>
  <input id="apiKey" type="password" pInputText appTextInputTrim class="p-inputtext-lg auth-dialog-input dialog-input-margin" [(ngModel)]="apiKey"
         placeholder="API key"
         [disabled]="loading">
</div>
