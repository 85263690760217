<div class="renta-placeholder-input-container" *ngIf="ds | async; let dataSource">
  <div class="title">
    <span>{{RentaPlaceholderInputSource.name}}</span>
  </div>
  <app-renta-action-placeholder
    *ngIf="!dataSource.isEdit"
    [text]="dataSource.text"
    [placeholder]="dataSource.placeholder"
    [isEditable]="dataSource.isEditable"
    [btnIcon]="dataSource.btnIcon"
    [mask]="dataSource.mask"
    (placeholderHandler)="editInput()"></app-renta-action-placeholder>
  <app-renta-action-input
    *ngIf="dataSource.isEdit"
    [textContent]="dataSource.text"
    [textPlaceholder]="dataSource.placeholder"
    [loading]="RentaPlaceholderInputSource.loading"
    (cancelHandler)="cancelEdit()"
    (saveHandler)="edit($event)"></app-renta-action-input>
</div>
