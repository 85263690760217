import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SettingsComponentFactoryResolver } from '../../services/settingsComponentFactory';
import { IntegrationService } from '../../services/integration.service';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { IntegrationSettingsComponent } from './integration-settings.component';
import { IntegrationEditInfo } from '../../../../models/Integration/IntegrationEditInfo';
import { AppGlobalPreloaderService } from '../../../../services/app-global-preloader.service';
import { UserService } from '../../../../services/user.service';
import { ProfileService } from '../../../../services/profile.service';

@Component({
  selector: 'app-edit-integration-settings',
  templateUrl: './integration-settings.component.html',
  providers: [SettingsComponentFactoryResolver, IntegrationService],
  styleUrls: ['../internal/integration-settings/common-settings.scss', './integration-settings.component.css'],
})
export class EditIntegrationSettingsComponent extends IntegrationSettingsComponent implements OnInit, AfterViewInit {
  public editIntegrationTitle: string;

  constructor(
    protected readonly integrationService: IntegrationService,
    protected readonly settingsComponentFactoryResolver: SettingsComponentFactoryResolver,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly appGlobalPreloaderService: AppGlobalPreloaderService,
    protected readonly userService: UserService,
    protected readonly profileService: ProfileService
  ) {
    super(
      integrationService,
      settingsComponentFactoryResolver,
      router,
      activatedRoute,
      appGlobalPreloaderService,
      userService,
      profileService
    );
    this.saveButton = 'Update Integration';
  }

  public ngOnInit(): void {
    this.createMode = false;
    this.activatedRoute.data.subscribe((data: Data): void => {
      const integrationEditInfo = data?.integrationEditInfo as IntegrationEditInfo;
      if (!integrationEditInfo) {
        // TODO: some error with of integration data
        return;
      }
      this.editIntegrationTitle = integrationEditInfo.integrationType.title;
      this.integrationService.setIntegrationId(integrationEditInfo.integrationId, false);
      this.integrationService.setSource(integrationEditInfo.integrationType, integrationEditInfo.sourceTokenId, null);
      this.integrationService.setDestination(integrationEditInfo.destinationType, integrationEditInfo.destinationTokenId, null);
    });
  }

  public getPageTitle(): string {
    return this.isEtl ? `Edit ${this.editIntegrationTitle} integration` : 'Edit your pipeline';
  }

  // TODO: for javascript sdk Id not isEtl
  public getPageSubtitle(): string {
    return this.isEtl ? `Here you can edit your ${this.editIntegrationTitle} integration.` : 'Here you can edit your pipeline';
  }
}
