import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IntegrationListService } from './services/integration-list.service';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.css']
})
export class IntegrationsComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly integrationService: IntegrationListService
  ) {
  }

  @HostListener('window:popstate', ['$event'])
  public onPopState(event: PopStateEvent): void {
    event.preventDefault();
    history.pushState(null, null, window.location.href);
   }

  public ngOnInit(): void {
    this.integrationService.getIntegrations().subscribe();
  }
}
