import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RentaInputDataSource } from '../../../models/DataSource/RentaInputDataSource';
import { RentaDropdownDataSource } from '../../../models/DataSource/RentaDropdownDataSource';
import { Observable } from 'rxjs';
import { ListItemModel } from '../../../../../models/Integration/ListItemModel';
import { KeyValueDropdownRowDataSource } from '../../../models/DataSource/KeyValueDropdownRowDataSource';
@Component({
  selector: 'app-renta-key-value-dropdown-row',
  templateUrl: './renta-key-value-dropdown-row.component.html',
  styleUrls: ['../../internal/base-settings-styles.css']
})
export class RentaKeyValueDropdownRowComponent implements OnInit {
  @Input()
  public KeyValueDropdownRowDataSource: KeyValueDropdownRowDataSource;

  @Input()
  public KeyName: string;

  @Input()
  public ValueName: string;

  @Input()
  public Id: string | number;

  @Input()
  public CanRemove: boolean;

  @Output()
  public OnDelete: EventEmitter<string | number> = new EventEmitter<string | number>();

  @Output()
  public OnValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public Disabled: boolean = false;

  public dataSource: Observable<{ key: RentaInputDataSource, values: RentaDropdownDataSource }>;

  constructor() {
  }

  public ngOnInit(): void {
    this.dataSource = this.KeyValueDropdownRowDataSource.connect();
  }

  public onApplyKey($event: string): void {
    this.KeyValueDropdownRowDataSource.setKey($event);
    this.OnValidChange.emit(this.KeyValueDropdownRowDataSource.isValidRow());
  }

  public delete(): void {
    this.OnDelete.emit(this.Id);
  }

  public onApplyValue(__: ListItemModel): void {
    this.OnValidChange.emit(this.KeyValueDropdownRowDataSource.isValidRow());
  }
}
