import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { RentaApiService } from '../../../../services/renta-api.service';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { FbAdsMeta } from '../../../../models/Integration/IntegrationsMeta/FbAdsMeta';
import { mergeMap } from 'rxjs/operators';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { EtlSettingsServiceBase } from './EtlSettingsServiceBase';

@Injectable()
export class FbAdsSettingsService extends EtlSettingsServiceBase<FbAdsMeta> {
  protected tablePrefix: string = 'fb_ads';
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.facebook_ads;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      Account: null,
      ReportTypes: null,
      Parameters: null,
      ActionReportTime: null,
      AttributionSettings: null,
      CustomAttributionClickWindow: null,
      CustomAttributionViewWindow: null,
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  constructor(public integrationInfoData: IntegrationInfoViewModel, protected readonly rentaApiService: RentaApiService,
              protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.memoryCacheKey = `tablePrefix-${IntegrationTypeEnum.facebook_ads}`;
  }

  public getSelectedReportType(): ListItemModel | null {
    return _.first(this.selectedSettings.ReportTypes) || null;
  }

  public setAccount(selectedAccount: ListItemModel): boolean {
    if (_.isNil(selectedAccount) || _.some(this.selectedSettings.Account, selectedAccount)) {
      return false;
    }

    this.selectedSettings.Account = [selectedAccount];
    return true;
  }

  public setReportType(selectedReportType: ListItemModel): boolean {
    if (_.isNil(selectedReportType) || _.some(this.selectedSettings.ReportTypes, selectedReportType)) {
      return false;
    }

    this.selectedSettings.ReportTypes = [selectedReportType];
    return true;
  }

  public setActionReportTime(selectedActionReportTime: ListItemModel): boolean {
    if (_.isNil(selectedActionReportTime) || _.some(this.selectedSettings.ActionReportTime , selectedActionReportTime)) {
      return false;
    }

    this.selectedSettings.ActionReportTime  = [selectedActionReportTime];
    return true;
  }

  public setAttributionSettings(selectedAttributionSettings: ListItemModel): boolean {
    if (_.isNil(selectedAttributionSettings) || _.some(this.selectedSettings.AttributionSettings , selectedAttributionSettings)) {
      return false;
    }

    this.selectedSettings.AttributionSettings = [selectedAttributionSettings];
    return true;
  }


  public setCustomAttributionClickWindow(selectedCustomAttributionClickWindow: ListItemModel): boolean {
    if (_.isNil(selectedCustomAttributionClickWindow) || _.some(this.selectedSettings.CustomAttributionClickWindow , selectedCustomAttributionClickWindow)) {
      return false;
    }

    this.selectedSettings.CustomAttributionClickWindow = [selectedCustomAttributionClickWindow];
    return true;
  }

  public setCustomAttributionViewWindow(selectedCustomAttributionViewWindow: ListItemModel): boolean {
    if (_.isNil(selectedCustomAttributionViewWindow) || _.some(this.selectedSettings.CustomAttributionViewWindow , selectedCustomAttributionViewWindow)) {
      return false;
    }

    this.selectedSettings.CustomAttributionViewWindow = [selectedCustomAttributionViewWindow];
    return true;
  }


  public getParameters(
    selectedAccount: ListItemModel,
    selectedReportType: ListItemModel
  ): Observable<Array<ListItemModel>> {
    const reportType: string = _.isNil(selectedReportType) ? _.first(this.selectedSettings.ReportTypes)?.id : selectedReportType.id;
    const account: string = _.isNil(selectedAccount) ? _.first(this.selectedSettings.Account)?.id : selectedAccount.id;

    if (_.isNil(reportType) || _.isNil(account)) {
      return of<[]>([]);
    }

    const url: string = this.meta.parametersUrl.replace('{reportTypeId}', reportType).replace('{accountId}', account);
    const body = { sourceTokenId: this.integrationInfoData.integrationSourceToken };

    return this.getCurrentParameters(url, body);
  }

  protected mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: response.dateRange || [],
      Date: response.date || null,
      Schedule: response.schedule || [],
      OverwritePeriod: response.overwritePeriod || [],
      [this.integrationTypeEnum]: {
        Account: response.accounts || [],
        ReportTypes: response.reportTypes || [],
        Parameters: response.parameters || [],
        ActionReportTime: response.actionReportTime || [],
        AttributionSettings: response.attributionSettings || [],
        CustomAttributionClickWindow: response.customAttributionClickWindow || [],
        CustomAttributionViewWindow: response.customAttributionViewWindow || [],
      },
      Name: response.name || defaultIntegrationName,
      TableName: response.tableName || defaultTableName
    };
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].Account = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].ActionReportTime = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].AttributionSettings = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].CustomAttributionClickWindow = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].CustomAttributionViewWindow = [];
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings, isRefresh: boolean = false): void {
    const isRefreshSettings = this.editMode && isRefresh;
    this.selectedIntegrationSettings.Id = initialSettings.Id;

    this.selectedSettings.Account = isRefreshSettings && _.first(this.selectedIntegrationSettings[this.integrationTypeEnum].Account)
      ? initialSettings[this.integrationTypeEnum].Account.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].Account[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].Account.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.ReportTypes = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0])
      ? initialSettings[this.integrationTypeEnum].ReportTypes.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].ReportTypes.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );

    this.selectedSettings.ActionReportTime = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].ActionReportTime[0])
      ? initialSettings[this.integrationTypeEnum].ActionReportTime.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].ActionReportTime[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].ActionReportTime.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.AttributionSettings = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].AttributionSettings[0])
      ? initialSettings[this.integrationTypeEnum].AttributionSettings.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].AttributionSettings[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].AttributionSettings.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.CustomAttributionClickWindow = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].CustomAttributionClickWindow[0])
      ? initialSettings[this.integrationTypeEnum].CustomAttributionClickWindow.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].CustomAttributionClickWindow[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].CustomAttributionClickWindow.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.CustomAttributionViewWindow = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].CustomAttributionViewWindow[0])
      ? initialSettings[this.integrationTypeEnum].CustomAttributionViewWindow.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].CustomAttributionViewWindow[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].CustomAttributionViewWindow.filter((f: ListItemModel): boolean => f.selected);

    this.initSelectedCommonSettings(initialSettings, isRefreshSettings);
  }

  protected refreshParameters(): Observable<any> {
    return this.rentaApiService.initIntegration(this.getWizardRequestData())
      .pipe(
        mergeMap((res: any): Observable<IntegrationSettings> => {
          this.meta = res.meta;
          res = this.mapIntegrationSettings(res, this.selectedIntegrationSettings.TableName, this.selectedIntegrationSettings.Name);
          const selectedAccount = _.first(this.selectedIntegrationSettings[this.integrationTypeEnum].Account) ||
            res[this.integrationTypeEnum].Account.find((f: ListItemModel): boolean => f.selected);
          const selectedReportTypes = this.selectedIntegrationSettings[this.integrationTypeEnum].ReportTypes[0] ||
            res[this.integrationTypeEnum].ReportTypes.find((f: ListItemModel): boolean => f.selected);

          return (_.isNil(selectedAccount) || _.isNil(res[this.integrationTypeEnum].Account.find((f: ListItemModel): boolean => f.id === selectedAccount.id)) ||
            _.isNil(selectedReportTypes) || _.isNil(res[this.integrationTypeEnum].ReportTypes.find((f: ListItemModel): boolean => f.id === selectedReportTypes.id)))
            ? of(res)
            : this.getParameters(selectedAccount, selectedReportTypes).pipe(
              mergeMap((params: Array<ListItemModel>): Observable<IntegrationSettings> => {
                res[this.integrationTypeEnum].Parameters = params;
                this.compareParametersWithCacheData(params);
                return of(res);
              })
            );
        })
      );
  }
}
