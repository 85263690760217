import { Component, OnDestroy, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';
import * as _ from 'lodash';

import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { RentaChipsDataSource } from '../../../../../shared/models/DataSource/RentaChipsDataSource';
import { RentaMultiselectDropdownDataSource } from '../../../../../shared/models/DataSource/RentaMultiselectDropdownDataSource';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { getSelectedChips } from '../../../../../../helpers/helpers';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { ChipsModel } from '../../../../../shared/models/ChipsModel';
import { BingAdsSettingsService } from '../../../../services/integrationSettings/bing-ads-settings.service';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';

@Component({
  templateUrl: './bing-ads-settings.component.html',
  styleUrls: ['../common-settings.scss']
})
export class BingAdsSettingsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;
  public AccountsDataSource: RentaMultiselectDropdownDataSource;
  public chipsAccountsDataSource: RentaChipsDataSource;
  public ReportTypesDataSource: RentaDropdownDataSource;
  public ParametersDataSource: RentaMultiselectDropdownDataSource;
  public chipsParametersDataSource: RentaChipsDataSource;

  constructor(public settingsService: BingAdsSettingsService) {
    super(IntegrationTypeEnum.bing);
    this.isLoaded = false;
  }

  public ngOnInit(): void {
    this.AccountsDataSource = new RentaMultiselectDropdownDataSource('Ad account', []);
    this.chipsAccountsDataSource = new RentaChipsDataSource('Selected Accounts', []);
    this.ReportTypesDataSource = new RentaDropdownDataSource('Report type', []);
    this.ParametersDataSource = new RentaMultiselectDropdownDataSource('Parameters', []);
    this.chipsParametersDataSource = new RentaChipsDataSource('Selected Parameters', []);

    this.chipsAccountsDataSource.connect().subscribe((res: Array<ChipsModel>): void => {
      const selectedAccounts = res.map<ListItemModel>((m: ChipsModel): ListItemModel => {
        return { id: m.id, name: m.title, group: m.type };
      });

      this.settingsService.setAccount(selectedAccounts);
    });

    this.connectParamsChipsDS(this.chipsParametersDataSource, this.settingsService);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
      this.AccountsDataSource.setLoading(res);
      this.chipsAccountsDataSource.setLoading(res);
      this.ReportTypesDataSource.setLoading(res);
      this.chipsParametersDataSource.setLoading(res);
      this.ParametersDataSource.setLoading(res);
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      this.AccountsDataSource.refresh(_.cloneDeep(res[this.integrationType].Accounts));
      const chipsAccountsDs = getSelectedChips(res[this.integrationType].Accounts);
      this.chipsAccountsDataSource.refresh(chipsAccountsDs);
      this.ReportTypesDataSource.refresh(_.cloneDeep(res[this.integrationType].ReportTypes));
      this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
      const chipsParamsDs = getSelectedChips(res[this.integrationType].Parameters);
      this.chipsParametersDataSource.refresh(chipsParamsDs);
      this.setRelatedDataSources([this.ParametersDataSource, this.ReportTypesDataSource]);
      this.settingsService.initCache();

      setTimeout((): void => {
        this.isLoaded = true;
      }, 1000);
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onApplyAccounts($event: Array<ListItemModel>): void {
    if (_.isNil($event)) {
      return;
    }

    const chipsDs = $event.map<ChipsModel>((item: ListItemModel): ChipsModel => {
      return { title: item.name, type: item.group, id: item.id, order: 0 };
    });

    this.chipsAccountsDataSource.refresh(chipsDs);
  }

  public onApplyReportType($event: ListItemModel): void {
    if (!this.settingsService.setReportType($event)) {
      return;
    }
    this.ParametersDataSource.setLoading(true);
    this.refreshRelatedDataSources();
    this.settingsService
      .getParameters($event)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(_.cloneDeep(res));
          this.chipsParametersDataSource.refresh(getSelectedChips(res));
        }
      });
  }

  public onCloseAccountsChip(e: ChipsModel): void {
    this.AccountsDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
  }

  public onCloseParametersChip(e: ChipsModel): void {
    this.ParametersDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
    setTimeout((): void => {
      this.settingsService.setDataToCache();
    });
  }

  public onApplyParameters($event: Array<ListItemModel>): void {
    this.onApplyParams($event, this.chipsParametersDataSource);
    this.settingsService.setDataToCache();
  }
}
