<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="mr-b-5">
    <app-renta-error-message [errorMessage]="error"
                             *ngIf="error"></app-renta-error-message>
  </div>

  <label for="spaceName" class="p-d-none"></label>
  <input id="spaceName"
         class="p-inputtext-lg auth-dialog-input"
         appTextInputTrim [(ngModel)]="spaceName"
         pInputText
         placeholder="Space name"
         type="text"
         [disabled]="loading">

  <label for="apiKey" class="p-d-none"></label>
  <input id="apiKey"
         class="p-inputtext-lg auth-dialog-input dialog-input-margin"
         appTextInputTrim [(ngModel)]="apiKey"
         pInputText
         placeholder="Api key"
         type="text"
         [disabled]="loading">

  <label for="apiSecret" class="p-d-none"></label>
  <input id="apiSecret"
         class="p-inputtext-lg auth-dialog-input dialog-input-margin" [(ngModel)]="apiSecret"
         appTextInputTrim
         pInputText
         placeholder="Api secret"
         type="password"
         [disabled]="loading">
</div>
