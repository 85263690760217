<p-toast position="bottom-left" key="notificationToast" styleClass="notificationToast">
  <ng-template let-message pTemplate="message">
    <div class="p-d-flex toast-content">
      <div class="message-icon">
        <i class="pi pi-exclamation-circle message-color-text" style="font-size: 16px"></i>
      </div>
      <div class="renta-font-small message-color-text">{{ message.summary }}</div>
    </div>
  </ng-template>
</p-toast>
<div class="p-grid p-fluid p-nogutter page-container">
  <div class="p-col-fixed">
    <app-sidebar [Visible]="display | async"></app-sidebar>
  </div>
  <div class="p-col content-container">
    <div *ngIf="preLoader" class='p-d-flex width-max p-mt-6'>
      <i class='p-m-auto pi pi-spin pi-spinner'></i>
    </div>
    <router-outlet
      (activate)="onRouteActivate($event)">
    </router-outlet>
  </div>
</div>
