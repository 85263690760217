import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';
import * as _ from 'lodash';
import { IntegrationListService } from '../../../integrations/services/integration-list.service';
import { RentaIntegrationListFilterDataSource } from '../../models/DataSource/RentaIntegrationListFilterDataSource';
import { IntegrationSourceModel } from '../../../../models/Integration/IntegrationSourceModel';

@Component({
  selector: 'app-renta-tab-dropdown',
  templateUrl: './renta-tab-dropdown.component.html',
  styleUrls: ['./renta-tab-dropdown.component.scss']
})
export class RentaTabDropdownComponent implements OnInit {
  @Input()
  public RentaIntegrationListFilterSource: RentaIntegrationListFilterDataSource;

  @Output()
  public OnApply: EventEmitter<IntegrationSourceModel> = new EventEmitter<IntegrationSourceModel>();

  public selectedIndex: number = 0;
  public selectedDropdownIntegration: IntegrationSourceModel;
  public integrationsSource: Array<IntegrationSourceModel> = [];
  public tabIntegrations: Array<IntegrationSourceModel> = [];
  public dropdownIntegrations: Array<IntegrationSourceModel> = [];

  private onlyOnce: boolean;

  constructor(private elementRef: ElementRef, private readonly integrationService: IntegrationListService, private renderer: Renderer2) {
  }

  public ngOnInit(): void {
    this.RentaIntegrationListFilterSource.connect().subscribe((res: Array<IntegrationSourceModel>): void => {
      if (_.isEqual(this.integrationsSource, res)) {
        return;
      }

      this.integrationsSource = res;
      this.setDefault();
    });
  }

  @HostListener('window:resize', ['$event.target'])
  public onResize(): void {
    this.setDefault();
  }

  public onApplyHandler(event: IntegrationSourceModel): void {
    this.OnApply.emit(event);
  }

  public tabChanged(): void {
    if (this.selectedIndex === 0) {
      this.RentaIntegrationListFilterSource.setSelectedIntegration(null);
      this.onApplyHandler(null);
      return;
    }

    this.selectedDropdownIntegration = null;
    this.RentaIntegrationListFilterSource.setSelectedIntegration(this.tabIntegrations[this.selectedIndex - 1]);
    this.onApplyHandler(this.tabIntegrations[this.selectedIndex - 1]);
  }

  public dropdownChanged(): void {
    this.selectedIndex = this.tabIntegrations.length;
    this.RentaIntegrationListFilterSource.setSelectedIntegration(this.selectedDropdownIntegration);
    this.onApplyHandler(this.selectedDropdownIntegration);
  }

  private calculateElementsCapacity(): number {
    const containerWidth = this.elementRef.nativeElement.children[0].offsetWidth;
    const elementWidth = 52;
    const totalWidth = elementWidth * 2 + elementWidth * this.integrationsSource.length;
    return totalWidth <= containerWidth ? null : Math.floor(containerWidth / elementWidth) - 1;
  }

  private setTabIntegrations(amount: number = null): void {
    this.tabIntegrations = [];
    this.selectedDropdownIntegration = this.dropdownIntegrations[0];

    if (_.isNil(amount)) {
      this.tabIntegrations = this.integrationsSource;
      return;
    }

    this.integrationsSource.forEach((elem: IntegrationSourceModel, idx: number): void => {
      if (idx + 1 < amount) {
        this.tabIntegrations.push(Object.assign({}, this.integrationsSource[idx]));
      }
    });
  }

  private setDropdownIntegrations(amount: number = null): void {
    this.dropdownIntegrations = [];

    if (_.isNil(amount)) {
      return;
    }

    this.integrationsSource.forEach((elem: IntegrationSourceModel, idx: number): void => {
      if (idx + 2 > amount) {
        this.dropdownIntegrations.push(Object.assign({}, this.integrationsSource[idx]));
      }
    });

    this.tabIntegrations.push(Object.assign({}, this.integrationsSource[0]));
    this.dropdownIntegrations.unshift(Object.assign({}, this.integrationsSource[0]));
  }

  private hidePitStopElements(): void {
    if (_.isNil(this.dropdownIntegrations) || this.dropdownIntegrations.length === 0) {
      return;
    }

    setTimeout((): void => {
      const elem = document.querySelectorAll('.p-tabview-nav li.ng-star-inserted');
      this.renderer.setStyle(elem[elem.length - 1], 'display', 'none');
    });
  }

  private checkSelectedIntegration(): void {
    if (this.onlyOnce) {
      return;
    }

    this.onlyOnce = true;
    const selected = this.RentaIntegrationListFilterSource.getSelectedIntegration();

    if (_.isNil(selected)) {
      return;
    }

    this.tabIntegrations.map((f: IntegrationSourceModel, idx: number): void => {
      if (f.integrationType === selected.integrationType) {
        this.selectedIndex = idx + 1;
        this.tabChanged();
      }
    });

    this.dropdownIntegrations.map((f: IntegrationSourceModel): void => {
      if (f.integrationType === selected.integrationType) {
        this.selectedDropdownIntegration = selected;
        this.dropdownChanged();
      }
    });
  }

  private setSelectedByDefault(): void {
    if (
      !_.isNil(this.RentaIntegrationListFilterSource.getSelectedIntegration()) &&
      !this.RentaIntegrationListFilterSource.containsSelectedIntegration()
    ) {

      this.selectedIndex = null;
      this.selectedDropdownIntegration = null;
      this.RentaIntegrationListFilterSource.setSelectedIntegration(null);
      this.onApplyHandler(null);
    }
  }

  private setDefault(): void {
    const amount = this.calculateElementsCapacity();
    this.setTabIntegrations(amount);
    this.setDropdownIntegrations(amount);
    this.hidePitStopElements();
    this.setSelectedByDefault();
    this.checkSelectedIntegration();
  }
}
