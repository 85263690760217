import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {RentaSettingInfoDataSource} from '../../models/DataSource/RentaSettingInfoDataSource';

@Component({
  selector: 'app-setting-info',
  templateUrl: './setting-info.component.html',
  styleUrls: ['../internal/base-settings-styles.css',
    './setting-info.component.css']
})
export class SettingInfoComponent implements OnInit {

  @Input()
  public RentaSettingInfoDataSource: RentaSettingInfoDataSource;
  public settingInfo: Observable<string>;

  constructor() { }

  public ngOnInit(): void {
    this.settingInfo = this.RentaSettingInfoDataSource.connect();
  }

}
