import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TokensServiceService } from '../../../../../../services/tokens-service.service';
import { IntegrationSourceModel } from '../../../../../../models/Integration/IntegrationSourceModel';

@Component({
  selector: 'app-choose-storage-modal',
  templateUrl: './choose-storage-modal.component.html',
  styleUrls: ['../../integration-select-base/integration-select-base.css', './choose-storage-modal.component.css'],
})
export class ChooseStorageModalComponent implements OnInit {
  @Input()
  public additionalInfo: any;

  @Output()
  public OnSubmit: EventEmitter<{ id: string; projectId: string; name: string }> = new EventEmitter<{
    id: string;
    projectId: string;
    name: string;
  }>();

  public storages: Array<IntegrationSourceModel>;

  constructor(private tokensServiceService: TokensServiceService) {}

  public ngOnInit(): void {
    this.tokensServiceService
      .getStorages(this.additionalInfo.integrationType, this.additionalInfo.id)
      .subscribe((res: Array<IntegrationSourceModel>): void => {
        this.storages = res;
      });
  }

  public onTabOpen($event: any): void {
    this.storages[$event.index].tokens = this.tokensServiceService.getStorageTokens(
      this.additionalInfo.integrationType,
      this.additionalInfo.id,
      this.storages[$event.index].title
    );
  }

  public selectStorage(projectId: string, name: string): void {
    this.OnSubmit.emit({ id: this.additionalInfo.id, projectId, name });
  }
}
