import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { map, mergeMap } from 'rxjs/operators';

import { RentaApiService } from '../../../../services/renta-api.service';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';
import { IntegrationSettings } from '../../../../models/Integration/IntegrationSettings';
import { IntegrationTypeEnum } from '../../../../models/common/IntegrationTypeEnum';
import { ApiResponse } from '../../../../models/common/ApiResponse`T';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { EtlSettingsServiceBase } from './EtlSettingsServiceBase';
import { GSheetsMeta } from '../../../../models/Integration/IntegrationsMeta/GSheetsMeta';

@Injectable()

export class GoogleSheetsSettingsService extends EtlSettingsServiceBase<GSheetsMeta> {
  protected tablePrefix: string = 'gs';
  protected integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.google_sheets;
  protected selectedIntegrationSettings: IntegrationSettings = {
    Id: null,
    Date: null,
    [this.integrationTypeEnum]: {
      Spreadsheets: null,
      Worksheets: null,
      HeaderRowNumber: 1,
      Parameters: null
    },
    DateRange: null,
    Schedule: null,
    Name: null,
    TableName: null,
    OverwritePeriod: null
  };

  constructor(
    public integrationInfoData: IntegrationInfoViewModel,
    protected readonly rentaApiService: RentaApiService,
    protected readonly rentaModalsService: RentaModalsService) {
    super(integrationInfoData, rentaApiService, rentaModalsService);
    this.memoryCacheKey = `tablePrefix-${IntegrationTypeEnum.google_sheets}`;
  }

  public isShowDateRange(): boolean {
    return false;
  }

  public getWorksheets(Spreadsheets: ListItemModel): Observable<Array<ListItemModel>> {
    return this.rentaApiService.httpClient
      .post<ApiResponse<{
        parameters: Array<ListItemModel>
      }>>(this.meta.worksheetsUrl.replace('{sourceTypeId}', Spreadsheets.id),
        {
          sourceTokenId: this.integrationInfoData.integrationSourceToken,
          integrationId: this.integrationInfoData.integrationId || null
        }
      )
      .pipe(
        map((res: ApiResponse<{ parameters: Array<ListItemModel> }>): Array<ListItemModel> => res.result.parameters)
      );
  }

  public getParameters(): Observable<Array<ListItemModel>> {
    const spreadsheetsId = this.selectedIntegrationSettings[this.integrationTypeEnum].Spreadsheets[0]?.id;
    const worksheetsId = this.selectedIntegrationSettings[this.integrationTypeEnum].Worksheets[0]?.id;
    const headerRowNumberId = this.selectedIntegrationSettings[this.integrationTypeEnum].HeaderRowNumber;

    if (_.isNil(spreadsheetsId) || _.isNil(worksheetsId) || _.isNil(headerRowNumberId)) {
      return of<[]>([]);
    }

    const url = this.meta.parametersUrl.replace('{sourceTypeId}', spreadsheetsId).replace('{worksheetId}', worksheetsId).replace('{headerRowNumber}', headerRowNumberId);

    const body = {
      sourceTokenId: this.integrationInfoData.integrationSourceToken,
      integrationId: this.integrationInfoData.integrationId || null
    };

    return this.getCurrentParameters(url, body);
  }

  public setHeaderRowNumber(rowNumber: number): boolean {
    this.selectedSettings.HeaderRowNumber = rowNumber;

    return true;
  }

  public setSpreadsheets(selectedSpreadsheet: ListItemModel): boolean {
    const indexProperty = this.selectedSettings.Spreadsheets?.findIndex((f: ListItemModel): boolean => f.id === selectedSpreadsheet.id);
    if (!_.isNil(selectedSpreadsheet) && indexProperty > -1) {
      return false;
    }

    this.selectedSettings.Spreadsheets = [selectedSpreadsheet];
    return true;
  }

  public setWorksheets(worksheet: ListItemModel): boolean {
    const indexProperty = this.selectedSettings.Worksheets?.findIndex((f: ListItemModel): boolean => f.id === worksheet.id);

    if (!_.isNil(indexProperty) && indexProperty > -1) {
      return false;
    }

    this.selectedSettings.Worksheets = [worksheet];
    return true;
  }

  public setSelectedParameters(selectedParameters: Array<ListItemModel>): void {
    super.setSelectedParameters(selectedParameters);
  }

  protected mapIntegrationSettings(response: any, defaultTableName?: string, defaultIntegrationName?: string): IntegrationSettings {
    return {
      Id: null,
      DateRange: response.dateRange || [],
      Date: response.date || null,
      Schedule: response.schedule || [],
      OverwritePeriod: response.overwritePeriod || [],
      [this.integrationTypeEnum]: {
        Spreadsheets: response.sourceTypes || [],
        Worksheets: response.worksheets || [],
        HeaderRowNumber: response.headerRowNumber || [],
        Parameters: response.parameters || []
      },
      Name: response.name || defaultIntegrationName,
      TableName: response.tableName || defaultTableName
    };
  }

  protected clearSelectedSettings(): void {
    this.selectedIntegrationSettings[this.integrationTypeEnum].Spreadsheets = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].Worksheets = [];
    this.selectedIntegrationSettings[this.integrationTypeEnum].HeaderRowNumber = null;
    this.selectedIntegrationSettings[this.integrationTypeEnum].Parameters = [];
  }

  protected initSelectedSettings(initialSettings: IntegrationSettings, isRefresh: boolean = false): void {
    const isRefreshSettings = this.editMode && isRefresh;
    this.selectedIntegrationSettings.Id = initialSettings.Id;
    this.selectedSettings.Spreadsheets = isRefreshSettings && !_.isNil(this.selectedIntegrationSettings[this.integrationTypeEnum].Spreadsheets[0])
      ? initialSettings[this.integrationTypeEnum].Spreadsheets.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].Spreadsheets[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].Spreadsheets.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.Worksheets = isRefreshSettings
      ? initialSettings[this.integrationTypeEnum].Worksheets.filter((f: ListItemModel): boolean => f.selected = this.selectedIntegrationSettings[this.integrationTypeEnum].Worksheets[0].id === f.id)
      : initialSettings[this.integrationTypeEnum].Worksheets.filter((f: ListItemModel): boolean => f.selected);

    this.selectedSettings.HeaderRowNumber = initialSettings[this.integrationTypeEnum].HeaderRowNumber;

    this.selectedSettings.Parameters = initialSettings[this.integrationTypeEnum].Parameters.filter(
      (f: ListItemModel): boolean => f.selected
    );

    this.initSelectedCommonSettings(initialSettings, isRefreshSettings);
  }

  protected refreshParameters(): Observable<any> {

    return this.rentaApiService.initIntegration(this.getWizardRequestData())
      .pipe(
        mergeMap((res: any): Observable<IntegrationSettings> => {
          this.meta = res.meta;
          res = this.mapIntegrationSettings(res, this.selectedIntegrationSettings.TableName, this.selectedIntegrationSettings.Name);

          const selectedAcc = this.selectedIntegrationSettings[this.integrationTypeEnum].Spreadsheets[0] ||
            res[this.integrationTypeEnum].Spreadsheets.find((f: ListItemModel): boolean => f.selected);

          const selectedProp = this.selectedIntegrationSettings[this.integrationTypeEnum].Worksheets[0] ||
            res[this.integrationTypeEnum].Worksheets.find((f: ListItemModel): boolean => f.selected);

          return (_.isNil(selectedAcc) || _.isNil(res[this.integrationTypeEnum].Spreadsheets.find((f: ListItemModel): boolean => f.id === selectedAcc.id)))
            ? of(res)
            : this.getWorksheets(selectedAcc).pipe(
              mergeMap((props: Array<ListItemModel>): Observable<IntegrationSettings> => {
                res[this.integrationTypeEnum].Property = props;
                return (_.isNil(selectedProp) || _.isNil(res[this.integrationTypeEnum].Worksheets.find((f: ListItemModel): boolean => f.id === selectedProp.id)))
                  ? of(res)
                  : this.getParameters().pipe(
                    mergeMap((params: Array<ListItemModel>): Observable<IntegrationSettings> => {
                      res[this.integrationTypeEnum].Parameters = params;
                      this.compareParametersWithCacheData(params);
                      return of(res);
                    })
                  );
              })
            );
        })
      );
  }
}
