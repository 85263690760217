import { Component, OnDestroy, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';

import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { RentaChipsDataSource } from '../../../../../shared/models/DataSource/RentaChipsDataSource';
import {
  RentaMultiselectDropdownDataSource
} from '../../../../../shared/models/DataSource/RentaMultiselectDropdownDataSource';

import { ChipsModel } from '../../../../../shared/models/ChipsModel';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { getSelectedChips } from '../../../../../../helpers/helpers';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';
import { GoogleSheetsSettingsService } from '../../../../services/integrationSettings/google-sheets-settings.service';
import { RentaInputDataSource } from '../../../../../shared/models/DataSource/RentaInputDataSource';
import {
  IconPositionEnum,
  RentaInputDataSourceModel
} from '../../../../../shared/models/DataSource/DataSourceModels/RentaInputDataSourceModel';
import { Subject, Subscription } from 'rxjs';

@Component({
  templateUrl: './google-sheets.component.html',
  styleUrls: ['../common-settings.scss']
})
export class GoogleSheetsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean = true;
  public SpreadsheetsDataSource: RentaDropdownDataSource;
  public WorksheetsDataSource: RentaDropdownDataSource;
  public headerRowNumberDataSource: RentaInputDataSource;
  public chipsParametersDataSource: RentaChipsDataSource;
  public ParametersDataSource: RentaMultiselectDropdownDataSource;

  private headerRowNumberChange$: Subject<Event> = new Subject<Event>();
  private subscription: Subscription;
  private readonly throttledApplyHeaderRowNumber: ($event: Event) => void;

  constructor(private settingsService: GoogleSheetsSettingsService
  ) {
    super(IntegrationTypeEnum.google_sheets);

    this.throttledApplyHeaderRowNumber = _.throttle(
      this.applyHeaderRowNumber.bind(this),
      500
    );
  }

  public ngOnInit(): void {
    this.subscription = this.headerRowNumberChange$
      .pipe(
        debounceTime(300)
      )
      .subscribe($event => this.throttledApplyHeaderRowNumber($event));

    this.SpreadsheetsDataSource = new RentaDropdownDataSource('Spreadsheets', []);
    this.WorksheetsDataSource = new RentaDropdownDataSource('Worksheets', []);
    this.headerRowNumberDataSource = new RentaInputDataSource('Header row number', null);
    this.ParametersDataSource = new RentaMultiselectDropdownDataSource('Worksheet columns', []);
    this.chipsParametersDataSource = new RentaChipsDataSource('Selected Parameters', []);

    this.connectParamsChipsDS(this.chipsParametersDataSource, this.settingsService);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
      this.SpreadsheetsDataSource.setLoading(res);
      this.WorksheetsDataSource.setLoading(res);
      this.headerRowNumberDataSource.setLoading(res);
      this.ParametersDataSource.setLoading(res);
      this.chipsParametersDataSource.setLoading(res);
      this.refreshRelatedDataSources();
    });

    this.settingsService.clearErrors.subscribe((__: Array<ErrorMessage>): void => {
      this.clearErrors();
    });

    this.settingsService.connectToLoading().subscribe((res: boolean): void => {
      this.isLoaded = !res;
    });


    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      this.SpreadsheetsDataSource.refresh(_.cloneDeep(res[this.integrationType].Spreadsheets));
      this.WorksheetsDataSource.refresh(_.cloneDeep(res[this.integrationType].Worksheets));
      this.headerRowNumberDataSource.refresh(_.cloneDeep(res[this.integrationType].HeaderRowNumber));
      this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
      const chipsParamsDs = getSelectedChips(res[this.integrationType].Parameters);
      this.chipsParametersDataSource.refresh(chipsParamsDs);
      this.settingsService.initCache();

      const headerCode: RentaInputDataSourceModel = {
        value: res[this.integrationType].HeaderRowNumber || null,
        iconOptions: {
          position: IconPositionEnum.none
        },
        disabled: false,
        placeholder: 'Set Header row number'
      };

      this.headerRowNumberDataSource.refresh(headerCode);

      this.setRelatedDataSources([
        this.SpreadsheetsDataSource,
        this.WorksheetsDataSource,
        this.headerRowNumberDataSource,
        this.ParametersDataSource
      ]);
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public onApplySpreadsheets($event: ListItemModel): void {
    if (!this.settingsService.setSpreadsheets($event)) {
      return;
    }
    this.WorksheetsDataSource.setLoading(true);
    this.refreshRelatedDataSources();
    this.settingsService
      .getWorksheets($event)
      .subscribe((res: Array<ListItemModel>): void => {
        this.WorksheetsDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.WorksheetsDataSource.refresh(_.cloneDeep(res));
        }
      });
  }


  public onApplyWorksheets($event: ListItemModel): void {
    if (!this.settingsService.setWorksheets($event)) {
      return;
    }
    this.ParametersDataSource.setLoading(true);
    this.refreshRelatedDataSources();
    this.settingsService
      .getParameters()
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(res);
          this.chipsParametersDataSource.refresh(getSelectedChips(res));
        }
      });
  }

  public onCloseChip(e: ChipsModel): void {
    this.ParametersDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
    setTimeout((): void => {
      this.settingsService.setDataToCache();
    });
  }

  public onApplyWorksheetColumns($event: Array<ListItemModel>): void {
    this.onApplyParams($event, this.chipsParametersDataSource);
    this.settingsService.setDataToCache();
  }

  public onHeaderRowNumberChange($event: any): void {
    this.headerRowNumberChange$.next($event);
  }

  private applyHeaderRowNumber($event: Event): void {
    const target = $event.target as HTMLInputElement;
    const headerRowNumber: number = Number(target.value);

    if (headerRowNumber <= 0 || isNaN(headerRowNumber)) {
      return;
    }

    if (!this.settingsService.setHeaderRowNumber(headerRowNumber)) {
      return;
    }

    this.ParametersDataSource.setLoading(true);
    this.refreshRelatedDataSources();

    this.settingsService.getParameters()
      .subscribe({
        next: (res: Array<ListItemModel>): void => {
          this.ParametersDataSource.setLoading(false);
          this.refreshRelatedDataSources();
          if (res.length > 0) {
            this.settingsService.compareParametersWithCacheData(res);
            this.ParametersDataSource.refresh(res);
            this.chipsParametersDataSource.refresh(getSelectedChips(res));
          }
        },
        error: (err: any): void => {
          this.ParametersDataSource.setLoading(false);
          console.error('Error fetching parameters:', err);
        }
      });

    this.settingsService.setHeaderRowNumber(headerRowNumber);
    this.settingsService.setDataToCache();
  }
}
