import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RentaInputDataSource } from '../../../models/DataSource/RentaInputDataSource';

@Component({
  selector: 'app-renta-input-string-row',
  templateUrl: './renta-input-string-row.component.html'
})
export class RentaInputStringRowComponent implements OnInit {

  @Output()
  public onValueChanged: EventEmitter<{name: string, value: string}> = new EventEmitter<{name: string, value: string}>();

  @Output()
  public onDelete: EventEmitter<string | number> = new EventEmitter<string | number>();

  @Input()
  public RentaInputSource: RentaInputDataSource;

  constructor() { }

  public ngOnInit(): void {
  }

  public delete(): void {
    this.onDelete.emit(this.RentaInputSource.name);
  }

  public onValueChange($event: string): void {
    this.RentaInputSource.refreshValue($event);
    this.onValueChanged.emit({name: this.RentaInputSource.name, value: $event});
  }
}
