import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { FilterModel } from '../../models/DataSource/RentaSettingsFiltersDataSource';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';

@Component({
  selector: 'app-renta-settings-filter',
  templateUrl: './renta-settings-filter.component.html',
  styleUrls: ['./renta-settings-filter.component.scss'],
})
export class RentaSettingsFilterComponent implements OnInit {
  @Input()
  public filterModel: FilterModel;
  @Input()
  public canEdit: boolean;

  @Output()
  public OnApply: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public OnDelete: EventEmitter<any> = new EventEmitter<any>();

  public FiltersSource: Array<ListItemModel>;
  public SelectedFilter: ListItemModel;
  private tmpSelectedFilterModel: ListItemModel;

  constructor() {}

  public ngOnInit(): void {
    setTimeout((): void => this.filterModel.source.setLoading(this.filterModel.editMode));
    this.FiltersSource = this.removeSelected(this.filterModel.source.getCollectionCopy());
    this.SelectedFilter = this.filterModel.selected;
  }

  public deleteFilter(): void {
    this.filterModel.selected = Object.assign({}, this.SelectedFilter);
    this.filterModel.selected.selected = false;
    this.OnDelete.emit(this.filterModel);
  }

  public editFilter(): void {
    this.filterModel.source.setLoading(true);
    this.FiltersSource = this.removeSelected(this.filterModel.source.getCollectionCopy());
    this.filterModel.editMode = true;
    this.tmpSelectedFilterModel = Object.assign({}, this.SelectedFilter);
    this.tmpSelectedFilterModel.operators = this.SelectedFilter.operators.map((o: ListItemModel): ListItemModel => Object.assign({}, o));
  }

  public cancel(): void {
    this.filterModel.source.setLoading(false);

    if (_.isNil(this.tmpSelectedFilterModel)) {
      this.deleteFilter();
    }

    this.filterModel.editMode = false;
    this.SelectedFilter = Object.assign({}, this.tmpSelectedFilterModel);
    this.tmpSelectedFilterModel = null;
  }

  public saveFilter(event: ListItemModel): void {
    if (!_.isNil(this.SelectedFilter)) {
      this.SelectedFilter.selected = false;
      this.filterModel.selected = this.SelectedFilter;
      this.OnApply.emit(this.filterModel);
    }

    this.SelectedFilter = event;
    this.SelectedFilter.selected = true;
    this.tmpSelectedFilterModel = null;
    this.filterModel.editMode = false;
    this.filterModel.selected = this.SelectedFilter;
    this.OnApply.emit(this.filterModel);
  }

  private removeSelected(arr: Array<ListItemModel>): Array<ListItemModel> {
    return arr.filter((elem: ListItemModel): ListItemModel => {
      if (!_.isNil(this.SelectedFilter) && elem.id === this.SelectedFilter.id) {
        return elem;
      }

      if (!elem.selected) {
        return elem;
      }
    });
  }
}
