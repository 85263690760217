<div class="setting-row-wrapper p-m-0">
  <app-renta-dropdown *ngIf="isLoaded; else loadingSetting"
                      [RentaDropdownSource]="ReportTypeDataSource"
                      [class.disabled]="isDataSourceLoading"
                      [toolTipInfo]="'Report type consists of the unique set of parameters and metrics available for uploading to the destination. Each Report Type has its own parameters and metrics collection.'"
                      (OnApply)="onApplyReportType($event)"></app-renta-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-chips *ngIf="isLoaded; else loadingSetting"
                   [DataSource]="chipsParametersDataSource"
                   [class.disabled]="isDataSourceLoading"
                   (OnDeleteChip)="onCloseChip($event)"></app-renta-chips>
</div>
<div class="setting-row-wrapper">
  <app-renta-multiselect-dropdown
    *ngIf="isLoaded; else loadingSetting"
    (OnApply)="onApplyParameters($event)"
    [RentaMultiselectDropdownSource]="ParametersDataSource"
    [class.disabled]="isDataSourceLoading"
    [additionalInfo]="'Specify the parameters required for uploading.'"
    [toolTipInfo]="'Parameters contains the list of parameters and metrics available for export.'"
    [hotRefresh]="true">
  </app-renta-multiselect-dropdown>
</div>
<ng-template #loadingSetting>
  <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
  <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
</ng-template>
