<div class="settings-component-container" *ngIf="ds | async; let dataSource">
  <div class="setting-title-wrapper p-d-flex p-flex-column" *ngIf='IsShowName'>
    <div class='p-d-flex'>
      <span class="renta-font-normal setting-title-font">{{ RentaInputSource.name }}</span>
      <i *ngIf='toolTipInfo !== null && toolTipInfo !== undefined && toolTipInfo.length > 0'
         class='renta-ic renta-ic-info-square' [pTooltip]="toolTipInfo" tooltipPosition="top"
         style='max-width: 20px;min-width: 20px;max-height: 20px;min-height: 20px'></i>
    </div>
    <span class='renta-font-small renta-subtext-color p-my-1'
          *ngIf='additionalInfo !== null && additionalInfo !== undefined && additionalInfo.length > 0'>{{ additionalInfo }}</span>
  </div>
  <span [ngClass]="getIconPosition(dataSource.iconOptions)" class="input-margin">
    <i class="drop-down-icon pi {{getIcon(dataSource.iconOptions)}}"></i>
    <label for="{{getId(RentaInputSource.name)}}" class="p-d-none"></label>
      <input #rentaInput id="{{getId(RentaInputSource.name)}}"
             type="text" pInputText class="dropdown-search-input width-max"
             [ngModel]="dataSource.value"
             placeholder="{{dataSource.placeholder ? dataSource.placeholder : 'Please, set ' + RentaInputSource.name}}"
             (ngModelChange)="onChange($event)"
             (change)="onSetChange($event.target.value)" [disabled]="dataSource.disabled"
             [maxlength]="150">
    </span>
  <app-renta-error-message [errorMessage]="RentaInputSource.error"
                           *ngIf="RentaInputSource.error"></app-renta-error-message>
</div>
