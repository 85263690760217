import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { MenuItem } from 'primeng/api';

import {
  CustomDateRangeViewModelEnum,
  DateRangeEnum,
  RentaDateRangeDataSource
} from '../../models/DataSource/RentaDateRangeDataSource';
import { DateRangeDataSourceModel } from '../../models/DataSource/DataSourceModels/DateRangeDataSourceModel';
import { SelectedDateRangeViewModel } from '../../../../models/viewModels/SelectedDateRangeViewModel';
import * as moment from 'moment';

@Component({
  selector: 'app-renta-date-range',
  templateUrl: './renta-date-range.component.html',
  styleUrls: ['../internal/base-settings-styles.css', './renta-date-range.component.css'],
  providers: [DatePipe]
})
export class RentaDateRangeComponent implements OnInit {
  @ViewChild('menu')
  public menu: ElementRef;

  @Input()
  public toolTipInfo: string;

  @Input()
  public additionalInfo: string;

  @Output()
  public OnApply: EventEmitter<SelectedDateRangeViewModel> = new EventEmitter<SelectedDateRangeViewModel>();

  @Input()
  public DateRangeDataSource: RentaDateRangeDataSource;

  public dataSource: Observable<DateRangeDataSourceModel>;
  public menuItems: MenuItem[];
  public toDateTitle: string = null;
  public selectedMenuItem: any;
  public selectedDateRangeEnum: CustomDateRangeViewModelEnum;
  public headerDateFrom: string;
  public headerDateTo: string;
  public headerDateRangeType: string;
  public untilTodayDateMode: boolean = false;
  public untilYesterdayDateMode: boolean = false;
  public maxDateLimit: Date = moment().toDate();
  public minDateLimit: Date = moment({}).add(-3, 'year').toDate();
  public yearRangeLimit: string;

  constructor() {
  }

  public ngOnInit(): void {
    this.yearRangeLimit = `${moment({}).add(-2, 'year').year()}:${moment().year()}`;
    this.dataSource = this.DateRangeDataSource.connect();
    this.dataSource.subscribe((res: DateRangeDataSourceModel): void => {
      if (!res) {
        return;
      }

      this.selectedMenuItem = this.DateRangeDataSource.getSelectedDateRange();
      this.selectedDateRangeEnum = res.DateRangeEnum;
      this.setSelectedMenuItem(res.DateRangeEnum);
      this.setCalendarHeader(this.selectedMenuItem, res);

      if (res.DateRangeEnum === 'Until Today') {
        this.setUntilDateMode(CustomDateRangeViewModelEnum.untilToday);
      }

      if (res.DateRangeEnum === 'Until Yesterday') {
        this.setUntilDateMode(CustomDateRangeViewModelEnum.untilYesterday);
      }

      if (
        this.selectedMenuItem.dateRangeEnumValue === DateRangeEnum.Custom_today ||
        this.selectedMenuItem.dateRangeEnumValue === DateRangeEnum.Custom_yesterday
      ) {
        this.selectedMenuItem.dateRangeEnumValue = DateRangeEnum.Custom_date;
        this.selectedMenuItem.to = this.selectedMenuItem.to.toLowerCase();
      }

      this.OnApply.emit(this.selectedMenuItem);
    });
    this.menuItems = this.getMenu();
  }

  @HostListener('click', ['$event'])
  public click(event: MouseEvent): void {
    event.stopPropagation();
  }

  public onCalendarOpen(): void {
    this.setSelectedMenuItem(this.selectedDateRangeEnum);
  }

  public onCalendarHeaderChange(event: CustomDateRangeViewModelEnum): void {
    this.setUntilDateMode(event);
    this.onApply(event);
  }

  public onSelect(): void {
    if (this.untilTodayDateMode) {
      this.onApply(CustomDateRangeViewModelEnum.untilToday);
    } else if (this.untilYesterdayDateMode) {
      this.onApply(CustomDateRangeViewModelEnum.untilYesterday);
    } else {
      this.onApply(CustomDateRangeViewModelEnum.custom);
    }
  }

  public onApply(selectedDateRangeEnumViewModel: CustomDateRangeViewModelEnum): void {
    this.setSelectedMenuItem(selectedDateRangeEnumViewModel);
    this.DateRangeDataSource.updateDateRange(selectedDateRangeEnumViewModel);
  }

  private setSelectedMenuItem(selectedDateRangeEnumViewModel: CustomDateRangeViewModelEnum): void {
    let label = null;

    switch (selectedDateRangeEnumViewModel) {
      case CustomDateRangeViewModelEnum.toToday:
        label = 'Today';
        break;
      case CustomDateRangeViewModelEnum.toYesterday:
        label = 'Yesterday';
        break;
      case CustomDateRangeViewModelEnum.untilToday:
        label = CustomDateRangeViewModelEnum.custom;
        break;
      case CustomDateRangeViewModelEnum.untilYesterday:
        label = CustomDateRangeViewModelEnum.custom;
        break;
      default:
        label = selectedDateRangeEnumViewModel;
        break;
    }

    const menuItems = this.getMenu();
    menuItems.forEach((item: MenuItem): string => (item.styleClass = item.label === label ? 'selected-menu-date' : ''));
    this.menuItems = menuItems;
  }

  private setCalendarHeader(selected: any, response: any): void {
    this.headerDateFrom = selected.from;
    this.headerDateTo = selected.to;

    if (
      selected.dateRangeEnumValue === DateRangeEnum.Custom_date ||
      selected.dateRangeEnumValue === DateRangeEnum.Custom_today ||
      selected.dateRangeEnumValue === DateRangeEnum.Custom_yesterday
    ) {
      this.headerDateRangeType = null;
    } else {
      this.headerDateRangeType = response.DateRangeEnum;
    }

    if (this.headerDateRangeType === 'To Today') {
      this.headerDateRangeType = 'Today';
    }

    if (this.headerDateRangeType === 'To Yesterday') {
      this.headerDateRangeType = 'Yesterday';
    }
  }

  private getMenu(): Array<MenuItem> {
    return [
      {
        label: CustomDateRangeViewModelEnum.custom,
        command: (): void => {
          this.headerDateTo = CustomDateRangeViewModelEnum.custom;
          this.setUntilDateMode(CustomDateRangeViewModelEnum.custom);
          this.onApply(CustomDateRangeViewModelEnum.custom);
        }
      },
      {
        label: 'Today',
        command: (): void => {
          this.onApply(CustomDateRangeViewModelEnum.toToday);
        }
      },
      {
        label: 'Yesterday',
        command: (): void => {
          this.onApply(CustomDateRangeViewModelEnum.toYesterday);
        }
      },
      {
        label: CustomDateRangeViewModelEnum.last7Days,
        command: (): void => {
          this.onApply(CustomDateRangeViewModelEnum.last7Days);
        }
      },
      {
        label: CustomDateRangeViewModelEnum.last14Days,
        command: (): void => {
          this.onApply(CustomDateRangeViewModelEnum.last14Days);
        }
      },
      {
        label: CustomDateRangeViewModelEnum.lastMonth,
        command: (): void => {
          this.onApply(CustomDateRangeViewModelEnum.lastMonth);
        }
      },
      {
        label: CustomDateRangeViewModelEnum.last2Month,
        command: (): void => {
          this.onApply(CustomDateRangeViewModelEnum.last2Month);
        }
      },
      {
        label: CustomDateRangeViewModelEnum.last6Month,
        command: (): void => {
          this.onApply(CustomDateRangeViewModelEnum.last6Month);
        }
      },
      {
        label: CustomDateRangeViewModelEnum.lastYear,
        command: (): void => {
          this.onApply(CustomDateRangeViewModelEnum.lastYear);
        }
      }
    ];
  }

  private setUntilDateMode(dateRange: CustomDateRangeViewModelEnum): void {
    if (dateRange === CustomDateRangeViewModelEnum.untilToday) {
      this.untilTodayDateMode = true;
      this.untilYesterdayDateMode = false;
      this.maxDateLimit = moment().toDate();
    } else if (dateRange === CustomDateRangeViewModelEnum.untilYesterday) {
      this.untilTodayDateMode = false;
      this.untilYesterdayDateMode = true;
      this.maxDateLimit = moment({}).add(-1, 'day').toDate();
    } else {
      this.untilTodayDateMode = false;
      this.untilYesterdayDateMode = false;
      this.maxDateLimit = moment().toDate();
    }
  }
}
