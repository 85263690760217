<div class="edit-filter width-max p-d-flex p-flex-column">
  <div class="filters-operators-area p-d-flex p-align-center width-max">
    <div class="setting-row-wrapper width-max f-grow-1">
      <app-renta-dropdown
        [RentaDropdownSource]="FiltersDataSource"
        [placeholder]="'Field'"></app-renta-dropdown>
    </div>
    <div class="setting-row-wrapper width-max f-grow-1">
      <app-renta-dropdown
        [RentaDropdownSource]="OperatorsDataSource"
        [placeholder]="'Condition'"
        [CanSearch]="false"></app-renta-dropdown>
    </div>
    <div class="filter-controls">
      <p-button
        [disabled]="false"
        styleClass="renta-btn-secondary"
        (onClick)="cancel()">
        <i class="pi pi-times"></i>
      </p-button>
    </div>
  </div>
  <div class="filter-input-area p-d-flex">
    <input
      class="renta-input-base"
      placeholder="Filter"
      type="text"
      pInputText
      [disabled]="!Selected.id"
      [(ngModel)]="Selected.value">
    <div class="filter-controls">
      <p-button
        [disabled]="!isSelectedFilterValid()"
        styleClass="renta-btn-secondary"
        (onClick)="save()">
        <i class="pi pi-check"></i>
      </p-button>
    </div>
  </div>
</div>
