import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-iikoweb-auth',
  templateUrl: './iikoweb-auth.component.html',
  encapsulation: ViewEncapsulation.None,
  })
export class IikowebAuthComponent {
  @Input()
  public error: string;

  @Input()
  public host: string;

  @Input()
  public login: string;

  @Input()
  public password: string;

  @Input()
  public loading: boolean;
}
