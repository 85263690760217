import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public sessionSetItem<T>(key: string, item: T): void {
    const isKeyExist = sessionStorage.getItem(key) !== null;

    if (isKeyExist) {
      throw new DOMException(`Key: ${key} is already exist in storage`);
    }

    sessionStorage.setItem(key, JSON.stringify(item));
  }

  public sessionGetItem<T>(key: string): T {
    const json = sessionStorage.getItem(key);
    return json ? JSON.parse(json) as T : null;
  }

  public sessionUpdateItem<T>(key: string, item: T): void {
    sessionStorage.setItem(key, JSON.stringify(item));
  }

  public sessionDeleteItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  public localGetItem<T>(key: string): T {
    const json = localStorage.getItem(key)
    return json ? JSON.parse(json) as T : null;
  }

  public localUpdateItem<T>(key: string, item: T): void {
    localStorage.setItem(key, JSON.stringify(item));
  }

  public localDeleteItem(key: string): void {
    localStorage.removeItem(key);
  }

  public cleanAll(): void {
    localStorage.clear();
  }
}
