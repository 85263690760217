import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RentaKeyValueRowDataSource } from '../../models/DataSource/RentaKeyValueRowDataSource';

@Component({
  selector: 'app-renta-key-value-row',
  templateUrl: './renta-key-value-row.component.html',
  styleUrls: ['./renta-key-value-row.component.scss']
})
export class RentaKeyValueRowComponent implements OnInit {
  @Output()
  public onDelete: EventEmitter<string | number> = new EventEmitter<string | number>();

  @Input()
  public Id: string | number;
  @Input()
  public KeyValueDataSource: RentaKeyValueRowDataSource;
  @Input()
  public WithDelete: boolean;

  constructor() {
  }

  public ngOnInit(): void {
  }

  public delete(): void {
    this.onDelete.emit(this.Id);
  }

  public onApplyValue($event: string): void {
    this.KeyValueDataSource.ValueDataSource.refreshValue($event);
  }

  public onApplyKey($event: string): void {
    this.KeyValueDataSource.KeyDataSource.refreshValue($event);
  }
}
