export enum ModalDialogTypeEnum {
  CreateToken,
  DeleteToken,
  CreateIntegration,
  ChooseDb,
  PasswordVerification,
  CroppingImage,
  ConfirmationModal,
  InfoModal,
  PaymentCardModal,
  StreamingModal,
  WebhookModal,
}
