import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { delay } from 'rxjs/operators';
import * as _ from 'lodash';

import { RentaModalsService } from '../../../services/renta-modals.service';
import { IntegrationToken } from '../../../../../models/Integration/IntegrationToken';
import { TokenViewModel } from '../../../../../models/viewModels/TokenViewModel';
import { CreateIntegrationService } from '../../../../integrations/services/create-integration.service';
import { BaseResponse } from '../../../../../models/common/BaseResponse';
import { IntegrationSourceModel } from '../../../../../models/Integration/IntegrationSourceModel';
import { IntegrationTypeEnum } from '../../../../../models/common/IntegrationTypeEnum';
import { SelectTokenModel } from '../../../../../models/Integration/SelectTokenModel';
import { UserService } from '../../../../../services/user.service';
import { ProfileService } from '../../../../../services/profile.service';
import { Subscription } from 'rxjs';

@Component({
  template: '',
})
export abstract class IntegrationSelectBaseComponent implements OnDestroy {
  public availableIntegrations: Array<IntegrationSourceModel>;
  public integrations: Array<IntegrationSourceModel>;
  public accordionActiveState: Array<boolean>;
  public isDisabled: boolean = false;

  @Output()
  public OnSelectToken: EventEmitter<SelectTokenModel> = new EventEmitter<SelectTokenModel>();

  @Input()
  public set search(value: string) {
    this.searchIntegration = value;
    this.changeSearch();
  }

  protected userEmail: string;
  protected emailSubscription: Subscription;

  private selectedIntegrationInfo: { index: number; integration: IntegrationSourceModel };
  private searchIntegration: string;

  protected constructor(
    public readonly modalDialogService: RentaModalsService,
    protected readonly createIntegrationService: CreateIntegrationService,
    protected readonly userService: UserService,
    protected readonly profileService: ProfileService
  ) { }

  public ngOnDestroy(): void {}

  public changeSearch(): void {
    if (this.integrations === undefined || this.integrations?.length === 0) {
      return;
    }

    this.availableIntegrations = this.integrations.filter((f: IntegrationSourceModel): boolean =>
      f.title.toLowerCase().includes(this.searchIntegration.toLowerCase())
    );
  }

  public headerClick(e: { index: number; event: MouseEvent }): void {
    const integration = this.availableIntegrations[e.index];

    if (integration.accounts !== 0) {
      return;
    }
    this.accordionActiveState[e.index] = false;

    this.showAuthModal(e.index, integration);
  }

  public onRemoveToken(
    onRemoveEvent: { originalEvent: MouseEvent; tokenModel: TokenViewModel },
    index: number,
    integration: IntegrationSourceModel,
    integToken: IntegrationToken
  ): void {
    onRemoveEvent.originalEvent.stopPropagation();
    this.selectedIntegrationInfo = {
      index,
      integration,
    };
    this.modalDialogService.removeTokenModal(integration, integToken).onClose.subscribe((res: boolean): void => {
      if (!res || !this.selectedIntegrationInfo) {
        return;
      }
      this.onModalClose(res, this.selectedIntegrationInfo.index, this.selectedIntegrationInfo.integration, false);
    });
  }

  public onModalClose(res: boolean, indexToRefresh: number, integration: IntegrationSourceModel, isTokenAdded: boolean): void {
    if (!res){
      return;
    }

    this.selectedIntegrationInfo = null;
    integration.tokens = null;
    this.refresh(indexToRefresh, isTokenAdded);
  }

  public selectAccount(selectedAccount: {
    integration: IntegrationSourceModel;
    integrationToken: TokenViewModel;
    integrationIndex: number;
  }): void {
    if (!_.isNil(selectedAccount.integrationToken.error)) {
      this.onAddToken({ index: selectedAccount.integrationIndex });
      return;
    }

    selectedAccount.integrationToken.isLoading = true;
    this.isDisabled = true;

    this.createIntegrationService
      .checkToken(selectedAccount.integration.integrationType, selectedAccount.integrationToken.tokenId)
      .pipe(delay(250))
      .subscribe((res: BaseResponse): void => {
        selectedAccount.integrationToken.isLoading = false;
        this.isDisabled = false;

        if (res.errors && res.errors.length > 0) {
          selectedAccount.integrationToken.error = res.errors[0].userMessage;
          return;
        }

        if (res) {
          this.OnSelectToken.emit({
            integrationType: selectedAccount.integration,
            integrationTokenId: selectedAccount.integrationToken.tokenId,
            integrationTokenName: selectedAccount.integrationToken.title,
          });
        }
      });
  }

  public getRandomInt(max: number): number {
    return Math.floor(Math.random() * Math.floor(max));
  }

  public onAddToken(e: { index: number; event?: MouseEvent }): void {
    const integration = this.availableIntegrations[e.index];
    this.showAuthModal(e.index, integration);
  }

  public onEditToken(
    onRemoveEvent: { originalEvent: MouseEvent; tokenModel: TokenViewModel },
    index: number,
    integration: IntegrationSourceModel,
    integToken: IntegrationToken
  ): void {
    onRemoveEvent.originalEvent.stopPropagation();
    this.showAuthModal(index, integration, integToken);
  }

  public canEdit(integToken: IntegrationSourceModel): boolean {
    return integToken.integrationType === IntegrationTypeEnum.javascript_sdk || integToken.integrationType === IntegrationTypeEnum.webHook;
  }

  protected abstract refresh(index: number, isTokenAdded: boolean): void;

  private showAuthModal(index: number, integration: IntegrationSourceModel, integToken: IntegrationToken = null): void {
      this.selectedIntegrationInfo = {
        index,
        integration,
      };

      switch (integration.integrationType) {
        case IntegrationTypeEnum.javascript_sdk:
          this.modalDialogService.showStreamingModal(integToken?.id).onClose.subscribe((res: any): void => {
            this.onModalClose(res, this.selectedIntegrationInfo.index, this.selectedIntegrationInfo.integration, true);
          });
          break;
        case IntegrationTypeEnum.webHook:
          this.modalDialogService.showWebhookModal(integToken?.id).onClose.subscribe((res: any): void => {
            this.onModalClose(res, this.selectedIntegrationInfo.index, this.selectedIntegrationInfo.integration, true);
          });
          break;
        default:
          this.modalDialogService.showSourceAuth(integration).subscribe((res: boolean): void => {
            if (!res || !this.selectedIntegrationInfo) {
              return;
            }
            this.onModalClose(res, this.selectedIntegrationInfo.index, this.selectedIntegrationInfo.integration, true);
          });
      }
   // }
  }
}
