import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RentaApiService } from '../../../../../services/renta-api.service';
import { RentaInputDataSource } from '../../../../shared/models/DataSource/RentaInputDataSource';
import {
  IconPositionEnum,
  RentaInputDataSourceModel
} from '../../../../shared/models/DataSource/DataSourceModels/RentaInputDataSourceModel';
import { RentaDropdownDataSource } from '../../../../shared/models/DataSource/RentaDropdownDataSource';
import { ListItemModel } from '../../../../../models/Integration/ListItemModel';
import { KeyValueCollectionDataSource } from '../../../../shared/models/DataSource/KeyValueCollectionDataSource.';
import { IntegrationTypeEnum } from '../../../../../models/common/IntegrationTypeEnum';
import { ApiResponse } from '../../../../../models/common/ApiResponse`T';
import { delay } from 'rxjs/operators';


@Component({
  selector: 'app-webhook-destination',
  templateUrl: './webhook-destination.component.html',
  styleUrls: ['../../internal/integration-settings/common-settings.scss', '../common-addon-tokens-modal.scss', './webhook-destination.component.scss']
})
export class WebhookDestinationComponent implements OnInit {

  public isLoading: boolean = false;
  public isLoaded: boolean = true;
  public isRefreshing: boolean = false;
  public SendingMethodDataSource: RentaDropdownDataSource;
  public HostDataSource: RentaInputDataSource;
  public headers: boolean = false;
  public HeaderOptionsDataSource: KeyValueCollectionDataSource;
  public NameDataSource: RentaInputDataSource;
  public tokenId: string;
  private host: string;
  private sendingMethod: string;
  private name: string;

  constructor(private readonly config: DynamicDialogConfig, private readonly ref: DynamicDialogRef, protected readonly rentaApiService: RentaApiService) {
    this.tokenId = this.config.data.tokenId;

  }

  public ngOnInit(): void {
    this.SendingMethodDataSource = new RentaDropdownDataSource('Webhook sending method', null);
    this.HostDataSource = new RentaInputDataSource('Host', null);
    this.NameDataSource = new RentaInputDataSource('Name', null);
    this.HeaderOptionsDataSource = new KeyValueCollectionDataSource([]);

    this.isLoaded = false;

    this.rentaApiService.getWebHookInfo(this.tokenId).subscribe((result: { method: string, host: string, name: string, headers: Array<{ key: string, value: string }> }): void => {
      const sendingMethods = [{ id: 'POST', name: 'POST', selected: result.method === 'POST' }, {
        id: 'GET',
        name: 'GET',
        selected: result.method === 'GET'
      }];

      this.sendingMethod = sendingMethods.find(f => f.selected)?.id;
      this.SendingMethodDataSource.refresh(sendingMethods);

      const host: RentaInputDataSourceModel = {
        value: result.host,
        placeholder: 'Set host',
        iconOptions: {
          position: IconPositionEnum.none
        },
        disabled: false
      };

      this.host = result.host;

      this.HostDataSource.refresh(host);

      const name: RentaInputDataSourceModel = {
        value: result.name,
        placeholder: 'Set name',
        iconOptions: {
          position: IconPositionEnum.none
        },
        disabled: false
      };

      this.name = result.name;

      this.NameDataSource.refresh(name);
      this.headers = result.headers?.length > 0;
      this.HeaderOptionsDataSource.refresh(this.headers ? result.headers : [{ key: '', value: '' }]);

      this.isLoaded = true;
    });
  }

  public onClose(): void {
    this.ref.close(false);
  }

  public create(): void {
    this.isRefreshing = true;
    const body = {
      name: this.name,
      method: this.sendingMethod,
      host: this.host,
      headers: this.headers ? this.HeaderOptionsDataSource.getCollection().reduce((acc: any, cur: { key: string, value: string }): any => ({
        ...acc,
        [cur.key]: cur.value
      }), {}) : null
    };

    if (this.tokenId) {
      this.rentaApiService.updateToken(IntegrationTypeEnum.webHook, this.tokenId, body).pipe(delay(1000)).subscribe((result: ApiResponse<{ id: string }>): void => {
        if (result.baseResponse.status) {
          this.ref.close(true);
        } else {
          for (const error of result.baseResponse.errors) {
            if (this.hasOwnProperty(error.message + 'DataSource')) {
              this[error.message + 'DataSource'].setError(error.userMessage);
            }
          }
        }
        this.isRefreshing = false;
      });
    } else {
      this.rentaApiService.saveToken(IntegrationTypeEnum.webHook, body).subscribe((res: ApiResponse<{ id: string }>): void => {
        if (res.baseResponse.status) {
          this.ref.close(true);
        } else {
          for (const error of res.baseResponse.errors) {
            if (this.hasOwnProperty(error.message + 'DataSource')) {
              this[error.message + 'DataSource'].setError(error.userMessage);
            }
          }
        }
        this.isRefreshing = false;
      });
    }
  }

  public onApplySendingMethod($event: ListItemModel): void {
    this.sendingMethod = $event.id;
  }

  public onApplyHost($event: string): void {
    this.host = $event;
  }

  public onSwitcherHeadersChange($event: { originalEvent: PointerEvent, checked: boolean }): void {
    this.headers = $event.checked;
  }

  public onApplyName($event: string): void {
    this.name = $event;
  }
}
