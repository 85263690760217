<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="mr-b-5">
    <app-renta-error-message [errorMessage]="error"
                             *ngIf="error"></app-renta-error-message>
  </div>
  <label for="email" class="p-d-none"></label>
  <input id="email"
         class="p-inputtext-lg auth-dialog-input"
         type="text"
         placeholder="Email"
         pInputText
         appTextInputTrim
         [(ngModel)]="email"
         [disabled]="loading">
  <label for="password" class="p-d-none"></label>
  <input id="password"
         class="p-inputtext-lg auth-dialog-input dialog-input-margin"
         type="password"
         placeholder="Password"
         pInputText
         appTextInputTrim
         [(ngModel)]="password"
         [disabled]="loading">
</div>
