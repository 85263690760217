import { Component, OnInit } from '@angular/core';
import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { KeyValueDropdownDataSource } from '../../../../../shared/models/DataSource/KeyValueDropdownDataSource';
import * as _ from 'lodash';
import { RentaSettingsFiltersDataSource } from '../../../../../shared/models/DataSource/RentaSettingsFiltersDataSource';
import { JsSdkSettingsService } from '../../../../services/integrationSettings/js-sdk-settings.service';

@Component({
  templateUrl: './webhook-addon-settings.component.html',
  styleUrls: ['../common-settings.scss']
})
export class WebhookAddonSettingsComponent extends SettingsBaseComponent implements OnInit {

  public isLoaded: boolean;
  public EventTypesDataSource: RentaDropdownDataSource;
  public EventNameDataSource: RentaDropdownDataSource;
  public OutputDataSource: KeyValueDropdownDataSource;
  public FiltersDataSource: RentaSettingsFiltersDataSource;
  public isShowEventNames: boolean = false;

  constructor(private settingsService: JsSdkSettingsService) {
    super(IntegrationTypeEnum.javascript_sdk);
  }

  public ngOnInit(): void {
    this.EventTypesDataSource = new RentaDropdownDataSource('Event Type', null);
    this.EventNameDataSource = new RentaDropdownDataSource('Event Name', null);
    this.OutputDataSource = new KeyValueDropdownDataSource('Javascript events value', 'Webhook parameters', [], []);
    this.FiltersDataSource = new RentaSettingsFiltersDataSource('Filters', []);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.isLoaded = false;

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      if (res[this.integrationType].EventTypes) {
        this.EventTypesDataSource.refresh(_.cloneDeep(res[this.integrationType].EventTypes));
      }

      if (res[this.integrationType].EventNames) {
        this.EventNameDataSource.refresh(_.cloneDeep(res[this.integrationType].EventNames));
      }

      if (res[this.integrationType].Parameters) {
        this.OutputDataSource.refresh(res[this.integrationType].Output, res[this.integrationType].Parameters || []);
      }

      if (res[this.integrationType].Filters) {
        this.FiltersDataSource.refresh(res[this.integrationType].Filters);
      }

      this.isLoaded = true;
    });
  }

  public onEventNameApply($event: ListItemModel): void {
    if (!this.settingsService.setEventName($event)) {
      return;
    }

    const eventType = this.EventTypesDataSource.getSelectedItem();
    this.getParametersData(eventType, $event);
  }


  public onEventTypeApply($event: ListItemModel): void {
    this.isShowEventNames = $event.id !== 'page' && $event.id !== 'identify';
    if (!this.settingsService.setEventType($event)) {
      return;
    }

    if (this.isShowEventNames) {
      this.settingsService.getEventNames($event).subscribe((res: Array<ListItemModel>): void => {
        this.EventNameDataSource.refresh(res);
        this.getParametersData($event);
      });
    } else {
      this.getParametersData($event);
    }
  }

  public onApplyFilters($event: Array<ListItemModel>): void {
    this.settingsService.setFilters($event);
  }

  public onApplyCollection($event: Array<{ parameter: string; value: string }>): void {
    this.settingsService.setEventParameters($event);
  }

  public onRefreshEventNames(): void {
    if (this.isShowEventNames) {
      const selectedEventType = this.EventTypesDataSource.getSelectedItem();
      this.settingsService.getEventNames(selectedEventType).subscribe((res: Array<ListItemModel>): void => {
        this.EventNameDataSource.refresh(res);
      });
    }
  }

  private getParametersData(selectedEventType: ListItemModel, selectedEventName?: ListItemModel): void {
    this.settingsService.getEventParameters(selectedEventType, selectedEventName).subscribe((parameters: Array<ListItemModel>): void => {
      this.OutputDataSource.refresh([], parameters || []);
      this.settingsService.getEventFilters(selectedEventType, selectedEventName).subscribe((fRes: Array<ListItemModel>): void => {
        this.FiltersDataSource.refresh(fRes);
        this.FiltersDataSource.setCanEdit(fRes.length > 0);
      });
    });
  }
}
