<div class="settings-component-container"
     *ngIf="ds | async; let dataSource">
  <div class="setting-title-wrapper">
    <span class="renta-font-normal setting-title-font">{{RentaSettingsFiltersSource.name}}</span>
  </div>
  <div class="setting-content-wrapper p-d-flex p-flex-column">
    <div class="filters-container width-max">
      <app-renta-settings-filter
        *ngFor="let filter of RentaSettingsFiltersSource.selectedFilters"
        [filterModel]="filter"
        (OnDelete)="deleteFilter($event)"
        (OnApply)="applyFilter($event)"
        [canEdit]='RentaSettingsFiltersSource.canEdit | async'>
      </app-renta-settings-filter>
    </div>
    <app-renta-error-message [errorMessage]="RentaSettingsFiltersSource.error"
                             *ngIf="RentaSettingsFiltersSource.error"></app-renta-error-message>
    <div class="action-button-container width-max p-d-flex p-align-center">
      <p-button
        [disabled]="!dataSource || dataSource.length == 0 || RentaSettingsFiltersSource.selectedFilters.length > 4 || RentaSettingsFiltersSource.loading || !(RentaSettingsFiltersSource.canEdit | async)"
        [style.max-width.px]="115"
        label="ADD FILTER"
        styleClass="renta-btn-secondary renta-font-small"
        (onClick)="addFilter()">
      </p-button>

    </div>
  </div>
</div>


