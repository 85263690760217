import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterModel, RentaSettingsFiltersDataSource } from '../../models/DataSource/RentaSettingsFiltersDataSource';
import { Observable } from 'rxjs';
import { ListItemModel } from '../../../../models/Integration/ListItemModel';

@Component({
  selector: 'app-renta-setting-filters',
  templateUrl: './renta-settings-filters.component.html',
  styleUrls: ['../internal/base-settings-styles.css', './renta-settings-filters.component.scss']
})
export class RentaSettingsFiltersComponent implements OnInit {
  @Input()
  public RentaSettingsFiltersSource: RentaSettingsFiltersDataSource;

  @Output()
  public OnApply: EventEmitter<Array<ListItemModel>> = new EventEmitter<Array<ListItemModel>>();

  public ds: Observable<Array<ListItemModel>> = null;

  constructor() {
  }

  public ngOnInit(): void {
    this.ds = this.RentaSettingsFiltersSource.connect();
  }

  public addFilter(selectedFilter: ListItemModel = null): void {
    this.RentaSettingsFiltersSource.addFilter(selectedFilter);
  }

  public applyFilter(filterModel: FilterModel): void {
    this.RentaSettingsFiltersSource.applyFilter(filterModel);
    this.OnApply.emit(this.RentaSettingsFiltersSource.getSelectedItems());
  }

  public deleteFilter(filterModel: FilterModel): void {
    this.RentaSettingsFiltersSource.deleteFilter(filterModel);
    this.OnApply.emit(this.RentaSettingsFiltersSource.getSelectedItems());
  }
}
