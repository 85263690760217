import { Component, Inject, OnInit } from '@angular/core';
import { SETTINGS_SERVICE_BASE } from '../../../../services/DiExtensions';
import { ISettingsServiceBase } from '../../../../services/integrationSettings/IEtlSettingsService';
import { RentaInputDataSource } from '../../../../../shared/models/DataSource/RentaInputDataSource';
import {
  IconPositionEnum,
  RentaInputDataSourceModel
} from '../../../../../shared/models/DataSource/DataSourceModels/RentaInputDataSourceModel';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';

@Component({
  templateUrl: './integration-info.component.html',
  styleUrls: ['../common-settings.scss']
})
export class IntegrationInfoComponent implements OnInit {
  public TableNameDataSource: RentaInputDataSource;
  public NameDataSource: RentaInputDataSource;
  public isLoaded: boolean = true;
  public isShowTableName: boolean = true;

  constructor(@Inject(SETTINGS_SERVICE_BASE) private settingsService: ISettingsServiceBase) {
    this.isShowTableName = this.settingsService.isETL();
  }

  public ngOnInit(): void {
    this.TableNameDataSource = new RentaInputDataSource('Table Name', null);
    this.NameDataSource = new RentaInputDataSource('Integration name', null);

    this.settingsService.connectToLoading().subscribe((res: boolean): void => {
      setTimeout((): void => {
        this.isLoaded = !res;
      });
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      const dsTableName: RentaInputDataSourceModel = {
        value: res.TableName,
        iconOptions: {
          position: IconPositionEnum.right
        },
        disabled: false
      };

      const dsIntegrationName: RentaInputDataSourceModel = {
        value: res.Name,
        iconOptions: {
          position: IconPositionEnum.right
        },
        placeholder: 'Set the integration name',
        disabled: false
      };

      this.TableNameDataSource.refresh(dsTableName);
      this.NameDataSource.refresh(dsIntegrationName);
      this.isLoaded = true;
      // TODO: Fix this
      this.settingsService.setIntegrationName(dsIntegrationName.value);
      if (this.isShowTableName) {
        this.settingsService.setTableName(dsTableName.value);
      }

    });

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      const prefix = 'DataSource';
      console.log(errors);
      for (const error of errors) {
        const dataSourceName = error.message + prefix;
        const dataSourceError = error.userMessage;
        if (this.hasOwnProperty(dataSourceName)) {
          this[dataSourceName].setError(dataSourceError);
        }
      }
    });
  }

  public onApplyTableName(tableName: any): void {
    this.settingsService.setTableName(tableName);
  }

  public onApplyIntegrationName(integrationName: any): void {
    this.settingsService.setIntegrationName(integrationName);
  }
}
