import { Component, Input, OnInit } from '@angular/core';
import { RentaApiService } from '../../../../../../services/renta-api.service';
import { environment } from '../../../../../../../environments/environment';
import { RentaDropdownDataSource } from '../../../../models/DataSource/RentaDropdownDataSource';
import { ApiResponse } from '../../../../../../models/common/ApiResponse`T';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';

@Component({
  selector: 'app-amazon-s3-auth',
  templateUrl: './amazon-s3-auth.component.html',
  styleUrls: ['./amazon-s3-auth.component.scss']
})
export class AmazonS3AuthComponent implements OnInit {

  @Input()
  public error: string;

  @Input()
  public AwsAccessKey: string;

  @Input()
  public AwsSecretAccessKey: string;

  @Input()
  public Region: string;

  public RegionDataSource: RentaDropdownDataSource;

  @Input()
  public BucketName: string;

  @Input()
  public loading: boolean;

  constructor(private readonly rentaApiService: RentaApiService) {
  }

  public ngOnInit(): void {
    this.loading = true;
    this.RegionDataSource = new RentaDropdownDataSource('Region', []);
    this.rentaApiService.httpClient.get<ApiResponse<Array<ListItemModel>>>(environment.baseApiUrl + '/amazon_s3/regions').subscribe(res => {
      this.RegionDataSource.refresh(res.result);
      this.loading = false;
    });
  }

  public onApplyRegion($event: ListItemModel): void {
    this.Region = $event.id;
  }
}
