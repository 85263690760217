<div class="settings-component-container" *ngIf="ds | async; let dataSource">
  <div class="setting-title-wrapper p-d-flex p-flex-column" *ngIf="ShowName">
    <div class='p-d-flex'>
    <span class="renta-font-normal setting-title-font">{{ RentaDropdownSource.name }}</span>
    <i *ngIf='toolTipInfo !== null && toolTipInfo !== undefined && toolTipInfo.length > 0' class='renta-ic renta-ic-info-square' [pTooltip]="toolTipInfo" tooltipPosition="top"  style='max-width: 20px;min-width: 20px;max-height: 20px;min-height: 20px'></i>
    </div>
    <span class='renta-font-small renta-subtext-color p-my-1' *ngIf='additionalInfo !== null && additionalInfo !== undefined && additionalInfo.length > 0'>{{additionalInfo}}</span>
  </div>
  <div
    appOverlayDropdown
    [DropdownCount]="dataSource.length"
    (OnToggleOverlay)="onFocus($event)"
    class="dropdown-container"
    [class.p-disabled]="dataSource.length === 0 || RentaDropdownSource.loading"
  >
    <div class="input-margin width-max" [ngClass]="isOpen && CanSearch ? 'p-input-icon-left' : 'p-input-icon-right'">
      <i class="drop-down-icon pi pi-search" *ngIf="isOpen && CanSearch"></i>
      <i class="drop-down-icon pi pi-chevron-down" *ngIf="(!isOpen || !CanSearch) && !RentaDropdownSource.loading"></i>
      <i class="drop-down-icon pi pi-spin pi-spinner" *ngIf="RentaDropdownSource.loading"></i>
      <label for="{{ getId(RentaDropdownSource.name) }}" class="p-d-none"></label>
      <input
        id="{{ getId(RentaDropdownSource.name) }}"
        type="text"
        pInputText
        class="dropdown-search-input width-max"
        [class.invalid]="Invalid"
        [class.input-text-has-selected]="getPlaceholder() !== defaultPlaceHolder"
        [class.readonly]="!CanSearch"
        [(ngModel)]="searchString"
        placeholder="{{ getPlaceholder() }}"
        (keyup)="RentaDropdownSource.filter(searchString)"
        [readOnly]="!CanSearch"
      />
    </div>
    <ng-template #dropdownContent>
      <div class="dropdown-content">
        <div class="dropdown-content-wrapper" [style.maxHeight.px]="maxHeight">
          <app-dropdown-content
            [ExtendedDescription]="ExtendedDescription"
            [DataSource]="dataSource"
            (OnClickListItem)="onSelectListItem($event)"
          >
          </app-dropdown-content>
        </div>
      </div>
    </ng-template>
  </div>
  <app-renta-error-message [errorMessage]="RentaDropdownSource.error" *ngIf="RentaDropdownSource.error"></app-renta-error-message>
</div>
