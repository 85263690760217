import { Component, Input, OnInit } from '@angular/core';
import { RentaKeyValueRowDataSource } from '../../models/DataSource/RentaKeyValueRowDataSource';
import { KeyValueCollectionDataSource } from '../../models/DataSource/KeyValueCollectionDataSource.';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-renta-key-value-collection',
  templateUrl: './renta-key-value-collection.component.html',
  styleUrls: ['./renta-key-value-collection.component.scss']
})
export class RentaKeyValueCollectionComponent implements OnInit {
  @Input()
  public Collection: KeyValueCollectionDataSource;

  public CollectionDataSource: Observable<Array<RentaKeyValueRowDataSource>>;

  constructor() { }

  public ngOnInit(): void {
    this.CollectionDataSource = this.Collection.connect();
  }

  public onDeleteItem(index: number): void {
   this.Collection.removeKeyValueRow(index);
  }

  public onAdd(): void {
    this.Collection.addEmptyKeyValueRow();
  }
}
