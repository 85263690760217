<div class="integration-settings-container p-d-flex p-flex-column p-p-6">
<div class='setting-row-wrapper p-m-0'>
  <app-renta-dropdown *ngIf='isLoaded; else loadingSetting'
                      [RentaDropdownSource]='AccountsDataSource'
                      (OnApply)='onAccountApply($event)'></app-renta-dropdown>
</div>

<div class='setting-row-wrapper'>
  <app-renta-dropdown *ngIf='isLoaded; else loadingSetting'
                      [RentaDropdownSource]='FbPixelDataSource'
                      (OnApply)='onFbPixelApply($event)'></app-renta-dropdown>
</div>
</div>

<div class="integration-settings-container p-d-flex p-flex-column p-p-6">
  <div class='p-d-flex p-flex-column description p-p-1'  *ngIf='isLoaded; else loadingSetting'>
    <span class='renta-font-normal p-text-bold'>Event type</span>
    <span class='renta-font-small renta-subtext-color p-mt-1'>Select the Javascript SDK event from which you want to sync data.</span>
  </div>

  <div class='setting-row-wrapper p-mt-2'>

    <app-renta-dropdown *ngIf='isLoaded; else loadingSetting'
                        [RentaDropdownSource]='EventTypesDataSource'
                        (OnApply)='onEventTypeApply($event)'
    [ShowName]='false'></app-renta-dropdown>

  </div>
  <div class='setting-row-wrapper p-d-flex p-ai-end' *ngIf='isShowEventNames'>
    <div class='width-max'>
    <app-renta-dropdown *ngIf='isLoaded; else loadingSetting'
                        [RentaDropdownSource]='EventNameDataSource'
                        (OnApply)='onEventNameApply($event)'></app-renta-dropdown>
  </div>
    <div class="p-ml-2">
      <p-button styleClass="renta-btn-secondary" (onClick)="onRefreshEventNames()">
        <i class="pi pi-refresh"></i>
      </p-button>
    </div>
  </div>
  <div class='setting-row-wrapper'>
    <app-renta-setting-filters
      *ngIf="isLoaded; else loadingSetting"
      (OnApply)="onApplyFilters($event)"
      [RentaSettingsFiltersSource]="FiltersDataSource">
    </app-renta-setting-filters>
  </div>

</div>

<div class="integration-settings-container p-d-flex p-flex-column p-p-6">
    <div class='p-d-flex p-flex-column description p-p-1'  *ngIf='isLoaded; else loadingSetting'>
      <span class='renta-font-normal p-text-bold'>Facebook Pixel event name</span>
      <span class='renta-font-small renta-subtext-color p-mt-1'>Select the standart event type or specify a custom event name that you want to send to the Facebook CAPI.</span>
    </div>

  <div class='setting-row-wrapper p-mt-2'>
    <app-renta-dropdown *ngIf='isLoaded; else loadingSetting'
                        [RentaDropdownSource]='FacebookPixelEventNameDataSource'
                        (OnApply)='onPixelEventNameApply($event)'
                        [ShowName]='false'></app-renta-dropdown>
  </div>
  <div class='setting-row-wrapper'>
    <app-renta-dropdown *ngIf='isLoaded; else loadingSetting'
                        [RentaDropdownSource]='ActionSourceDataSource'
                        (OnApply)='onActionSourceApply($event)'></app-renta-dropdown>
  </div>

  <div class='p-d-flex p-flex-column description setting-row-wrapper'  *ngIf='isLoaded; else loadingSetting'>
    <span class='renta-font-normal p-text-bold'>Data mapping</span>
    <span class='renta-font-small renta-subtext-color p-mt-1'>Configure how event data should be mapped to fields in your destinations.</span>
  </div>

  <div class='setting-row-wrapper p-mt-4 p-p-0'>
    <app-renta-key-value-dropdowns
      *ngIf='isLoaded; else loadingSetting'
      [Collection]='OutputDataSource'
      (OnApplyCollection)='onApplyCollection($event)'
    ></app-renta-key-value-dropdowns>
  </div>

  <div class="setting-row-wrapper">
    <app-renta-selection
      *ngIf='isLoaded; else loadingSetting'
      [RentaSelectionOptionDataSource]="debugModeDataSource" (OnApply)="onApplyDebugMode($event)">
    </app-renta-selection>
  </div>


  <div class="setting-row-wrapper" *ngIf='isDebugMode' style='margin-top: 12px;'>
    <div class=' p-my-1'>
      <span class='renta-font-small renta-subtext-color'>Enter the Facebook Pixel test event code:</span>
    </div>
    <div *ngIf="isLoaded; else loadingSetting">
      <app-renta-input [RentaInputSource]="TestEventCodeDataSource"
                       [IsShowName]='false'
                       [IsTransliterate]='true'
                       (OnApply)="onApplyTestEventCode($event)"></app-renta-input>
    </div>
  </div>

</div>

<ng-template #loadingSetting>
  <p-skeleton styleClass='p-mb-2' width='120px' height='24px' borderRadius='8px'></p-skeleton>
  <p-skeleton styleClass='p-mb-3' height='37px' borderRadius='8px'></p-skeleton>
</ng-template>

















