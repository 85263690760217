import { ListItemModel } from '../models/Integration/ListItemModel';
import { ChipsModel } from '../modules/shared/models/ChipsModel';

declare global {
  // interface Array<T> {
  //   mapToListItemModel(mapFunc: (item: T) => ListItemModel): Array<ListItemModel>;
  // }
}

export function transliterate(input: string): string {
  if (!input || input.length <= 0) {
    return;
  }

  let result = '';

  const transl: any = getTranslCollection();

  input = firstSymbolValidation(input);

  for (let i = 0; i < input.length; i++) {
    if (!isLetter(input[i]) && !isNumber(input[i])) {
      result += '_';
    } else if (!transl[input[i]]) {
      result += input[i];
    } else {
      result += transl[input[i]];
    }
  }

  function firstSymbolValidation(title: string): string {
    if (title.charCodeAt(0) === 32 || !isLetter(title[0])) {
      title = title.slice(1);
    }

    return title;
  }

  function isNumber(symb: string): boolean {
    return symb.charCodeAt(0) >= 48 && symb.charCodeAt(0) <= 57;
  }

  function isLetter(symb: string): boolean {
    const charCode = symb.charCodeAt(0);

    return (
      (charCode >= 65 && charCode <= 90) || // ENG lower case
      (charCode >= 97 && charCode <= 122) || // ENG upper case
      (charCode >= 1072 && charCode <= 1103) || // RU lower case
      (charCode >= 1040 && charCode <= 1071)
    ); // RU upper case
  }

  return result;
}

export function getSelectedChips(data: Array<ListItemModel>): Array<ChipsModel> {
  return data
    .filter((f: ListItemModel): boolean => f.selected)
    .map((item: ListItemModel, index: number): { id: string; order: number; title: string; type: string } => {
      return { id: item.id, order: index, title: item.name, type: item.group };
    });
}

export function dataURItoBlob(dataURI: any): Blob {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

// tslint:disable
const getTranslCollection = () => {
  return {
    '\u0430': 'a',
    '\u0431': 'b',
    '\u0432': 'v',
    '\u0433': 'g',
    '\u0434': 'd',
    '\u0435': 'e',
    '\u0451': 'e',
    '\u0436': 'zh',
    '\u0437': 'z',
    '\u0438': 'i',
    '\u0439': 'j',
    '\u043a': 'k',
    '\u043b': 'l',
    '\u043c': 'm',
    '\u043d': 'n',
    '\u043e': 'o',
    '\u043f': 'p',
    '\u0440': 'r',
    '\u0441': 's',
    '\u0442': 't',
    '\u0443': 'u',
    '\u0444': 'f',
    '\u0445': 'h',
    '\u0446': 'c',
    '\u0447': 'ch',
    '\u0448': 'sh',
    '\u0449': 'shch',
    '\u044a': "'",
    '\u044b': 'y',
    '\u044c': '',
    '\u044d': 'e',
    '\u044e': 'yu',
    '\u044f': 'ya',
    '\u0410': 'A',
    '\u0411': 'B',
    '\u0412': 'V',
    '\u0413': 'G',
    '\u0414': 'D',
    '\u0415': 'E',
    '\u0416': 'Zh',
    '\u0417': 'Z',
    '\u0418': 'I',
    '\u0419': 'J',
    '\u041A': 'K',
    '\u041B': 'L',
    '\u041C': 'M',
    '\u041D': 'N',
    '\u041E': 'O',
    '\u041F': 'P',
    '\u0420': 'R',
    '\u0421': 'S',
    '\u0422': 'T',
    '\u0423': 'U',
    '\u0424': 'F',
    '\u0425': 'H',
    '\u0426': 'C',
    '\u0427': 'Ch',
    '\u0428': 'Sh',
    '\u0429': 'Shch',
    '\u042A': "'",
    '\u042B': 'Y',
    '\u042C': '',
    '\u042D': 'E',
    '\u042E': 'Yu',
    '\u042F': 'Ya',
    '\u00AB': '_',
    '\u00BB': '_',
  };
};

// tslint:disable
export const groupBy = (data, keyFn) =>
  data.reduce((agg, item) => {
    const group = keyFn(item);
    agg[group] = [...(agg[group] || []), item];
    return agg;
  }, {});


export const isNullOrEmpty = (val: string) => val === null || val === undefined || val.trim().length < 1;

export function createOauthWindow(
  url: string,
  toolbar = 'no',
  scrollbars?: () => void,
  statusbar = 'no',
  menubar = 'no',
  resizable = 'no',
  width = 800,
  height = 700,
  left = 790,
  top = 100,
  callbackWindow?: () => void
): Window {
  if (url == null) {
    return null;
  }

  const options =
    `toolbar=${toolbar},` +
    `scrollbars=${scrollbars},` +
    `location=${location},` +
    `statusbar=${statusbar},` +
    `menubar=${menubar},` +
    `resizable=${resizable},` +
    `width=${width},` +
    `height=${height},` +
    `left=${left},` +
    `top=${top}`;

  const oauthWindow = window.open(url, 'OAuthPopUp', options);

  window.addEventListener('beforeunload', function() {
    if (callbackWindow) {
      callbackWindow();
    }
  });

  return oauthWindow;
}
