import { Component, OnDestroy, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';
import * as _ from 'lodash';

import { FbAdsSettingsService } from '../../../../services/integrationSettings/fb-ads-settings.service';
import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { RentaMultiselectDropdownDataSource } from '../../../../../shared/models/DataSource/RentaMultiselectDropdownDataSource';
import { RentaChipsDataSource } from '../../../../../shared/models/DataSource/RentaChipsDataSource';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { ChipsModel } from '../../../../../shared/models/ChipsModel';
import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { getSelectedChips } from '../../../../../../helpers/helpers';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';

@Component({
  templateUrl: './fb-ads-settings.component.html',
  styleUrls: ['../common-settings.scss']
})
export class FbAdsSettingsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;
  public customAttributionSectionIsShown: boolean;
  public AccountDataSource: RentaDropdownDataSource;
  public ReportTypesDataSource: RentaDropdownDataSource;
  public chipsParametersDataSource: RentaChipsDataSource;
  public ParametersDataSource: RentaMultiselectDropdownDataSource;
  public actionReportTimeDataSource: RentaDropdownDataSource;
  public attributionSettingsDataSource: RentaDropdownDataSource;
  public customAttributionClickWindowDataSource: RentaDropdownDataSource;
  public customAttributionViewWindowDataSource: RentaDropdownDataSource;

    constructor(public settingsService: FbAdsSettingsService) {
    super(IntegrationTypeEnum.facebook_ads);
    this.isLoaded = false;
    this.customAttributionSectionIsShown = false;
  }

  public ngOnInit(): void {
    this.AccountDataSource = new RentaDropdownDataSource('Ad account', []);
    this.ReportTypesDataSource = new RentaDropdownDataSource('Report type', []);
    this.ParametersDataSource = new RentaMultiselectDropdownDataSource('Parameters', []);
    this.chipsParametersDataSource = new RentaChipsDataSource('Selected Parameters', []);
    this.actionReportTimeDataSource = new RentaDropdownDataSource('Ad action report time', []);
    this.attributionSettingsDataSource = new RentaDropdownDataSource('Attribution settings', []);
    this.customAttributionClickWindowDataSource = new RentaDropdownDataSource('Click attribution window', []);
    this.customAttributionViewWindowDataSource = new RentaDropdownDataSource('Impression attribution window', []);

    this.connectParamsChipsDS(this.chipsParametersDataSource, this.settingsService);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
      this.AccountDataSource.setLoading(res);
      this.ReportTypesDataSource.setLoading(res);
      this.actionReportTimeDataSource.setLoading(res);
      this.ParametersDataSource.setLoading(res);
      this.chipsParametersDataSource.setLoading(res);
      this.attributionSettingsDataSource.setLoading(res);

      this.customAttributionClickWindowDataSource.setLoading(res);
      this.customAttributionViewWindowDataSource.setLoading(res);
    });


    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      if (res[this.integrationType].Account) {
        this.AccountDataSource.refresh(_.cloneDeep(res[this.integrationType].Account));
      }

      if (res[this.integrationType].ReportTypes) {
        this.ReportTypesDataSource.refresh(_.cloneDeep(res[this.integrationType].ReportTypes));
      }

      if (res[this.integrationType].ActionReportTime) {
        this.actionReportTimeDataSource.refresh(_.cloneDeep(res[this.integrationType].ActionReportTime));
      }

      if (res[this.integrationType].AttributionSettings) {
      this.attributionSettingsDataSource.refresh(_.cloneDeep(res[this.integrationType].AttributionSettings));

      const attributionSettings = res[this.integrationType].AttributionSettings;
      const customAttributionSelected = attributionSettings.find(
          (setting: { id: string; selected: boolean; }) => setting.id === 'custom_attribution' && setting.selected === true
        );

      this.customAttributionSectionIsShown = !!customAttributionSelected;
      }

      if (res[this.integrationType].CustomAttributionClickWindow) {
        this.customAttributionClickWindowDataSource.refresh(_.cloneDeep(res[this.integrationType].CustomAttributionClickWindow));
      }

      if (res[this.integrationType].CustomAttributionViewWindow) {
        this.customAttributionViewWindowDataSource.refresh(_.cloneDeep(res[this.integrationType].CustomAttributionViewWindow));
      }

      if (res[this.integrationType].Parameters) {
        this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));
        const chipsParamsDs = getSelectedChips(res[this.integrationType].Parameters);
        this.chipsParametersDataSource.refresh(chipsParamsDs);
      }

      this.settingsService.initCache();
      this.setRelatedDataSources([this.AccountDataSource, this.ReportTypesDataSource, this.ParametersDataSource]);

      setTimeout((): void => {
        this.isLoaded = true;
      }, 1000);
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onApplyAccounts($event: ListItemModel): void {
    if (!this.settingsService.setAccount($event)) {
      return;
    }
    this.ParametersDataSource.setLoading(true);
    this.refreshRelatedDataSources();
    this.settingsService
      .getParameters($event, null)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(_.cloneDeep(res));
          this.chipsParametersDataSource.refresh(getSelectedChips(res));
        }
      });
  }

  public onApplyReportType($event: ListItemModel): void {
    if (!this.settingsService.setReportType($event)) {
      return;
    }
    this.ParametersDataSource.setLoading(true);
    this.refreshRelatedDataSources();
    this.settingsService
      .getParameters(null, $event)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(_.cloneDeep(res));
          this.chipsParametersDataSource.refresh(getSelectedChips(res));
        }
      });
  }

  public onCloseChip(e: ChipsModel): void {
    this.ParametersDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
    setTimeout((): void => {
      this.settingsService.setDataToCache();
    });
  }

  public onApplyParameters($event: Array<ListItemModel>): void {
    this.onApplyParams($event, this.chipsParametersDataSource);
    this.settingsService.setDataToCache();
  }

  public onApplyReportTime($event: ListItemModel): void {
    if (!this.settingsService.setActionReportTime($event)) {
      return;
    }
  }

  public onApplyAttributionSettings($event: ListItemModel): void {
    if (!this.settingsService.setAttributionSettings($event)) {
      return;
    }

    this.customAttributionSectionIsShown = $event.id === "custom_attribution";
  }

  public onApplyCustomAttributionClickWindow($event: ListItemModel): void {
    if (!this.settingsService.setCustomAttributionClickWindow($event)) {
      return;
    }
  }

  public onApplyCustomAttributionViewWindow($event: ListItemModel): void {
    if (!this.settingsService.setCustomAttributionViewWindow($event)) {
      return;
    }
  }

}
