export enum IconPositionEnum {
  right = 'right',
  left = 'left',
  none = 'none'
}

export interface IconOptions {
  icon?: string;
  position: IconPositionEnum;
}

export interface RentaInputDataSourceModel {
  value: string;
  iconOptions: IconOptions;
  disabled: boolean;
  placeholder?: string;
}
