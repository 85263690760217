import {Component, Input, OnInit,  ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FileIntNumericService} from "../../../../services/file-int-numeric-service.service";

@Component({
  selector: 'app-files-auth',
  templateUrl: './files-auth.component.html',
  styleUrls: ['./files-auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilesAuthComponent implements OnInit {

  public fileForm: FormGroup;

  @Input() public error: string;

  @Input() public loading: boolean;

  constructor(private fb: FormBuilder, public fileIntNumericService: FileIntNumericService) {
  }


  public ngOnInit(): void {

    this.fileForm = this.fb.group({
      name: ['', Validators.required],
      url: ['', Validators.required],
      separator: [this.fileIntNumericService.separator[0].id],
      typeofAuth: [this.fileIntNumericService.typeofAuth[0].id],
      login: [''],
      password: [''],
    });
  }

  public onSeparatorChange(event: any): void {
    const selectedSeparatorId = event.value;
    const selectedSeparator = this.fileIntNumericService.separator.find(item => item.id === selectedSeparatorId);
    if (selectedSeparator) {
      this.fileForm.get('separator').setValue(selectedSeparator.id);
    }
  }

  public onTypeofAuthChange(event: any): void {
    const selectedTypeofAuthId = event.value;
    const selectedTypeofAuth = this.fileIntNumericService.typeofAuth.find(item => item.id === selectedTypeofAuthId);
    if (selectedTypeofAuth) {
      this.fileForm.get('typeofAuth').setValue(selectedTypeofAuth.id);
    }
  }
}
