export interface Subscription {
  plan: string;
  expiration: Date;
  state: SubscriptionState;
}

export enum SubscriptionState {
  Paid = 'Activated',
  Expired = 'Deactivated',
}
