import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {InputTextModule} from 'primeng/inputtext';
import {ProgressBarModule} from 'primeng/progressbar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

import {AuthRoutingModule} from './auth-routing.module';
import {SignInComponent} from './components/sign-in/sign-in.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import { AuthComponent } from './auth.component';
import {SharedModule} from '../shared/shared.module';
import { environment } from '../../../environments/environment';


@NgModule({
  declarations: [SignInComponent, ResetPasswordComponent, AuthComponent],
    imports: [
        CommonModule,
        AuthRoutingModule,
        ReactiveFormsModule,
        TooltipModule,
        InputTextModule,
        ButtonModule,
        MessagesModule,
        MessageModule,
        ProgressSpinnerModule,
        ProgressBarModule,
        SharedModule,
        RecaptchaV3Module,
    ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.googleRecapchaSiteKey,
    },
  ],
})
export class AuthModule {
}
