import { Injectable } from '@angular/core';

interface PropertyOption {
  id: string;
  name: string;
}
@Injectable({
  providedIn: 'root'
})
export class FileIntNumericService {

  public separator: PropertyOption[] = [
    { id: 'tsv', name: 'tsv (\\t)' },
    { id: 'csv_semicolon', name: 'semicolon (;)' },
    { id: 'csv_comma', name: 'csv(,)' },
  ];

  public typeofAuth: PropertyOption[] = [
    { id: 'public_access', name: 'Public access' },
    { id: 'basic_auth', name: 'Basic auth' },
  ];
}
