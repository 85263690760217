import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-active-campaign-auth',
  templateUrl: './active-campaign-auth.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class ActiveCampaignAuthComponent implements OnInit {
  @Input()
  public error: string;

  @Input()
  public apiUrl: string;

  @Input()
  public apiKey: string;

  @Input()
  public loading: boolean;

  constructor() {}

  public ngOnInit(): void {}
}
