import * as _ from 'lodash';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';

import { SettingsBaseComponent } from '../settings-base/settings-base.component';
import { RentaDropdownDataSource } from '../../../../../shared/models/DataSource/RentaDropdownDataSource';
import { RentaChipsDataSource } from '../../../../../shared/models/DataSource/RentaChipsDataSource';
import { RentaMultiselectDropdownDataSource } from '../../../../../shared/models/DataSource/RentaMultiselectDropdownDataSource';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { ErrorMessage } from '../../../../../../models/common/ErrorMessage';
import { getSelectedChips } from '../../../../../../helpers/helpers';
import { ListItemModel } from '../../../../../../models/Integration/ListItemModel';
import { ChipsModel } from '../../../../../shared/models/ChipsModel';
import { SocialBakersSettingsService } from '../../../../services/integrationSettings/social-bakers-settings.service';
import { IntegrationSettings } from '../../../../../../models/Integration/IntegrationSettings';

@Component({
  templateUrl: './social-bakers-settings.component.html',
  styleUrls: ['../common-settings.scss']
})
export class SocialBakersSettingsComponent extends SettingsBaseComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;
  public ReportTypeDataSource: RentaDropdownDataSource;
  public SourceTypeDataSource: RentaDropdownDataSource;
  public ProfilesDataSource: RentaMultiselectDropdownDataSource;
  public PostsDataSource: RentaMultiselectDropdownDataSource;
  public ParametersDataSource: RentaMultiselectDropdownDataSource;
  public chipsProfilesDataSource: RentaChipsDataSource;
  public chipsPostsDataSource: RentaChipsDataSource;
  public chipsParametersDataSource: RentaChipsDataSource;
  public showPosts: boolean = false;

  constructor(private settingsService: SocialBakersSettingsService) {
    super(IntegrationTypeEnum.socialBakers);
    this.isLoaded = false;
  }

  public ngOnInit(): void {
    this.ReportTypeDataSource = new RentaDropdownDataSource('Report Type', []);
    this.SourceTypeDataSource = new RentaDropdownDataSource('Source Type', []);
    this.ProfilesDataSource = new RentaMultiselectDropdownDataSource('Profiles', []);
    this.PostsDataSource = new RentaMultiselectDropdownDataSource('Filter by post labels', []);
    this.ParametersDataSource = new RentaMultiselectDropdownDataSource('Parameters', []);
    this.chipsProfilesDataSource = new RentaChipsDataSource('Selected Profiles', []);
    this.chipsPostsDataSource = new RentaChipsDataSource('Selected Filter by post labels', []);
    this.chipsParametersDataSource = new RentaChipsDataSource('Selected Parameters', []);

    this.chipsProfilesDataSource.connect().subscribe((res: Array<ChipsModel>): void => {
      const selectedParameters = res.map<ListItemModel>((m: ChipsModel): ListItemModel => {
        return { id: m.id, name: m.title, group: m.type };
      });

      this.settingsService.setProfiles(selectedParameters);
    });

    this.chipsPostsDataSource.connect().subscribe((res: Array<ChipsModel>): void => {
      const selectedParameters = res.map<ListItemModel>((m: ChipsModel): ListItemModel => {
        return { id: m.id, name: m.title, group: m.type };
      });

      this.settingsService.setPosts(selectedParameters);
    });

    this.connectParamsChipsDS(this.chipsParametersDataSource, this.settingsService);

    this.settingsService.onError.subscribe((errors: Array<ErrorMessage>): void => {
      this.onError(errors);
    });

    this.settingsService.connectToSourceDestinationLoading().subscribe((res: boolean): void => {
      this.ReportTypeDataSource.setLoading(res);
      this.SourceTypeDataSource.setLoading(res);
      this.ProfilesDataSource.setLoading(res);
      this.PostsDataSource.setLoading(res);
      this.ParametersDataSource.setLoading(res);
      this.chipsProfilesDataSource.setLoading(res);
      this.chipsPostsDataSource.setLoading(res);
      this.chipsParametersDataSource.setLoading(res);
    });

    this.settingsService.loadSettings().subscribe((res: IntegrationSettings): void => {
      this.ReportTypeDataSource.refresh(_.cloneDeep(res[this.integrationType].ReportTypes));
      this.SourceTypeDataSource.refresh(_.cloneDeep(res[this.integrationType].SourceType));
      this.ProfilesDataSource.refresh(_.cloneDeep(res[this.integrationType].Profiles));
      this.PostsDataSource.refresh(_.cloneDeep(res[this.integrationType].Posts));
      this.ParametersDataSource.refresh(_.cloneDeep(res[this.integrationType].Parameters));

      const chipsProfilesDs = getSelectedChips(res[this.integrationType].Profiles);
      this.chipsProfilesDataSource.refresh(chipsProfilesDs);
      const chipsPostsDs = getSelectedChips(res[this.integrationType].Posts);
      this.chipsPostsDataSource.refresh(chipsPostsDs);
      const chipsParamsDs = getSelectedChips(res[this.integrationType].Parameters);
      this.chipsParametersDataSource.refresh(chipsParamsDs);
      this.settingsService.initCache();

      this.setRelatedDataSources([
        this.ReportTypeDataSource,
        this.SourceTypeDataSource,
        this.ParametersDataSource,
        this.PostsDataSource
      ]);

      this.showPosts = this.ReportTypeDataSource.getSelectedItem().name === 'Posts';

      setTimeout((): void => {
        this.isLoaded = true;
      }, 1000);
    });
  }

  public ngOnDestroy(): void {
    this.settingsService.deleteDataFromCache();
  }

  public onApplyProfiles($event: Array<ListItemModel>): void {
    this.settingsService.setProfiles($event);

    const chipsDs = $event.map<ChipsModel>((item: ListItemModel): ChipsModel => {
      return { title: item.name, type: item.group, id: item.id, order: 0 };
    });

    this.chipsProfilesDataSource.refresh(chipsDs);
  }

  public onApplyReportType($event: ListItemModel): void {
    if (!this.settingsService.setReportType($event)) {
      return;
    }

    if ($event.name === 'Posts') {
      this.showPosts = true;
      this.PostsDataSource.setLoading(true);
      this.settingsService
        .getPosts()
        .pipe(delay(250))
        .subscribe((res: Array<ListItemModel>): void => {
          this.PostsDataSource.setLoading(false);
          this.refreshRelatedDataSources();
          if (res.length > 0) {
            this.PostsDataSource.refresh(_.cloneDeep(res));
            this.chipsPostsDataSource.refresh(getSelectedChips(res));
          }
        });
    } else {
      this.showPosts = false;
      this.PostsDataSource.refresh([]);
      this.chipsPostsDataSource.refresh(getSelectedChips([]));
    }

    this.ParametersDataSource.setLoading(true);
    this.refreshRelatedDataSources();
    this.settingsService
      .getParameters($event, null)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(_.cloneDeep(res));
          this.chipsParametersDataSource.refresh(getSelectedChips(res));
        }
      });
  }

  public onApplySourceType($event: ListItemModel): void {
    if (!this.settingsService.setSourceType($event)) {
      return;
    }

    this.ParametersDataSource.setLoading(true);
    this.ProfilesDataSource.setLoading(true);
    this.refreshRelatedDataSources();

    this.settingsService
      .getProfiles($event)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ProfilesDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.ProfilesDataSource.refresh(_.cloneDeep(res));
          this.chipsProfilesDataSource.refresh(getSelectedChips(res));
        }
      });

    this.settingsService
      .getParameters(null, $event)
      .pipe(delay(250))
      .subscribe((res: Array<ListItemModel>): void => {
        this.ParametersDataSource.setLoading(false);
        this.refreshRelatedDataSources();
        if (res.length > 0) {
          this.settingsService.compareParametersWithCacheData(res);
          this.ParametersDataSource.refresh(_.cloneDeep(res));
          this.chipsParametersDataSource.refresh(getSelectedChips(res));
        }
      });
  }

  public onApplyPosts($event: Array<ListItemModel>): void {
    this.settingsService.setPosts($event);

    const chipsDs = $event.map<ChipsModel>((item: ListItemModel): ChipsModel => {
      return { title: item.name, type: item.group, id: item.id, order: 0 };
    });

    this.chipsPostsDataSource.refresh(chipsDs);
  }

  public onApplyParameters($event: Array<ListItemModel>): void {
    this.onApplyParams($event, this.chipsParametersDataSource);
    this.settingsService.setDataToCache();
  }

  public onCloseProfilesChip(e: ChipsModel): void {
    this.ProfilesDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
  }

  public onClosePostsChip(e: ChipsModel): void {
    this.PostsDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
  }

  public onCloseChip(e: ChipsModel): void {
    this.ParametersDataSource.applyUncheck({ id: e.id, group: e.type, name: e.title });
    setTimeout((): void => {
      this.settingsService.setDataToCache();
    });
  }
}
