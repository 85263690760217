import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { delay, tap } from 'rxjs/operators';

import { RentaModalsService } from '../../../shared/services/renta-modals.service';
import { IntegrationSelectBaseComponent } from '../../../shared/components/internal/integration-select-base/integration-select-base.component';
import { CreateIntegrationService } from '../../services/create-integration.service';
import { IntegrationSourceModel } from '../../../../models/Integration/IntegrationSourceModel';
import { IntegrationToken } from '../../../../models/Integration/IntegrationToken';
import { Observable } from 'rxjs';
import { UserService } from '../../../../services/user.service';
import { ProfileService } from '../../../../services/profile.service';
import { IntegrationRefreshService } from '../../services/integration-refresh.service';

@Component({
  selector: 'app-integration-destination',
  templateUrl: './integration-destination.component.html',
  styleUrls: [
    '../../../shared/components/internal/integration-select-base/integration-select-base.css',
    './integration-destination.component.scss'
  ]
})
export class IntegrationDestinationComponent extends IntegrationSelectBaseComponent implements OnInit {
  @Output()
  public OnDataLoading: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  @Input()
  public sourceType: Observable<IntegrationSourceModel>;

  public openTabIndex: number
  constructor(
    protected readonly createIntegrationService: CreateIntegrationService,
    protected readonly userService: UserService,
    protected readonly profileService: ProfileService,
    public readonly modalDialogService: RentaModalsService,
    private integrationRefreshService: IntegrationRefreshService
  ) {
    super(modalDialogService, createIntegrationService, userService, profileService);
  }

  public ngOnInit(): void {
    this.OnDataLoading.emit(true);
    this.integrationRefreshService.refreshEvent.subscribe(() => {
      this.refreshAfterSubmit();
    });

    this.emailSubscription = this.profileService.getEmail().subscribe(email => {
      this.userEmail = email;

      if (this.userEmail !== null) {
        (window as any).renta.page('Destination list', {
          email: this.userEmail,
          userId: this.userService.getUserId()
        });
      }
    });

    this.sourceType.subscribe((st: IntegrationSourceModel): void => {
      if (st === null){
        this.OnDataLoading.emit(false);
        return;
      }

      this.createIntegrationService.getAvailableIntegrationDestinations(st.integrationType).subscribe((res: Array<IntegrationSourceModel>): void => {
        this.OnDataLoading.emit(false);
        this.integrations = res;
        this.availableIntegrations = this.integrations;
        this.accordionActiveState = new Array<boolean>(this.integrations.length).fill(false);
      });
    });
  }

  public onTabOpen($event: any): void {
    this.refresh($event.index, false);

    this.openTabIndex = $event.index;

  }

  protected refresh(index: number, isTokenAdded: boolean): void {
    const integrationToLoad = this.availableIntegrations[index];
    if (isTokenAdded) {
      (window as any).renta.track('Destination added', {
        source: integrationToLoad.title,
        userId: this.userService.getUserId(),
        email: this.userEmail,
      });
    }

    if (integrationToLoad.accounts !== 0 || integrationToLoad.tokens === null) {
      this.availableIntegrations[index].tokens = this.createIntegrationService.getIntegrationTokens(integrationToLoad.integrationType).pipe(
        delay(250),
        tap((res: Array<IntegrationToken>): void => {
          this.accordionActiveState[index] = res.length !== 0;
          this.availableIntegrations[index].accounts = res.length;
        })
      );
    }
  }

  protected refreshAfterSubmit(index: number =  this.openTabIndex): void {
   const integrationToLoad = this.availableIntegrations[index];
   this.availableIntegrations[index].tokens = this.createIntegrationService.getIntegrationTokens(integrationToLoad.integrationType).
    pipe(
      delay(250), tap((res: Array<IntegrationToken>): void => {
          this.accordionActiveState[index] = res.length !== 0;
          this.availableIntegrations[index].accounts = res.length;
        })
      );
  }
}
