<div class="setting-row-wrapper p-m-0">
  <app-renta-dropdown *ngIf="isLoaded; else loadingSetting"
                      [RentaDropdownSource]="DatabaseDataSource"
                      [class.disabled]="isDataSourceLoading"
                      (OnApply)="onApplyDatabases($event)"></app-renta-dropdown>
</div>
<div class="setting-row-wrapper">
  <app-renta-dropdown
    *ngIf="isLoaded; else loadingSetting"
    (OnApply)="onApplyParameter($event)"
    [RentaDropdownSource]="ParametersDataSource">
  </app-renta-dropdown>
</div>
<ng-template #loadingSetting>
  <p-skeleton styleClass="p-mb-2" width="120px" height="24px" borderRadius="8px"></p-skeleton>
  <p-skeleton styleClass="p-mb-3" height="37px" borderRadius="8px"></p-skeleton>
</ng-template>
