import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { MenuItemRoleEnum } from '../../../../models/common/MenuItemRoleEnum';
import { MenuItemPopupModel } from '../../models/MenuItemPopupModel';
import { MenuPopupModel } from '../../models/MenuPopupModel';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-popup',
  templateUrl: './menu-popup.component.html',
  styleUrls: ['./menu-popup.component.scss'],
})
export class MenuPopupComponent implements AfterViewInit {
  @Input()
  public menuPopupData: MenuPopupModel;

  @Input()
  public selfDestruction: () => void;

  @Input()
  public setPosition: (popupMenu: ElementRef) => void;

  @ViewChild('customHeader', { read: ViewContainerRef })
  public customHeader: ViewContainerRef;

  @ViewChild('popupMenu')
  public popupMenu: ElementRef;

  constructor(private eRef: ElementRef, private readonly router: Router) {}

  public ngAfterViewInit(): void {
    this.setPosition(this.popupMenu);

    if (this.menuPopupData.headerComponent) {
      this.setHeaderCustomItem();
    }
  }

  public onClickHandler(event: MouseEvent): void {
    event.stopPropagation();
  }

  public onClick(event: MouseEvent, menu: MenuItemPopupModel): void {
    if (!_.isNil(menu.routerLink) && menu.routerLink.length) {
      this.navigateTo(menu.routerLink);
    }

    if (!_.isNil(menu.command)) {
      menu.command();
    }

    if (!_.isNil(menu.link)) {
      window.open(menu.link);
    }

    this.selfDestruction();
    event.stopPropagation();
  }

  public navigateTo(refUrl: string): void {
    if (this.router.url !== refUrl) {
      this.router.navigate([refUrl]).then();
    }

    this.selfDestruction();
  }

  public isMenuItemStandart(menu: MenuItemPopupModel): boolean {
    if (_.isNil(menu.type)) {
      menu.type = MenuItemRoleEnum.standard;
    }

    return menu.type === MenuItemRoleEnum.standard;
  }

  public isMenuItemCustom(menu: MenuItemPopupModel): boolean {
    if (_.isNil(menu.type)) {
      menu.type = MenuItemRoleEnum.standard;
    }

    return menu.type === MenuItemRoleEnum.custom;
  }

  public isMenuItemTitle(menu: MenuItemPopupModel): boolean {
    if (_.isNil(menu.type)) {
      menu.type = MenuItemRoleEnum.standard;
    }

    return menu.type === MenuItemRoleEnum.title;
  }

  public isMenuItemDivider(menu: MenuItemPopupModel): boolean {
    if (_.isNil(menu.type)) {
      menu.type = MenuItemRoleEnum.standard;
    }

    return menu.type === MenuItemRoleEnum.divider;
  }

  @HostListener('document:click', ['$event'])
  private clickout(event: MouseEvent): void {
    if (!this.eRef.nativeElement.contains(event.target) && !this.eRef.nativeElement.parentNode.contains(event.target)) {
      this.selfDestruction();
    }
  }

  private setHeaderCustomItem(): void {
    setTimeout((): void => {
      this.customHeader.createComponent(this.menuPopupData.headerComponent, 0);
    });
  }
}
