<p-progressBar mode="indeterminate" styleClass="modal-preloader" *ngIf="checkMarkInfo === null"></p-progressBar>
<div class="p-d-flex p-flex-column p-ai-center p-jc-center dialog-wrapper">
  <div class="p-d-flex p-flex-column p-p-6" *ngIf="checkMarkInfo === null">
    <span class="info">{{integration.title}} integration is creating, please wait...</span>
  </div>
  <div class="checkmark-wrapper" *ngIf="checkMarkInfo !== null">
    <app-checkmark [Success]="checkMarkInfo.success" [Title]="checkMarkInfo.title"
                   [TitleDescription]="checkMarkInfo.info"></app-checkmark>
  </div>
</div>

