import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RentaApiService } from '../services/renta-api.service';
import { ProductionLogsRun, ProductionLogsRunActions, RunData } from './models/request/IntegrationOverviewPayloads';
import { IntegrationNotificationService } from '../services/signalR/services/integration-notification.service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IntegrationEventType } from '../services/signalR/models/IntegrationEventType';
import { IntegrationEvent } from '../services/signalR/models/events/integration/IntegrationEvent';
import { Title } from '@angular/platform-browser';

type GroupedRuns = { [key: string]: ProductionLogsRun[] };

@Component({
  selector: 'app-integration-overview',
  templateUrl: './integration-overview.component.html',
  styleUrls: ['./integration-overview.component.scss']
})
export class IntegrationOverviewComponent implements OnInit, OnDestroy {
  public integrationId: string | null = null;
  public groupedRuns$: BehaviorSubject<GroupedRuns> = new BehaviorSubject<GroupedRuns>({});
  public selectedActions$: BehaviorSubject<ProductionLogsRunActions[]> = new BehaviorSubject<ProductionLogsRunActions[]>([]);
  public metadata: RunData | null = null;
  public selectedActionData: any = null;
  public selectedActionId: any | null = null;
  public errorMessage: string | null = null;
  public runsLoading: boolean = true;
  public actionsLoading: boolean = false;
  public selectedRunId: string | null = null;

  private eventSubscription: Subscription | null = null;

  constructor(
    private route: ActivatedRoute,
    private rentaApiService: RentaApiService,
    private integrationNotificationService: IntegrationNotificationService,
    private titleService: Title
) {}

  public ngOnInit(): void {
    this.titleService.setTitle('Integration logs | Renta');
    this.integrationId = this.route.snapshot.paramMap.get('id');

    if (this.integrationId) {
      this.getProductionLogsRuns(this.integrationId).subscribe(
        runs => {
          const groupedRuns = this.groupByDate(runs);
          this.groupedRuns$.next(groupedRuns);
          this.runsLoading = false;

          // Automatically select the first run if available
          if (runs && runs.length > 0) {
            const firstRun = runs[0];
            this.selectRun(firstRun);
          }
        },
        error => {
          console.error('Error in getProductionLogsRuns:', error);
          this.errorMessage = 'An error occurred while loading runs.';
          this.runsLoading = false;
        }
      );

      this.subscribeToIntegrationEvents();
    } else {
      this.runsLoading = false;
    }
  }

  public ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  public getProductionLogsRuns(integrationId: string): Observable<ProductionLogsRun[]> {
    return this.rentaApiService.getProductionLogsRuns(integrationId).pipe(
      map(response => {
        if (typeof response === 'string') {
          this.errorMessage = response;
          console.error('Error loading runs:', response);
          return [];
        }
        this.errorMessage = null;
        return response.map(run => ({
          run_id: run.run_id,
          data: {
            ...run.data,
            date: new Date(run.data.date)
          }
        }));
      }),
      catchError(() => {
        this.errorMessage = 'An error occurred while loading runs.';
        return of([]);
      })
    );
  }

  public selectRun(run: ProductionLogsRun): void {
    this.selectedRunId = run.run_id;
    this.metadata = run.data;
    if (this.integrationId && run.run_id) {
      this.actionsLoading = true;
      this.getProductionLogsRunActions(this.integrationId, run.run_id).subscribe(
        actions => {
          this.selectedActions$.next(actions);
          this.actionsLoading = false;
        },
        error => {
          console.error('Error in getProductionLogsRunActions:', error);
          this.errorMessage = 'An error occurred while loading actions.';
          this.actionsLoading = false;
        }
      );
    }
  }

  public getProductionLogsRunActions(integrationId: string, runId: string): Observable<ProductionLogsRunActions[]> {
    return this.rentaApiService.getProductionLogsRunActions(integrationId, runId).pipe(
      map(response => {
        if (typeof response === 'string') {
          this.errorMessage = response;
          console.error('Error loading actions:', response);
          return [];
        }
        this.errorMessage = null;
        return response;
      }),
      catchError(error => {
        console.error('Error in getProductionLogsRunActions:', error);
        this.errorMessage = 'An error occurred while loading actions.';
        return of([]);
      })
    );
  }

  public selectAction(action: ProductionLogsRunActions): void {
    this.selectedActionData = action.data;
    this.selectedActionId = action.action_id;
  }

  public closeActionDetails(): void {
    this.selectedActionData = null;
  }

  private subscribeToIntegrationEvents(): void {
    // Subscribe to IntegrationFinished event
    this.integrationNotificationService
      .onEvent<IntegrationEvent>(IntegrationEventType.IntegrationFinished)
      .subscribe(event => {
        // Update the status of the run in groupedRuns$
        const currentGroupedRuns = this.groupedRuns$.value;
        const updatedGroupedRuns = { ...currentGroupedRuns };

        let runFound = false;
        for (const dateKey of Object.keys(updatedGroupedRuns)) {
          const runs = updatedGroupedRuns[dateKey];
          const runIndex = runs.findIndex(run => run.run_id === event.runId);
          if (runIndex !== -1) {
            runs[runIndex].data.status = 'success';
            runs[runIndex].data.run_finish_date_time = event.runFinishDatetime;
            runFound = true;
            break;
          }
        }

        if (runFound) {
          this.groupedRuns$.next(updatedGroupedRuns);
        } else {
          console.error(`Run with id ${event.runId} not found in groupedRuns$.`);
        }

        // Update metadata if the run is selected
        if (this.selectedRunId === event.runId && this.metadata) {
          this.metadata.status = 'success';
        }
      });

    // Subscribe to IntegrationProgressUpdated event
    this.integrationNotificationService
      .onEvent<IntegrationEvent>(IntegrationEventType.IntegrationProgressUpdated)
      .subscribe(event => {

        // Update the rows_extracted of the run in groupedRuns$
        const currentGroupedRuns = this.groupedRuns$.value;
        const updatedGroupedRuns = { ...currentGroupedRuns };

        let runFound = false;
        for (const dateKey of Object.keys(updatedGroupedRuns)) {
          const runs = updatedGroupedRuns[dateKey];
          const runIndex = runs.findIndex(run => run.run_id === event.runId);
          if (runIndex !== -1) {
            runs[runIndex].data.rows_extracted = event.downloadedNumberRows ?? 0;
            runFound = true;
            break;
          }
        }

        if (runFound) {
          this.groupedRuns$.next(updatedGroupedRuns);
          console.log(`Updated rows_extracted for run ${event.runId}.`);
        } else {
          console.error(`Run with id ${event.runId} not found in groupedRuns$.`);
        }

        // Update metadata if the run is selected
        if (this.selectedRunId === event.runId && this.metadata) {
          this.metadata.rows_extracted = event.downloadedNumberRows ?? 0;
        }
      });

    this.integrationNotificationService
      .onEvent<IntegrationEvent>(IntegrationEventType.IntegrationStarted)
      .subscribe(event => {
        console.log('Получено событие IntegrationStarted:', event);

        const newRun: ProductionLogsRun = {
          run_id: event.runId,
          data: {
            date: new Date(event.timeStamp),
            status: 'in process',
            run_type: event.runType,
            action_time: event.timeStamp,
            action_time_str: event.actionTimeStr,
            run_started: event.timeStamp
          }
        };

        const currentGroupedRuns = this.groupedRuns$.value;
        const updatedGroupedRuns = { ...currentGroupedRuns };
        const dateKey = newRun.data?.date.toISOString().split('T')[0];

        if (dateKey) {
          if (!updatedGroupedRuns[dateKey]) {
            updatedGroupedRuns[dateKey] = [];
          }
          updatedGroupedRuns[dateKey].unshift(newRun);
          // Обновляем значение BehaviorSubject
          this.groupedRuns$.next(updatedGroupedRuns);
        } else {
          console.error('The date key is undefined');
        }
      });

    // Подписка на получение нового action для определенного runId
    this.integrationNotificationService
      .onEvent<IntegrationEvent>(IntegrationEventType.IntegrationRunActionReceived)
      .subscribe(event => {
        // Проверяем, относится ли action к выбранному run
        if (event.runId === this.selectedRunId) {
          // Создаем новый action
          const newAction: ProductionLogsRunActions = {
            action_id: event.actionId.toString(),
            type: event.actionType,
            run_id: event.runId,
            created_at: event.timeStamp,
            data: event.actionData
          };

          // Получаем текущий список actions
          const currentActions = this.selectedActions$.value;
          // Добавляем новый action в начало списка
          const updatedActions = [newAction, ...currentActions];
          // Обновляем BehaviorSubject
          this.selectedActions$.next(updatedActions);
        }
      });
  }

  private groupByDate(runs: ProductionLogsRun[]): { [key: string]: ProductionLogsRun[] } {
    const grouped: { [key: string]: ProductionLogsRun[] } = {};

    runs.forEach(run => {
      const date: string = run.data.date.toISOString().split('T')[0];
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(run);
    });
    return grouped;
  }
}
