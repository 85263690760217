import * as moment from 'moment';
import { Moment } from 'moment/moment';

import { DataSourceBase } from './DataSourceBase';
import { DateRangeDataSourceModel } from './DataSourceModels/DateRangeDataSourceModel';
import { SelectedDateRangeViewModel } from '../../../../models/viewModels/SelectedDateRangeViewModel';

export enum DateRangeEnum {
  today = '0' as any,
  yesterday = '1' as any,
  Last_7_days = '7' as any,
  Last_14_days = '14' as any,
  Last_month = '30' as any,
  Last_2_month = '60' as any,
  Last_6_month = '180' as any,
  Last_year = '365' as any,
  Custom_date = '-1' as any,
  Custom_today = '-2' as any,
  Custom_yesterday = '-3' as any,
}

export enum CustomDateRangeViewModelEnum {
  custom = 'Custom',
  toToday = 'To Today',
  untilToday = 'Until Today',
  toYesterday = 'To Yesterday',
  untilYesterday = 'Until Yesterday',
  last7Days = 'Last week',
  last14Days = 'Last 2 weeks',
  lastMonth = 'Last month',
  last2Month = 'Last 2 months',
  last6Month = 'Last 6 months',
  lastYear = 'Last year',
}

export class RentaDateRangeDataSource extends DataSourceBase<DateRangeDataSourceModel> {
  public name: string;
  private customDateRangeVieModelMapping: { [key: string]: DateRangeEnum } = {};
  private customDateRangeMapping: { [key: number]: CustomDateRangeViewModelEnum } = {};

  constructor(name: string, from: string, to: string, dateRangeEnum: DateRangeEnum) {
    super(null);
    this.name = name;
    this.initEnums();
    this.refreshDataSource(from, to, dateRangeEnum);
  }

  public getSelectedDateRange(): SelectedDateRangeViewModel {
    const ds = this.dataSource.getValue();
    const from = moment(ds.range[0]).format('yyyy-MM-DD');
    let to = moment(ds.range[1]).format('yyyy-MM-DD');

    if (this.customDateRangeVieModelMapping[ds.DateRangeEnum] === DateRangeEnum.Custom_yesterday) {
      to = 'Yesterday';
    } else if (this.customDateRangeVieModelMapping[ds.DateRangeEnum] === DateRangeEnum.Custom_today) {
      to = 'Today';
    }

    const dateRangeEnumValue = this.customDateRangeVieModelMapping[ds.DateRangeEnum];

    return {
      from,
      to,
      dateRangeEnumValue,
      dateRangeEnumTitle: DateRangeEnum[dateRangeEnumValue],
    } as SelectedDateRangeViewModel;
  }

  public updateDateRange(customDateRangeViewModelEnum: CustomDateRangeViewModelEnum): void {
    const d = this.dataSource.getValue();
    const dateRangeEnum = this.customDateRangeVieModelMapping[customDateRangeViewModelEnum];
    this.refreshDataSource(d.range[0]?.toString(), d.range[1]?.toString(), dateRangeEnum);
  }

  public initEnums(): void {
    this.customDateRangeVieModelMapping[CustomDateRangeViewModelEnum.toYesterday] = DateRangeEnum.yesterday;
    this.customDateRangeVieModelMapping[CustomDateRangeViewModelEnum.custom] = DateRangeEnum.Custom_date;
    this.customDateRangeVieModelMapping[CustomDateRangeViewModelEnum.last2Month] = DateRangeEnum.Last_2_month;
    this.customDateRangeVieModelMapping[CustomDateRangeViewModelEnum.last6Month] = DateRangeEnum.Last_6_month;
    this.customDateRangeVieModelMapping[CustomDateRangeViewModelEnum.last14Days] = DateRangeEnum.Last_14_days;
    this.customDateRangeVieModelMapping[CustomDateRangeViewModelEnum.last7Days] = DateRangeEnum.Last_7_days;
    this.customDateRangeVieModelMapping[CustomDateRangeViewModelEnum.lastMonth] = DateRangeEnum.Last_month;
    this.customDateRangeVieModelMapping[CustomDateRangeViewModelEnum.toToday] = DateRangeEnum.today;
    this.customDateRangeVieModelMapping[CustomDateRangeViewModelEnum.lastYear] = DateRangeEnum.Last_year;
    this.customDateRangeVieModelMapping[CustomDateRangeViewModelEnum.untilToday] = DateRangeEnum.Custom_today;
    this.customDateRangeVieModelMapping[CustomDateRangeViewModelEnum.untilYesterday] = DateRangeEnum.Custom_yesterday;

    this.customDateRangeMapping[DateRangeEnum.yesterday] = CustomDateRangeViewModelEnum.toYesterday;
    this.customDateRangeMapping[DateRangeEnum.Custom_date] = CustomDateRangeViewModelEnum.custom;
    this.customDateRangeMapping[DateRangeEnum.Last_2_month] = CustomDateRangeViewModelEnum.last2Month;
    this.customDateRangeMapping[DateRangeEnum.Last_6_month] = CustomDateRangeViewModelEnum.last6Month;
    this.customDateRangeMapping[DateRangeEnum.Last_14_days] = CustomDateRangeViewModelEnum.last14Days;
    this.customDateRangeMapping[DateRangeEnum.Last_7_days] = CustomDateRangeViewModelEnum.last7Days;
    this.customDateRangeMapping[DateRangeEnum.Last_month] = CustomDateRangeViewModelEnum.lastMonth;
    this.customDateRangeMapping[DateRangeEnum.today] = CustomDateRangeViewModelEnum.toToday;
    this.customDateRangeMapping[DateRangeEnum.Last_year] = CustomDateRangeViewModelEnum.lastYear;
    this.customDateRangeMapping[DateRangeEnum.Custom_today] = CustomDateRangeViewModelEnum.untilToday;
    this.customDateRangeMapping[DateRangeEnum.Custom_yesterday] = CustomDateRangeViewModelEnum.untilYesterday;
  }

  public refreshDataSource(fromStr: string, toStr: string, dateRangeEnum: DateRangeEnum): void {
    let to: Moment = null;
    let from: Moment = null;

    dateRangeEnum = RentaDateRangeDataSource.convertDateRangeEnum(toStr, dateRangeEnum);

    switch (dateRangeEnum) {
      case DateRangeEnum.today:
        to = moment();
        from = moment();
        break;
      case DateRangeEnum.Custom_today: {
        to = moment();
        from = moment(fromStr);
        break;
      }
      case DateRangeEnum.yesterday:
        to = moment({}).add(-1, 'day');
        from = moment({}).add(-1, 'day');
        break;
      case DateRangeEnum.Custom_yesterday: {
        to = moment({}).add(-1, 'day');
        from = moment(fromStr);

        if (from.toDate() > to.toDate()) {
          const tmp = from;
          from = to;
          to = tmp;
        }
        break;
      }
      case DateRangeEnum.Last_7_days: {
        to = moment({}).add(-1, 'day');
        from = moment({}).add(-8, 'days');
        break;
      }
      case DateRangeEnum.Last_14_days: {
        to = moment({}).add(-1, 'day');
        from = moment({}).add(-15, 'days');
        break;
      }
      case DateRangeEnum.Last_month: {
        to = moment({}).add(-1, 'day');
        from = moment({}).add(-1, 'month');
        from = moment(from).add(-1, 'day');
        break;
      }
      case DateRangeEnum.Last_2_month: {
        to = moment({}).add(-1, 'day');
        from = moment({}).add(-2, 'months');
        from = moment(from).add(-1, 'day');
        break;
      }
      case DateRangeEnum.Last_6_month: {
        to = moment({}).add(-1, 'day');
        from = moment({}).add(-6, 'months');
        from = moment(from).add(-1, 'day');
        break;
      }
      case DateRangeEnum.Last_year: {
        to = moment({}).add(-1, 'day');
        from = moment({}).add(-1, 'year');
        from = moment(from).add(-1, 'day');
        break;
      }
      case DateRangeEnum.Custom_date: {
        to = toStr !== null && toStr !== undefined ? moment(toStr) : null;
        from = moment(fromStr);
        break;
      }
    }

    this.refreshDataSourceInternal(from, to, this.customDateRangeMapping[dateRangeEnum]);
  }

  private refreshDataSourceInternal(from: Moment, to: Moment, customDateRangeViewModelEnum: CustomDateRangeViewModelEnum): void {
    if (from === null) {
      return;
    }

    const ds = this.dataSource.getValue() || new DateRangeDataSourceModel();

    ds.DateRangeEnum = customDateRangeViewModelEnum;
    ds.dateFromTitle = from.format('ll');

    if (customDateRangeViewModelEnum === CustomDateRangeViewModelEnum.untilToday) {
      ds.dateToTitle = 'Today';
    } else if (customDateRangeViewModelEnum === CustomDateRangeViewModelEnum.untilYesterday) {
      ds.dateToTitle = 'Yesterday';
    } else {
      ds.dateToTitle = to?.format('ll');
    }

    if (ds.range === undefined || ds.range === null || customDateRangeViewModelEnum !== CustomDateRangeViewModelEnum.custom) {
      ds.range = new Array<Date>(2);
      ds.range = [from?.toDate(), to?.toDate()];
      this.refresh(ds);
      return;
    }

    ds.range[0] = from?.toDate();
    ds.range[1] = to?.toDate();

    this.dataSource.next(ds);
  }

  private static convertDateRangeEnum(toStr: string, dateRangeEnum: DateRangeEnum): DateRangeEnum {
    if (toStr === 'today') {
      return DateRangeEnum.Custom_today;
    }

    if (toStr === 'yesterday') {
      return DateRangeEnum.Custom_yesterday;
    }

    return dateRangeEnum;
  }
}
